import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
} from "react";

import LoaderScreen from "../components/LoaderScreen";
import {
  getProfileInfo,
  getUserData,
  getUserPackage,
  refreshAccessToken,
  updateProfileInfo,
} from "../services/ida";
import { getPartnerByUrl } from "../services/partners";
import {
  conversationKey,
  getCookie,
  getFromStorage,
  homeRedirectKey,
  partnerKey,
  removeCookie,
  removeFromStorage,
  setInStorage,
  userEmailKey,
  userKey,
} from "../services/storage";
import { dataDeepCopy } from "../services/utils";
import { ConvAnswer } from "../types/conversation";
import { IdaUserPackageType } from "../types/ida";
import {
  UserActionType,
  UserContextType,
  UserProfileType,
  UserType,
} from "../types/user";

const initialUser: UserType = {
  info: null,
  accessToken: "",
  expDate: 0,
  refreshToken: "",
};

const UserContext = createContext({} as UserContextType);
const UserDispatchContext = createContext<Dispatch<UserActionType> | null>(
  null
);

const userReducer = (state: UserType, action: UserActionType) => {
  switch (action.type) {
    case "set-data": {
      const age =
        new Date().getFullYear() -
        parseInt(action.payload.personNumber.slice(0, 4));
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        expDate: action.payload.expDate,
        profile: action.payload.profile,
        info: {
          ...state.info,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          personNumber: action.payload.personNumber,
          age: age,
        },
      };
    }
    case "refresh-token":
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        expDate: action.payload.expDate,
      };
    case "set-package":
      return {
        ...state,
        package: action.payload,
      };
    case "set-package-id":
      return {
        ...state,
        pkg: action.payload,
      };
    case "set-package-id-override":
      return {
        ...state,
        pkgOvr: action.payload,
      };
    case "set-conversation":
      return {
        ...state,
        data: {
          ...state.data,
          conv: action.payload,
        },
      };
    case "set-profile":
      return {
        ...state,
        profile: action.payload,
      };
    case "set-from-storage":
      return {
        ...state,
        ...action.payload,
      };
    case "clear":
      return initialUser;
    default:
      return state;
  }
};

export const UserProvider = ({ children }: PropsWithChildren) => {
  const [user, dispatch] = useReducer(userReducer, initialUser);

  const populateFromStorage = () => {
    const localUser = getFromStorage(userKey, initialUser);
    const data = dataDeepCopy(localUser);

    // force fetch data from api
    // data.accessTokenExpires = 0;

    if (data) {
      dispatch({ type: "set-from-storage", payload: data });
    }
  };
  const saveToStorage = () => {
    setInStorage(userKey, user);
  };
  const checkExpiredToken = () => {
    if (!user.accessToken) {
      return false;
    }
    if (user && Date.now() >= user.expDate) {
      return false;
      // logout();
      // console.log("token invalid", user);
      // refreshAccessToken(user.accessToken, user.refreshToken)
      //   .then((data) => {
      //     dispatch({
      //       type: "refresh-token",
      //       payload: {
      //         accessToken: data.accessToken,
      //         refreshToken: data.refreshToken,
      //         expDate: Date.now() + parseInt(data.expiresIn) * 1000,
      //       },
      //     });
      //   })
      //   .catch((e) => console.log(e));
    }
    return true;
  };
  const fetchPackageData = (accessToken: string) => {
    return getUserPackage(accessToken)
      .then((data: IdaUserPackageType[]) => {
        if (data.length >= 1) {
          const pRaw = data[data.length - 1];
          const payload = {
            pid: pRaw.id,
            sid: pRaw.stripeSubscriptionId,
            status: pRaw.stripeSubscriptionStatus,
            contracts: pRaw.fenixServices,
            caseId: pRaw.caseId,
          };

          dispatch({
            type: "set-package",
            payload,
          });
          return payload;
        }
      })
      .catch((e) => console.log(e));
  };
  const fetchProfileData = (accessToken: string) => {
    return getProfileInfo(accessToken)
      .then((data: UserProfileType) => {
        dispatch({
          type: "set-profile",
          payload: data,
        });
        return data;
      })
      .catch((e) => console.log(e));
  };
  const fetchUserData = (accessToken: string) => {
    return false;
    // return getUserData(accessToken)
    //   .then((data) => {
    //     const convRaw = data.find((el) => el.key === "conv");
    //     if (convRaw) {
    //       const convData = JSON.parse(convRaw.value);
    //       setInStorage(conversationKey, convData);
    //       dispatch({ type: "set-conversation", payload: convData });
    //       convDispatch({ type: "set-from-storage", payload: convData });
    //       return convData;
    //     }
    //   })
    //   .catch((e) => console.log(e));
  };
  const updateProfile = (accessToken: string, data: UserProfileType) => {
    return updateProfileInfo(accessToken, data)
      .then((data: UserProfileType) => {
        dispatch({
          type: "set-profile",
          payload: data,
        });
        return data;
      })
      .catch((e) => console.log(e));
  };
  const setPackageId = (values: ConvAnswer[]) => {
    let pkgId = 3;

    const pk = getCookie(partnerKey);
    const partner = getPartnerByUrl(pk);
    if (partner && partner.pkg) {
      pkgId = partner.pkg;
    }

    if (user?.pkgOvr && process.env.NODE_ENV === "development") {
      pkgId = user.pkgOvr;
    }

    dispatch({ type: "set-package-id", payload: pkgId });
  };
  const logout = () => {
    dispatch({ type: "clear" });
    removeFromStorage(userKey);
    removeFromStorage(conversationKey);
    removeFromStorage(userEmailKey);
    removeCookie(userEmailKey);
    const redirectUrl = getFromStorage(homeRedirectKey, "/");
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  const obj = {
    user,
    populateFromStorage,
    saveToStorage,
    fetchPackageData,
    fetchProfileData,
    fetchUserData,
    checkExpiredToken,
    setPackageId,
    updateProfile,
    logout,
  };

  return (
    <UserContext.Provider value={obj}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
};

export function useUserContext() {
  return useContext(UserContext);
}

export function useUserDispatchContext() {
  return useContext(UserDispatchContext);
}

export function RequireUserAuth({ children }: PropsWithChildren) {
  const { user, checkExpiredToken, logout } = useUserContext();

  useEffect(() => {
    if (!user.accessToken) {
      return;
    }
    const isValid = checkExpiredToken();
    if (!isValid) {
      logout();
    }
  }, [user]);

  // if (!user) {
  //   return <LoaderScreen />;
  // }

  return children;
}
