import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Sticky from "react-stickynode";

import { articles, faqBlock, steps } from "../assets/data/datalist";
import Accordion from "../components/Accordion";
import QuoteArticle from "../components/articles/QuoteArticle";
import TableArticle from "../components/articles/TableArticle";
import TipsArticle from "../components/articles/TipsArticle";
import TrustboxArticle from "../components/articles/TrustboxArticle";
import Footer from "../components/Footer";
import HeadMenu from "../components/HeadMenu";
import ArticlesSlider from "../components/slider/ArticlesSlider";
import VideoItem from "../components/VideoItem";
import { ServiceSectionProvider } from "../context/ServiceSectionContext";
import CleanDataParser from "../helpers/editorjs-parser";
import useFetchPosts from "../services/hooks/useFetchPosts";
import { useMediaQuery } from "../services/hooks/useMediaQuery";
import {
  getBankIdIcon,
  getButtonArrow,
  getEmail,
  getLink,
  getViewsIcon,
} from "../services/icons";
import { useScrollPosition } from "../services/utils";

type ArticleType = {
  id: number;
  date: string;
  views: string;
  hero: URL;
  thumb: URL;
  authorImage?: URL;
  name: string;
  title: string;
  link: string;
  categoryFirstLevel: string;
  categoryFirstLevelLink: string;
  text: string;
};

// helpers
const clamp = (value: number) => Math.max(0, value);

const isBetween = (value: number, floor: number, ceil: number) =>
  value >= floor && value <= ceil;
// hooks
const useScrollspy = (ids: string[], offset = 0) => {
  const [activeId, setActiveId] = useState("");
  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset;

      const position = ids
        .map((id) => {
          const element = document.getElementById(id);

          if (!element) return { id, top: -1, bottom: -1 };

          const rect = element.getBoundingClientRect();
          const top = clamp(rect.top + scroll - offset);
          const bottom = clamp(rect.bottom + scroll - offset);

          return { id, top, bottom };
        })
        .find(({ top, bottom }) => isBetween(scroll, top, bottom));

      //setActiveId(position?.id || ids[ids.length - 1]);
      setActiveId(
        document.getElementById("heading").getBoundingClientRect().bottom > 0
          ? ids[0]
          : document.getElementById("related").getBoundingClientRect().top -
              84 <
            0
          ? ids[ids.length - 1]
          : position?.id
      );
    };

    listener();

    window.addEventListener("resize", listener);
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("resize", listener);
      window.removeEventListener("scroll", listener);
    };
  }, [ids, offset]);
  return activeId;
};

export const article: ArticleType[] = [
  {
    id: 1,
    date: "18 augusti 2023",
    views: "1012",
    hero: new URL(
      "../assets/images/article/hero_1_1600x960.jpg",
      import.meta.url
    ),
    thumb: new URL(
      "../assets/images/article/thumb/hero_1_310x220.jpg",
      import.meta.url
    ),
    authorImage: new URL("../assets/images/advisor.png", import.meta.url),
    name: "Henning Wijkmark",
    title: "Fullmakt",
    link: "fullmakt",
    categoryFirstLevel: "Livsplanering",
    categoryFirstLevelLink: "livsplanering",
    text: "En fullmakt är ett juridiskt dokument som ger en person (fullmaktsgivaren), befogenhet att agera å någon annans vägnar, (fullmaktstagaren). Det är ett kraftfullt verktyg som kan underlätta och effektivisera olika livssituationer och transaktioner. Den här artikeln syftar till att ge dig en översikt över vad en fullmakt är, vilket syfte den har, när den kan vara användbar, hur man skriver en fullmakt, vad man bör tänka på och en hel del annat som kan vara relevant.",
  },
];

export default function SinglePostPilar({
  title,
  slug,
  views,
  author,
  publishDate,
  featuredImage,
  childCategory,
  parentCategory,
  content,
}: {
  title?: string;
  slug?: string;
  publishDate?: string | undefined;
  featuredImage?: string | undefined;
  views?: string;
  author?: string;
  childCategory?: object;
  parentCategory?: object | undefined;
  content?: object;
}) {
  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }
  const { id } = useParams<{ id: string }>();
  const [isHover, setIsHover] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(true);
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const [activeSection, setActiveSection] = useState(null);
  const [activeTitle, setActiveTitle] = useState("Introduktion");
  const observer = useRef(null);
  const scrollPosition = useScrollPosition();

  const ids = content.map(({ id }) => id.toString());
  const activeId = useScrollspy(ids, 84);

  useEffect(() => {
    document.title = title! + " - Fenix Family AB";
    // create new instance and pass a callback function
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    observer.current = new IntersectionObserver(
      (entries) => {
        const visibleSection = entries.find(
          (entry) => entry.isIntersecting
        )?.target;
        // Update state with the visible section ID
        if (visibleSection) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setActiveSection(visibleSection.id);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setActiveTitle(visibleSection.getAttribute("data-title"));
        }
      },
      {
        threshold: isDesktop ? 0.25 : 0,
      }
    );

    // Get custom attribute data-section from all sections
    const sections = document.querySelectorAll("[data-section]");

    sections.forEach((section) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      observer.current.observe(section);
    });
    // Cleanup function to remove observer
    return () => {
      sections.forEach((section) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        observer.current.unobserve(section);
      });
    };
  }, []);

  function handleClick() {
    setOpenSidebar(!openSidebar);
  }

  const renderLoginButton = (buttonClass: string, iconColor: string) => {
    return (
      <Link
        to="/login"
        className={
          buttonClass +
          ` inline-flex items-center gap-2 rounded border-[1px] border-deep-blue py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] lg:gap-3 lg:self-end`
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover
          ? getBankIdIcon("#fff", "20", "100%")
          : getBankIdIcon(iconColor, "20", "100%")}
        Logga in
      </Link>
    );
  };

  const publishDay = new Date(publishDate);

  const postData = useFetchPosts(
    `${process.env.STRAPI_API_URL}/api/articles?populate=*`,
    `${process.env.STRAPI_API_URL}/api/secondary-articles?populate=*`,
    `${process.env.STRAPI_API_URL}/api/article-categories?populate=*`
  );

  const childCategories = postData.categoriesList?.data
    ?.filter((item: any) => item?.attributes.parent?.data.some((it: any) => it))
    ?.sort((a: any, b: any) =>
      a.attributes.slug > b.attributes.slug
        ? 1
        : b.attributes.slug > a.attributes.slug
        ? -1
        : 0
    );

  const postNavigationItem = content?.map((item: any, index: number) => {
    return (
      <li
        className={`menu-item relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest  before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
          item.id.toString() === activeSection
            ? " text-[#0C2632] before:bg-[#0C2632]"
            : " text-[#736F62] before:bg-[#CECABA]"
        }`}
        key={`menu-item-${item.id.toString()}`}
      >
        <HashLink
          onClick={handleClick}
          scroll={(el) =>
            el.scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }
          to={"#" + item.id.toString()}
        >
          {item.contentBlockTitle}
        </HashLink>
      </li>
    );
  });

  const articleTextBlock = content?.map(
    (item: any, index: number, content: any) => {
      return (
        <Fragment key={index}>
          <div
            key={`section-${item.id.toString()}`}
            id={item.id.toString()}
            className="section scroll-mt-40 pt-10 lg:scroll-my-20"
            data-section
            data-title={item.contentBlockTitle}
          >
            {index + 2 === content.length && <TrustboxArticle />}
            <div className="text-block">
              {item.contentBlock ? (
                item.contentBlock.map((block: any, idx: any) =>
                  CleanDataParser(block, idx)
                )
              ) : (
                <div />
              )}
            </div>
          </div>
        </Fragment>
      );
    }
  );

  return (
    <ServiceSectionProvider>
      <div className="relative overflow-hidden bg-[#FFFEFB]">
        <div
          id="heading"
          className={classNames(
            scrollPosition > 84 ? "bg-white" : "bg-white",
            "fixed z-1000 w-full transition-colors"
          )}
        >
          <HeadMenu
            sticky={scrollPosition > 84}
            theme={"dark"}
            color={scrollPosition > 84 ? "#0C2632" : "#0C2632"}
          />
        </div>
        <div className="hero-article relative w-full overflow-hidden text-deep-blue">
          <img
            src={featuredImage?.data?.attributes?.formats?.large?.url}
            alt={title}
            className="absolute left-0 top-0 z-10 h-full w-full object-cover"
            loading="lazy"
          />
          <div className="absolute left-0 top-0 z-20 h-full w-full bg-[#0C2632]/10" />
          <div className="z-1 relative z-30 mx-auto px-5 pb-6 pt-24 sm:max-w-[640px] md:max-w-[768px] lg:h-[735px] lg:max-w-[1024px] lg:pb-16 lg:pt-24 xl:max-w-[1360px]">
            <div className="hero-article flex h-full max-w-[750px] flex-col justify-between">
              <ul className="float-left inline space-x-2 text-[11px] capitalize text-white lg:flex">
                <li className="inline after:ml-2.5 after:content-['|']">
                  <Link to={"/"}>hem</Link>
                </li>
                <li className="inline after:ml-2.5 after:content-['|']">
                  <Link to={"/artiklar/"}>artiklar</Link>
                </li>
                {parentCategory && (
                  <li className="inline after:ml-2.5 after:content-['|']">
                    <Link
                      to={`/artiklar/${parentCategory?.attributes.parent.data[0]?.attributes.slug}/`}
                    >
                      {
                        parentCategory?.attributes.parent.data[0]?.attributes
                          ?.title
                      }
                    </Link>
                  </li>
                )}
                <li className="inline after:ml-2.5 after:content-['|']">
                  <Link
                    to={`/artiklar/${
                      parentCategory
                        ? parentCategory?.attributes.parent.data[0]?.attributes
                            .slug +
                          "/" +
                          childCategory?.slug +
                          "/"
                        : childCategory?.slug + "/"
                    }`}
                  >
                    {childCategory?.title}
                  </Link>
                </li>
                <li className="pointer-events-none inline">
                  <Link to={`/artiklar/${childCategory?.slug}/${slug}/`}>
                    {title}
                  </Link>
                </li>
              </ul>
              <div className="article-heading mt-28 lg:mt-0">
                <p className="text-xs font-medium uppercase tracking-[.25em] text-white">
                  {publishDay?.toLocaleDateString("sv-SE", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </p>
                <h1 className="mb-2 font-interblack text-[36px] italic leading-[2.7rem] text-white lg:mt-20 lg:text-[64px] xl:mt-5 xl:leading-[4.4rem]">
                  {title}
                </h1>
                <div className="flex text-[13px] text-white">
                  av {author}
                  <p className="flex items-center pl-5 text-white">
                    {getViewsIcon("#E8E3D2")}
                    <span className="pl-2">{views}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="article relative w-full overflow-hidden">
          <div className="relative mx-auto flex flex-col px-5 pt-0 pb-16 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:flex-row lg:pt-20 xl:max-w-[1360px]">
            <aside className="lg:rounded-b-0 absolute left-0 top-0 flex w-full lg:relative lg:max-w-[325px]">
              {isDesktop ? (
                <Sticky
                  top="#header"
                  bottomBoundary="#content"
                  activeClass="active w-full"
                  innerZ={999}
                >
                  <div className="sidebar self-start overflow-hidden rounded-[8px] bg-[#E8E3D2]">
                    <div className="inline-flex w-full items-start justify-start gap-3 bg-[#E8E3D2] px-6 py-10 lg:max-w-[324px]">
                      <ul className="relative inline-flex list-none flex-col items-start justify-start gap-6 pl-6 pr-5 before:absolute before:top-0 before:left-[5px] before:h-full before:w-[1px] before:bg-[#CECABA] before:content-['']">
                        {postNavigationItem}
                      </ul>
                    </div>
                  </div>
                </Sticky>
              ) : (
                <Sticky top="#header" activeClass="active w-full" innerZ={999}>
                  <div
                    className={
                      !openSidebar
                        ? "sidebar self-start rounded-b-[8px] bg-[#E8E3D2]"
                        : "sidebar h-[76px] self-start overflow-hidden rounded-b-[8px] bg-[#E8E3D2]"
                    }
                  >
                    <button
                      onClick={handleClick}
                      className={`relative h-[76px] w-full pl-5 pr-7 text-left font-internormal text-xs font-medium uppercase tracking-[0.2em] after:absolute after:right-5 after:top-7 after:h-[10px] after:w-[10px]  after:transform after:border-b after:border-r after:border-black/70 lg:hidden ${
                        openSidebar ? " after:rotate-45" : "after:rotate-225"
                      }`}
                    >
                      {activeTitle}
                    </button>
                    <div className="inline-flex w-full items-start justify-start gap-3 bg-[#E8E3D2] px-6 pt-3 pb-10">
                      <ul className="relative inline-flex list-none flex-col items-start justify-start gap-6 pl-6 pr-5 before:absolute before:top-0 before:left-[5px] before:h-full before:w-[1px] before:bg-[#CECABA] before:content-['']">
                        {postNavigationItem}
                      </ul>
                    </div>
                  </div>
                </Sticky>
              )}
            </aside>
            <article
              id="content"
              className="article-content w-full pt-28 lg:pt-0"
            >
              <div className="max-w-[760px] text-deep-blue lg:ml-24">
                {articleTextBlock}
                <br />
                <br />
                <br />
                <div
                  id="related"
                  className="last-articles relative mb-20 mt-6 pt-10 before:absolute before:top-0 before:left-[-50rem] before:h-full before:w-[500%] before:bg-neutral-yellow before:content-['']"
                >
                  <h4 className="relative mb-6 font-intermedium text-xs uppercase tracking-[0.2em] text-[#736F62]">
                    Har du även läst:
                  </h4>
                  <ArticlesSlider
                    articles={postData?.posts?.data
                      .filter((x: any) => x.attributes.slug != slug)
                      .slice(0, 2)}
                    init={false}
                    quantity={2}
                    childCategories={childCategories}
                  />
                </div>
                <div
                  id="steps"
                  className="step-block scroll-my-40 pb-10 lg:scroll-my-20"
                >
                  <div className="inline-flex flex-col items-center justify-start gap-10">
                    <h2 className="text-center font-internormal text-base font-semibold uppercase leading-normal tracking-[4px]">
                      Varför Fenix livsplanering?
                    </h2>
                    <div className="flex flex-col items-start justify-start gap-8 md:flex-row lg:inline-flex lg:gap-20">
                      {steps.map((step, index) => (
                        <div
                          key={index}
                          className="inline-flex flex-col items-center justify-start gap-6"
                        >
                          <div className="flex h-[80px] w-[80px] items-center justify-center rounded-full bg-[#E8E3D2] text-center text-[28px] font-semibold uppercase lg:h-[118px] lg:w-[118px] lg:text-[42px]">
                            {index + 1}
                          </div>
                          <div className="max-w-[194px] text-center text-[13px] font-normal leading-none">
                            {step.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <VideoItem />
                <br />
                <br />
                <br />
                <TrustboxArticle />
              </div>
            </article>
          </div>
        </div>
        <div
          id="faq"
          className="relative w-full border-t border-[#CECABA] text-deep-blue lg:border-t-0"
        >
          <div className="mx-auto px-5 pt-28 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:border-t lg:border-[#CECABA] lg:pt-24 lg:pb-32 xl:max-w-[1360px]">
            <div className="mx-auto max-w-[980px]">
              <h2 className="text-center font-interblack text-[28px] italic leading-[2.7rem] text-deep-blue xl:text-[48px] xl:leading-[4.5rem]">
                FAQ
              </h2>
            </div>
            <div className="mx-auto mt-3 max-w-[985px] pt-5 pb-24">
              <Accordion items={faqBlock} />
            </div>
          </div>
        </div>
        <div className="relative w-full bg-[#F3F0E6] text-deep-blue">
          <div className="mx-auto px-5 pt-12 pb-12 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pt-9 xl:max-w-[1360px]">
            <h2 className="mb-6 pt-8 font-intermedium text-base uppercase tracking-[.25em] lg:mb-3">
              Populära artiklar
            </h2>
            <ArticlesSlider
              articles={postData?.posts?.data
                .filter((x: any) => x.attributes.slug != slug)
                .slice(0, 4)}
              init={false}
              quantity={4}
              childCategories={childCategories}
            />
          </div>
        </div>
      </div>
      <Footer />
    </ServiceSectionProvider>
  );
}
