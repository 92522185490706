import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from "react";

import { conversationPublicData } from "../services/conversation";
import {
  ConvActionType,
  ConvContextType,
  ConvType,
} from "../types/conversation";

const defaultConv = {
  index: 0,
  items: [],
  values: [],
  status: "init",
  restarted: false,
};
const initialConv = defaultConv;

const ConversationPublicContext = createContext({} as ConvContextType);
const ConversationPublicDispatchContext =
  createContext<Dispatch<ConvActionType> | null>(null);

const conversationReducer = (state: ConvType, action: ConvActionType) => {
  switch (action.type) {
    case "set-status": {
      return {
        ...state,
        status: action.payload.status,
      };
    }
    case "set-values": {
      const prevValues = state.values.filter(
        (el) => el.id !== action.payload.obj.id
      );
      if (action.payload.answers.length === 0) {
        return {
          ...state,
          values: [...prevValues],
        };
      }
      return {
        ...state,
        values: [...prevValues, action.payload.obj],
      };
    }
    case "set-items":
      return {
        ...state,
        index: action.payload.index,
        items: [action.payload.item, ...state.items],
      };
    case "set-finished": {
      return {
        ...state,
        status: action.payload.status,
        index: 0,
      };
    }
    case "set-data":
      return {
        index: action.payload.index,
        items: action.payload.items,
        values: action.payload.values,
        status: action.payload.status,
      };
    case "clear":
      return { ...defaultConv, status: "started", restarted: true };
  }
  return state;
};

export const ConversationPublicProvider = ({ children }: PropsWithChildren) => {
  const [conversation, dispatch] = useReducer(conversationReducer, initialConv);

  const nextItem = (nextIndex = null): void => {
    if (conversation.values.length !== conversation.items.length) {
      return;
    }
    const ind = nextIndex !== null ? nextIndex : conversation.index;
    const question = conversationPublicData[ind];
    if (conversation.restarted && question?.skip) {
      nextItem(ind + 1);
      return;
    }
    if (!question) {
      dispatch({
        type: "set-finished",
        payload: {
          status: "finished",
        },
      });
      return;
    }

    if (question.dependencies) {
      const metDependencies = [];
      question.dependencies.map((dep) => {
        if (dep.type === "question") {
          const res = conversation.values.find((el) => {
            if (el.id === dep.id) {
              const metValues = [];
              const depValues = dep.values;
              const depOperator = dep.op;

              depValues.map((depValue) => {
                if (el.value.includes(depValue)) {
                  metValues.push(depValue);
                }
              });

              if (
                depOperator === "and" &&
                depValues.length === metValues.length
              ) {
                return true;
              }

              if (depOperator === "or" && metValues.length > 0) {
                return true;
              }

              return false;
            }
          });
          if (res) {
            metDependencies.push(dep);
          }
        }
      });

      if (metDependencies.length !== question.dependencies.length) {
        nextItem(ind + 1);
        return;
      }
    }

    dispatch({
      type: "set-items",
      payload: {
        item: question,
        index: ind + 1,
      },
    });
  };

  const obj = {
    conversation,
    nextItem,
  };

  return (
    <ConversationPublicContext.Provider value={obj}>
      <ConversationPublicDispatchContext.Provider value={dispatch}>
        {children}
      </ConversationPublicDispatchContext.Provider>
    </ConversationPublicContext.Provider>
  );
};

export function useConvPublicContext() {
  return useContext(ConversationPublicContext);
}

export function useConvPublicDispatchContext() {
  return useContext(ConversationPublicDispatchContext);
}
