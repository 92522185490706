import { BlobProvider } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  useConvContext,
  useConvDispatchContext,
} from "../../context/ConversationContext";
import {
  useUserContext,
  useUserDispatchContext,
} from "../../context/UserContext";
import {
  getInheritanceImages,
  getRecommendationMainText,
  getRecommendationPreContractText,
  getRecommendationText,
  getRecommendedContracts,
} from "../../services/contracts";
import { getPriceForAllContracts } from "../../services/contracts";
import { isDesktopView } from "../../services/utils";
import Button from "../Button";
import PageHead from "../PageHead";
import PdfRecommendation from "../PdfRecommendation";

const arrowDark = new URL(
  "../../assets/images/button-arrow-dark.svg",
  import.meta.url
);
const pdfIcon = new URL("../../assets/images/pdf.png", import.meta.url);

const circleIcon = () => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="4" fill="#CECABA" />
  </svg>
);

export default function Recommendation({ onFinish }) {
  const [proceedToPackage, setProceedToPackage] = useState(true);
  const [ownContracts, setOwnContracts] = useState();
  const [partnerServices, setPartnerServices] = useState();
  const [productTextsRecommendation, setProductTextsRecommendation] =
    useState();

  const [ownContractsPrice, setOwnContractsPrice] = useState(0);
  const [inhImages, setInhImages] = useState([]);
  const { conversation } = useConvContext();
  const dispatchConv = useConvDispatchContext();
  const dispatchUser = useUserDispatchContext();
  const { user } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    setProceedToPackage(!user.package);
  }, [user]);

  useEffect(() => {
    setOwnContractsPrice(getPriceForAllContracts());

    setInhImages(getInheritanceImages(conversation.values));
  }, [conversation, user]);

  useEffect(() => {
    if (!user.info?.age || !conversation.values) {
      return;
    }

    const recCont = getRecommendedContracts(
      conversation.values,
      user.info?.age
    );
    setOwnContracts(recCont);

    const partServ = getRecommendedContracts(
      conversation.values,
      user.info?.age,
      "partner"
    );
    setPartnerServices(partServ);

    const pList = [
      recCont.find((e) => e.title === "Testamente"),
      partServ.find((e) => e.id === "3"), // Kapitalförsäkring
      partServ.find((e) => e.id === "5"), // Sparkonto med ränta
      partServ.find((e) => e.id === "1"), // Livförsäkring
      recCont.find((e) => e.title === "Framtidsfullmakt"),
      partServ.find((e) => e.id === "7"), // Gravidförsäkring
      partServ.find((e) => e.id === "8"), // Barnförsäkring
      recCont.find((e) => e.title === "Äktenskapsförord"),
      recCont.find((e) => e.title === "Samboavtal"),
      partServ.find((e) => e.id === "6"), // Utgiftsskydd
      partServ.find((e) => e.id === "2"), // Sjuk- och olycksfallsförsäkring
      partServ.find((e) => e.id === "11"), // Djurförsäkring
      recCont.find((e) => e.title === "Skuldebrev"),
      recCont.find((e) => e.title === "Gåvobrev" && e.subtitle === "Fastighet"),
      recCont.find(
        (e) => e.title === "Gåvobrev" && e.subtitle === "Lös egendom"
      ),
      partServ.find((e) => e.id === "9"), // Hemförsäkring
    ];
    const pListTexts = pList.reduce((acc, item) => {
      if (!item) return acc;
      const text = getRecommendationText(
        conversation.values,
        item,
        user.info?.age
      );
      return [...acc, { id: item.id, text: text }];
    }, []);
    setProductTextsRecommendation(pListTexts);
  }, [conversation.values, user.info?.age]);

  const renderProceedButton = (type = "button", id = "") => {
    const handleOnClick = () => {
      if (proceedToPackage) {
        onFinish();
      } else {
        navigate("/vault");
      }
    };
    const title = proceedToPackage
      ? "Skapa lugn&ro här"
      : "TILL DITT KASSASKÅP";

    if (type === "link") {
      return (
        <button
          type="button"
          onClick={handleOnClick}
          className="flex flex-row gap-3 self-center lg:self-end"
          id={id}
        >
          <div className="font-intermedium uppercase tracking-[0.1em] underline">
            {title}
          </div>
          <img src={arrowDark.href} />
        </button>
      );
    }

    return (
      <Button
        onClick={handleOnClick}
        title={title}
        cssClasses="mx-auto"
        id={id}
      />
    );
  };

  const renderPackageSelectButtons = () => {
    if (process.env.NODE_ENV !== "development") {
      return;
    }

    const assignPackage = (type) => {
      let pkgId;
      switch (type) {
        case "somo":
          pkgId = 3;
          break;
        case "discount":
          pkgId = 99;
          break;
        case "ac":
          pkgId = 101;
          break;
        case "sf":
          pkgId = 6;
          break;
        case "sp":
          pkgId = 7;
          break;
        case "free":
        default:
          pkgId = 100;
          break;
      }

      dispatchUser({ type: "set-package-id", payload: pkgId });
      dispatchUser({ type: "set-package-id-override", payload: pkgId });
      dispatchConv({
        type: "set-status",
        payload: { status: "packet", accessToken: user.accessToken },
      });
      setTimeout(() => {
        navigate("/conversation");
      }, 500);
    };

    return (
      <div className="container mt-5 grid grid-cols-2 items-center justify-center gap-3 lg:grid-cols-4">
        <Button
          onClick={(e) => {
            e.target.innerText = "loading...";
            assignPackage("somo");
          }}
          title="Social Media package"
          size="small"
          rightArrow={false}
          cssClasses="mx-auto inline-block"
        />
        <Button
          onClick={(e) => {
            e.target.innerText = "loading...";
            assignPackage("discount");
          }}
          title="Discounted package"
          size="small"
          rightArrow={false}
          cssClasses="mx-auto inline-block"
        />
        <Button
          onClick={(e) => {
            e.target.innerText = "loading...";
            assignPackage("ac");
          }}
          title="ActiveCampaign package"
          size="small"
          rightArrow={false}
          cssClasses="mx-auto inline-block"
        />
        <Button
          onClick={(e) => {
            e.target.innerText = "loading...";
            assignPackage("sf");
          }}
          title="Svensk Fond package"
          size="small"
          rightArrow={false}
          cssClasses="mx-auto inline-block"
        />
        <Button
          onClick={(e) => {
            e.target.innerText = "loading...";
            assignPackage("sp");
          }}
          title="Strategipension package"
          size="small"
          rightArrow={false}
          cssClasses="mx-auto inline-block"
        />
        <Button
          onClick={(e) => {
            e.target.innerText = "loading...";
            assignPackage("free");
          }}
          title="Free package"
          size="small"
          rightArrow={false}
          cssClasses="mx-auto inline-block"
        />
      </div>
    );
  };

  return (
    <>
      <PageHead
        title={`${user.info?.firstName}'s Lugn&Ro-paket`}
        subtitle="Här är en överblick över vad vi rekommenderat"
        icon="chat"
        rightComp={
          <BlobProvider
            document={
              <PdfRecommendation
                inhImages={inhImages}
                name={`${user.info?.firstName} ${user.info?.lastName}`}
                mainText={getRecommendationMainText(
                  user.info?.firstName,
                  ownContractsPrice
                )}
                preContractText={getRecommendationPreContractText(
                  conversation.values
                )}
                ownContracts={ownContracts}
                partnerServices={partnerServices}
                productTextsRecommendation={productTextsRecommendation}
              />
            }
          >
            {({ blob, url, loading, error }) => {
              const attrs = {
                href: url,
                target: "_blank",
                className: "mt-1 lg:mt-0 lg:self-center",
                rel: "noreferrer",
              };
              if (isDesktopView()) {
                attrs[
                  "download"
                ] = `${user.info?.firstName}_rekommendation.pdf`;
              }
              return (
                <a {...attrs}>
                  <div className="flex cursor-pointer flex-row items-center gap-2 self-end font-interbold text-[11px] uppercase tracking-[2px] underline">
                    <img src={pdfIcon.href} width={23} className="mr-1" />{" "}
                    {loading ? (
                      "laddar..."
                    ) : (
                      <>
                        spara som pdf <img src={arrowDark.href} width={16} />
                      </>
                    )}
                  </div>
                </a>
              );
            }}
          </BlobProvider>
        }
      />
      <div className="mt-5 lg:mt-20 lg:pl-[40px]">
        <div className="mx-auto pb-8 text-[14px] text-dark-blue xl:max-w-4xl">
          <div className="mb-8 font-interlight leading-[21px] lg:mb-14">
            {getRecommendationMainText(
              user.info?.firstName,
              ownContractsPrice
            ).map((p, index) => (
              <div
                key={p.id}
                className={`${
                  (index === 0 || index === 4) &&
                  "mb-5 font-intermedium text-[18px]"
                } ${index === 3 && "mb-5"} ${
                  index === 2 && "mt-5 mb-5 lg:mb-10"
                }`}
              >
                {p.text}
              </div>
            ))}
          </div>
          <div className="mt-5 mb-5 font-intermedium text-[16px] uppercase lg:mt-10">
            Viktigt att tänka på i din situation
          </div>
          {conversation.values &&
            getRecommendationPreContractText(conversation.values)}
        </div>

        <div className="mx-auto w-full max-w-6xl rounded-[5px] border-[1px] border-[#CFCAB8] bg-white p-4 lg:p-10">
          <div className="mx-auto flex flex-col gap-5 lg:flex-row lg:gap-10 xl:max-w-4xl">
            <div className="flex flex-1 flex-col gap-5">
              {ownContracts &&
                ownContracts.map((contract) => (
                  <div key={contract.id} className="flex items-center gap-2">
                    {circleIcon()}
                    <div className="font-intermedium text-[18px] lg:text-[22px]">
                      {contract.title}{" "}
                      {contract.subtitle ? contract.subtitle : ""}
                    </div>
                  </div>
                ))}
            </div>
            <div className="flex flex-1 flex-col justify-center gap-5">
              {partnerServices &&
                partnerServices.map((service) => (
                  <div key={service.id} className="flex items-center gap-2">
                    {circleIcon()}
                    <div className="font-intermedium text-[18px] lg:text-[22px]">
                      {service.title}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="mt-10 flex flex-col">
          {renderProceedButton("button", "recommendation-to-package-top")}
        </div>

        <div className="mt-4 lg:mt-5">
          <div className="mx-auto py-4 text-dark-blue lg:py-8 xl:max-w-4xl">
            <div className="mb-5 font-intermedium uppercase">
              Vår rekommendation och vad vi baserar den på
            </div>
            <div className="flex flex-col gap-5">
              {productTextsRecommendation &&
                productTextsRecommendation.map((item) => {
                  return (
                    <div key={item.id} className="flex flex-col gap-3">
                      <div
                        key={Math.random()}
                        className="font-interlight text-[14px] leading-[21px]"
                        dangerouslySetInnerHTML={{
                          __html: item.text,
                        }}
                      />
                    </div>
                  );
                })}

              <div className="font-interlight text-[14px] leading-[21px] lg:mb-5">
                Vi hoppas att vi har förstått dig rätt, har du några frågor får
                du gärna kontakta oss här:{" "}
                <a className="underline" href="mailto:pre-need@fenixfamily.se">
                  pre-need@fenixfamily.se
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-5 border-[#CFCAB8] lg:my-10" />

        {inhImages && inhImages.length > 0 && (
          <>
            <div className="lg:mt-5">
              <div className="mx-auto py-4 text-dark-blue lg:py-8 xl:max-w-4xl">
                <div className="mb-5 font-intermedium uppercase">
                  Vem ärver mig?
                </div>
                <div className="mb-5 font-interlight leading-[21px]">
                  För att förenkla ytterligare har vi översatt din arvsordning
                  till en illustration.
                </div>
                {inhImages.map((im) => (
                  <img
                    key={im.href}
                    src={im.href}
                    className="mb-3 rounded-[8px] border-[1px] border-[#CECABA] lg:mb-6"
                  />
                ))}
              </div>
            </div>
            <hr className="my-3 border-[#CFCAB8] lg:my-10" />
          </>
        )}

        <div className="lg:mt-5">
          <div className="mx-auto py-4 text-dark-blue lg:py-8 xl:max-w-4xl">
            <div className="mb-5 font-intermedium uppercase">
              Fenix Family - enkla lösningar på saker du borde gjort för länge
              sen
            </div>
            <div className="mb-5 font-interlight text-[14px] leading-[21px]">
              Fenix Family föddes ur Fenix Begravning där vi ofta fick frågor om
              andra saker än själva begravningsceremonin. För i livets slutskede
              ställs ofta ekonomi, juridik och hur man planerat både sitt liv
              och framtiden för sina närmaste, på sin spets. Idag kan vi hjälpa
              alla som vill skapa testamenten, äktenskapsförord, avtal och
              fullmakter, med en enkel, trygg och digital lösning på problem som
              ofta kan upplevas som känsliga och svåra.
              <div className="mt-5">
                Vi svarar gärna på dina frågor{" "}
                <a className="underline" href="mailto:pre-need@fenixfamily.se">
                  pre-need@fenixfamily.se
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="my-5 lg:my-10">
          {renderProceedButton("button", "recommendation-to-package-bottom")}
        </div>

        {renderPackageSelectButtons()}
      </div>
    </>
  );
}
