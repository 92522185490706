import React, { useState } from "react";
import { Link } from "react-router-dom";

import { getBankIdIcon, getButtonArrow } from "../services/icons";
import VideoItem from "./VideoItem";

const planning = new URL("../assets/images/planning.jpg", import.meta.url);
const advisors = new URL("../assets/images/advisors.jpeg", import.meta.url);
const smartDigital = new URL(
  "../assets/images/smart_digital.jpeg",
  import.meta.url
);

export default function CampaignContent({
  buttonOnClick,
}: {
  buttonOnClick: () => void;
}) {
  const [isHover, setIsHover] = useState(false);
  const renderLoginButton = (
    buttonClass: string,
    iconColor: string,
    title?: string
  ) => {
    return (
      <Link
        to="/login"
        className={
          `mt-3 inline-flex h-[61px] items-center gap-2 rounded bg-deep-blue px-9 pt-[18px] pb-[18px] font-intermedium text-base uppercase leading-normal tracking-[.12em] text-white hover:border-[#6F86A0] hover:bg-[#6F86A0] hover:text-white lg:gap-3` +
          buttonClass
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover
          ? getBankIdIcon(iconColor, "20", "100%")
          : getBankIdIcon("#fff", "20", "100%")}
        {title}
      </Link>
    );
  };

  return (
    <div className="relative w-full bg-white text-deep-blue">
      <div className="mx-auto px-5 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:py-7 xl:max-w-[1360px]">
        <div
          id="survive"
          className="flex flex-col-reverse border-b border-deep-blue pt-8 pb-8 lg:flex-row lg:justify-between lg:gap-28 lg:pb-16 lg:pt-4"
        >
          <div className="flex w-full flex-col justify-between lg:max-w-[605px]">
            <div>
              <h2 className="mb-4 font-interlight text-[30px] uppercase leading-tight lg:text-[44px]">
                att planera är att bearbeta –
              </h2>
              <p
                className="font-interlight tracking-wider"
                dangerouslySetInnerHTML={{
                  __html:
                    "På Fenix Family hjälper vi människor på hela livets resa med dess utmaningar och problem. Vi är där genom glädje och sorg, liv och död, därför lär vi oss hela tiden om människors behov – hela livspusslet.<br/><br/>En del av livet är slutet…<br/>Hur vill du bli ihågkommen, hur vill du bli förknippad? Spelar det ens någon roll om man sätter sin prägel för eftervärlden? Naturligtvis, arvet efter dig lever vidare långt efter det att du gått ur tiden.<br/><br/>Några saker vi har lärt oss är behovet att få uttrycka det sagda och osagda till dem du lämnar efter dig. Att kunna berätta hur du vill att ditt avsked ska se ut, och förklara för efterföljande generationer vem du var och med det beskriva varför de är som de är.",
                }}
              ></p>
            </div>
            <button
              onClick={buttonOnClick}
              className="eftervarlden_skriv_ditt_brev mt-3 flex h-[61px] items-center gap-2 self-start rounded bg-deep-blue px-9 py-5 font-intermedium text-base uppercase leading-none tracking-[.12em] text-white hover:border-[#6F86A0] hover:bg-[#6F86A0] hover:text-[#fff]"
            >
              återge dina tankar {getButtonArrow("#fff", "20")}
            </button>
          </div>
          <div className="mb-6 w-full lg:mb-0 lg:max-w-[585px]">
            <VideoItem
              videoHref="https://static.fenixbegravning.se/fenix_family_pp_campaign.mp4"
              thumb={planning.href}
              playerClasses="object-cover min-h-[240px]"
              containerClasses="h-full rounded-2xl overflow-hidden w-full lg:w-[585px] lg:min-h-[490px]"
            />
          </div>
        </div>
        <div
          id="advice"
          className="flex flex-col-reverse border-b border-deep-blue pt-8 pb-8 lg:flex-row lg:justify-between lg:gap-28 lg:pb-16 lg:pt-16"
        >
          <div className="flex w-full flex-col justify-between lg:max-w-[605px]">
            <div>
              <h2 className="mb-4 font-interlight text-[30px] uppercase leading-tight lg:text-[44px]">
                vi har hela livet på oss...
              </h2>
              <p
                className="font-interlight tracking-wider"
                dangerouslySetInnerHTML={{
                  __html:
                    "...Och det vet vi ju aldrig hur långt det är. Ånger är en stor börda, och vi vet alla att det man ångrar är det man inte gjorde. Få människor ångrar att ge en komplimang eller ett stödjande ord. Några lärdomar vi fått är att oavsett om du är 25, 55 eller 85 år så är det aldrig för sent att påminna sig själv om att inte skjuta på saker som du kan ta tag i NU.<br/><br/>Börja idag, så kommer du snart att inse vad du kommer att bli förknippad med i livet och därefter...",
                }}
              ></p>
            </div>
            <button
              onClick={buttonOnClick}
              className="eftervarlden_skriv_ditt_brev mt-3 flex h-[61px] items-center gap-2 self-start rounded bg-deep-blue px-9 py-5 font-intermedium text-base uppercase leading-none tracking-[.12em] text-white hover:border-[#6F86A0] hover:bg-[#6F86A0] hover:text-[#fff]"
            >
              skriv ditt brev {getButtonArrow("#fff", "20", "20")}
            </button>
          </div>
          <div className="mb-6 w-full lg:mb-0 lg:max-w-[585px]">
            <div className="h-full w-full overflow-hidden rounded-2xl bg-[#43262D] px-6 pt-8 pb-4 lg:max-h-[450px] lg:w-[585px] lg:px-10 lg:pt-16 lg:pb-10">
              <h3 className="font-interblack text-2xl italic text-[#FCEFC6] lg:text-4xl">
                Nio lärdomar att prova:
              </h3>
              <div className="mt-7 flex gap-8">
                <ul className="ml-4 list-disc font-interlight text-sm text-[#FCEFC6] lg:text-lg">
                  <li className="pb-3 lg:pb-5">Säg, jag älskar dig</li>
                  <li className="pb-3 lg:pb-5">Våga vara modig</li>
                  <li className="pb-3 lg:pb-5">Sluta ångra dig</li>
                  <li className="pb-3 lg:pb-5">
                    Att vara långsint är bara jobbigt
                  </li>
                  <li className="pb-5">
                    Varje kram kan vara den sista med hen
                  </li>
                </ul>
                <ul className="ml-4 list-disc font-interlight text-sm text-[#FCEFC6] lg:text-lg">
                  <li className="pb-3 lg:pb-5">Just nu är bäst</li>
                  <li className="pb-3 lg:pb-5">
                    Låt din passion prägla vem du är
                  </li>
                  <li className="pb-3 lg:pb-5">
                    Problem kan vara en heliumballong
                  </li>
                  <li className="pb-3 lg:pb-5">Att förlåta är skönt</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          id="fenixbegravning"
          className="flex flex-col-reverse border-b border-deep-blue pt-8 pb-8 lg:flex-row lg:justify-between lg:gap-28 lg:pb-16 lg:pt-16"
        >
          <div className="flex w-full flex-col justify-between lg:max-w-[605px]">
            <div>
              <h2 className="mb-4 font-interlight text-[30px] uppercase leading-tight lg:text-[44px]">
                värdiga avsked med varma rådgivare
              </h2>
              <p
                className="font-interlight tracking-wider"
                dangerouslySetInnerHTML={{
                  __html:
                    "Sedan 2014 har vi på Fenix erbjudit familjer sinnesro i livets alla faser.\n" +
                    "Som trygg och kunnig begravningsbyrå underlättar vi planeringen i en svår stund. Med oss har du full transparens när det kommer till kostnader i stort som smått, från den noggrant utvalda gravstenen till det smakfulla blomsterarrangemanget. <br/><br/>Oavsett om du står inför ett föränderligt eller svårt ögonblick i livet, finns vi här för dig genom varje steg för ett värdigt och personligt avsked. Du kan vara trygg med att vi gör hela processen lite enklare och mer hanterbar. ",
                }}
              ></p>
            </div>
            <Link
              to="https://fenixbegravning.se/"
              className="mt-2.5 flex gap-2 font-intermedium text-base uppercase tracking-[.25em] underline decoration-1 underline-offset-8 hover:text-deep-blue hover:no-underline"
            >
              till fenix family begravning {getButtonArrow("#0C2632", "20")}
            </Link>
          </div>
          <div className="mb-6 w-full lg:mb-0 lg:max-w-[585px]">
            <img
              className="min-h-[240px] w-full overflow-hidden rounded-2xl lg:w-[585px]"
              src={advisors.href}
              alt="campaign preplan"
            />
          </div>
        </div>
        <div
          id="livsplanering"
          className="flex flex-col-reverse pt-8 pb-8 lg:flex-row lg:justify-between lg:gap-28 lg:pb-16 lg:pt-16"
        >
          <div className="w-full flex-col justify-between lg:max-w-[605px]">
            <h2 className="mb-4 font-interlight text-[30px] uppercase leading-tight lg:text-[44px]">
              kompetent, empatisk juridik och en smart digital tjänst
            </h2>
            <p
              className="font-interlight text-base tracking-wider"
              dangerouslySetInnerHTML={{
                __html:
                  "Fenix Family föddes ur Fenix Begravning där vi ofta fick frågor om andra saker än själva begravningsceremonin. För i livets slutskede ställs ofta ekonomi, juridik och hur man planerat både sitt liv och framtiden för sina närmaste, på sin spets.<br/><br/>Idag kan vi hjälpa alla som vill skapa testamenten, bouppteckningar, äktenskapsförord, avtal och fullmakter, med en enkel, trygg och digital lösning på problem som ofta kan upplevas som känsliga och svåra.\n",
              }}
            ></p>
            {renderLoginButton(``, `#fff`, `börja livsplanera`)}
          </div>
          <div className="mb-6 w-full lg:mb-0 lg:max-w-[585px]">
            <img
              className="min-h-[240px] w-full max-w-[585px] overflow-hidden rounded-2xl"
              src={smartDigital.href}
              alt="campaign preplan"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
