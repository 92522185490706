import { CardProps } from "../components/RecommendationCard";
import { ConvAnswer } from "../types/conversation";
import { conversationData } from "./conversation";
import { stripEmojis } from "./utils";

const { v4: uuidv4 } = require("uuid");

export type Contract = {
  id: string;
  title: string;
  subtitle?: string;
  main: string;
  mainCta: string;
  price: number;
  visible: number;
  type?: number;
  bgColor?: CardProps["theme"];
  recommendation: boolean;
  recommendationText?: string;
  recommendationRequirements?: { [s: string]: string[] };
  recommendationOrder?: number;
  recommendationAgeRequirements?: ["greater" | "less", number];
};

export type PartnerService = {
  id: string;
  title: string;
  subtitle?: string;
  main: string;
  visible: number;
  bgColor?: CardProps["theme"];
  recommendation: boolean;
  recommendationRequirements?: { [s: string]: string[] };
  recommendationOrder?: number;
  recommendationAgeRequirements?: ["greater" | "less", number];
  recommendationText?: string;
};

export const contracts: Contract[] = [
  {
    id: "632aee391df59f7f0b5ba924",
    title: "Äktenskapsförord",
    main: "Att vara gift eller gifta sig är ett av livets stora ögonblick. Och även om det inte är det första man vill prata om som nygifta, så är det alltid klokt att skriva ett äktenskapsförord när förhållandet är bra. Då går saker och ting lättare ifall förhållandet någon gång skulle bli sämre.",
    mainCta: "Skapa ett äktenskapsförord",
    price: 119500,
    visible: 1,
    type: 7,
    bgColor: "yellow",
    recommendation: true,
    recommendationRequirements: { marital_status: ["6"] },
    recommendationOrder: 3,
    recommendationText:
      "Även om äktenskapsförord inte är det första man vill prata om som gifta, så är det alltid klokt att skriva ett när förhållandet är bra. Då går saker och ting lättare ifall förhållandet någon gång skulle bli sämre. Så länge äktenskapet varar äger ni era tillgångar var för sig, men vid skilsmässa delas allt som inte är enskild egendom lika de mellan er. Om du är sambo och planerar att gifta dig så är det bra att ha ett samboavtal som gäller fram till bröllopet.",
  },
  {
    id: "632aee381df59f7f0b5ba91f",
    title: "Skuldebrev",
    main: "Ett lån kan verkligen göra skillnad för den som behöver det. Men den dagen du behöver ha tillbaka dina pengar, kan det vara till stor hjälp att ha svart på vitt att lånet skett och hur stort det var.",
    mainCta: "Skapa ett skuldebrev",
    price: 89500,
    visible: 1,
    type: 8,
    bgColor: "dark-grey",
    recommendation: true,
    recommendationOrder: 5,
    recommendationRequirements: { goal: ["3"] },
    recommendationText:
      "Att du vill låna ut pengar kan verkligen göra skillnad för den som behöver det. Men den dagen du behöver ha tillbaka dina pengar kan det vara till stor hjälp att ha svart på vitt att lånet skett och hur stort det var. Med ett skuldebrev blir risken för konflikter kring vem som är skyldig vem vad betydligt mindre.",
  },
  {
    id: "632aee391df59f7f0b5ba920",
    title: "Gåvobrev",
    subtitle: "Fastighet",
    main: "Det är givetvis tanken som räknas, men när det kommer till dyrbara gåvor, exempelvis fastigheter, är det klokt att tänka till. Ett gåvobrev skapar trygghet för både givare och mottagare, angående exempelvis äganderätt och eventuella villkor. ",
    mainCta: "Skapa ett gåvobrev för fastighet",
    price: 119500,
    visible: 1,
    type: 9,
    bgColor: "green",
    recommendation: true,
    recommendationOrder: 6,
    recommendationRequirements: { goal: ["1"] },
    recommendationText:
      "Vilken fin gest att du vill ge bort fastighet. Men när det kommer till dyrbara gåvor, exempelvis fastigheter, är det klokt att tänka till. Ett gåvobrev skapar trygghet för både givare och mottagare angående exempelvis äganderätt, nyttjanderätt och eventuella villkor. Ett skriftligt gåvobrev minskar till exempel risken för missförstånd och konflikter mellan arvingar då gåvor kan räknas som förskott på arv.",
  },
  {
    id: "632aee391df59f7f0b5ba923",
    title: "Samboavtal",
    main: "Att vara eller bli sambo är ofta ett stort steg. Och även om det inte är det första man vill prata om som nyinflyttade tillsammans, så är det är alltid klokt att skriva ett samboavtal när förhållandet är bra. Då går saker och ting lättare ifall förhållandet någon gång skulle bli sämre.",
    mainCta: "Skapa ett samboavtal",
    price: 79500,
    visible: 1,
    type: 10,
    bgColor: "brown",
    recommendation: true,
    recommendationRequirements: { marital_status: ["3", "4"] },
    recommendationOrder: 4,
    recommendationText:
      "Du är [MARITAL_STATUS] och även om det inte är det första man vill prata om som nyinflyttade tillsammans, så är det är alltid klokt att skriva ett samboavtal när förhållandet är bra. Då går saker och ting lättare ifall förhållandet någon gång skulle bli sämre. Man kan till exempel reglera att den som betalat för den gemensamma bostaden ska få köpeskillingen vid en försäljning. Gör ni inte det räknas bostaden som gemensam oavsett vem som betalat för den. Skulle ni vilja fördela samboegendomen olika vid en separation är det också klokt att skriva ett skuldebrev. Om du planerar att gifta dig inom sex månader så är det bra att ha ett samboavtal som gäller fram till bröllopet.",
  },
  {
    id: "632d75181df59f7f0b5ba962",
    title: "Framtidsfullmakt",
    main: "Vad framtiden har att erbjuda vet man aldrig. Men något man kan vara ganska säker på, är att man kan behöva pålitlig hjälp med saker som idag går av bara farten. En framtidsfullmakt för någon du litar på gör att bankärenden och andra åtaganden flyter på även den dag du inte kan sköta dem själv.",
    mainCta: "Skapa en framtidsfullmakt",
    price: 119500,
    visible: 1,
    type: 11,
    bgColor: "grey",
    recommendation: true,
    recommendationOrder: 2,
    recommendationRequirements: {
      marital_status: ["1", "7", "8"],
      pets: ["1"],
      trust_person: ["1"],
    },
    recommendationAgeRequirements: ["greater", 50],
    recommendationText:
      "Oavsett om du har någon som kan hjälpa dig om du får svårt att ta hand om dig själv eller inte kan det vara bra att fundera över vem som, den dag det behövs, kan hjälpa dig med saker som idag går av bara farten. En framtidsfullmakt för någon du litar på gör att till exempel bankärenden flyter på och någon tar hand om husdjur även den dag du inte längre kan sköta dem själv.",
  },
  {
    id: "6426b25d4faac2706e6e5215",
    title: "Testamente",
    main: "Oavsett livssituation så är det väldigt bra att ha ett testamente, speciellt om du har specifika önskemål om hur dina tillgångar ska fördelas. Av erfarenhet vet vi att ett tydligt testamente kan bespara nära och kära mycket besvär och konflikter.",
    mainCta: "Skapa ett testamente",
    price: 139500,
    visible: 1,
    type: 12,
    bgColor: "dark-blue",
    recommendation: true,
    recommendationOrder: 1,
    recommendationText:
      "Du är [MARITAL_STATUS][KIDS_TESTAMENTE][ASSETS][GOAL_TESTAMENTE]. Därför är det på sin plats med ett testamente, något som är väldigt bra att ha, speciellt om du har specifika önskemål om hur dina tillgångar ska fördelas. Muntliga överenskommelser gäller nämligen inte så det är viktigt att få dina önskemål på pränt. Av erfarenhet vet vi att ett tydligt testamente kan bespara nära och kära mycket besvär och konflikter den dag du går bort.",
  },
  {
    id: "632d79cfd0eeeb308cfa403b",
    title: "Gåvobrev",
    subtitle: "Lös egendom",
    main: "Det är givetvis tanken som räknas, men när det kommer till dyrbara gåvor är det klokt att tänka till. Ett gåvobrev skapar trygghet för både givare och mottagare, angående exempelvis äganderätt och eventuella villkor.",
    mainCta: "Skapa ett gåvobrev för lös egendom",
    price: 119500,
    visible: 1,
    type: 13,
    bgColor: "green",
    recommendation: true,
    recommendationOrder: 7,
    recommendationRequirements: { goal: ["2"] },
    recommendationText:
      "Vilken fin gest att du vill ge bort lös egendom. Men när det kommer till dyrbara gåvor är det klokt att tänka till. Ett gåvobrev skapar trygghet för både givare och mottagare angående exempelvis äganderätt, nyttjanderätt och eventuella villkor. Ett skriftligt gåvobrev minskar till exempel risken för missförstånd och konflikter mellan arvingar då gåvor kan räknas som förskott på arv.",
  },
];

export const partnerServices: PartnerService[] = [
  {
    id: "1",
    title: "Livförsäkring",
    main: "När man är under 65 år gammal kan man teckna en livförsäkring. Den gör att din familj inte behöver ta drastiska beslut i en redan svår situation, de får exempelvis större möjlighet att bo kvar i er gemensamma bostad. Den kan också förenkla ifall du har specifika tankar kring vem som ska ärva vad den dag du går bort.",
    visible: 1,
    bgColor: "blue",
    recommendation: true,
    recommendationOrder: 1,
    recommendationRequirements: {
      marital_status: ["3", "4", "6"],
      kids: ["2", "3", "4"],
      debt_desc: ["1"],
      goal: ["4"],
    },
    recommendationAgeRequirements: ["less", 65],
    recommendationText:
      "Det kan vara en bra idé för dig som är under 65 år att teckna en livförsäkring eftersom [MARITAL_STATUS_LIV][KIDS_LIV][DEBT_LIV]. Livförsäkringen gör att din familj inte behöver ta drastiska beslut i en redan svår situation, de får exempelvis större möjlighet att bo kvar i er gemensamma bostad. Den kan också förenkla ifall du har specifika tankar kring vem som ska ärva vad den dag du går bort.",
  },
  {
    id: "2",
    title: "Sjuk- och olycksfallsförsäkring",
    main: "Skulle du råka ut för en olycka eller bli sjuk ger den här försäkringen dig ekonomisk ersättning utöver vad samhället bidrar med. Du kan försäkra dig för olika belopp och välja mellan två olika försäkringar beroende på hur gammal du är.",
    visible: 1,
    bgColor: "blue",
    recommendation: true,
    recommendationOrder: 6,
    recommendationText:
      "En bra grund för att skapa stabilitet och trygghet är en Sjuk- och olycksfallsförsäkring. Den ger dig ekonomisk ersättning utöver vad samhället bidrar med ifall du skulle råka ut för en olycka eller bli sjuk. Du kan försäkra dig för olika belopp och välja mellan två olika försäkringar beroende på hur gammal du är.",
  },
  {
    id: "3",
    title: "Kapitalförsäkring",
    main: "Om man har tydliga tankar kring vem som ska ärva vad och hur mycket, kan en Kapitalförsäkring underlätta mycket. Sparformen är långsiktig och perfekt om du vill spara till barnbarn eller pension. Kapitalförsäkringen kan även villkoras så att pengarna automatiskt tillfaller en speciell förmånstagare den dag du går bort.",
    visible: 1,
    bgColor: "blue",
    recommendation: true,
    recommendationOrder: 2,
    recommendationRequirements: {
      goal: ["5"],
      investing: ["2", "3"],
    },
    recommendationText:
      "Ett av dina mål är att du vill spara långsiktigt. Vi rekommenderar att du investerar i en Kapitalförsäkring. Den kan underlätta mycket om du har tydliga tankar kring vem av dina efterlevande som ska ärva vad och hur mycket. Sparformen är långsiktig och även ett perfekt alternativ om du vill spara till barnbarn eller din egen pension. Kapitalförsäkringen kan även villkoras så att pengarna automatiskt tillfaller en speciell förmånstagare den dag du går bort. Ett investeringssparkonto (ISK) är en annan sparform på lång sikt. Med ett ISK kommer pengarna betalas ut till dödsboet eller enligt testamente.",
  },
  // {
  //   id: "4",
  //   title: "ISK",
  //   main: "ISK och Kapitalförsäkring är båda enkla sätt att långsiktigt spara i fonder och aktier. De har många likheter men ett par viktiga skillnader: Med ISK kan du exempelvis inte göra månatliga utbetalningar och vem som ska ärva pengarna behöver regleras i ditt testamente.",
  //   visible: 1,
  //   bgColor: "blue",
  //   recommendation: true,
  //   recommendationOrder: 4,
  //   recommendationRequirements: {
  //     investing: ["2"],
  //   },
  //   recommendationText:
  //     "Du [GOAL] och för det är ISK och Kapitalförsäkring enkla sätt att långsiktigt spara i fonder och aktier. De har många likheter men ett par viktiga skillnader: Med ISK kan du exempelvis inte göra månatliga utbetalningar utan bara engångsuttag, och vem som ska ärva pengarna kan regleras i ditt testamente.",
  // },
  {
    id: "5",
    title: "Sparkonto med ränta",
    main: "Om du vill spara på kort sikt eller skapa en kontantbuffert för oförutsedda händelser, är ett sparkonto det bästa eftersom pengarna är tillgängliga direkt. En bra regel är att ha ca 2-5 månadslöner redo beroende på hur många ni är i familjen.",
    visible: 1,
    bgColor: "blue",
    recommendation: true,
    recommendationOrder: 3,
    recommendationRequirements: {
      investing: ["1", "3"],
    },
    recommendationText:
      "Du vill spara kortsiktigt, kanske för att skapa en kontantbuffert för oförutsedda händelser eller för att köpa något du vill ha. För detta är ett sparkonto med ränta det bästa eftersom pengarna är tillgängliga direkt. Så ifall diskmaskinen går sönder eller din inkomst skulle minska så finns det en pott redo att fylla luckorna. En bra regel är att ha motsvarande ca 2-5 månadslöner tillgängligt beroende på hur många ni är i familjen.",
  },
  // {
  //   id: "6",
  //   title: "Utgiftsskydd",
  //   main: "Är du bosatt i Sverige och har fast anställning över 17 timmar i veckan kan du teckna ett Utgiftsskydd. Det kan hjälpa dig att täcka dina boendekostnader om du skulle bli sjuk eller ofrivilligt arbetslös.",
  //   visible: 1,
  //   bgColor: "blue",
  //   recommendation: true,
  //   recommendationOrder: 4,
  //   recommendationRequirements: {
  //     debt_desc: ["1", "2"],
  //   },
  //   recommendationAgeRequirements: ["less", 65],
  //   recommendationText:
  //     "Med anledning av att du är [AGE] år, [ASSETS_NUMBER] och är bosatt i Sverige så kan du teckna ett Utgiftsskydd om du har fast anställning över 17 timmar i veckan. Det kan hjälpa dig att täcka dina boendekostnader om du skulle bli sjuk eller ofrivilligt arbetslös.",
  // },
  {
    id: "7",
    title: "Gravidförsäkring",
    main: "För en extra trygg start på livet är en Gravidförsäkring en bra idé. Den kan tecknas från den stund du vet att du är gravid och gäller tills att barnet är 6 månader. Den ger även den som är gravid ett extra skydd under graviditet och förlossning.",
    visible: 1,
    bgColor: "blue",
    recommendation: true,
    recommendationOrder: 8,
    recommendationRequirements: {
      kids: ["5"],
    },
    recommendationText:
      "Du väntar barn, grattis! För en extra trygg start på livet är en Gravidförsäkring en bra idé. Den ger även dig ett extra skydd under graviditet och förlossning. Den kan tecknas från den stund du vet att du är gravid och gäller tills att barnet är 6 månader. Redan i anslutning till gravidförsäkringen är det rekommenderat att teckna en barnförsäkring.",
  },
  {
    id: "8",
    title: "Barnförsäkring",
    main: "I anslutning till gravidförsäkringen är det rekommenderat att teckna en barnförsäkring. Den gäller från den dag barnet föds, gäller dygnet runt och skyddar vid sjukdom och olycksfall tills barnet fyller 25 år.",
    visible: 1,
    bgColor: "blue",
    recommendation: true,
    recommendationOrder: 9,
    recommendationRequirements: {
      kids_insurance: ["2", "3"],
    },
    recommendationText:
      "Du har barn, så det är på sin plats att se över deras försäkringsskydd. Barnförsäkringen kan påbörjas från den dag barnet föds, gäller dygnet runt från dagen den köpts och skyddar vid sjukdom och olycksfall tills barnet fyller 25 år.",
  },
  {
    id: "9",
    title: "Hemförsäkring",
    main: "En hemförsäkring är A och O för alla med fast bostad. Den täcker inte bara själva bostaden utan även dina saker, dina resor och oavsett om du hyr eller äger ditt hem.",
    visible: 1,
    bgColor: "blue",
    recommendation: true,
    recommendationOrder: 5,
    recommendationText:
      "En hemförsäkring är A och O för alla med fast bostad. Skulle något hända så täcker den inte bara själva bostaden utan även dina saker, dina resor och oavsett om du hyr eller äger ditt hem. Trots det så saknar hela 20% av befolkningen en hemförsäkring, är du säker på att du har en?",
  },
  {
    id: "11",
    title: "Djurförsäkring",
    main: "De fyrfota familjemedlemmarna ska såklart ha en försäkring de med. Om inte annat för att sjukdomar och olyckor snabbt kan bli väldigt kostsamma historier. Välj försäkring beroende på vilket djur du har så får du ekonomisk ersättning den dag vård kan behövas.",
    visible: 1,
    bgColor: "blue",
    recommendation: true,
    recommendationOrder: 7,
    recommendationRequirements: {
      pets: ["1"],
    },
    recommendationText:
      "Självklart ska även de fyrfota familjemedlemmarna ha en försäkring. Om inte annat för att sjukdomar och olyckor snabbt kan bli väldigt kostsamma historier. Välj försäkring beroende på vilket djur du har så får du ekonomisk ersättning den dag vård kan behövas.",
  },
];

const getAnswersObj = (conversationAnswers: ConvAnswer[]) => {
  return conversationAnswers.reduce((acc, answ) => {
    const cd = conversationData.find((el) => el.id === answ.id);
    const t = { ...acc };
    if (!cd.options) {
      t[answ.name] = answ.value;
    } else {
      const opts = cd.options.reduce((acc, o) => {
        const t = [...acc];
        if (answ.value.includes(o.value)) {
          t.push({ ...o });
        }
        return t;
      }, []);

      t[answ.name] = opts;
    }
    return t;
  }, {});
};

export const getRecommendedContracts = (
  conversationAnswers: ConvAnswer[],
  age: number,
  ctsType = "own"
): Contract[] => {
  const out = [];
  const ctsList = ctsType === "own" ? contracts : partnerServices;

  ctsList.map((contract) => {
    if (!contract.recommendation) {
      return false;
    }

    if (contract.recommendationRequirements) {
      const req = contract.recommendationRequirements;
      const reqMet = [];
      Object.entries(contract.recommendationRequirements).map((el) => {
        const [key, value] = el;
        let hasValue = false;

        const answerValues = conversationAnswers.filter(
          (ans) => ans.name === key
        );
        if (answerValues.length !== 0) {
          value.map((v) => {
            if (answerValues[0].value.includes(v)) {
              hasValue = true;
            }
          });
        }
        if (hasValue) {
          reqMet.push(el);
        }
      });

      let passAge = true;
      if (contract.recommendationAgeRequirements) {
        const [op, ageLimit] = contract.recommendationAgeRequirements;
        if (op === "greater") {
          passAge = age > ageLimit;
        }
        if (op === "less") {
          passAge = age < ageLimit;
        }
      }
      if (
        Object.entries(req).length >= reqMet.length &&
        reqMet.length > 0 &&
        passAge
      ) {
        out.push(contract);
      }
    } else {
      out.push(contract);
    }
  });

  return out.sort((a, b) => {
    return a.recommendationOrder - b.recommendationOrder;
  });
};

export const getRecommendationText = (
  conversationAnswers: ConvAnswer[],
  contract,
  age
) => {
  if (!contract.recommendationText) {
    return "";
  }

  const valuesToString = (values) => {
    if (!values) {
      return "";
    }
    return values
      .map((el) => {
        return `<span class="lowercase ${
          process.env.NODE_ENV === "development" ? "font-intermedium" : ""
        }">${stripEmojis(el.label).toString().trim()}</span>`;
      })
      .join(", ");
  };
  const av = getAnswersObj(conversationAnswers);
  const filterValuesForContract = (name, valuesExclude: string[] = []) => {
    const requirements = contract.recommendationRequirements;
    const requirementsByName =
      requirements && requirements[name] ? requirements[name] : [];
    const answers = av[name];
    let result = [];
    if (!answers) return result;

    if (!requirementsByName.length) {
      result = answers
        ? answers.reduce((acc, an) => {
            const o = [...acc];
            if (!valuesExclude.includes(an.value)) {
              o.push(an);
            }
            return o;
          }, [])
        : answers;
    } else {
      result = answers.reduce((acc, an) => {
        const o = [...acc];
        if (requirementsByName.includes(an.value)) {
          o.push(an);
        }
        return o;
      }, []);
    }
    return result;
  };

  let out = contract.recommendationText.replaceAll("[AGE]", age);

  const goalValues = filterValuesForContract("goal");
  out = out.replaceAll("[GOAL]", valuesToString(goalValues));

  const investingValues = filterValuesForContract("investing");
  out = out.replaceAll("[INVESTING]", valuesToString(investingValues));

  const kidsValues = filterValuesForContract("kids");
  out = out.replaceAll("[KIDS]", valuesToString(kidsValues));

  const kidsTestValues = filterValuesForContract("kids", ["5"]);
  const kidsTestString = valuesToString(kidsTestValues);
  out = out.replaceAll(
    "[KIDS_TESTAMENTE]",
    kidsTestString.length > 0 ? `, ${kidsTestString}` : ""
  );

  const kidsInsuranceValues = filterValuesForContract("kids_insurance");
  out = out.replaceAll("[KIDS_INS]", valuesToString(kidsInsuranceValues));

  const maritalStatusValues = filterValuesForContract("marital_status");
  out = out.replaceAll("[MARITAL_STATUS]", valuesToString(maritalStatusValues));

  const petsValues = filterValuesForContract("pets");
  out = out.replaceAll("[PETS]", valuesToString(petsValues));

  let gt = "";
  if (av["goal"]) {
    const optionExists = av["goal"].find((el) => el.value === "5");
    if (optionExists) {
      gt = ` och ${valuesToString([optionExists])}`;
    }
  }
  out = out.replaceAll("[GOAL_TESTAMENTE]", gt);

  let assets = "";
  let assetsNumber = "";
  const assetsAns = av["assets"][0];
  if (assetsAns && assetsAns.value === "1") {
    const ad = valuesToString(filterValuesForContract("assets_desc"));
    const aa = valuesToString(filterValuesForContract("assets_amount"));
    assets = `, har ${ad} till ett värde av ca ${aa}`;
    assetsNumber = `äger tillgångar till ett värde av ca ${aa}`;
  }
  out = out.replaceAll("[ASSETS]", assets);
  out = out.replaceAll("[ASSETS_NUMBER]", assetsNumber);

  if (maritalStatusValues.length > 0) {
    out = out.replaceAll(
      "[MARITAL_STATUS_LIV]",
      `du är ${valuesToString(maritalStatusValues)}`
    );
  } else {
    out = out.replaceAll("[MARITAL_STATUS_LIV]", "");
  }
  if (kidsValues.length > 0) {
    const comma = maritalStatusValues.length > 0 ? ", " : "du ";
    out = out.replaceAll("[KIDS_LIV]", `${comma}${valuesToString(kidsValues)}`);
  } else {
    out = out.replaceAll("[KIDS_LIV]", "");
  }

  let debt = "";
  const debtAns = av["debt"][0];
  if (debtAns && debtAns.value === "1") {
    let prefix =
      maritalStatusValues.length > 0 || kidsValues.length > 0
        ? " och har "
        : " har ";

    prefix =
      maritalStatusValues.length === 0 && kidsValues.length === 0
        ? " du har "
        : prefix;

    const dd = valuesToString(filterValuesForContract("debt_desc"));
    const da = valuesToString(filterValuesForContract("debt_amount"));
    debt = `${prefix}${
      dd.length > 0 ? `${dd}` : "lån"
    } till ett värde av ${da}`;
  }
  out = out.replaceAll("[DEBT_LIV]", debt);

  return out;
};

export const getRecommendationMainText = (name, ownContractsPrice) => {
  return [
    {
      id: uuidv4(),
      text: `Hej ${name},`,
    },
    {
      id: uuidv4(),
      text: "Tack för att du valt att göra din livsplanering med oss på Fenix Family. Baserat på vår konversation rekommenderar vi att du går vidare med ett Lugn&Ro-paket bestående av de avtal, försäkringar och eventuellt sparande nedan som du behöver. I paketet ingår inte bara tillgång till de digitala avtalen utan även möjligheten att boka möte med rådgivare.",
    },
    {
      id: uuidv4(),
      text: `Men en sak i taget, börja med att: köpa ditt avtalspaket, boka möte med rådgivare, få lugn och ro - njut av livet.`,
    },
    {
      id: uuidv4(),
      text: "Mvh",
    },
    {
      id: uuidv4(),
      text: "Fenix Family",
    },
  ];
};

export const getRecommendationPreContractText = (
  conversationAnswers: ConvAnswer[]
) => {
  const marital_status = conversationAnswers.filter(
    (el) => el.name === "marital_status"
  );
  const kids = conversationAnswers.filter((el) => el.name === "kids");

  const is_sabmo =
    marital_status.length > 0 &&
    (marital_status[0].value.includes("3") ||
      marital_status[0].value.includes("4"));

  const is_gift =
    marital_status.length > 0 && marital_status[0].value.includes("6");

  const is_sarkullbarn = kids.length > 0 && kids[0].value.includes("3");

  if (is_sabmo) {
    return "Som sambor regleras er bostad och allt som finns i den av Sambolagen. Allt utöver det - båtar, bilar, pengar - tillhör den som är registrerad ägare. Viktigt att veta är även att sambor inte ärver varandra så länge ni inte reglerar det i ett testamente.";
  }

  if (is_gift && is_sarkullbarn) {
    return "Eftersom du är gift och har barn från en tidigare relation är det extra viktigt att veta hur dina tillgångar fördelas vid arv. Till skillnad från gemensamma barn så har särkullbarn nämligen rätt att få ut hela sitt arv direkt vilket är bra att planera för redan nu. Om du vill fördela arvet på något annat sätt kan du reglera det i ett testamente.";
  }

  return "Här kan du se vilka avtal du behöver och vilka av dina svar som ligger till grund för vår rekommendation. Skapar du dessa avtal får du inte bara lite mer lugn och ro idag, du gör även framtiden enklare för din närmaste. ";
};

const inhImages = {
  "1": new URL("../assets/images/inheritance/1.jpg", import.meta.url),
  "1.1": new URL("../assets/images/inheritance/1.1.jpg", import.meta.url),
  "2": new URL("../assets/images/inheritance/2.jpg", import.meta.url),
  "2.1": new URL("../assets/images/inheritance/2.1.jpg", import.meta.url),
  "3": new URL("../assets/images/inheritance/3.jpg", import.meta.url),
  "4": new URL("../assets/images/inheritance/4.jpg", import.meta.url),
  "4.1": new URL("../assets/images/inheritance/4.1.jpg", import.meta.url),
  "5": new URL("../assets/images/inheritance/5.jpg", import.meta.url),
  "5.1": new URL("../assets/images/inheritance/5.1.jpg", import.meta.url),
  "6": new URL("../assets/images/inheritance/6.jpg", import.meta.url),
  "7": new URL("../assets/images/inheritance/7.jpg", import.meta.url),
  "7.1": new URL("../assets/images/inheritance/7.1.jpg", import.meta.url),
  "8": new URL("../assets/images/inheritance/8.jpg", import.meta.url),
  "8.1": new URL("../assets/images/inheritance/8.1.jpg", import.meta.url),
  "9": new URL("../assets/images/inheritance/9.jpg", import.meta.url),
  "10": new URL("../assets/images/inheritance/10.jpg", import.meta.url),
  "10.1": new URL("../assets/images/inheritance/10.1.jpg", import.meta.url),
  "11": new URL("../assets/images/inheritance/11.jpg", import.meta.url),
  "11.1": new URL("../assets/images/inheritance/11.1.jpg", import.meta.url),
  "12": new URL("../assets/images/inheritance/12.jpg", import.meta.url),
  "13": new URL("../assets/images/inheritance/13.jpg", import.meta.url),
  "13.1": new URL("../assets/images/inheritance/13.1.jpg", import.meta.url),
  "14": new URL("../assets/images/inheritance/14.jpg", import.meta.url),
  "14.1": new URL("../assets/images/inheritance/14.1.jpg", import.meta.url),
  "15": new URL("../assets/images/inheritance/15.jpg", import.meta.url),
  "16": new URL("../assets/images/inheritance/16.jpg", import.meta.url),
  "16.1": new URL("../assets/images/inheritance/16.1.jpg", import.meta.url),
  "17": new URL("../assets/images/inheritance/17.jpg", import.meta.url),
  "17.1": new URL("../assets/images/inheritance/17.1.jpg", import.meta.url),
  "18": new URL("../assets/images/inheritance/18.jpg", import.meta.url),
  "19": new URL("../assets/images/inheritance/19.jpg", import.meta.url),
  "19.1": new URL("../assets/images/inheritance/19.1.jpg", import.meta.url),
  "20": new URL("../assets/images/inheritance/20.jpg", import.meta.url),
  "20.1": new URL("../assets/images/inheritance/20.1.jpg", import.meta.url),
  "21": new URL("../assets/images/inheritance/21.jpg", import.meta.url),
  "22": new URL("../assets/images/inheritance/22.jpg", import.meta.url),
  "22.1": new URL("../assets/images/inheritance/22.1.jpg", import.meta.url),
  "23": new URL("../assets/images/inheritance/23.jpg", import.meta.url),
  "23.1": new URL("../assets/images/inheritance/23.1.jpg", import.meta.url),
  "24": new URL("../assets/images/inheritance/24.jpg", import.meta.url),
  "25": new URL("../assets/images/inheritance/25.jpg", import.meta.url),
  "25.1": new URL("../assets/images/inheritance/25.1.jpg", import.meta.url),
  "26": new URL("../assets/images/inheritance/26.jpg", import.meta.url),
  "26.1": new URL("../assets/images/inheritance/26.1.jpg", import.meta.url),
  "27": new URL("../assets/images/inheritance/27.jpg", import.meta.url),
  "28": new URL("../assets/images/inheritance/28.jpg", import.meta.url),
  "28.1": new URL("../assets/images/inheritance/28.1.jpg", import.meta.url),
  "29": new URL("../assets/images/inheritance/29.jpg", import.meta.url),
  "29.1": new URL("../assets/images/inheritance/29.1.jpg", import.meta.url),
  "30": new URL("../assets/images/inheritance/30.jpg", import.meta.url),
};

export const getInheritanceImages = (ca: ConvAnswer[]) => {
  const outImages = [];
  const answers = ca.reduce<{ [k: string]: string[] }>((acc, item) => {
    const a = { ...acc };
    a[item.name] = item.value;
    return a;
  }, {});

  const is_married =
    answers["marital_status"] && answers["marital_status"].includes("6");
  const is_coliv =
    answers["marital_status"] && answers["marital_status"].includes("4");
  const have_kids =
    answers["kids"] &&
    !answers["kids"].includes("1") &&
    !answers["kids"].includes("5");
  const kids_current = answers["kids"] && answers["kids"].includes("2");
  const kids_previous = answers["kids"] && answers["kids"].includes("3");
  const is_widow =
    answers["marital_status"] && answers["marital_status"].includes("8");
  const is_spouse_inherited =
    answers["spouse_inheritance"] &&
    answers["spouse_inheritance"].includes("1");

  if (
    is_married &&
    have_kids &&
    kids_current &&
    kids_previous &&
    is_widow &&
    is_spouse_inherited
  ) {
    outImages.push("1", "1.1");
  }

  if (
    is_married &&
    have_kids &&
    kids_current &&
    kids_previous &&
    is_widow &&
    !is_spouse_inherited
  ) {
    outImages.push("2", "2.1");
  }

  if (is_married && have_kids && kids_current && kids_previous && !is_widow) {
    outImages.push("3");
  }

  if (
    is_married &&
    have_kids &&
    kids_current &&
    !kids_previous &&
    is_widow &&
    is_spouse_inherited
  ) {
    outImages.push("4", "4.1");
  }

  if (
    is_married &&
    have_kids &&
    kids_current &&
    !kids_previous &&
    is_widow &&
    !is_spouse_inherited
  ) {
    outImages.push("5", "5.1");
  }

  if (is_married && have_kids && kids_current && !kids_previous && !is_widow) {
    outImages.push("6");
  }

  if (
    is_married &&
    have_kids &&
    !kids_current &&
    is_widow &&
    is_spouse_inherited
  ) {
    outImages.push("7", "7.1");
  }

  if (
    is_married &&
    have_kids &&
    !kids_current &&
    is_widow &&
    !is_spouse_inherited
  ) {
    outImages.push("8", "8.1");
  }

  if (is_married && have_kids && !kids_current && !is_widow) {
    outImages.push("9");
  }

  if (is_married && !have_kids && is_widow && is_spouse_inherited) {
    outImages.push("10", "10.1");
  }

  if (is_married && !have_kids && is_widow && !is_spouse_inherited) {
    outImages.push("11", "11.1");
  }

  if (is_married && !have_kids && !is_widow) {
    outImages.push("12");
  }

  if (
    !is_married &&
    is_coliv &&
    have_kids &&
    kids_current &&
    kids_previous &&
    is_widow &&
    is_spouse_inherited
  ) {
    outImages.push("13", "13.1");
  }

  if (
    !is_married &&
    is_coliv &&
    have_kids &&
    kids_current &&
    kids_previous &&
    is_widow &&
    !is_spouse_inherited
  ) {
    outImages.push("14", "14.1");
  }

  if (
    !is_married &&
    is_coliv &&
    have_kids &&
    kids_current &&
    kids_previous &&
    !is_widow
  ) {
    outImages.push("15");
  }

  if (
    !is_married &&
    is_coliv &&
    have_kids &&
    kids_current &&
    !kids_previous &&
    is_widow &&
    is_spouse_inherited
  ) {
    outImages.push("16", "16.1");
  }

  if (
    !is_married &&
    is_coliv &&
    have_kids &&
    kids_current &&
    !kids_previous &&
    is_widow &&
    !is_spouse_inherited
  ) {
    outImages.push("17", "17.1");
  }

  if (
    !is_married &&
    is_coliv &&
    have_kids &&
    kids_current &&
    !kids_previous &&
    !is_widow
  ) {
    outImages.push("18");
  }

  if (
    !is_married &&
    is_coliv &&
    have_kids &&
    !kids_current &&
    kids_previous &&
    is_widow &&
    is_spouse_inherited
  ) {
    outImages.push("19", "19.1");
  }

  if (
    !is_married &&
    is_coliv &&
    have_kids &&
    !kids_current &&
    kids_previous &&
    is_widow &&
    !is_spouse_inherited
  ) {
    outImages.push("20", "20.1");
  }

  if (
    !is_married &&
    is_coliv &&
    have_kids &&
    !kids_current &&
    kids_previous &&
    !is_widow
  ) {
    outImages.push("21");
  }

  if (
    !is_married &&
    is_coliv &&
    !have_kids &&
    is_widow &&
    is_spouse_inherited
  ) {
    outImages.push("22", "22.1");
  }

  if (
    !is_married &&
    is_coliv &&
    !have_kids &&
    is_widow &&
    !is_spouse_inherited
  ) {
    outImages.push("23", "23.1");
  }

  if (!is_married && is_coliv && !have_kids && !is_widow) {
    outImages.push("24");
  }

  if (
    !is_married &&
    !is_coliv &&
    !have_kids &&
    is_widow &&
    is_spouse_inherited
  ) {
    outImages.push("25", "25.1");
  }

  if (
    !is_married &&
    !is_coliv &&
    !have_kids &&
    is_widow &&
    !is_spouse_inherited
  ) {
    outImages.push("26", "26.1");
  }

  if (!is_married && !is_coliv && !have_kids && !is_widow) {
    outImages.push("27");
  }

  if (
    !is_married &&
    !is_coliv &&
    have_kids &&
    is_widow &&
    is_spouse_inherited
  ) {
    outImages.push("28", "28.1");
  }

  if (
    !is_married &&
    !is_coliv &&
    have_kids &&
    is_widow &&
    !is_spouse_inherited
  ) {
    outImages.push("29", "29.1");
  }

  if (!is_married && !is_coliv && have_kids && !is_widow) {
    outImages.push("30");
  }

  return outImages.reduce((acc, im) => {
    return inhImages[im] ? [...acc, inhImages[im]] : acc;
  }, []);
};

export const getPriceForAllContracts = () => {
  const tv = contracts.reduce((acc, el) => acc + el.price, 0);
  return tv / 100;
};
