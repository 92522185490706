import React, { useState } from "react";
import { Link } from "react-router-dom";

import { getBankIdIcon } from "../../services/icons";
import TrustBox from "../TrustBox";

export default function TrustboxArticle() {
  const [isHover, setIsHover] = useState(false);

  const renderLoginButton = (buttonClass: string, iconColor: string) => {
    return (
      <Link
        to="/login"
        className={
          buttonClass +
          ` inline-flex items-center gap-2 rounded border-[1px] border-deep-blue py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] lg:gap-3 lg:self-end`
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover
          ? getBankIdIcon("#fff", "20", "100%")
          : getBankIdIcon(iconColor, "20", "100%")}
        Logga in
      </Link>
    );
  };
  return (
    <div className="trustbox-block relative mb-10 rounded-[3px] bg-[#D1D8E0] px-5 py-[40px] md:py-[60px] md:px-10">
      <div className="flex flex-col items-start justify-start gap-6 self-stretch">
        <div className="self-stretch font-interblack text-[36px] italic leading-[39.60px] md:text-5xl md:leading-[52.80px]">
          Kom igång med
          <br />
          Fenix Family
        </div>
        <div className="items-start justify-start gap-20 self-stretch md:inline-flex md:items-center">
          <div className="-ml-5 flex grow basis-0 justify-start gap-6 md:shrink md:items-center">
            <TrustBox template={"5419b732fbfb950b10de65e5"} height={"24px"} />
          </div>
          {renderLoginButton(
            `mt-5 bg-transparent text-deep-blue hover:bg-deep-blue hover:text-white md:mt-0`,
            `#0C2632`
          )}
        </div>
      </div>
    </div>
  );
}
