import React, { useEffect, useState } from "react";

import ConversationList from "./conversation/List";
import Modal from "./Modal";

type ConversationEmbeddedType = {
  showModalOverride?: boolean;
  showInline?: boolean;
  showInModal?: boolean;
};

export default function ConversationEmbedded({
  showInline = true,
  showInModal = true,
  showModalOverride = false,
}: ConversationEmbeddedType) {
  const [convListModal, setConvListModal] = useState(false);

  useEffect(() => {
    const showModal = new URLSearchParams(window.location.search).get("m");
    const showModalNew = new URLSearchParams(window.location.search).get("cfm");
    if (showModal || showModalNew) {
      setConvListModal(true);
    }
  }, []);

  useEffect(() => {
    if (showModalOverride) {
      setConvListModal(true);
    }
  }, [showModalOverride]);

  return (
    <>
      {convListModal && showInModal && (
        <Modal title="" onClose={() => setConvListModal(false)}>
          <div className="flex h-auto min-h-[calc(100vh_-_62px)] flex-col bg-white lg:h-full lg:min-h-[70vh] lg:min-w-[730px] lg:bg-transparent">
            <ConversationList
              showHead={false}
              embeddedMode={true}
              convHeightClass="h-full lg:min-h-full lg:max-h-[70vh]"
              sumHeightClass="lg:max-h-[calc(70vh_-_150px)]"
              startHeightClass="overflow-hidden min-h-[calc(100vh_-_62px_-_110px)] lg:min-h-full"
            />
          </div>
        </Modal>
      )}
      {showInline && !convListModal && (
        <div className="h-full lg:h-[590px]">
          <ConversationList
            showHead={false}
            embeddedMode={true}
            convHeightClass="max-h-full lg:min-h-full lg:max-h-[590px]"
            sumHeightClass="lg:max-h-[calc(590px_-_30px_-_142px)]"
          />
        </div>
      )}
    </>
  );
}
