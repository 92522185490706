import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import CampaignContent from "../components/CampaignContent";
import CampaignNavigation from "../components/CampaignNavigation";
import Footer from "../components/Footer";
import HeadMenu from "../components/HeadMenu";
import Modal from "../components/Modal";
import { ServiceSectionProvider } from "../context/ServiceSectionContext";
import { useDevice } from "../services/hooks/useDevice";
// import { usePartnerPackageCleanUp } from "../services/hooks/usePartnerPackageCleanUp";
import { getButtonArrow } from "../services/icons";
import {
  getCookie,
  ppCampKey,
  removeCookie,
  setCookie,
} from "../services/storage";
import {
  decodeBase64,
  encodeBase64,
  useScrollPosition,
} from "../services/utils";

type CampaignData = {
  id: number;
  name: string | "";
  ceremony: string;
  type: string;
  poem1: string;
  poem2: string;
  poem3: string;
  burialPlace?: string | null;
  color?: string | null;
  flower?: string | null;
  music?: string | null;
  email?: string | null;
};

const videoMain = "https://static.fenixbegravning.se/fenix_family_campaign.mp4";
const videoMainThumb = new URL(
  "../assets/video/campaign.jpeg",
  import.meta.url
);

export default function CampaignPage() {
  const [data, setData] = useState({
    id: 0,
    name: "",
  });
  const [showInModal, setShowInModal] = useState(false);
  const [convListModal, setConvListModal] = useState(false);
  const refVideo = useRef<HTMLVideoElement>();
  const scrollPosition = useScrollPosition();
  const inputRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();
  // usePartnerPackageCleanUp();

  useEffect(() => {
    const data = getCookie(ppCampKey);
    if (!data) {
      return;
    }

    try {
      const objCampaign: CampaignData = JSON.parse(decodeBase64(data));
      if (objCampaign) {
        setData(objCampaign);
      }
    } catch (e) {
      // console.log(e);
      removeCookie(ppCampKey);
    }
  }, []);

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }

  function handleClickModal() {
    setShowInModal(true);
    setConvListModal(true);
  }

  const saveCampaignHistory = (uniqueId: number, value: string) => {
    const savePreplanObject = {
      id: uniqueId,
      name: value,
    };
    const dt = encodeBase64(JSON.stringify(savePreplanObject));
    setCookie(ppCampKey, dt);
  };

  function handleClick() {
    if (inputRef.current != null && inputRef?.current.value.length > 1) {
      navigate(`/till-eftervarlden/brevet/`);

      saveCampaignHistory(
        parseInt(String(Date.now() * Math.random())),
        inputRef?.current.value
      );
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (
      event.key === "Enter" &&
      inputRef.current != null &&
      inputRef?.current.value.length > 1
    ) {
      event.preventDefault();

      navigate(`/till-eftervarlden/brevet/`);
      saveCampaignHistory(
        parseInt(String(Date.now() * Math.random())),
        inputRef?.current.value
      );
    }
  };

  return (
    <ServiceSectionProvider>
      <div className="relative overflow-hidden bg-[#FFFEFB]">
        <div
          className={classNames(
            scrollPosition > 84 ? "bg-white" : "bg-transparent",
            "fixed z-30 w-full transition-colors"
          )}
        >
          <HeadMenu
            hideMenu={true}
            showButton={true}
            buttonClass={"eftervarlden_skriv_ditt_brev"}
            onClick={handleClickModal}
            hideLogin={true}
            sticky={scrollPosition > 84}
            theme={"light"}
            color={scrollPosition > 84 ? "#0C2632" : "#fff"}
          />
        </div>
        <div className="relative h-screen w-full text-white">
          <div className="absolute top-0 z-10 h-[80px] w-full lg:h-[120px]"></div>
          <video
            ref={refVideo}
            autoPlay
            playsInline
            muted
            loop
            src={videoMain}
            className="h-full min-h-screen w-screen object-cover"
            style={{ transform: "scale(1.0)" }}
            preload="auto"
            poster={videoMainThumb.href}
          />
          <div className="absolute top-0 z-20 h-full min-h-screen w-full">
            <div className="h-full px-5">
              <div className="container mx-auto h-full">
                <div
                  className={`relative flex flex-col items-center justify-between lg:items-end ${
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useDevice().isMobile && useDevice().mobileOS === "ios"
                      ? "h-[100dvh]"
                      : "h-full"
                  }`}
                >
                  <div className="flex grow flex-col items-center justify-between lg:items-start lg:justify-center">
                    <div></div>
                    <h1 className="font-interblack text-[36px] italic leading-none text-white lg:text-[64px]">
                      Ditt brev till <br />
                      eftervärlden
                    </h1>
                    <button
                      onClick={handleClickModal}
                      className="eftervarlden_skriv_ditt_brev mb-6 flex items-center justify-evenly bg-[#43262D] py-4 px-8 font-intermedium uppercase tracking-widest text-white hover:bg-[#43262D]/[.7] lg:relative lg:bottom-0 lg:mt-5 lg:mb-0  lg:h-[75px] lg:w-[260px]"
                    >
                      skriv ditt brev {getButtonArrow()}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CampaignNavigation />
        <CampaignContent buttonOnClick={handleClickModal} />
      </div>
      {convListModal && showInModal && (
        <Modal title="" onClose={() => setConvListModal(false)}>
          <div className="mx-auto flex h-full w-[90%] flex-col items-center rounded-[5px] bg-white py-16 text-center lg:min-h-[465px] lg:w-full lg:min-w-[750px]">
            <h3 className="text-center font-cormorantitalic text-[30px] italic leading-normal lg:text-[45px]">
              Vem vill du ska vara <br /> mottagare av ditt brev?
            </h3>
            <input
              type="text"
              name="letterInput"
              ref={inputRef}
              onKeyDown={handleKeyDown}
              placeholder="Namn, Smeknamn..."
              className="mt-12 h-11 w-[90%] rounded border border-[#CECABA] bg-white p-3 text-[13px] italic text-[#736F62] placeholder:text-[13px] placeholder:italic placeholder:text-[#736F62] focus:outline-none lg:w-full lg:max-w-[325px]"
            />
            <button
              id="eftervarlden_mottagare_fortsatt"
              className="mt-12 inline-flex items-center gap-2 rounded border-[1px] border-[#43262D] bg-[#43262D] py-4 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] text-white hover:border-[#43262D]/[.7] hover:bg-[#43262D]/[.7]"
              onClick={handleClick}
            >
              fortsätt {getButtonArrow("#fff", "18", "18")}
            </button>
          </div>
        </Modal>
      )}
      <Footer />
    </ServiceSectionProvider>
  );
}
