import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { steps, timeline } from "../assets/data/datalist";
import AdvisorsSection from "../components/AdvisorsSection";
import ConversationEmbedded from "../components/ConversationEmbedded";
import Footer from "../components/Footer";
import HeadMenu from "../components/HeadMenu";
import ServicesSingleSection from "../components/ServicesSingleSection";
import TimelineSlider from "../components/slider/TimelineSlider";
import TrustBox from "../components/TrustBox";
import { ServiceSectionProvider } from "../context/ServiceSectionContext";
import { useMediaQuery } from "../services/hooks/useMediaQuery";
import {
  getBankIdIcon,
  getButtonArrow,
  getQuoteIcon,
  getShortDownArrowIcon,
} from "../services/icons";
import { useScrollPosition } from "../services/utils";

const aboutUsImg = new URL("../assets/images/about_Fenix.jpg", import.meta.url);

export default function AboutPage({ title }: { title?: string }) {
  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }
  const [showConversationModal, setShowConversationModal] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const ref = useRef<HTMLDivElement | null>(null);
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    document.title = title!;
  }, []);

  const renderLoginButton = (
    buttonClass: string,
    iconColor: string,
    showArrow?: true
  ) => {
    return (
      <Link
        to="/login"
        className={
          `inline-flex items-center gap-2 rounded border-[1px] border-deep-blue bg-deep-blue py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] lg:gap-3 lg:self-end ` +
          buttonClass
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover
          ? getBankIdIcon("#fff", "20", "100%")
          : getBankIdIcon(iconColor, "20", "100%")}
        Kom igång
        {showArrow
          ? isHover
            ? getButtonArrow("#fff")
            : getButtonArrow(iconColor)
          : ""}
      </Link>
    );
  };

  const timelineItem = (year: string, text: string, index: number) => {
    return (
      <li
        ref={ref}
        key={index}
        className="relative self-stretch font-medium text-[#0C2632] before:absolute before:top-1.5 before:-left-8 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:bg-[#CECABA] before:content-[''] after:absolute after:-left-[35px] after:top-0 after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#fff] after:content-[''] last:before:bg-[#273F5C]"
      >
        <p className="font-internormal text-base font-semibold tracking-[0.25em]">
          {year}
        </p>
        <p className="text-[13px]">{text}</p>
      </li>
    );
  };

  useEffect(() => {
    if (showConversationModal) {
      setShowConversationModal(false);
    }
  }, [showConversationModal]);
  return (
    <ServiceSectionProvider>
      <div className="relative overflow-hidden bg-[#FFFEFB]">
        <div
          className={classNames(
            scrollPosition > 84 ? "bg-white" : "bg-transparent",
            "fixed z-1000 w-full transition-colors"
          )}
        >
          <HeadMenu
            sticky={scrollPosition > 84}
            theme={"dark"}
            color={scrollPosition > 84 ? "#0C2632" : "#0C2632"}
          />
        </div>
        <div className="relative w-full text-white">
          <div className="flex flex-col-reverse pt-44 lg:flex-row lg:gap-5 lg:pt-0">
            <div className="w-full lg:max-w-[50%]">
              <img
                src={aboutUsImg.href}
                alt="Fenix"
                className="relative h-auto w-[100%] max-w-[85%] rounded-tr-[13px] rounded-br-[13px] object-cover object-right-top lg:h-full lg:max-h-[640px] lg:max-w-[100%] lg:rounded-l-none lg:rounded-tr-[0] lg:rounded-br-[24px] 2k:max-h-[1000px] 4k:max-h-[1200px]"
                loading="lazy"
              />
            </div>
            <div className="w-full lg:max-w-[50%]">
              <div className="flex h-full flex-col justify-end pr-5 pl-5 text-deep-blue ">
                <p className="mb-2 text-xs uppercase tracking-[0.2em]">
                  Om oss
                </p>
                <h1 className="mb-9 font-interblack text-[28px] italic leading-[31px] lg:mb-0 lg:text-[64px] lg:leading-[74px]">
                  Kompetenta människor och smarta digitala tjänster
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full bg-white text-deep-blue">
          <div className="mx-auto px-5 pb-16 pt-28 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-16 lg:pt-36 xl:max-w-[1360px]">
            <div id="tidslinje" className="max-w-[1000px]">
              <h3 className="mb-3 font-internormal text-base font-semibold uppercase tracking-[0.25em]">
                Empati, erfarenhet och kunskap
              </h3>
              <p className="mb-10 whitespace-pre-wrap font-interlight text-base leading-[22px]">
                Fenix Family föddes ur Fenix Begravning där vi ofta fick frågor
                om andra saker än själva begravningsceremonin. För i livets
                slutskede ställs ofta ekonomi, juridik och hur man planerat både
                sitt liv och framtiden för sina närmaste, på sin spets.
                <br />
                Idag kan vi hjälpa alla som vill skapa testamenten,
                bouppteckningar, äktenskapsförord, avtal och fullmakter, med en
                enkel, trygg och digital lösning på problem som ofta kan
                upplevas som känsliga och svåra.{" "}
                <span className="mt-3 inline-flex items-center font-internormal text-[14px] font-semibold uppercase tracking-[0.12em] lg:mt-0">
                  Läs mer om vad vi kan hjälpa dig med{" "}
                  {getShortDownArrowIcon("#0C2632")}
                </span>
              </p>
              <p className="font-intermedium text-[12px] uppercase tracking-[0.2em]">
                Välkommen till Fenix Family
              </p>
              <p className="font-interlight italic">
                Charlotte, Johannes och Mikael
              </p>
            </div>
            {isDesktop ? (
              <div className="flex justify-end">
                <div className="inline-flex w-full items-start justify-start px-6 py-10 lg:mr-24 lg:max-w-[480px]">
                  <ul className="relative inline-flex list-none flex-col items-start justify-start gap-6 pl-8 pr-5 before:absolute before:top-0 before:left-[5px] before:h-[110%] before:w-[1px] before:bg-[#CECABA] before:content-['']">
                    {timeline.map((item, index) => {
                      return timelineItem(item.year, item.text, index);
                    })}
                  </ul>
                </div>
              </div>
            ) : (
              <TimelineSlider />
            )}
          </div>
          <div className="mx-auto px-5 pt-28 pb-28 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pt-16 lg:pb-32 xl:max-w-[1360px]">
            <h2 className="mb-7 text-center font-interblack text-[36px] italic leading-[1.1] lg:text-[48px] lg:leading-[1.1]">
              Varför Fenix livsplanering?
            </h2>
            <p
              id="varfor-livsplanera"
              className="mx-auto mb-14 max-w-[1100px] text-center font-interlight text-lg italic"
            >
              Med Fenix Livsplanering får du snabbt, tryggt och enkelt ordning
              på viktiga juridiska delar av ditt och dina närmastes liv. <br />
              Här, nu och inför framtiden. Avtal och önskemål som gör att du
              helhjärtat kan ägna livet åt saker du verkligen tycker om.
            </p>
            <div className="mx-auto mb-20 flex max-w-[1100px] flex-col items-center justify-around gap-10 lg:flex-row lg:items-start">
              {steps.map((step, index) => (
                <div
                  key={step.title}
                  className="flex shrink-0 grow basis-0 flex-col items-center justify-center gap-5"
                >
                  <div className="flex h-[152px] w-[152px] items-center justify-center rounded-full bg-[#E8E3D2]">
                    <img
                      src={step.icon.href}
                      alt="Fenix"
                      className="w-[50px]"
                      loading="lazy"
                    />
                  </div>
                  <div className="flex max-w-[310px] flex-col text-center text-dark-blue">
                    <p className="mb-4 font-intermedium text-base uppercase tracking-[0.25em]">
                      Steg {index + 1}
                    </p>
                    <p className="font-internormal text-[13px] leading-[15px] ">
                      {step.title}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center">
              {renderLoginButton(
                `bg-deep-blue text-[#fff] hover:border-[#6F86A0] hover:bg-[#6F86A0]`,
                `#fff`
              )}
            </div>
          </div>
          <div
            id="jobba-med-oss"
            className="relative flex h-[420px] w-full flex-col items-center justify-center bg-deep-blue bg-team bg-cover bg-no-repeat px-6 text-white lg:h-[720px]"
          >
            <div className="max-w-[590px] text-center">
              <h2 className="mb-2 font-interblack text-[28px] italic leading-[1.1] lg:text-[48px]">
                Bli en del av Fenix Family
              </h2>
              <p className="font-internormal text-base font-medium">
                Vi ser alltid fram emot att välkomna nya medlemmar till vår
                familj. Är du intresserad av att jobba i ett hängivet team som
                vill att juridiken ska göra människors liv bättre, hör av dig!
              </p>
              <Link
                to={"https://careers.fenixbegravning.se/"}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-8 inline-flex h-[52px] items-center gap-2 rounded border-[1px] border-deep-blue bg-deep-blue px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] text-white hover:border-[#6F86A0] hover:bg-[#6F86A0] lg:gap-3 lg:self-end"
              >
                JOBBA MED OSS
              </Link>
            </div>
          </div>
          <div className="relative w-full bg-deep-blue text-white">
            <div className="mx-auto px-5 pt-16 pb-20 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-24 lg:pt-24 xl:max-w-[1360px]">
              <div className="quote-block relative max-w-[970px] rounded-[3px] pt-10 pb-8 lg:pb-12 lg:pt-20">
                <span className="quote-icon absolute -left-0.5 top-5 opacity-40 lg:-left-3 lg:top-10">
                  {getQuoteIcon(
                    "#CECABA",
                    isDesktop ? "91" : "48px",
                    isDesktop ? "76" : "40px"
                  )}
                </span>
                <div className="z-1 relative font-interlight text-[24px] italic leading-[29px] lg:text-[42px] lg:leading-[50.4px]">
                  Jag är mycket nöjd med Fenix Familys tjänst. Du får hjälp
                  snabbt. Det är lätt att hitta den information du letar efter.
                  Du känner dig trygg hela vägen."
                  <div className="mt-10 flex flex-col items-start lg:flex-row lg:items-end">
                    {renderLoginButton(
                      `!bg-[#F7D152] !py-2.5 not-italic text-[#0C2632] hover:text-[#fff] hover:border-[#fff] hover:!bg-[#0C2632]/10`,
                      `#0C2632`,
                      true
                    )}
                    <div className="-ml-4 mt-12 lg:ml-10 lg:mt-0">
                      <TrustBox
                        template={"5419b732fbfb950b10de65e5"}
                        height={"24px"}
                        theme={"dark"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AdvisorsSection
            title="Partners"
            buttonOnClick={() => setShowConversationModal(true)}
          />
          <div className="mx-auto px-5 pt-12 pb-24 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pt-16 lg:pb-40 xl:max-w-[1360px]">
            <ServicesSingleSection />
            <div className="mt-10 flex justify-center lg:mt-16">
              {renderLoginButton(
                `bg-deep-blue text-[#fff] hover:border-[#6F86A0] hover:bg-[#6F86A0]`,
                `#fff`
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ConversationEmbedded
        showInline={false}
        showModalOverride={showConversationModal}
      />
    </ServiceSectionProvider>
  );
}
