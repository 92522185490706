import React from "react";
import { Link } from "react-router-dom";

const chatIcon = new URL("../assets/images/chat.svg", import.meta.url);
const fileIcon = new URL("../assets/images/file.svg", import.meta.url);
const vaultIcon = new URL("../assets/images/vault.svg", import.meta.url);

type PageHeadProps = {
  title: string;
  subtitle?: string;
  icon?: "chat" | "file" | "vault";
  mode?: "compact";
  rightComp?: React.ReactNode;
};

export default function PageHead({
  title,
  subtitle,
  icon,
  mode,
  rightComp,
}: PageHeadProps) {
  let image;
  let imageWidth;
  let titleClasses = "font-intermedium text-[20px] lg:text-[24px]";
  switch (icon) {
    case "chat":
      image = chatIcon;
      imageWidth = 33;
      break;
    case "file":
      image = fileIcon;
      imageWidth = 20;
      break;
    case "vault":
      image = vaultIcon;
      imageWidth = 24;
      break;
    default:
      image = null;
      imageWidth = null;
      break;
  }
  if (mode === "compact") {
    titleClasses = "";
  }
  return (
    <div className="relative flex flex-row items-start justify-center gap-2 border-b border-[#CFCAB8] border-opacity-50 pb-3 lg:gap-4 lg:border-none lg:p-0">
      {image && imageWidth && (
        <img src={image.href} width={imageWidth} alt="" />
      )}
      <div className="flex grow flex-col lg:flex-row lg:border-b lg:border-[#CFCAB8] lg:border-opacity-50 lg:pb-3">
        <div className="grow">
          <div className="text-[16px] uppercase tracking-[0.5px] lg:tracking-[2px]">
            <div className={`leading-none ${titleClasses}`}>{title}</div>
            {subtitle && (
              <div className="mt-1 text-[12px] lg:mt-2 lg:text-[16px]">
                {subtitle}
              </div>
            )}
          </div>
        </div>
        {rightComp}
      </div>
    </div>
  );
}
