import React, { useState } from "react";

import { getButtonArrow, getCloseIcon } from "../services/icons";
import Button from "./Button";
import ContactUs from "./ContactUs";
import AdvisorsSlider from "./slider/AdvisorsSlider";

type AdvisorPropsType = {
  onClick: (m: boolean) => void;
  number?: boolean;
};

const bgIamge = new URL("../assets/images/lawyer.jpg", import.meta.url);

const CompactSection = ({ onClick }: AdvisorPropsType) => {
  const toggleMode = () => {
    onClick(false);
    const el = document.getElementById("vara-jurister");
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };
  return (
    <div className="relative flex flex-col overflow-hidden rounded-[8px] bg-[#f3f1e8] text-dark-blue lg:flex-row lg:justify-between">
      <div className="flex flex-1 flex-col items-start justify-center px-6 pt-10 pb-10 lg:px-10 lg:pt-10 lg:pb-6 xl:px-28 xl:py-10">
        <h2 className="mb-3 font-interblack text-[32px] italic leading-none lg:mb-5 lg:text-[48px]">
          Enkelt och digitalt, mänskligt och empatiskt.
        </h2>
        <div
          className="max-w-lg font-interlight"
          dangerouslySetInnerHTML={{
            __html:
              "Vårt mål är att göra familjejuridik tillgängligt och enkelt för alla. Det ska inte behövas mer än en BankID-inloggning, svar på några frågor om din livssituation och vad du vill ha hjälp med för att få avtal på plats. Men det kan vara betryggande att veta, att bakom vår enkla digitala lösning finns riktiga människor med stor juridisk kompetens redo att sätta sig in i din livssituation, och lösa de utmaningar du står inför.",
          }}
        />
        <Button
          cssClasses="!px-0 py-4 mt-7 bg-transparent border-transparent hover:underline !text-deep-blue justify-center !text-[14px] lg:px-0 lg:mt-9 lg:py-[16px]"
          rightArrow={false}
          title={"Träffa våra jurister här"}
          onClick={toggleMode}
        >
          {getButtonArrow("#0C2632", "20", "100%")}
        </Button>
      </div>
      <div className="relative h-full max-h-[325px] flex-1 overflow-hidden object-cover lg:block lg:max-h-[655px] lg:max-w-[535px] lg:rounded-r-[8px] lg:rounded-t-[0]">
        <img
          src={bgIamge.href}
          alt="Fenix"
          className="relative h-full w-full lg:top-[50%]"
        />
      </div>
    </div>
  );
};

const ExpandedSection = ({ onClick, number }: AdvisorPropsType) => {
  return (
    <div className="relative flex flex-col overflow-hidden rounded-[8px] bg-[#f3f1e8] px-0 py-24 text-dark-blue lg:flex-col lg:p-10 xl:px-28 xl:py-28">
      <div
        className="absolute top-5 right-5 z-10 flex h-[36px] w-[36px] cursor-pointer justify-center rounded-full bg-white bg-opacity-80"
        onClick={() => onClick(true)}
      >
        {getCloseIcon("#525252", "18", "100%")}
      </div>
      <AdvisorsSlider />
      <div className="flex justify-center">
        <ContactUs
          theme="dark"
          phoneNumber={number}
          cssClass={"lg:text-[14px] lg:py-[15px] lg:px-[23px]"}
        />
      </div>
    </div>
  );
};

export default function AdvisorToggleSection({ dynamicNumber = false }) {
  const [compactMode, setCompactMode] = useState(true);

  const toggleMode = (mode = false) => {
    setCompactMode(mode);
  };

  if (compactMode) {
    return <CompactSection onClick={toggleMode} />;
  }

  return <ExpandedSection onClick={toggleMode} number={dynamicNumber} />;
}
