import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

const logo = new URL("../assets/images/logo-white.svg", import.meta.url);
const closeIcon = new URL("../assets/images/icon-close.svg", import.meta.url);

type Props = PropsWithChildren<{
  title: string;
  titleClass?: string;
  showLogo?: boolean;
  onClose: () => void;
  footerComp?: React.ReactElement;
}>;

const ModalFullScreen = ({
  title,
  titleClass,
  showLogo,
  children,
  onClose,
  footerComp,
}: Props) => {
  let tClasses = "font-internormal text-white uppercase text-[12px]";
  if (titleClass) {
    tClasses = titleClass;
  }

  return (
    <div
      className="fixed z-50 flex h-screen w-full flex-col"
      style={{ backgroundColor: "rgba(0, 0, 0, .75)" }}
    >
      <div className="flex items-center p-5">
        {showLogo && (
          <div className="flex justify-center">
            <Link to="/todo">
              <img
                src={logo.href}
                alt="Fenix"
                width="42"
                height="42"
                className="h-fit max-w-none"
              />
            </Link>
          </div>
        )}

        <div className="flex flex-grow items-center">
          <div className={`w-full ${tClasses}`}>{title}</div>
        </div>
        <div className="relative top-0 right-0">
          <img
            src={closeIcon.href}
            alt="close"
            width="22"
            height="22"
            className="box-content h-fit max-w-none cursor-pointer"
            onClick={onClose}
          />
        </div>
      </div>
      <div className="lg:mb-none mb-[80px] flex-grow overflow-auto">
        {children}
      </div>
      {footerComp}
    </div>
  );
};

export default ModalFullScreen;
