import { useEffect, useState } from "react";

interface PostsPilar {
  id: number;
  title: string;
  slug: string;
}

interface PostsSecondary {
  id: number;
  title: string;
  slug: string;
}

interface Categories {
  id: number;
  title: string;
  slug: string;
  parent: object;
}

const useFetchPosts = (
  url?: string,
  urlSecondary?: string,
  urlCat?: string
) => {
  const [posts, setPosts] = useState(null);
  const [postsSecondary, setPostsSecondary] = useState(null);
  const [categoriesList, setCategoriesList] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const responsePostsPilar = await fetch(url);
        const responsePostsSecondary = await fetch(urlSecondary);
        const responceCategory = await fetch(urlCat);

        const PostsPilarJson = (await responsePostsPilar.json()) as PostsPilar;
        const PostsSecondaryJson =
          (await responsePostsSecondary.json()) as PostsSecondary;
        const CategoriesJson = (await responceCategory.json()) as Categories;

        setPosts(PostsPilarJson);
        setPostsSecondary(PostsSecondaryJson);
        setCategoriesList(CategoriesJson);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [url]);

  return { loading, error, posts, postsSecondary, categoriesList };
};

export default useFetchPosts;
