// Import Swiper styles
import "swiper/swiper-bundle.css";

import React, { useState } from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { reviews } from "../../assets/data/datalist";
import { getQuoteIcon } from "../../services/icons";

export default function ReviewsSlider() {
  const [pagesEl, setPagesEl] = useState<HTMLElement | null>(null);
  return (
    <>
      <div className="relative mx-auto max-w-[255px] pt-2.5 pb-10 lg:max-w-[645px]">
        <Swiper
          modules={[Pagination]}
          keyboard={true}
          slidesPerView={1}
          allowTouchMove={true}
          simulateTouch={true}
          grabCursor={true}
          edgeSwipeDetection={true}
          touchReleaseOnEdges={true}
          pagination={{
            clickable: true,
            el: pagesEl,
          }}
          mousewheel={{
            releaseOnEdges: true,
          }}
          className="swiper-reviews mx-auto"
        >
          {reviews.map((review: any, index: number) => {
            return (
              <SwiperSlide key={index} className="">
                <div className="relative mt-5 pt-5">
                  <div className="absolute top-0 left-3">{getQuoteIcon("rgba(206, 202, 186, 0.4)", "45px", "37px")}</div>
                  <div className="text-center">
                    <p className="font-interlight text-base italic leading-snug text-[#736F62]">{review.text}</p>
                    <p className="font-intermedium text-base italic leading-snug text-[#736F62]">-{" "}{review.title}</p>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div
          ref={(node) => setPagesEl(node)}
          className="swiper-pagination swiper-pagination--reviews swiper-pagination-clickable swiper-pagination-bullets left-0 right-0 mx-auto mt-3"
        ></div>
      </div>
    </>
  );
}
