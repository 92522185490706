import { usePDF } from "@react-pdf/renderer";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Footer from "../components/Footer";
import HeadMenu from "../components/HeadMenu";
import LoaderScreen from "../components/LoaderScreen";
import Popout from "../components/LocationsSelect";
import Modal from "../components/Modal";
import PdfPreplanPreview from "../components/PdfPreplanPreview";
import PdfPreview from "../components/PdfPreview";
import { ServiceSectionProvider } from "../context/ServiceSectionContext";
import { getBankIdIcon, getButtonArrow } from "../services/icons";
import {
  getCookie,
  ppCampKey,
  removeCookie,
  setCookie,
} from "../services/storage";
import {
  decodeBase64,
  encodeBase64,
  useScrollPosition,
} from "../services/utils";

type CampaignData = {
  id: number;
  name: string | "";
  ceremony: string | null;
  type: string | null;
  poem1: string;
  poem2: string;
  poem3: string;
  burialPlace?: string | null;
  color?: string | null;
  flower?: string | null;
  music?: string | null;
  email?: string | null;
};

interface Location {
  id: number;
  label: string;
  value: string;
}

export default function LetterPage() {
  const scrollPosition = useScrollPosition();
  const [showPreview, setShowPreview] = useState(false);
  const [data, setData] = useState({
    id: 0,
    name: "",
    ceremony: "",
    type: "",
    poem1: "",
    poem2: "",
    poem3: "",
    burialPlace: null,
    color: null,
    flower: null,
    music: null,
    email: null,
  });
  const [isHover, setIsHover] = useState(false);
  const [locations, setLocations] = useState<Array<Location>>([]);
  const [isPoems, setIsPoems] = useState({
    auto: true,
    poem1:
      "Då var min tid kommen. Sörj mig lagom, men fira livet som vi fick tillsammans – tidlöst, lite grann varje dag. För livet som vi fick, kan aldrig begravas för att jag inte längre finns vid din sida – tack vare det, så ser du mig leende när du blundar. Men nu får du fortsätta själv, utan mig – men aldrig är du ensam. Allt kommer att bli bra. Jag kommer att titta till dig ibland; din minnesbild, är bara jag som tackar dig för livet som vi fick... ",
  });
  const [funeralCeremony, setFuneralCeremony] = useState("");
  const [selectedFuneralType, setSelectedFuneralType] = useState("");
  const [color, setColor] = useState("");
  const [music, setMusic] = useState("");
  const [flower, setFlower] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("Ogiltiga tecken i adressen");
  const [emailDirty, setEmailDirty] = useState(false);
  const refCheckbox = useRef<HTMLInputElement>(null);
  const [showError, setShowError] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [loading, setLoading] = useState(true);
  const [previewModal, setPreviewModal] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const navigate = useNavigate();

  const options = require("../assets/data/fnx_location.json");

  const renderLoginButton = (
    buttonClass: string,
    iconColor: string,
    title: string
  ) => {
    return (
      <Link
        to="/login"
        id="eftervarlden_loggain"
        className={
          buttonClass +
          ` mt-10 inline-flex items-center gap-2 rounded border-[1px] border-[#43262D] bg-[#43262D] py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] text-white hover:border-[#43262D]/[.7] hover:bg-[#43262D]/[.7] lg:gap-3 lg:self-center`
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover
          ? getBankIdIcon("#fff", "20", "100%")
          : getBankIdIcon(iconColor, "20", "100%")}
        {title}
      </Link>
    );
  };

  const [pdfIns, updatePdfIns] = usePDF({
    document: (
      <PdfPreplanPreview
        name={data.name}
        auto={isPoems.auto}
        ceremony={funeralCeremony}
        type={selectedFuneralType}
        burialPlace={selectedLocation}
        color={color}
        flower={flower}
        music={music}
        poem1={isPoems.poem1}
      />
    ),
  });

  useEffect(() => {
    updatePdfIns(
      <PdfPreplanPreview
        name={data.name}
        auto={isPoems.auto}
        ceremony={funeralCeremony}
        type={selectedFuneralType}
        burialPlace={selectedLocation}
        color={color}
        flower={flower}
        music={music}
        poem1={isPoems.poem1}
      />
    );
  }, [
    data,
    isPoems,
    funeralCeremony,
    selectedFuneralType,
    selectedLocation,
    color,
    flower,
    music,
  ]);

  useEffect(() => {
    if (pdfIns.blob && !pdfIns.loading) {
      const reader = new FileReader();
      reader.readAsDataURL(pdfIns.blob);
      reader.onloadend = function () {
        const base64String = reader.result;
        setPreviewFile({
          content: base64String,
          type: pdfIns.blob?.type,
          filename: "",
        });
      };
    }
  }, [showPreview]);

  useEffect(() => {
    if (data.name && data.name.trim().length !== 0) {
      setLoading(false);
    }
  }, [data.name]);

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }

  function onChangeLocation(value: string) {
    const selectedLocationName = value;
    setSelectedLocation(selectedLocationName);
  }

  useEffect(() => {
    const data = getCookie(ppCampKey);
    if (!data) {
      return navigate(`/kampanj`);
    }

    try {
      const objCampaign: CampaignData = JSON.parse(decodeBase64(data));
      if (objCampaign) {
        setData(objCampaign);
      }
    } catch (e) {
      // console.log(e);
      removeCookie(ppCampKey);
      navigate(`/kampanj`);
    }

    setLocations(options);
  }, []);

  const saveCampaignHistory = (
    uniqueId: number,
    value: string,
    ceremony: string | null,
    type: string | null,
    poem1: string,
    burialPlace?: string | null,
    color?: string | null,
    flower?: string | null,
    music?: string | null
  ) => {
    const savePreplanObject = {
      id: uniqueId,
      name: value,
      ceremony: ceremony,
      type: type,
      poem1: poem1,
      burialPlace: burialPlace,
      color: color,
      flower: flower,
      music: music,
    };
    const dt = encodeBase64(JSON.stringify(savePreplanObject));
    setCookie(ppCampKey, dt);
  };

  const saveCampaignMailHistory = (
    uniqueId: number,
    value: string,
    ceremony: string | null,
    type: string | null,
    poem1: string,
    burialPlace?: string | null,
    color?: string | null,
    flower?: string | null,
    music?: string | null,
    email?: string | null
  ) => {
    const savePreplanObject = {
      id: uniqueId,
      name: value,
      ceremony: ceremony,
      type: type,
      poem1: poem1,
      burialPlace: burialPlace,
      color: color,
      flower: flower,
      music: music,
      email: email,
    };
    const dt = encodeBase64(JSON.stringify(savePreplanObject));
    setCookie(ppCampKey, dt);
  };

  const handleChangePoem = () => {
    setIsPoems({
      ...isPoems,
      auto: !isPoems.auto,
      poem1:
        refCheckbox.current != null && refCheckbox.current.checked
          ? "Då var min tid kommen. Sörj mig lagom, men fira livet som vi fick tillsammans – tidlöst, lite grann varje dag. För livet som vi fick, kan aldrig begravas för att jag inte längre finns vid din sida – tack vare det, så ser du mig leende när du blundar. Men nu får du fortsätta själv, utan mig – men aldrig är du ensam. Allt kommer att bli bra. Jag kommer att titta till dig ibland; din minnesbild, är bara jag som tackar dig för livet som vi fick..."
          : "",
    });
  };

  const handleChangeFuneralType = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedFuneralType(event.target.value);
    event.target.classList.add("!text-deep-blue");
  };

  const handleChangeCeremony = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setFuneralCeremony(event.target.value);
    event.target.classList.add("!text-deep-blue");
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setIsPoems({
      ...isPoems,
      [event.target.name]: value,
    });
  };

  function isValidEmail(email: any) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    saveCampaignHistory(
      data.id,
      data.name,
      funeralCeremony !== "" && funeralCeremony === "yes"
        ? "Jag vill ha en begravningsceremoni"
        : funeralCeremony !== "" && funeralCeremony === "no"
        ? "Jag vill inte ha en begravningsceremoni"
        : null,
      selectedFuneralType !== "" && selectedFuneralType === "cremation"
        ? "Jag vill kremeras"
        : selectedFuneralType !== "" && selectedFuneralType === "coffin"
        ? "Jag vill begravas i kista"
        : null,
      isPoems.poem1.trim().length !== 0 ? isPoems.poem1 : "",
      selectedLocation !== "" ? selectedLocation : null,
      color,
      flower,
      music
    );
    if (
      selectedLocation.trim().length !== 0 &&
      funeralCeremony !== "" &&
      selectedFuneralType !== ""
    ) {
      setShowPreview(true);
    }
    if (
      selectedLocation.trim().length === 0 ||
      funeralCeremony === "" ||
      selectedFuneralType === ""
    ) {
      setShowError(true);
    }
  }

  function handleClickMail(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    const objCampaign: CampaignData = JSON.parse(getCookie(ppCampKey) || "{}");
    if (!isValidEmail(email)) {
      setEmailError("Ogiltiga tecken i adressen");
      setEmailDirty(true);
    } else {
      setEmailError("");
      setEmailDirty(false);
      saveCampaignMailHistory(
        data.id,
        data.name,
        objCampaign.ceremony,
        objCampaign.type,
        objCampaign.poem1,
        objCampaign.burialPlace,
        objCampaign.color,
        objCampaign.flower,
        objCampaign.music,
        email !== "" ? email : null
      );
    }
  }

  if (loading) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }

  return (
    <ServiceSectionProvider>
      <div className="relative overflow-hidden bg-[#FFFEFB] bg-campaign bg-cover bg-no-repeat">
        <div
          className={classNames(
            scrollPosition > 84 ? "bg-white" : "bg-transparent",
            "fixed z-30 w-full transition-colors"
          )}
        >
          <HeadMenu
            hideMenu={true}
            hideLogin={true}
            sticky={scrollPosition > 84}
            theme={"light"}
            color={scrollPosition > 84 ? "#0C2632" : "#fff"}
          />
        </div>
        <div className="container relative mx-auto my-20 flex min-h-screen w-[95%] flex-grow flex-col rounded-2xl bg-[#FFFEFB] px-5 py-10 lg:my-28 lg:max-w-[1320px] lg:py-32 lg:px-24">
          <div className="mb-4 flex items-center justify-between">
            <p className="font-intermedium text-xs uppercase tracking-[.2em] text-[#736F62]">
              brev till eftervärlden
            </p>
            {!showPreview && (
              <div className="flex items-center">
                <label
                  htmlFor="defaultPoem"
                  className="flex cursor-pointer items-center gap-2 text-[13px]"
                >
                  <div className="relative flex items-center before:absolute before:top-2 before:left-1.5 before:h-1.5 before:w-3 before:-rotate-45 before:border-l-2 before:border-b-2 before:border-l-[#fff] before:border-b-[#fff] before:content-['']">
                    <input
                      type="checkbox"
                      ref={refCheckbox}
                      id="eftervarlden_brev_hjalp"
                      name="defaultPoem"
                      defaultChecked={true}
                      onChange={handleChangePoem}
                      className="h-[24px] w-[24px] cursor-pointer appearance-none rounded-full border border-[#6F86A0] checked:!bg-[#6F86A0]"
                    />
                  </div>
                  Jag vill ha hjälp att skriva mitt brev
                </label>
              </div>
            )}
          </div>
          {!showPreview && (
            <form>
              <div className="relative mb-10 rounded border border-[#CFCAB8] bg-white px-6 py-4 italic text-deep-blue">
                <h1 className="font-cormorantitalic text-[33px] capitalize leading-tight focus:outline-none lg:text-[40px]">
                  Älskade {data.name},
                </h1>
                <textarea
                  name="poem1"
                  id="poem1"
                  cols={30}
                  rows={4}
                  readOnly={isPoems.auto ? true : false}
                  onChange={handleChange}
                  value={isPoems.poem1}
                  placeholder="Skriv ditt brev här..."
                  className="w-full font-cormorantitalic text-[20px] italic placeholder:text-[#CECABA] focus:outline-none lg:text-[24px]"
                ></textarea>
              </div>
              <div className="mt-8 mb-10 grid gap-8 lg:mt-4 lg:grid-cols-3">
                <div className="flex basis-4/12 flex-col justify-between">
                  <label
                    htmlFor="funeralCeremony"
                    className="font-intermedium text-base"
                  >
                    Vill du att det hålls en ceremoni efter din bortgång?
                  </label>
                  <div className="relative flex flex-col font-internormal text-base before:absolute before:top-1/2 before:right-3 before:h-2 before:w-2 before:-rotate-45 before:border-l-2 before:border-b-2 before:border-l-[#736F62] before:border-b-[#736F62] before:content-['']">
                    <select
                      name="funeralCeremony"
                      id="funeralCeremony"
                      value={funeralCeremony}
                      onChange={handleChangeCeremony}
                      className="mt-2 h-[44px] appearance-none rounded border border-[#CECABA] px-3 text-[13px] text-[#736F62] focus:outline-[#0C2632]"
                    >
                      <option disabled value="" className="!text-[#808080]">
                        Välj ett alternativ
                      </option>
                      <option value="yes">Ja</option>
                      <option value="no">Nej</option>
                    </select>
                    {funeralCeremony === "" && showError && (
                      <span className="error absolute -bottom-[35px] right-0 text-xs text-[#ea3324] lg:-bottom-[25px] lg:text-sm">
                        Det här fältet måste du fylla i för att kunna gå vidare
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex basis-4/12 flex-col justify-between">
                  <label
                    htmlFor="funeralType"
                    className="font-intermedium text-base"
                  >
                    Vill du begravas i urna eller kista?
                  </label>
                  <div className="relative flex flex-col font-internormal text-base before:absolute before:top-1/2 before:right-3 before:h-2 before:w-2 before:-rotate-45 before:border-l-2 before:border-b-2 before:border-l-[#736F62] before:border-b-[#736F62] before:content-['']">
                    <select
                      name="funeralType"
                      id="funeralType"
                      value={selectedFuneralType}
                      onChange={handleChangeFuneralType}
                      className="mt-2 h-[44px] appearance-none rounded border border-[#CECABA] px-3 text-[13px] text-[#736F62] focus:outline-[#0C2632]"
                    >
                      <option disabled value="">
                        Välj ett alternativ
                      </option>
                      <option value="cremation">
                        Jag vill begravas i urna
                      </option>
                      <option value="coffin">Jag vill begravas i kista</option>
                    </select>
                    {selectedFuneralType === "" && showError && (
                      <span className="error absolute -bottom-[35px] right-0 text-xs text-[#ea3324] lg:-bottom-[25px] lg:text-sm">
                        Det här fältet måste du fylla i för att kunna gå vidare
                      </span>
                    )}
                  </div>
                </div>
                <div className="relative flex basis-4/12 flex-col justify-between">
                  <label
                    htmlFor="locationsInput"
                    className="font-intermedium text-base"
                  >
                    Har du någon speciell plats i åtanke för din sista vila?
                  </label>
                  <Popout list={locations} onChange={onChangeLocation} />
                  {selectedLocation.trim().length === 0 && showError && (
                    <span className="error absolute -bottom-[35px] right-0 text-xs text-[#ea3324] lg:-bottom-[25px] lg:text-sm">
                      Det här fältet måste du fylla i för att kunna gå vidare
                    </span>
                  )}
                </div>
              </div>
              <div className="mt-8 grid gap-8 lg:mt-6 lg:grid-cols-3">
                <div className="flex basis-4/12 flex-col justify-between">
                  <label
                    htmlFor="colorInput"
                    className="font-intermedium text-base"
                  >
                    Vad är din favoritfärg?
                  </label>
                  <div className="relative flex flex-col font-internormal text-base">
                    <input
                      type="text"
                      name={"colorInput"}
                      value={color}
                      onChange={(event) => setColor(event.target.value)}
                      className="mt-2 h-[44px] appearance-none rounded border border-[#CECABA] px-3 text-[13px] placeholder:text-[#736F62] focus:outline-[#0C2632]"
                      placeholder="Röd, blå, grön etc"
                    />
                  </div>
                </div>
                <div className="flex basis-4/12 flex-col justify-between">
                  <label
                    htmlFor="flowerInput"
                    className="font-intermedium text-base"
                  >
                    Har du någon älsklingsblomma?
                  </label>
                  <div className="relative flex flex-col font-internormal text-base">
                    <input
                      type="text"
                      name={"flowerInput"}
                      value={flower}
                      onChange={(event) => setFlower(event.target.value)}
                      className="mt-2 h-[44px] appearance-none rounded border border-[#CECABA] px-3 text-[13px] placeholder:text-[#736F62] focus:outline-[#0C2632]"
                      placeholder="Ros, lilja, tulpan etc"
                    />
                  </div>
                </div>
                <div className="flex basis-4/12 flex-col justify-between">
                  <label
                    htmlFor="musicInput"
                    className="font-intermedium text-base"
                  >
                    Finns det någon låt du tycker extra mycket om?
                  </label>
                  <div className="relative flex flex-col font-internormal text-base">
                    <input
                      type="text"
                      name={"musicInput"}
                      value={music}
                      onChange={(event) => setMusic(event.target.value)}
                      className="mt-2 h-[44px] appearance-none rounded border border-[#CECABA] px-3 text-[13px] placeholder:text-[#736F62] focus:outline-[#0C2632]"
                      placeholder="Min favoritlåt"
                    />
                  </div>
                </div>
              </div>
              <button
                id="eftervarlden_brev_forhandsgranska"
                className="mt-12 inline-flex items-center gap-2 rounded border-[1px] border-deep-blue bg-deep-blue py-4 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] text-white hover:border-[#6F86A0] hover:bg-[#6F86A0]"
                onClick={handleClick}
              >
                förhandsgranska {getButtonArrow("#fff", "18", "18")}
              </button>
            </form>
          )}
          {showPreview && (
            <div className="flex flex-col lg:flex-row lg:gap-16">
              <div className="flex basis-1/2 flex-col">
                <div className="lg:min-h-[610px]">
                  {previewFile && (
                    <div
                      className="relative mx-auto flex cursor-pointer"
                      onClick={() => setPreviewModal(true)}
                      id="preplan_preview"
                    >
                      <PdfPreview file={previewFile.content} />
                    </div>
                  )}
                </div>
                {/*<div className="mt-4 flex flex-col justify-between">*/}
                {/*  <label*/}
                {/*    htmlFor="emailInput"*/}
                {/*    className="font-intermedium text-base"*/}
                {/*  >*/}
                {/*    Lämna din emailadress så skickar vi brevet till dig.*/}
                {/*  </label>*/}
                {/*  <div className="relative flex flex-col font-intermedium text-base">*/}
                {/*    <input*/}
                {/*      type="text"*/}
                {/*      name={"emailInput"}*/}
                {/*      value={email}*/}
                {/*      onChange={(event) => setEmail(event.target.value)}*/}
                {/*      className="focus:outline-[#0C2632 mt-2 h-[44px] appearance-none rounded border border-[#CECABA] px-3 text-[13px]"*/}
                {/*      placeholder="Emailadress"*/}
                {/*    />*/}
                {/*    <button*/}
                {/*      onClick={handleClickMail}*/}
                {/*      className="absolute right-[10px] top-[15px] h-[30px] w-[30px] rounded-full bg-[#E8E3D2]"*/}
                {/*    >*/}
                {/*      {getButtonArrow("#fff", "20", "20", "-rotate-90 ml-1")}*/}
                {/*    </button>*/}
                {/*    {emailError && emailDirty && (*/}
                {/*      <label className="error mt-1 text-right text-xs text-[#EA3324] lg:text-sm">*/}
                {/*        {emailError}*/}
                {/*      </label>*/}
                {/*    )}*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
              <div className="mt-8 flex min-h-[610px] basis-1/2 flex-col lg:mt-0">
                <h2 className="mx-auto mb-4 w-full max-w-[400px] font-intermedium text-[32px]">
                  Logga in med ditt BankID
                </h2>
                <ol className="mx-auto w-full max-w-[400px] list-inside list-decimal text-[18px]">
                  <li>Öppna BankID-appen på din mobil eller dator</li>
                  <li>Legitimera dig genom att skriva in din personliga kod</li>
                  <li>
                    Du loggas in och kan läsa ditt brev samt ladda ner det
                  </li>
                </ol>
                {renderLoginButton(
                  `text-white`,
                  `#fff`,
                  `Logga in och läs ditt brev`
                )}
              </div>
            </div>
          )}
        </div>
        {previewModal && (
          <Modal onClose={() => setPreviewModal(false)}>
            {previewFile && (
              <div className="relative">
                <div className="flex justify-center">
                  <PdfPreview file={previewFile.content} hidePreview={true} />
                </div>
                {renderLoginButton(
                  ` absolute top-[13rem] leading-tight max-w-[280px] mx-auto left-0 right-0 w-[fit-content] lg:max-w-full`,
                  `#fff`,
                  `Logga in och läs ditt brev`
                )}
              </div>
            )}
          </Modal>
        )}
      </div>
      <Footer />
    </ServiceSectionProvider>
  );
}
