import * as React from "react";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import { getBankIdIcon } from "../services/icons";

export type CleanDataInput = {
  html: {
    blocks: {
      type: string;
      children: {
        text: string;
        level?: number;
        items?: any[];
        code?: string;
        embed?: string;
        service?: string;
        width?: string;
        height?: string;
        file?: {
          url: string;
        };
        caption?: string;
        style?: "ordered";
        html?: string;
      };
    }[];
  };
};

/**
 * Clean data parser to React JSX
 */
const CleanDataParser = (
  block: CleanDataInput["html"]["blocks"][0],
  index: number
) => {
  const [isHover, setIsHover] = useState(false);

  const renderLoginButton = (buttonClass: string, iconColor: string) => {
    return (
      <Link
        to="/login"
        className={
          buttonClass +
          ` inline-flex items-center gap-2 rounded border-[1px] border-deep-blue py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] lg:gap-3 lg:self-end`
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover
          ? getBankIdIcon("#fff", "20", "100%")
          : getBankIdIcon(iconColor, "20", "100%")}
        Logga in
      </Link>
    );
  };

  const onClickSupportHandle = (event: any) => {
    if (window.Intercom) {
      window.Intercom("show");
    }
  };

  switch (block.type) {
    case "heading":
      switch (block.level) {
        case 1:
          return (
            <h1
              key={index}
              dangerouslySetInnerHTML={{
                __html: block.children[0].text,
              }}
            />
          );
        case 2:
          return (
            <h2
              key={index}
              className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]"
              dangerouslySetInnerHTML={{
                __html: block.children[0].text,
              }}
            />
          );
        case 3:
          return (
            <h3
              key={index}
              className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]"
              dangerouslySetInnerHTML={{
                __html: block.children[0].text,
              }}
            />
          );
        case 4:
          return (
            <h4
              key={index}
              dangerouslySetInnerHTML={{
                __html: block.children[0].text,
              }}
            />
          );
        case 5:
          return (
            <h5
              key={index}
              dangerouslySetInnerHTML={{
                __html: block.children[0].text,
              }}
            />
          );
        case 6:
          return (
            <h6
              key={index}
              dangerouslySetInnerHTML={{
                __html: block.children[0].text,
              }}
            />
          );

        default:
          return (
            <p
              key={index}
              dangerouslySetInnerHTML={{
                __html: block.children[0].text,
              }}
            />
          );
      }

    case "paragraph":
      return (
        <p
          key={index}
          className={"whitespace-pre-wrap pb-5 font-interlight text-base"}
        >
          {block.children.map((textItem: string, idx: number) => {
            return (
              <Fragment key={`${textItem}-${idx}-item`}>
                {textItem.bold ? (
                  <b>{textItem.text}</b>
                ) : textItem.underline ? (
                  <u>{textItem.text}</u>
                ) : textItem.type === "link" &&
                  textItem.url === "https://fenixfamily.se/login" ? (
                  <Fragment>
                    {renderLoginButton(
                      `bg-deep-blue text-white hover:border-[#fff] hover:bg-[#0C2632]/10`,
                      `#fff`
                    )}
                  </Fragment>
                ) : textItem.type === "link" &&
                  textItem.url === "/#intercom" ? (
                  <div className="mt-2 flex justify-center">
                    <button
                      className="inline-flex items-center gap-2 rounded border-[1px] border-deep-blue bg-deep-blue py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] text-[#fff] hover:border-[#6F86A0] hover:bg-[#6F86A0] lg:gap-3 lg:self-end"
                      onClick={onClickSupportHandle}
                    >
                      Kontakta oss
                    </button>
                  </div>
                ) : (textItem.type === "link" &&
                    textItem.url != "https://fenixfamily.se/login") ||
                  (textItem.type === "link" && textItem.url != "/#intercom") ? (
                  <Link to={textItem.url}>
                    <u>{textItem.children[0].text}</u>
                  </Link>
                ) : (
                  textItem.text
                )}
              </Fragment>
            );
          })}
        </p>
      );

    case "delimiter":
      return <hr key={index} />;

    case "image":
      return (
        <img
          key={index}
          alt="FenixFamily article image"
          className="img-fluid"
          src={block.image.url}
          title="FenixFamily article image"
          loading="lazy"
        />
      );

    case "list":
      if (!Array.isArray(block.children)) {
        //console.warn('Failed to parse `list` without `items`.\nBlock:', block)
        return null;
      }

      switch (block.format) {
        case "ordered": {
          return (
            <ol
              className="list-none rounded-[3px] border border-[#A0C1AB] py-4 pr-6 pl-12 lg:py-6"
              key={index}
            >
              {block.children.map((li: string, idx: number) => {
                const liText = li.children.map(
                  (liItem: string, index: number) => {
                    return (
                      <Fragment key={`${liItem}-${index}-item`}>
                        {liItem.bold ? <b>{liItem.text}</b> : liItem.text}
                      </Fragment>
                    );
                  }
                );
                return (
                  <li
                    key={`${li}-${idx}-item`}
                    className="relative mb-4 font-intermedium text-base italic leading-[22px] before:absolute before:top-1 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#467655] before:content-['']"
                  >
                    {liText}
                  </li>
                );
              })}
            </ol>
          );
        }
        default: {
          return (
            <ul
              key={index}
              className="marker:leading-1 list-disc pl-6 marker:text-[30px]"
            >
              {block.children.map((li: string, idx: number) => {
                const liText = li.children.map(
                  (liItem: string, index: number) => {
                    return (
                      <Fragment key={`${liItem}-${index}-item`}>
                        {liItem.bold ? (
                          <b className="mb-0 inline font-intermedium text-[18px]">
                            {liItem.text}
                          </b>
                        ) : (
                          liItem.text
                        )}
                      </Fragment>
                    );
                  }
                );
                return (
                  <li
                    key={`${li}-${idx}-item`}
                    className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]"
                  >
                    {liText}
                  </li>
                );
              })}
            </ul>
          );
        }
      }

    case "code":
      return <div key={index}>{block.children[0].text}</div>;

    case "quote": {
      return (
        <blockquote key={index}>
          {" "}
          <p dangerouslySetInnerHTML={{ __html: block.data.text }} />
        </blockquote>
      );
    }

    case "embed": {
      return (
        <div key={index}>
          {" "}
          <iframe
            title={block.data.service}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            style={{ width: block.data.width }}
            height={block.data.height}
            src={block.data.embed}
            className="embed-tool__content"
          ></iframe>
        </div>
      );
    }

    case "raw": {
      if (!block.data.html) {
        console.warn("Failed to parse `raw` without `html`.\nBlock:", block);
        return null;
      }

      return (
        <div
          key={index}
          dangerouslySetInnerHTML={{ __html: block.data.html }}
        />
      );
    }

    default:
      // console.log('Unknown block type', block.type)
      // console.log(block)
      return null;
  }
};

export default CleanDataParser;
