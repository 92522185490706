import React, { useEffect, useRef, useState } from "react";

const video =
  "https://static.fenixbegravning.se/fenix_family_livsplanering.mp4";
const videoThumb = new URL(
  "../assets/video/image_thumb_tn.jpg",
  import.meta.url
);

const VideoItem = ({
  videoHref,
  thumb,
  playerClasses,
  containerClasses,
}: {
  videoHref?: string;
  thumb?: string;
  playerClasses?: string;
  containerClasses?: string;
}) => {
  const [play, setPlay] = useState(false);
  const refVideo = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (!refVideo.current) {
      return;
    }

    if (play) {
      void refVideo.current.play();
    } else {
      refVideo.current.pause();
    }
  }, [play]);

  const togglePlay = () => {
    setPlay(!play);
  };

  const getPlayPauseIcon = () => {
    if (play) {
      return (
        <svg
          width="62"
          height="62"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M384 810.666667c-25.6 0-42.666667-17.066667-42.666667-42.666667L341.333333 256c0-25.6 17.066667-42.666667 42.666667-42.666667s42.666667 17.066667 42.666667 42.666667l0 512C426.666667 793.6 409.6 810.666667 384 810.666667z"
            fill="white"
          />
          <path
            d="M640 810.666667c-25.6 0-42.666667-17.066667-42.666667-42.666667L597.333333 256c0-25.6 17.066667-42.666667 42.666667-42.666667s42.666667 17.066667 42.666667 42.666667l0 512C682.666667 793.6 665.6 810.666667 640 810.666667z"
            fill="white"
          />
        </svg>
      );
    }
    return (
      <svg
        width="62"
        height="62"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.1248 16.9248L37.0748 26.9998L23.1248 37.0748V16.9248Z"
          stroke="#FFFEFB"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M52.5748 26.9998C52.5748 41.1245 41.1245 52.5748 26.9998 52.5748C12.8751 52.5748 1.4248 41.1245 1.4248 26.9998C1.4248 12.8751 12.8751 1.4248 26.9998 1.4248C41.1245 1.4248 52.5748 12.8751 52.5748 26.9998Z"
          stroke="#FFFEFB"
          strokeWidth="2"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  return (
    <div className={`group relative ${containerClasses}`}>
      <div
        className="absolute z-10 flex h-full w-full flex-col items-center justify-center"
        onClick={togglePlay}
      >
        <div
          className={`flex cursor-pointer flex-col items-center justify-center rounded-full ${
            !play ? "opacity-80" : "opacity-0"
          } transition group-hover:opacity-80`}
        >
          {getPlayPauseIcon()}
        </div>
      </div>
      <video
        ref={refVideo}
        playsInline
        loop
        src={videoHref ? videoHref : video}
        className={`object-fit mx-auto h-full w-full ${playerClasses}`}
        style={{ transform: "scale(1.0)" }}
        preload="auto"
        poster={thumb ? thumb : videoThumb.href}
      />
    </div>
  );
};

export default VideoItem;
