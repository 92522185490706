import React from "react";
import { HashLink } from "react-router-hash-link";

import { campaignNavigation } from "../assets/data/datalist";
import { useMediaQuery } from "../services/hooks/useMediaQuery";

export default function CampaignNavigation() {
  const handleChange = (e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    const selectedSection = e.target.value as string;
    const element = document.getElementById(selectedSection);
    element!.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  };
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  return (
    <div className="relative w-full bg-white text-deep-blue">
      <div className="mx-auto py-7 px-5 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1360px]">
        <div className="no-scrollbar w-full gap-3 overflow-scroll lg:flex lg:items-center lg:justify-between">
          {isDesktop ? (
            campaignNavigation.map(
              (item: { title: string; anchor: string }, index: number) => {
                return (
                  <HashLink
                    key={index}
                    scroll={(el) =>
                      el.scrollIntoView({
                        behavior: "auto",
                        block: "center",
                      })
                    }
                    to={`#${item.anchor}`}
                    className="items-center font-intermedium text-xs uppercase tracking-[.25em] hover:text-[#6F86A0] hover:underline lg:text-base"
                  >
                    {item.title}
                  </HashLink>
                );
              }
            )
          ) : (
            <div className="relative flex items-center after:absolute after:top-1/3 after:right-4 after:h-[10px] after:w-[10px] after:rotate-45 after:transform after:border-b after:border-r after:border-black/70">
              <select
                onChange={handleChange}
                id="campaign-select"
                className="h-[44px] w-full appearance-none rounded border border-[#CECABA] px-3 font-internormal"
              >
                {campaignNavigation.map(
                  (item: { title: string; anchor: string }, index: number) => {
                    return (
                      <option
                        key={index}
                        value={item.anchor}
                        className="text-base uppercase tracking-widest text-deep-blue"
                      >
                        {item.title}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
