import React, { useState } from "react";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ""),
});

const formatOptionLabel = ({ label }: { label: string }) => (
  <div className="flex">
    <div className="w-full flex-auto">{label}</div>
  </div>
);

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.10156 10.5L12.0016 14.7L15.9016 10.5"
          stroke="#0C2632"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

export default function Popout(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(props.list);
  const [value, setValue] = useState<Option | null>();

  const handleCreate = (inputValue: string) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoading(false);
      setOptions((prev) => [...prev, newOption]);
      setValue(newOption);
    }, 1000);
    props.onChange(inputValue);
  };

  const handleLocationSelect = (e: any) => {
    (newValue) => setValue(newValue);
    props.onChange(
      options.find(function (item: any) {
        return item.value === e.value;
      })?.label
    );
  };

  return (
    <div className="relative">
      <CreatableSelect
        className={
          "mt-3 h-[44px] bg-[#FFFEFB] font-internormal text-base focus:outline-none"
        }
        classNames={{
          input: (state) => "font-internormal text-[13px] indent-5",
          dropdownIndicator: (state) =>
            state.selectProps.menuIsOpen ? "rotate-180" : "",
          control: (state) =>
            state.menuIsOpen || state.isFocused
              ? "!border-2 !border-[#0C2632] focus:outline-[#0C2632]"
              : "border !border-[#CECABA]",
        }}
        placeholder={"Välj eller skriv plats"}
        isClearable
        isDisabled={isLoading}
        isLoading={isLoading}
        formatOptionLabel={formatOptionLabel}
        onChange={handleLocationSelect}
        onCreateOption={handleCreate}
        value={value}
        isSearchable
        name="color"
        options={options}
        formatCreateLabel={(userInput) => `Lägg till egen plats "${userInput}"`}
        noOptionsMessage={() => "Inga resultat"}
        tabSelectsValue={false}
        components={{ DropdownIndicator }}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            height: "100%",
            boxShadow: "none",
            borderBottomColor: state.menuIsOpen
              ? "transparent !important"
              : "#CECABA",
            borderBottomLeftRadius: state.menuIsOpen ? 0 : "5px",
            borderBottomRightRadius: state.menuIsOpen ? 0 : "5px",
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            fontSize: "13px",
            fontFamily: "InterMedium",
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            fontSize: "13px",
            fontFamily: "InterNormal",
            color: "#736F62",
          }),
          container: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: "5px",
          }),
          clearIndicator: (baseStyles, state) => ({
            ...baseStyles,
            display: "none",
          }),
          indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            display: "none",
          }),
          valueContainer: (baseStyles, state) => ({
            ...baseStyles,
            padding: "0 12px 0 11px",
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isSelected ? "#F3F0E6" : "#fff",
            color: state.isSelected ? "#000" : "#000",
            fontSize: "13px",
            lineHeight: "25px",
            paddingTop: "6px",
            paddingBottom: "6px",
            cursor: "pointer",
          }),
          menu: (baseStyles, state) => ({
            ...baseStyles,
            top: "99.5%",
            boxShadow: "none",
            marginTop: "-1px",
            marginBottom: 0,
            border: "2px solid #0C2632",
            borderTopWidth: 0,
            borderRadius: "5px",
            borderTopLeftRadius: "0",
            borderTopRightRadius: "0",
          }),
          menuList: (baseStyles, state) => ({
            ...baseStyles,
            paddingTop: "0",
            paddingBottom: "0",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          }),
        }}
      />
    </div>
  );
}
