import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import ErrorBoundary from "./components/ErrorBoundary";
import GdprRedirect from "./components/GdprRedirect";
import Layout from "./components/Layout";
import LayoutPartner from "./components/LayoutPartner";
import LayoutPublic from "./components/LayoutPublic";
import LayoutPublicChat from "./components/LayoutPublicChat";
import { useUserContext } from "./context/UserContext";
import AboutPage from "./pages/AboutPage";
import AffiliatePage from "./pages/AffiliatePage";
import ArtiklarPage from "./pages/artiklar/ArtiklarPage";
import CategoryPage from "./pages/artiklar/CategoryPage";
import SubCategoryPage from "./pages/artiklar/SubcategoryPage";
import CampaignPage from "./pages/CampaignPage";
import ConfirmConsentPage from "./pages/ConfirmPage";
import FaqPage from "./pages/FaqPage";
import HomePage from "./pages/HomePage";
import LetterPage from "./pages/LetterPage";
import LoginPage from "./pages/LoginPage";
import NoPage from "./pages/NoPage";
import PartnerPage from "./pages/PartnerPage";
import Post1 from "./pages/Post1";
import Post2 from "./pages/Post2";
import Post3 from "./pages/Post3";
import Post4 from "./pages/Post4";
import Post5 from "./pages/Post5";
import Post6 from "./pages/Post6";
import ProductPage from "./pages/ProductPage";
import RejectConsentPage from "./pages/RejectPage";
import SinglePostPilar from "./pages/SinglePostPilar";
import SinglePostSeo from "./pages/SinglePostSeo";
import LoaderPage from "./pages/SkeletonPage";
import useFetchPosts from "./services/hooks/useFetchPosts";

export default function App() {
  const { user, populateFromStorage, saveToStorage } = useUserContext();

  useEffect(() => {
    populateFromStorage();
  }, []);

  useEffect(() => {
    saveToStorage();
  }, [user]);

  const postData = useFetchPosts(
    `${process.env.STRAPI_API_URL}/api/articles?populate=*`,
    `${process.env.STRAPI_API_URL}/api/secondary-articles?populate=*`,
    `${process.env.STRAPI_API_URL}/api/article-categories?populate=*`
  );

  const childCategories = postData.categoriesList?.data
    ?.filter((item: any) => item?.attributes.parent?.data.some((it: any) => it))
    ?.sort((a: any, b: any) =>
      a.attributes.slug > b.attributes.slug
        ? 1
        : b.attributes.slug > a.attributes.slug
        ? -1
        : 0
    );

  const routePostsSecondary = postData.postsSecondary?.data.map(
    (item: any, index: number) => {
      const currentParentCategory = childCategories.find(
        (element: any) =>
          element.attributes.slug === item.attributes.slug.split("/")[0]
      );

      const pilarArticleCat = postData.posts.data.find(
        (element: any) =>
          element.attributes.slug ===
          item.attributes.pilar_article.data?.attributes.slug
      );

      const parentCat = pilarArticleCat
        ? pilarArticleCat?.attributes.article_category.data.attributes
        : currentParentCategory.attributes.parent?.data[0].attributes;

      const childCat = item.attributes.pilar_article.data?.attributes
        ? item.attributes.pilar_article.data?.attributes
        : item.attributes.slug;

      return (
        <Route
          path={`/artiklar/${parentCat.slug}/${
            item.attributes.pilar_article.data
              ? item.attributes.pilar_article.data?.attributes.slug + "/"
              : ""
          }${item.attributes.slug}/`}
          element={
            <SinglePostSeo
              title={item.attributes.title}
              childCategory={childCat}
              parentCategory={parentCat}
              views={item.attributes.viewCounter}
              author={item.attributes.authorFullName}
              slug={item.attributes.slug}
              publishDate={item.attributes.articleDate}
              content={item.attributes.content}
            />
          }
          key={index}
        />
      );
    }
  );

  const routePostsPilar = postData.posts?.data.map(
    (item: any, index: number) => {
      const currentParentCategory = childCategories.find(
        (element: any) =>
          element.attributes.slug ===
          item.attributes.article_category.data.attributes.slug
      );
      return (
        <Route
          path={`/artiklar/${
            currentParentCategory
              ? currentParentCategory.attributes.parent.data[0].attributes
                  .slug + "/"
              : ""
          }${item.attributes.article_category.data.attributes.slug}/${
            item.attributes.slug
          }/`}
          element={
            <SinglePostPilar
              title={item.attributes.title}
              slug={item.attributes.slug}
              views={item.attributes.viewCounter}
              author={item.attributes.authorFullName}
              featuredImage={item.attributes.featuredImage}
              publishDate={item.attributes.articleDate}
              childCategory={item.attributes.article_category.data.attributes}
              parentCategory={currentParentCategory}
              content={item.attributes.content}
            />
          }
          key={item}
        />
      );
    }
  );

  const routeCategories = postData.categoriesList?.data
    ?.filter(
      (item: any) => !item?.attributes.parent?.data.some((it: any) => it)
    )
    ?.sort((a: any, b: any) =>
      a.attributes.slug > b.attributes.slug
        ? 1
        : b.attributes.slug > a.attributes.slug
        ? -1
        : 0
    )
    ?.map((category, key) => {
      return (
        <Route
          path={`/artiklar/${category.attributes.slug}/`}
          element={
            <CategoryPage
              title={`${category.attributes.title} - Fenix Family AB`}
              slug={category.attributes.slug}
              categoryTitle={category.attributes.title}
            />
          }
          key={key}
        />
      );
    });

  const routeSubCategories = postData.categoriesList?.data
    ?.filter((x: any) => x.attributes.parent.data.length)
    .map((item, key) => {
      return (
        <Route
          path={`/artiklar/${item?.attributes?.parent?.data[0]?.attributes?.slug}/${item?.attributes?.slug}`}
          element={
            <SubCategoryPage
              title={`${item?.attributes?.title} - Fenix Family AB`}
              slug={item?.attributes?.slug}
              subcategoryTitle={item?.attributes?.title}
              categorySlug={item?.attributes?.parent?.data[0]?.attributes?.slug}
              categoryTitle={
                item?.attributes?.parent?.data[0]?.attributes?.title
              }
            />
          }
          key={key}
        />
      );
    });

  return (
    <ErrorBoundary>
      <Routes>
        <Route element={<LayoutPublic />}>
          <Route
            path="/"
            element={<HomePage title="Fenix Family Livsplanering - Fenix" />}
          />
          <Route
            path="/bekrafta/dela-data-samtycke/"
            element={
              <ConfirmConsentPage title="Fenix Family Livsplanering - Fenix" />
            }
          />
          <Route
            path="/bekrafta/dela-data-nekat/"
            element={
              <RejectConsentPage title="Fenix Family Livsplanering - Fenix" />
            }
          />
          <Route
            path="/faq/"
            element={<FaqPage title="FAQ - Fenix Family AB" />}
          />
          <Route
            path="/om-oss/"
            element={<AboutPage title="Om Oss - Fenix Family AB" />}
          />
          <Route
            path="/artiklar/"
            element={<ArtiklarPage title="Artiklar - Fenix Family AB" />}
          ></Route>
          {routeCategories}
          {routeSubCategories}
          {routePostsPilar}
          {routePostsSecondary}
        </Route>
        <Route element={<LayoutPublicChat />}>
          <Route
            path="/livsplanering/"
            element={<ProductPage title="Livsplanering - Fenix" />}
          />
          <Route
            path="/partners/"
            element={<PartnerPage title="Partner - Fenix Family AB" />}
          />
        </Route>
        <Route path="/partner" element={<Navigate to="/partners/" />} />
        <Route element={<LayoutPartner />}>
          <Route path="/partner/:pt?/?" element={<AffiliatePage />} />
          <Route
            path="/partner/shp"
            element={<Navigate to="/partner/svenskhypotekspension" />}
          />
          <Route path="/till-eftervarlden/" element={<CampaignPage />} />
          <Route path="/till-eftervarlden/brevet/" element={<LetterPage />} />
          <Route
            path="/kampanj"
            element={<Navigate to="/till-eftervarlden" />}
          />
          <Route
            path="/kampanj/brevet"
            element={<Navigate to="/till-eftervarlden/brevet" />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/vault" element={<LoginPage />} />
          <Route path="/debug" element={<LoginPage />} />
          <Route path="/conversation" element={<LoginPage />} />
          <Route path="/recommendation" element={<LoginPage />} />
          <Route path="/payment/:data" element={<LoginPage />} />
          <Route path="contract/:contractQuery" element={<LoginPage />} />
        </Route>
        {!postData.loading ? (
          <Route element={<Layout />}>
            <Route path="*" element={<NoPage />} />
          </Route>
        ) : (
          <Route>
            <Route path="*" element={<LoaderPage />} />
          </Route>
        )}
        <Route path="/gdpr" element={<GdprRedirect />} />
      </Routes>
    </ErrorBoundary>
  );
}
