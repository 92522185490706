import React, { useEffect, useRef } from "react";

const TrustBox = ({
  template,
  height,
  theme,
}: {
  template?: string;
  height?: string;
  theme?: string;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (window.Trustpilot && ref.current) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="sv-SE"
      data-template-id={template ? template : "5406e65db0d04a09e042d5fc"}
      data-businessunit-id="531f41b60000640005785ed7"
      data-style-height={height ? height : "28px"}
      data-style-width="100%"
      data-theme={theme ? theme : "light"}
    >
      <a
        href="https://se.trustpilot.com/review/fenixbegravning.se"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};
export default TrustBox;
