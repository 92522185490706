// Import Swiper styles
import "swiper/swiper-bundle.css";

import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { getButtonArrow } from "../../services/icons";

export default function ArticlesSeoSlider({
  articlesSecondary,
  pilarArticles,
  childCategories,
}: any) {
  const [pagesEl, setPagesEl] = useState<HTMLElement | null>(null);
  return (
    <>
      <Fragment>
        <Swiper
          modules={[Pagination, Grid]}
          grabCursor={true}
          keyboard={true}
          allowTouchMove={true}
          simulateTouch={true}
          edgeSwipeDetection={true}
          touchReleaseOnEdges={true}
          pagination={{
            clickable: true,
            el: pagesEl,
          }}
          mousewheel={{
            releaseOnEdges: true,
          }}
          breakpoints={{
            320: {
              slidesPerView:
                articlesSecondary.data && articlesSecondary.data.length > 2
                  ? 1.3
                  : 1,
              spaceBetween: 15,
              grid: {
                rows: 2,
                fill: "row",
              },
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 120,
              grid: {
                rows: 2,
                fill: "row",
              },
            },
          }}
          className="swiper-articles-seo -mr-5 pb-28 md:-mr-0"
        >
          {articlesSecondary &&
            articlesSecondary.map((item: any, index: number) => {
              const currentParentCategory = childCategories.find(
                (element: any) =>
                  element.attributes.slug === item.attributes.slug.split("/")[0]
              );
              const pilarArticleCat = pilarArticles.find(
                (element: any) =>
                  element.attributes.slug ===
                  item.attributes.pilar_article.data?.attributes.slug
              );
              return (
                <SwiperSlide
                  key={index}
                  className="article-grid-item mr-16 !mt-0 border-b border-[#CECABA] py-5 text-deep-blue"
                >
                  <h3 className="font-inter mb-1 text-base font-medium italic text-deep-blue">
                    {item.attributes.title}
                  </h3>
                  <p className="mb-1 text-[13px] text-[#736F62]">
                    {item.attributes.content &&
                      item.attributes.content[0] &&
                      item.attributes.content[0].contentBlock[0].children[0].text
                        .split(" ")
                        .slice(0, 15)
                        .join(" ") + "..."}
                  </p>
                  <Link
                    to={
                      "/artiklar/" +
                      (pilarArticleCat
                        ? pilarArticleCat?.attributes.article_category.data
                            .attributes.slug + "/"
                        : currentParentCategory?.attributes.parent.data[0]
                            ?.attributes.slug + "/") +
                      (item.attributes.pilar_article.data?.attributes.slug
                        ? item.attributes.pilar_article.data?.attributes.slug +
                          "/"
                        : "") +
                      item.attributes.slug +
                      "/"
                    }
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "auto",
                      });
                    }}
                    className="font-inter flex cursor-pointer text-[13px] font-semibold hover:underline"
                  >
                    Läs artikel{" "}
                    <div className="ml-2">
                      {getButtonArrow("#0C2632", "13", "100%")}
                    </div>
                  </Link>
                </SwiperSlide>
              );
            })}
        </Swiper>
        <div
          ref={(node) => setPagesEl(node)}
          className="swiper-pagination swiper-pagination--articles swiper-pagination-clickable swiper-pagination-bullets !bottom-8 block"
        ></div>
      </Fragment>
    </>
  );
}
