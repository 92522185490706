// Import Swiper styles
import "swiper/swiper-bundle.css";

import React, { useState } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { advisors } from "../../assets/data/datalist";
import { getButtonArrow } from "../../services/icons";

export default function AdvisorsSlider() {
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <div className="pt-2.5 pb-16">
        <Swiper
          modules={[Navigation]}
          navigation={{ prevEl, nextEl }}
          keyboard={true}
          slidesPerView={5}
          allowTouchMove={true}
          simulateTouch={true}
          grabCursor={true}
          edgeSwipeDetection={true}
          touchReleaseOnEdges={true}
          mousewheel={{
            releaseOnEdges: true,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1.7,
              spaceBetween: 15,
              slidesOffsetBefore: 20,
              slidesOffsetAfter: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 30,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
          }}
          className="swiper-advisors mx-auto max-w-[1100px] !pb-6"
        >
          {advisors.map((item: any, index: number) => {
            return (
              <SwiperSlide key={index} className="">
                <div className="inline-flex flex-col items-start justify-start gap-6">
                  <div className="relative rounded-tl rounded-tr rounded-bl-2xl rounded-br-2xl">
                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                    <img
                      className="left-[-17px] h-[235px] w-full"
                      src={item.image.href}
                      srcSet={`${item.image.href} 1x, ${item.imageRetina.href} 2x`}
                      alt={item.title}
                      loading="lazy"
                    />
                  </div>
                  <div className="flex h-16 flex-col items-start justify-start gap-[10px] self-stretch text-deep-blue">
                    <div className="font-internormal text-xs font-medium uppercase leading-[14.40px] tracking-widest">
                      {item.name}
                    </div>
                    <div className="font-internormal text-xs font-medium uppercase leading-[14.40px] tracking-widest">
                      {item.title}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div
          ref={(node) => setPrevEl(node)}
          className="swiper-button-prev swiper-button-prev-advisor swiper-button-advisor relative !right-24 !top-[70%] !left-auto !h-[32px] !w-[32px] rounded-full bg-deep-blue !text-transparent after:absolute after:top-2.5 after:left-3 after:h-[11px] after:w-[11px] after:rotate-45 after:border-b after:border-l after:border-[#fff] after:content-[''] lg:!top-[45%] lg:!left-10 lg:bg-transparent lg:after:border-[#467655]"
        ></div>
        <div
          ref={(node) => setNextEl(node)}
          className="swiper-button-next swiper-button-next-advisor swiper-button-advisor relative !top-[70%] !right-6 !h-[32px] !w-[32px] rounded-full bg-deep-blue !text-transparent after:absolute after:top-2.5 after:left-2 after:h-[11px] after:w-[11px] after:rotate-[-130deg] after:border-b after:border-l after:border-[#fff] after:content-[''] lg:!right-10 lg:!top-[45%] lg:bg-transparent lg:after:border-[#467655]"
        ></div>
      </div>
    </>
  );
}
