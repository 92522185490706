// Import Swiper styles
import "swiper/swiper-bundle.css";

import React, { useState } from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import ArticleGridItem from "../ArticleGridItem";

export default function ArticlesSlider({
  articles,
  init,
  quantity,
  exclude,
  childCategories,
}: {
  articles: any;
  init: boolean;
  quantity: number;
  exclude?: string;
  childCategories?: any;
}) {
  const [pagesEl, setPagesEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <div className="slider-popular relative">
        <Swiper
          modules={[Pagination]}
          keyboard={true}
          allowTouchMove={true}
          simulateTouch={true}
          edgeSwipeDetection={true}
          touchReleaseOnEdges={true}
          mousewheel={{
            releaseOnEdges: true,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1.23,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 2.3,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: quantity,
              spaceBetween: 30,
              init: init,
            },
          }}
          pagination={{
            clickable: true,
            el: pagesEl,
          }}
          grabCursor={true}
          className="swiper-popular -mr-5 md:-mr-0"
        >
          {articles
            ?.filter((c: any) => c !== exclude)
            .slice(0, 4)
            .map((item: any, index: number) => {
              const hasParentCategory = childCategories?.find(
                (element: any) =>
                  element.attributes.slug ===
                  item.attributes.article_category.data.attributes.slug
              );
              return (
                <SwiperSlide key={index}>
                  <ArticleGridItem
                    item={item}
                    hasParentCat={hasParentCategory}
                  />
                </SwiperSlide>
              );
            })}
        </Swiper>
        <div
          ref={(node) => setPagesEl(node)}
          className="swiper-pagination swiper-pagination--articles swiper-pagination-clickable swiper-pagination-bullets block lg:hidden"
        ></div>
      </div>
    </>
  );
}
