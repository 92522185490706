import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { faqBlock, productDetails, steps } from "../assets/data/datalist";
import Accordion from "../components/Accordion";
import AdvisorToggleSection from "../components/AdvisorToggleSection";
import ConversationEmbedded from "../components/ConversationEmbedded";
import Footer from "../components/Footer";
import HeadMenu from "../components/HeadMenu";
import TrustBox from "../components/TrustBox";
import VideoItem from "../components/VideoItem";
import { ServiceSectionProvider } from "../context/ServiceSectionContext";
import { useMediaQuery } from "../services/hooks/useMediaQuery";
import {
  getBankIdIcon,
  getButtonArrow,
  getQuoteIcon,
  getShortDownArrowIcon,
} from "../services/icons";
import { defaultPackagePrice } from "../services/partners";
import { useScrollPosition } from "../services/utils";

const heroImg = new URL("../assets/images/product-hero.jpg", import.meta.url);
const gifThumb = new URL(
  "../assets/images/conversation_image.jpg",
  import.meta.url
);
const gifThumb2 = new URL(
  "../assets/images/conversation-image_2-530x510.jpg",
  import.meta.url
);

export default function ProductPage({ title }: { title?: string }) {
  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }
  const [showConversationModal, setShowConversationModal] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    document.title = title!;
  }, []);

  useEffect(() => {
    if (showConversationModal) {
      setShowConversationModal(false);
    }
  }, [showConversationModal]);

  const renderLoginButton = (
    buttonClass: string,
    iconColor: string,
    showArrow?: true
  ) => {
    return (
      <Link
        to="/login"
        className={
          `inline-flex items-center gap-2 rounded border-[1px] border-deep-blue bg-deep-blue py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] text-[#fff] hover:border-[#CECABA] hover:text-[#CECABA] lg:gap-3 lg:self-end` +
          buttonClass
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover
          ? getBankIdIcon(iconColor, "20", "100%")
          : getBankIdIcon("#fff", "20", "100%")}
        Kom igång
      </Link>
    );
  };

  return (
    <ServiceSectionProvider>
      <div className="relative overflow-hidden bg-[#FFFEFB]">
        <div
          className={classNames(
            scrollPosition > 84 ? "bg-white" : "bg-transparent",
            "fixed z-30 w-full transition-colors"
          )}
        >
          <HeadMenu
            sticky={scrollPosition > 84}
            theme={"dark"}
            color={
              scrollPosition > 84 ? "#0C2632" : isDesktop ? "#fff" : "#0C2632"
            }
          />
        </div>
        <div className="relative w-full text-white">
          <div className="flex flex-col-reverse pt-28 lg:flex-row lg:gap-5 lg:pt-0">
            <div className="w-full lg:max-w-[50%]">
              <img
                src={heroImg.href}
                alt="Fenix"
                className="relative h-auto min-h-[345px] w-[100%] max-w-[85%] rounded-tr-[13px] rounded-br-[13px] object-cover object-top lg:h-full lg:min-h-[640px] lg:max-w-[100%] lg:rounded-l-none lg:rounded-tr-[0] lg:rounded-br-[24px] 2k:min-h-[800px] 4k:min-h-[1200px]"
                loading="lazy"
              />
            </div>
            <div className="w-full lg:max-w-[50%]">
              <div className="flex h-full flex-col justify-end pr-5 pl-5 text-deep-blue ">
                <p className="mb-2 text-xs uppercase tracking-[0.2em]">
                  Fenix Livsplanering
                </p>
                <h1 className="mb-9 font-interblack text-[36px] italic leading-[39px] lg:mb-0 lg:text-[64px] lg:leading-[70px]">
                  Tjänster för <br /> mindre oro och <br /> mer liv.
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div
          id="prisochpaket"
          className="relative w-full bg-white pb-10 text-deep-blue lg:pb-20"
        >
          <div className="mx-auto px-5 pb-10 pt-28 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-4 lg:pt-36 xl:max-w-[1360px]">
            <div className="w-full max-w-[1100px]">
              <p className="flex flex-wrap items-center pb-2 font-intermedium text-xs uppercase tracking-[0.2em]">
                Allt du behöver för{" "}
                <span className="px-2 text-lg italic text-[#5F3741]">
                  {defaultPackagePrice}:-
                </span>
              </p>
              <h2 className="mb-5 max-w-[900px] font-interlight text-[24px] italic leading-[29px] lg:mb-3 lg:text-[42px] lg:leading-[50px]">
                I priset ingår en livsplan baserat på testamente, ett års
                prenumeration, fler avtal samt förvaring i ditt digitala
                kassaskåp.
              </h2>
              <p className="mb-3 max-w-[900px] whitespace-pre-wrap font-interlight">
                Vi vill göra familjejuridiken enklare och mer tillgänglig. Vi
                kallar det Livsplanering. Det innebär att vi tillsammans tar
                reda på vilka juridiska avtal du skulle ha nytta av, skapar dem,
                förvarar dem tryggt både digitalt och fysiskt samt övervakar dem
                så att det blir precis som du önskar när avtalen väl ska
                verkställas. En livsplanering kan spara dig och de dina mycket
                besvär, både nu och i framtiden.
              </p>
              <span className="mt-0 inline-flex items-center font-intermedium text-[14px] uppercase tracking-[0.12em]">
                Se vad som ingår i vår livsplanering{" "}
                {getShortDownArrowIcon("#0C2632")}
              </span>
            </div>
          </div>
        </div>
        <div className="relative w-full text-deep-blue">
          <div className="mx-auto px-5 pb-10 pt-20 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pt-36 xl:max-w-[1360px]">
            <div className="ml-auto flex max-w-[1205px] flex-col items-center justify-between gap-5 lg:flex-row lg:gap-10">
              <div id="behovsanalys" className="max-w-[530px]">
                <h2 className="mb-5 font-interblack text-[28px] italic leading-[31px]  lg:text-[48px] lg:leading-[53px]">
                  Få de avtal du vill ha, och de du inte visste att du behövde
                </h2>
                <p className="font-interlight text-base leading-[22px]">
                  Vad du har för juridiska behov beror ofta på var du är i
                  livet. Familjesituation, ålder och tillgångar är faktorer som
                  alla påverkar vad du har för behov av avtal, sparande och
                  försäkringar. Vissa behov känner du säkert redan till, andra
                  kan vi upptäcka tillsammans så att du får allt du behöver på
                  plats.
                </p>
                <div className="mt-10 mb-10 inline-block lg:mb-0">
                  <button
                    className="min-w-[260px] justify-center rounded-[4px] border-[#0C2632] bg-deep-blue px-5 py-4 font-internormal text-[14px] font-semibold uppercase tracking-[0.12em] text-[#fff] hover:border-[#6F86A0] hover:bg-[#6F86A0] lg:gap-3 lg:p-4 lg:px-4 lg:py-[14px]"
                    onClick={() => setShowConversationModal(true)}
                    title="Gör vår behovsanalys"
                    id="conv-start-home"
                  >
                    Gör vår behovsanalys
                  </button>
                </div>
              </div>
              <div className="max-w-[530px]">
                <img
                  src={gifThumb2.href}
                  alt="conversation flow"
                  className="min-h-[510px] object-cover drop-shadow-[4px_2px_35px_0px_rgba(0,0,0,0.08)]"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div
            id="kom-igang"
            className="mx-auto px-5 pt-16 pb-28 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-16 lg:pt-16 xl:max-w-[1360px]"
          >
            <div className="mx-auto mb-10 flex max-w-[1100px] flex-col items-center justify-around gap-10 lg:flex-row lg:items-start">
              {steps.map((step, index) => (
                <div
                  key={step.title}
                  className="flex shrink-0 grow basis-0 flex-col items-center justify-center gap-5"
                >
                  <div className="flex h-[152px] w-[152px] items-center justify-center rounded-full bg-[#F3F0E6]">
                    <img
                      src={step.icon.href}
                      className="w-[50px]"
                      loading="lazy"
                      alt="Fenix"
                    />
                  </div>
                  <div className="flex max-w-[310px] flex-col text-center text-dark-blue">
                    <p className="mb-4 font-intermedium text-base uppercase tracking-[0.25em]">
                      Steg {index + 1}
                    </p>
                    <p className="font-internormal text-[13px] leading-[15px] ">
                      {step.title}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center">
              {renderLoginButton(
                `bg-deep-blue text-white hover:text-white hover:!border-[#6F86A0] hover:bg-[#6F86A0]`,
                `#fff`
              )}
            </div>
          </div>
          <div className="mx-auto px-5 pt-16 pb-10 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-36 lg:pt-16 xl:max-w-[1360px]">
            <div className="flex flex-col lg:flex-row lg:items-end lg:justify-end lg:gap-36">
              <div className="flex max-w-[530px] flex-col justify-between">
                <h3 className="mb-3 font-interblack text-[22px] font-extrabold italic leading-[22px] lg:text-[48px] lg:leading-[53px]">
                  Trygghet för dig och dem som är viktigast i ditt liv
                </h3>
                <p className="mb-7 font-interlight text-base leading-[22px]">
                  Att planera livet juridiskt skapar tydlighet, lugn och ro för
                  dig och din partner. Med tydliga och korrekta avtal om vem som
                  äger vad, vad som ska ärvas av vem och hur mycket du har lånat
                  ut till vilken släkting, får ni inte bara kontroll och
                  överblick över era tillgångar. Vår erfarenhet vittnar också om
                  att ni minskar risken för framtida familjetvister och osämja
                  som otydliga överenskommelser kan innebära.
                </p>
                <Link
                  to="/login"
                  className="my-5 flex flex-row items-center gap-1 font-intermedium text-sm font-semibold uppercase leading-[25px] tracking-[0.12em] text-deep-blue hover:underline lg:my-3"
                >
                  Börja livsplanera {getButtonArrow("#0C2632", "20", "100%")}
                </Link>
              </div>
              <div className="w-full max-w-[530px]">
                <img
                  src={gifThumb.href}
                  alt="conversation flow"
                  className="max-h-[400px] w-full object-cover drop-shadow-[4px_2px_35px_0px_rgba(0,0,0,0.08)]"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full bg-deep-blue text-white">
          <div className="mx-auto px-5 pt-16 pb-20 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-24 lg:pt-24 xl:max-w-[1360px]">
            <div className="quote-block relative ml-0 max-w-[970px] rounded-[3px] pt-10 pb-8 lg:ml-7 lg:pb-12 lg:pt-20">
              <span className="quote-icon absolute -left-0.5 top-5 opacity-40 lg:-left-3 lg:top-10">
                {getQuoteIcon(
                  "#CECABA",
                  isDesktop ? "91" : "48px",
                  isDesktop ? "76" : "40px"
                )}
              </span>
              <div className="z-1 relative font-interlight text-[24px] italic leading-[29px] lg:text-[42px] lg:leading-[50.4px]">
                Jag är mycket nöjd med Fenix Familys tjänst. Du får hjälp
                snabbt. Det är lätt att hitta den information du letar efter. Du
                känner dig trygg hela vägen."
                <div className="mt-10 flex flex-col items-start lg:flex-row lg:items-end">
                  {renderLoginButton(
                    ` py-3 not-italic !border-[#fff] hover:!border-[#CECABA] `,
                    `#CECABA`
                  )}
                  <div className="-ml-4 mt-12 lg:ml-0 lg:mt-0">
                    <TrustBox
                      template={"5419b732fbfb950b10de65e5"}
                      height={"24px"}
                      theme={"dark"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full bg-white text-deep-blue">
          <div className="mx-auto px-5 pt-28 pb-16 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-32 lg:pt-36 xl:max-w-[1360px]">
            <h2 className="max-w-[650px] pr-4 font-interblack text-[28px] italic leading-[31px] lg:pr-0 lg:text-[48px] lg:leading-[53px]">
              Allt detta ingår
            </h2>
            <div
              id="vad-ingar"
              className="flex flex-col flex-wrap gap-y-10 pt-10 pb-16 lg:flex-row lg:justify-between lg:gap-x-3"
            >
              {productDetails.map((item, index) => (
                <div
                  key={index}
                  className="inline-flex flex-col items-start justify-start lg:max-w-[32%]"
                >
                  <img src={item.icon} alt="icon Fenix" />
                  <div className="flex flex-col items-start justify-start gap-0.5 self-stretch">
                    <div className="mt-3 self-stretch font-intermedium text-base font-semibold uppercase leading-normal tracking-[0.25em]">
                      {item.title}
                    </div>
                    <div
                      className="self-stretch text-[13px] font-normal leading-none"
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div id="sa-fungerar-det" className="pb-24 pt-12 lg:pb-32">
              <h2 className="mb-6 text-center font-interblack text-[22px] italic leading-[24px] text-deep-blue xl:text-[36px] xl:leading-[40px]">
                Så här funkar det
              </h2>
              <div className="flex flex-col items-center justify-center">
                <div className="w-full max-w-[870px]">
                  <VideoItem />
                </div>
              </div>
            </div>
            <div id="vara-jurister" className="">
              <AdvisorToggleSection />
            </div>
          </div>
          <div className="mx-auto px-5 pt-10 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pt-2 lg:pb-24 xl:max-w-[1360px]">
            <div className="mx-auto max-w-[870px]">
              <h2 className="text-center font-interblack text-[28px] italic leading-[2.7rem] text-deep-blue xl:text-[48px] xl:leading-[4.5rem]">
                FAQ
              </h2>
            </div>
            <div className="mx-auto mt-3 max-w-[870px] pt-5 pb-28 lg:pb-24">
              <Accordion items={faqBlock} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ConversationEmbedded
        showInline={false}
        showModalOverride={showConversationModal}
      />
    </ServiceSectionProvider>
  );
}
