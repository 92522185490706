import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
} from "@react-pdf/renderer";
import React from "react";

import { removeHtmlTags } from "../services/utils";

const fenixLogo = new URL(
  "../assets/images/fenixlogodark.png",
  import.meta.url
);

const willLock = new URL(
  "../assets/images/testamente_lock.jpg",
  import.meta.url
);

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 20,
    textAlign: "center",
    fontFamily: "InterMedium",
    color: "#002733",
    marginBottom: 20,
    textTransform: "uppercase",
  },
  subtitle: {
    fontSize: 15,
    marginBottom: 12,
    marginTop: 12,
    textAlign: "center",
    fontFamily: "InterMedium",
    color: "#002733",
    textTransform: "uppercase",
  },
  text: {
    marginBottom: 10,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "InterNormal",
  },
  textServices: {
    marginBottom: 10,
    fontSize: 14,
    textAlign: "left",
    fontFamily: "InterMedium",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 80,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

Font.register({
  family: "InterMedium",
  src: new URL("../assets/fonts/Inter-Medium.ttf", import.meta.url).href,
});

Font.register({
  family: "InterNormal",
  src: new URL("../assets/fonts/Inter-Regular.ttf", import.meta.url).href,
});

const PdfWillPreview = ({ name = "test", personNumber = "" }) => {
  return (
    <Document>
      <Page style={styles.body} wrap>
        <Image
          style={{
            width: 150,
            marginBottom: 30,
          }}
          src={fenixLogo.href}
        />
        <Text style={styles.subtitle}>TESTAMENTE</Text>
        <Text style={styles.text}>
          Undertecknad, {name} ({personNumber}), förordnar härmed som min
          yttersta vilja och testamente följande.
        </Text>
        <Image
          style={{
            width: "100%",
          }}
          src={willLock.href}
        />
      </Page>
    </Document>
  );
};

export default PdfWillPreview;
