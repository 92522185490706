import React, { useEffect } from "react";

import LoaderScreen from "../components/LoaderScreen";
import {
  getFromStorage,
  homeRedirectKey,
  partnerKey,
  removeFromStorage,
} from "../services/storage";

const appUrl: string = process.env.APP_URL;

export default function LoginPage() {
  useEffect(() => {
    const loc = window.location;
    const searchParams = new URLSearchParams(loc.search);

    const partner = getFromStorage(partnerKey);
    if (partner && !searchParams.has(partnerKey)) {
      searchParams.append(partnerKey, partner);
    }

    const homeRedirect = getFromStorage(homeRedirectKey);
    if (homeRedirect && !searchParams.has(homeRedirectKey)) {
      searchParams.append(homeRedirectKey, homeRedirect);
    }

    removeFromStorage(partnerKey);
    removeFromStorage(homeRedirectKey);

    const params = searchParams.size > 0 ? `?${searchParams.toString()}` : "";
    const url = `${appUrl}${loc.pathname}${params}`;
    window.location.href = url;
    return;
  }, []);

  return <LoaderScreen />;
}
