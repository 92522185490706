[
{
    "id": "2",
    "label": "Å kyrkogård",
    "value": "a-kyrkogard-norrkoping"
},
{
    "id": "6",
    "label": "Åby kyrkogård",
    "value": "aby-kyrkogard-kalmar"
},
{
    "id": "9",
    "label": "Acklinga kyrkogård",
    "value": "acklinga-kyrkogard-tidaholm"
},
{
    "id": "11",
    "label": "Adak Kyrkogård",
    "value": "adak-kyrkogard-mala"
},
{
    "id": "14",
    "label": "Adelöv Kyrkogård",
    "value": "adelov-kyrkogard-tranas"
},
{
    "id": "18",
    "label": "Adelsö kyrkogård",
    "value": "adelso-kyrkogard-ekero"
},
{
    "id": "23",
    "label": "Adventskyrkans kyrkogård",
    "value": "adventskyrkans-kyrkogard-hallsberg"
},
{
    "id": "27",
    "label": "Agunnaryds kyrkogård",
    "value": "agunnaryds-kyrkogard-ljungby"
},
{
    "id": "34",
    "label": "Akebäcks kyrkogård",
    "value": "akebacks-kyrkogard-gotland"
},
{
    "id": "35",
    "label": "Åkerbräckans Kyrkogård",
    "value": "akerbrackans-kyrkogard-lysekil"
},
{
    "id": "37",
    "label": "Åkerby kyrkogård",
    "value": "akerby-kyrkogard-uppsala"
},
{
    "id": "42",
    "label": "Åkers kyrkogård",
    "value": "akers-kyrkogard-vaggeryd"
},
{
    "id": "43",
    "label": "Åkers Kyrkogård",
    "value": "akers-kyrkogard-strangnas"
},
{
    "id": "48",
    "label": "Ala kyrkogård",
    "value": "ala-kyrkogard-gotland"
},
{
    "id": "50",
    "label": "Alanäs Kyrkogård",
    "value": "alanas-kyrkogard-stromsund"
},
{
    "id": "52",
    "label": "Ålands kyrkogård",
    "value": "alands-kyrkogard-uppsala"
},
{
    "id": "55",
    "label": "Alboga kyrkogård",
    "value": "alboga-kyrkogard-herrljunga"
},
{
    "id": "57",
    "label": "Alböke kyrkogård",
    "value": "alboke-kyrkogard-borgholm"
},
{
    "id": "60",
    "label": "Alby Kyrkogård",
    "value": "alby-kyrkogard-ange"
},
{
    "id": "61",
    "label": "Ale Skövde Kyrkogård",
    "value": "ale-skovde-kyrkogard-ale"
},
{
    "id": "63",
    "label": "Älekulla Kyrkogård",
    "value": "alekulla-kyrkogard-mark"
},
{
    "id": "65",
    "label": "Ålems kyrkogård",
    "value": "alems-kyrkogard-mosteras"
},
{
    "id": "69",
    "label": "Alfta Kyrkogård",
    "value": "alfta-kyrkogard-ovanaker"
},
{
    "id": "72",
    "label": "Älgå Kyrkogård",
    "value": "alga-kyrkogard-arvika"
},
{
    "id": "75",
    "label": "Älgarås kyrkogård",
    "value": "algaras-kyrkogard-gullspang"
},
{
    "id": "77",
    "label": "Älghults kyrkogård",
    "value": "alghults-kyrkogard-uppvidinge"
},
{
    "id": "79",
    "label": "Algutsboda Kyrkogård",
    "value": "algutsboda-kyrkogard-emmaboda"
},
{
    "id": "81",
    "label": "Algutsrums Kyrkogård",
    "value": "algutsrums-kyrkogard-morbylanga"
},
{
    "id": "82",
    "label": "Algutstorp Kyrkogård",
    "value": "algutstorp-kyrkogard-vargarda"
},
{
    "id": "92",
    "label": "Allerums Kyrkogård",
    "value": "allerums-kyrkogard-helsingborg"
},
{
    "id": "104",
    "label": "Almby kyrkogård",
    "value": "almby-kyrkogard-lekeberg"
},
{
    "id": "107",
    "label": "Älmeboda kyrkogård",
    "value": "almeboda-kyrkogard-tingsryd"
},
{
    "id": "111",
    "label": "Almesåkra Kyrkogård",
    "value": "almesakra-kyrkogard-nassjo"
},
{
    "id": "113",
    "label": "Älmhults Kyrkogård",
    "value": "almhults-kyrkogard-almhult"
},
{
    "id": "116",
    "label": "Almundsryds kyrkogård",
    "value": "almundsryds-kyrkogard-tingsryd"
},
{
    "id": "118",
    "label": "Almunge Kyrkogård",
    "value": "almunge-kyrkogard-uppsala"
},
{
    "id": "126",
    "label": "Alseda Kyrkogård",
    "value": "alseda-kyrkogard-vetlanda"
},
{
    "id": "129",
    "label": "Alsike kyrkogård",
    "value": "alsike-kyrkogard-knivsta"
},
{
    "id": "131",
    "label": "Alskogs Kyrkogård",
    "value": "alskogs-kyrkogard-gotland"
},
{
    "id": "134",
    "label": "Alsters kyrkogård",
    "value": "alsters-kyrkogard-karlstad"
},
{
    "id": "138",
    "label": "Altuna kyrkogård",
    "value": "altuna-kyrkogard-enkoping"
},
{
    "id": "140",
    "label": "Alunda kyrkogård",
    "value": "alunda-kyrkogard-osthammar"
},
{
    "id": "144",
    "label": "Alva Kyrkogård",
    "value": "alva-kyrkogard-gotland"
},
{
    "id": "150",
    "label": "Alvesta kyrkogård",
    "value": "alvesta-kyrkogard-alvesta"
},
{
    "id": "153",
    "label": "Älvestads kyrkogård",
    "value": "alvestads-kyrkogard-motala"
},
{
    "id": "154",
    "label": "Älvho Kyrkogård",
    "value": "alvho-kyrkogard-ljusdal"
},
{
    "id": "157",
    "label": "Älvkarleby Kyrkogård",
    "value": "alvkarleby-kyrkogard-alvkarleby"
},
{
    "id": "158",
    "label": "Älvkyrkogården",
    "value": "alvkyrkogarden-kalix"
},
{
    "id": "165",
    "label": "Älvsbackas kyrkogård",
    "value": "alvsbackas-kyrkogard-karlstad"
},
{
    "id": "174",
    "label": "Ambjörnarps Kyrkogård",
    "value": "ambjornarps-kyrkogard-tranemo"
},
{
    "id": "177",
    "label": "Ammarnäs Kyrkogård",
    "value": "ammarnas-kyrkogard-mala"
},
{
    "id": "179",
    "label": "Amnehärads Kyrkogård",
    "value": "amneharads-kyrkogard-gullspang"
},
{
    "id": "183",
    "label": "Amsbergs Kyrkogård",
    "value": "amsbergs-kyrkogard-borlange"
},
{
    "id": "188",
    "label": "Anderslövs Kyrkogård",
    "value": "anderslovs-kyrkogard-trelleborg"
},
{
    "id": "191",
    "label": "Anderstorps Kyrkogård",
    "value": "anderstorps-kyrkogard-gislaved"
},
{
    "id": "192",
    "label": "Andrarum Kyrkogård",
    "value": "andrarum-kyrkogard-tomelilla"
},
{
    "id": "198",
    "label": "Aneboda kyrkogård",
    "value": "aneboda-kyrkogard-vaxjo"
},
{
    "id": "201",
    "label": "Aneby kyrkogård",
    "value": "aneby-kyrkogard-aneby"
},
{
    "id": "203",
    "label": "Anga kyrkogård",
    "value": "anga-kyrkogard-gotland"
},
{
    "id": "206",
    "label": "Angarns kyrkogård",
    "value": "angarns-kyrkogard-vallentuna"
},
{
    "id": "209",
    "label": "Ånge Kyrkogård",
    "value": "ange-kyrkogard-ange"
},
{
    "id": "211",
    "label": "Ängelholms kyrkogård",
    "value": "angelholms-kyrkogard-angelholm"
},
{
    "id": "214",
    "label": "Angelstads kyrkogård",
    "value": "angelstads-kyrkogard-ljungby"
},
{
    "id": "217",
    "label": "Angerdshestra Kyrkogård",
    "value": "angerdshestra-kyrkogard-jonkoping"
},
{
    "id": "220",
    "label": "Angereds kyrkogård",
    "value": "angereds-kyrkogard-goteborg"
},
{
    "id": "226",
    "label": "Ängs Kyrkogården",
    "value": "angs-kyrkogarden-ljusdal"
},
{
    "id": "230",
    "label": "Ånimskogs kyrkogård",
    "value": "animskogs-kyrkogard-amal"
},
{
    "id": "232",
    "label": "Ankarede Kyrkogård",
    "value": "ankarede-kyrkogard-stromsund"
},
{
    "id": "236",
    "label": "Ankarsrums Kyrkogård",
    "value": "ankarsrums-kyrkogard-vastervik"
},
{
    "id": "240",
    "label": "Annefors Kyrkogård",
    "value": "annefors-kyrkogard"
},
{
    "id": "242",
    "label": "Annelövs kyrkogård",
    "value": "annelovs-kyrkogard"
},
{
    "id": "246",
    "label": "Annerstads gamla kyrkogård",
    "value": "annerstads-gamla-kyrkogard"
},
{
    "id": "248",
    "label": "Annerstads kyrkogård",
    "value": "annerstads-kyrkogard"
},
{
    "id": "258",
    "label": "Antens Kyrkogård",
    "value": "antens-kyrkogard-trollhattan"
},
{
    "id": "263",
    "label": "Äppelbro kyrkogård",
    "value": "appelbro-kyrkogard-vansbro"
},
{
    "id": "265",
    "label": "Appuna kyrkogård",
    "value": "appuna-kyrkogard-mjolby"
},
{
    "id": "269",
    "label": "Arbrå Kyrkogård",
    "value": "arbra-kyrkogard-bollnas"
},
{
    "id": "271",
    "label": "Arby Kyrkogård",
    "value": "arby-kyrkogard-kalmar"
},
{
    "id": "273",
    "label": "Årdala kyrkogård",
    "value": "ardala-kyrkogard-flen"
},
{
    "id": "275",
    "label": "Ardre Kyrkogård",
    "value": "ardre-kyrkogard-gotland"
},
{
    "id": "280",
    "label": "Ärentuna kyrkogård",
    "value": "arentuna-kyrkogard-uppsala"
},
{
    "id": "284",
    "label": "Arjeplogs Kyrkogård",
    "value": "arjeplogs-kyrkogard-arjeplog"
},
{
    "id": "288",
    "label": "Ärla g:a kyrkogård",
    "value": "arla-ga-kyrkogard-eskilstuna"
},
{
    "id": "290",
    "label": "Ärla kyrkogård",
    "value": "arla-kyrkogard-eskilstuna"
},
{
    "id": "294",
    "label": "Arlövs Kyrkogård",
    "value": "arlovs-kyrkogard-burlov"
},
{
    "id": "297",
    "label": "Arnäs Kyrkogård",
    "value": "arnas-kyrkogard-ornskoldsvik"
},
{
    "id": "298",
    "label": "Arnö kyrkogård",
    "value": "arno-kyrkogard-enkoping"
},
{
    "id": "301",
    "label": "Arrie kyrkogård",
    "value": "arrie-kyrkogard-vellinge"
},
{
    "id": "308",
    "label": "Årsunda kyrkogård",
    "value": "arsunda-kyrkogard-sandviken"
},
{
    "id": "312",
    "label": "Ärtemarks Kyrkogård",
    "value": "artemarks-kyrkogard-bengtsfors"
},
{
    "id": "314",
    "label": "Arvidsjaurs Kyrkogård",
    "value": "arvidsjaurs-kyrkogard-arvidsjaur"
},
{
    "id": "315",
    "label": "Arvika Kyrkogård",
    "value": "arvika-kyrkogard-arvika"
},
{
    "id": "323",
    "label": "Ås Kyrkogård",
    "value": "as-kyrkogard-varberg"
},
{
    "id": "324",
    "label": "Ås Kyrkogård",
    "value": "as-kyrkogard-krokom"
},
{
    "id": "325",
    "label": "Ås Kyrkogård",
    "value": "as-kyrkogard-gislaved"
},
{
    "id": "326",
    "label": "Ås Kyrkogård",
    "value": "as-kyrkogard-morbylanga"
},
{
    "id": "327",
    "label": "Ås kyrkogård",
    "value": "as-kyrkogard-grastorp"
},
{
    "id": "329",
    "label": "Asa kyrkogård",
    "value": "asa-kyrkogard-vaxjo"
},
{
    "id": "332",
    "label": "Åsarp Kyrkogård",
    "value": "asarp-kyrkogard-falkoping"
},
{
    "id": "335",
    "label": "Åsarps kyrkogård",
    "value": "asarps-kyrkogard-falkoping"
},
{
    "id": "338",
    "label": "Asarums kyrkogård",
    "value": "asarums-kyrkogard-karlshamn"
},
{
    "id": "341",
    "label": "Åsbo kyrkogård",
    "value": "asbo-kyrkogard-odeshog"
},
{
    "id": "343",
    "label": "Åsbräcka Kyrkogård",
    "value": "asbracka-kyrkogard-lilla-edet"
},
{
    "id": "346",
    "label": "Asby kyrkogård",
    "value": "asby-kyrkogard-ydre"
},
{
    "id": "348",
    "label": "Åseda kyrkogård",
    "value": "aseda-kyrkogard-uppvidinge"
},
{
    "id": "351",
    "label": "Åsenhöga kyrkogård",
    "value": "asenhoga-kyrkogard-gnosjo"
},
{
    "id": "360",
    "label": "Askeby Kyrkogård",
    "value": "askeby-kyrkogard-linkoping"
},
{
    "id": "363",
    "label": "Askers kyrkogård",
    "value": "askers-kyrkogard-orebro"
},
{
    "id": "366",
    "label": "Askeryds kyrkogård",
    "value": "askeryds-kyrkogard-aneby"
},
{
    "id": "368",
    "label": "Åskilje kyrkogård",
    "value": "askilje-kyrkogard-storuman"
},
{
    "id": "369",
    "label": "Askim Norra kyrkogård",
    "value": "askim-norra-kyrkogard-goteborg"
},
{
    "id": "370",
    "label": "Askim Södra kyrkogård",
    "value": "askim-sodra-kyrkogard-goteborg"
},
{
    "id": "373",
    "label": "Asklanda Kyrkogård",
    "value": "asklanda-kyrkogard-vargarda"
},
{
    "id": "376",
    "label": "Asks Kyrkogård",
    "value": "asks-kyrkogard-svalov"
},
{
    "id": "377",
    "label": "Asks kyrkogård",
    "value": "asks-kyrkogard-motala"
},
{
    "id": "378",
    "label": "Askums gamla kyrkogård",
    "value": "askums-gamla-kyrkogard-sotenas"
},
{
    "id": "380",
    "label": "Askums kyrkogårdar",
    "value": "askums-kyrkogardar-sotenas"
},
{
    "id": "382",
    "label": "Åsle Kyrkogård",
    "value": "asle-kyrkogard-falkoping"
},
{
    "id": "385",
    "label": "Asmundtorps kyrkogård",
    "value": "asmundtorps-kyrkogard-landskrona"
},
{
    "id": "387",
    "label": "Aspås Kyrkogård",
    "value": "aspas-kyrkogard-krokom"
},
{
    "id": "390",
    "label": "Aspeboda Kyrkogård",
    "value": "aspeboda-kyrkogard-falkenberg"
},
{
    "id": "396",
    "label": "Äspereds kyrkogård",
    "value": "aspereds-kyrkogard-boras"
},
{
    "id": "399",
    "label": "Äsphults kyrkogård",
    "value": "asphults-kyrkogard-kristianstad"
},
{
    "id": "401",
    "label": "Äspinge Kyrkogård",
    "value": "aspinge-kyrkogard-horby"
},
{
    "id": "407",
    "label": "Aspö kyrkogård",
    "value": "aspo-kyrkogard-strangnas"
},
{
    "id": "408",
    "label": "Äspö Kyrkogård",
    "value": "aspo-kyrkogard-trelleborg"
},
{
    "id": "410",
    "label": "Atlingbo kyrkogård",
    "value": "atlingbo-kyrkogard-gotland"
},
{
    "id": "414",
    "label": "Ättetorps kyrkogård",
    "value": "attetorps-kyrkogard-norrkoping"
},
{
    "id": "416",
    "label": "Åtvidabergs gamla kyrkogård",
    "value": "atvidabergs-gamla-kyrkogard-atvidaberg"
},
{
    "id": "417",
    "label": "Åtvidabergs nya kyrkogård",
    "value": "atvidabergs-nya-kyrkogard-atvidaberg"
},
{
    "id": "422",
    "label": "Augerums Kyrkogård",
    "value": "augerums-kyrkogard-karlskrona"
},
{
    "id": "424",
    "label": "Ausås Kyrkogård",
    "value": "ausas-kyrkogard-angelholm"
},
{
    "id": "427",
    "label": "Avaskärs kapell",
    "value": "avaskars-kapell-karlskrona"
},
{
    "id": "433",
    "label": "Axbergs Kyrkogård",
    "value": "axbergs-kyrkogard-orebro"
},
{
    "id": "436",
    "label": "Backa Gamla kyrkogård",
    "value": "backa-gamla-kyrkogard-goteborg"
},
{
    "id": "438",
    "label": "Backa Nya kyrkogård",
    "value": "backa-nya-kyrkogard-goteborg"
},
{
    "id": "439",
    "label": "Bäckaby Gamla Kyrkogård",
    "value": "backaby-gamla-kyrkogard-vetlanda"
},
{
    "id": "441",
    "label": "Bäckaby Nya Kyrkogård",
    "value": "backaby-nya-kyrkogard-vetlanda"
},
{
    "id": "443",
    "label": "Backaryds kyrkogård",
    "value": "backaryds-kyrkogard-ronneby"
},
{
    "id": "448",
    "label": "Bäcke kyrkogård",
    "value": "backe-kyrkogard-bengtsfors"
},
{
    "id": "450",
    "label": "Bäckebo Kyrkogård",
    "value": "backebo-kyrkogard-nybro"
},
{
    "id": "456",
    "label": "Backens kyrkogård",
    "value": "backens-kyrkogard-umea"
},
{
    "id": "459",
    "label": "Bäcks kyrkogård",
    "value": "backs-kyrkogard-gullspang"
},
{
    "id": "462",
    "label": "Bäckseda kyrkogård",
    "value": "backseda-kyrkogard-vetlanda"
},
{
    "id": "470",
    "label": "Bäl kyrkogård",
    "value": "bal-kyrkogard-gotland"
},
{
    "id": "472",
    "label": "Bälaryds kyrkogård",
    "value": "balaryds-kyrkogard-aneby"
},
{
    "id": "474",
    "label": "Baldringe Kyrkogård",
    "value": "baldringe-kyrkogard-ystad"
},
{
    "id": "478",
    "label": "Bälinge kyrkogård",
    "value": "balinge-kyrkogard-nykoping"
},
{
    "id": "479",
    "label": "Bälinge kyrkogård",
    "value": "balinge-kyrkogard-uppsala"
},
{
    "id": "480",
    "label": "Bälinge kyrkogård",
    "value": "balinge-kyrkogard-alingsas"
},
{
    "id": "483",
    "label": "Balingsta Kyrkogård",
    "value": "balingsta-kyrkogard-uppsala"
},
{
    "id": "485",
    "label": "Bällefors kyrkogård",
    "value": "ballefors-kyrkogard-gullspang"
},
{
    "id": "489",
    "label": "Baltaks kyrkogård",
    "value": "baltaks-kyrkogard-tidaholm"
},
{
    "id": "491",
    "label": "Bankekinds Kyrkogård",
    "value": "bankekinds-kyrkogard-linkoping"
},
{
    "id": "494",
    "label": "Bankeryds Kyrkogård",
    "value": "bankeryds-kyrkogard"
},
{
    "id": "497",
    "label": "Bara kyrkogård",
    "value": "bara-kyrkogard"
},
{
    "id": "500",
    "label": "Bärbo kyrkogård",
    "value": "barbo-kyrkogard"
},
{
    "id": "502",
    "label": "Bärebergs Kyrkogård",
    "value": "barebergs-kyrkogard"
},
{
    "id": "504",
    "label": "Bärfendals Kyrkogård",
    "value": "barfendals-kyrkogard-munkedal"
},
{
    "id": "506",
    "label": "Barkåkra Kyrkogård",
    "value": "barkakra-kyrkogard-angelholm"
},
{
    "id": "509",
    "label": "Barlingbo kyrkogård",
    "value": "barlingbo-kyrkogard-gotland"
},
{
    "id": "511",
    "label": "Barnarps Kyrkogård",
    "value": "barnarps-kyrkogard-jonkoping"
},
{
    "id": "513",
    "label": "Barne-Åsaka Kyrkogård",
    "value": "barne-asaka-kyrkogard-essunga"
},
{
    "id": "515",
    "label": "Barsebäcks kyrkogård",
    "value": "barsebacks-kyrkogard-kavlinge"
},
{
    "id": "517",
    "label": "Bårslövs Kyrkogård",
    "value": "barslovs-kyrkogard-landskrona"
},
{
    "id": "520",
    "label": "Barva kyrkogård",
    "value": "barva-kyrkogard-eskilstuna"
},
{
    "id": "522",
    "label": "Baskemölla Kyrkogård",
    "value": "baskemolla-kyrkogard-simrishamn"
},
{
    "id": "524",
    "label": "Båstads Kyrkogård",
    "value": "bastads-kyrkogard-bastad"
},
{
    "id": "525",
    "label": "Båstads Nya Begravningsplats",
    "value": "bastads-nya-begravningsplats-bastad"
},
{
    "id": "529",
    "label": "Beatebergs kyrkogård",
    "value": "beatebergs-kyrkogard-gullspang"
},
{
    "id": "532",
    "label": "Bellö kyrkogård",
    "value": "bello-kyrkogard-nassjo"
},
{
    "id": "533",
    "label": "Benestads Gamla Kyrkogård",
    "value": "benestads-gamla-kyrkogard-tomelilla"
},
{
    "id": "535",
    "label": "Benestads Nya Kyrkogård",
    "value": "benestads-nya-kyrkogard-tomelilla"
},
{
    "id": "543",
    "label": "Berga kyrkogård",
    "value": "berga-kyrkogard-ljungby"
},
{
    "id": "544",
    "label": "Berga Kyrkogård",
    "value": "berga-kyrkogard-gullspang"
},
{
    "id": "548",
    "label": "Berghems Kyrkogård",
    "value": "berghems-kyrkogard-mark"
},
{
    "id": "550",
    "label": "Berglunda Kyrkogård",
    "value": "berglunda-kyrkogard-storuman"
},
{
    "id": "559",
    "label": "Bergs kyrkogård",
    "value": "bergs-kyrkogard-vaxjo"
},
{
    "id": "560",
    "label": "Bergs kyrkogård",
    "value": "bergs-kyrkogard-skovde"
},
{
    "id": "564",
    "label": "Bergshammars kyrkogård",
    "value": "bergshammars-kyrkogard-nykoping"
},
{
    "id": "573",
    "label": "Bergums kyrkogård",
    "value": "bergums-kyrkogard-goteborg"
},
{
    "id": "575",
    "label": "Bergunda kyrkogård",
    "value": "bergunda-kyrkogard-vaxjo"
},
{
    "id": "577",
    "label": "Berthåga kyrkogård",
    "value": "berthaga-kyrkogard-uppsala"
},
{
    "id": "580",
    "label": "Bettna kyrkogård",
    "value": "bettna-kyrkogard-flen"
},
{
    "id": "582",
    "label": "Billdals kyrkogård",
    "value": "billdals-kyrkogard-goteborg"
},
{
    "id": "584",
    "label": "Billeberga Kyrkogård",
    "value": "billeberga-kyrkogard-svalov"
},
{
    "id": "588",
    "label": "Billinge kyrkogård",
    "value": "billinge-kyrkogard-eslov"
},
{
    "id": "591",
    "label": "Billingsfors kyrkogård",
    "value": "billingsfors-kyrkogard-bengtsfors"
},
{
    "id": "593",
    "label": "Bingsjö Kyrkogård",
    "value": "bingsjo-kyrkogard-rattvik"
},
{
    "id": "595",
    "label": "Binnebergs kyrkogård",
    "value": "binnebergs-kyrkogard-skovde"
},
{
    "id": "604",
    "label": "Biskopskulla kyrkogård",
    "value": "biskopskulla-kyrkogard-enkoping"
},
{
    "id": "606",
    "label": "Bitterna kyrkogård",
    "value": "bitterna-kyrkogard-vara"
},
{
    "id": "609",
    "label": "Bjälbo kyrkogård",
    "value": "bjalbo-kyrkogard-odeshog"
},
{
    "id": "611",
    "label": "Bjällerups kyrkogård",
    "value": "bjallerups-kyrkogard-staffanstorp"
},
{
    "id": "613",
    "label": "Bjäresjö Kyrkogård",
    "value": "bjaresjo-kyrkogard-ystad"
},
{
    "id": "616",
    "label": "Bjärklunda kyrkogård",
    "value": "bjarklunda-kyrkogard-skara"
},
{
    "id": "618",
    "label": "Bjärshögs kyrkogård",
    "value": "bjarshogs-kyrkogard-svedala"
},
{
    "id": "623",
    "label": "Björka Kyrkogård",
    "value": "bjorka-kyrkogard-sjobo"
},
{
    "id": "624",
    "label": "Björkberg Kyrkogård",
    "value": "bjorkberg-kyrkogard-ljusdal"
},
{
    "id": "627",
    "label": "Björke kyrkogård",
    "value": "bjorke-kyrkogard-gotland"
},
{
    "id": "629",
    "label": "Björkebergs kyrkogård",
    "value": "bjorkebergs-kyrkogard-linkoping"
},
{
    "id": "632",
    "label": "Björketorps kyrkogård",
    "value": "bjorketorps-kyrkogard-harryda"
},
{
    "id": "636",
    "label": "Björklinge Kyrkogård",
    "value": "bjorklinge-kyrkogard-uppsala"
},
{
    "id": "640",
    "label": "Björkö kyrkogård",
    "value": "bjorko-kyrkogard-vetlanda"
},
{
    "id": "643",
    "label": "Björkö-Arholma kyrkogård",
    "value": "bjorko-arholma-kyrkogard-norrtalje"
},
{
    "id": "647",
    "label": "Björksele Kyrkogård",
    "value": "bjorksele-kyrkogard-storuman"
},
{
    "id": "651",
    "label": "Björkviks kyrkogård",
    "value": "bjorkviks-kyrkogard-katrineholm"
},
{
    "id": "654",
    "label": "Björlanda kyrkogård",
    "value": "bjorlanda-kyrkogard-goteborg"
},
{
    "id": "657",
    "label": "Björna kyrkogård",
    "value": "bjorna-kyrkogard-ornskoldsvik"
},
{
    "id": "660",
    "label": "Björneborgs kyrkogård",
    "value": "bjorneborgs-kyrkogard-kristinehamn"
},
{
    "id": "663",
    "label": "Björnekulla Kyrkogård",
    "value": "bjornekulla-kyrkogard-astorp"
},
{
    "id": "665",
    "label": "Björnlunda kyrkogård",
    "value": "bjornlunda-kyrkogard-gnesta"
},
{
    "id": "669",
    "label": "Björsäters kyrkogård",
    "value": "bjorsaters-kyrkogard-mariestad"
},
{
    "id": "670",
    "label": "Björsäters kyrkogård",
    "value": "bjorsaters-kyrkogard-atvidaberg"
},
{
    "id": "677",
    "label": "Bjurbäcks kyrkogård",
    "value": "bjurbacks-kyrkogard-mullsjo"
},
{
    "id": "682",
    "label": "Bjurtjärns Kyrkogård",
    "value": "bjurtjarns-kyrkogard-storfors"
},
{
    "id": "683",
    "label": "Bjurum Kyrkogård",
    "value": "bjurum-kyrkogard-falkoping"
},
{
    "id": "686",
    "label": "Bjuvs Kyrkogård",
    "value": "bjuvs-kyrkogard-bjuv"
},
{
    "id": "688",
    "label": "Blacksta kyrkogård",
    "value": "blacksta-kyrkogard-flen"
},
{
    "id": "689",
    "label": "Blackstad Kyrkogård",
    "value": "blackstad-kyrkogard-oskarshamn"
},
{
    "id": "692",
    "label": "Bladåker Kyrkogård",
    "value": "bladaker-kyrkogard-uppsala"
},
{
    "id": "696",
    "label": "Blädinge kyrkogård",
    "value": "bladinge-kyrkogard-alvesta"
},
{
    "id": "700",
    "label": "Blåviks kyrkogård",
    "value": "blaviks-kyrkogard-boxholm"
},
{
    "id": "703",
    "label": "Blekets Kyrkogård",
    "value": "blekets-kyrkogard-tjorn"
},
{
    "id": "707",
    "label": "Blentarps Kyrkogård",
    "value": "blentarps-kyrkogard-sjobo"
},
{
    "id": "709",
    "label": "Blidö kyrkogård",
    "value": "blido-kyrkogard-norrtalje"
},
{
    "id": "711",
    "label": "Blidsbergs Kyrkogård",
    "value": "blidsbergs-kyrkogard-ulricehamn"
},
{
    "id": "714",
    "label": "Blomskogs kyrkogård",
    "value": "blomskogs-kyrkogard-arjang"
},
{
    "id": "716",
    "label": "Bo kyrkogård",
    "value": "bo-kyrkogard-hallsberg"
},
{
    "id": "719",
    "label": "Bockara Kyrkogård",
    "value": "bockara-kyrkogard-oskarshamn"
},
{
    "id": "721",
    "label": "Bocksjö kyrkogård",
    "value": "bocksjo-kyrkogard-karlsborg"
},
{
    "id": "727",
    "label": "Boda Kyrkogård",
    "value": "boda-kyrkogard-rattvik"
},
{
    "id": "728",
    "label": "Boda kyrkogård",
    "value": "boda-kyrkogard-kil"
},
{
    "id": "729",
    "label": "Böda kyrkogård",
    "value": "boda-kyrkogard-borgholm"
},
{
    "id": "731",
    "label": "Bodafors kyrkogård",
    "value": "bodafors-kyrkogard-nassjo"
},
{
    "id": "734",
    "label": "Bodarp Kyrkogård",
    "value": "bodarp-kyrkogard-trelleborg"
},
{
    "id": "739",
    "label": "Bodums Kyrkogård",
    "value": "bodums-kyrkogard-stromsund"
},
{
    "id": "741",
    "label": "Boge kyrkogård",
    "value": "boge-kyrkogard-gotland"
},
{
    "id": "742",
    "label": "Bogen Kyrkogård",
    "value": "bogen-kyrkogard-arvika"
},
{
    "id": "746",
    "label": "Boglösa kyrkogård",
    "value": "boglosa-kyrkogard"
},
{
    "id": "748",
    "label": "Bogsta kyrkogård",
    "value": "bogsta-kyrkogard"
},
{
    "id": "750",
    "label": "Böja kyrkogård",
    "value": "boja-kyrkogard"
},
{
    "id": "755",
    "label": "Bokenäs Kyrkogård",
    "value": "bokenas-kyrkogard"
},
{
    "id": "761",
    "label": "Bollebygds kyrkogård",
    "value": "bollebygds-kyrkogard-bollebygd"
},
{
    "id": "762",
    "label": "Bollebygds nya kyrkogård",
    "value": "bollebygds-nya-kyrkogard-bollebygd"
},
{
    "id": "764",
    "label": "Bollerups Kyrkogård",
    "value": "bollerups-kyrkogard-tomelilla"
},
{
    "id": "765",
    "label": "Bollmora kyrkogård",
    "value": "bollmora-kyrkogard-tyreso"
},
{
    "id": "768",
    "label": "Bollnäs Kyrkogård",
    "value": "bollnas-kyrkogard-bollnas"
},
{
    "id": "770",
    "label": "Bolmsö kyrkogård",
    "value": "bolmso-kyrkogard-ljungby"
},
{
    "id": "772",
    "label": "Bolshögs Kyrkogård",
    "value": "bolshogs-kyrkogard-simrishamn"
},
{
    "id": "775",
    "label": "Bolstads kyrkogård",
    "value": "bolstads-kyrkogard-mellerud"
},
{
    "id": "777",
    "label": "Bomhus Kyrkogård",
    "value": "bomhus-kyrkogard-gavle"
},
{
    "id": "780",
    "label": "Bonderups Kyrkogård",
    "value": "bonderups-kyrkogard-lund"
},
{
    "id": "782",
    "label": "Bondstorps Kyrkogård",
    "value": "bondstorps-kyrkogard-vaggeryd"
},
{
    "id": "784",
    "label": "Böne Kyrkogård",
    "value": "bone-kyrkogard-ulricehamn"
},
{
    "id": "787",
    "label": "Boo Gamla Kyrkogård",
    "value": "boo-gamla-kyrkogard-laxa"
},
{
    "id": "789",
    "label": "Boo Kyrkogård",
    "value": "boo-kyrkogard-laxa"
},
{
    "id": "790",
    "label": "Boöns kyrkogård",
    "value": "boons-kyrkogard-karlshamn"
},
{
    "id": "792",
    "label": "Borgeby kyrkogård",
    "value": "borgeby-kyrkogard-lomma"
},
{
    "id": "794",
    "label": "Borgholm kyrkogård",
    "value": "borgholm-kyrkogard-borgholm"
},
{
    "id": "797",
    "label": "Borgholms kyrkogård",
    "value": "borgholms-kyrkogard-borgholm"
},
{
    "id": "800",
    "label": "Borgs kyrkogård",
    "value": "borgs-kyrkogard-norrkoping"
},
{
    "id": "804",
    "label": "Borgsjö Kyrkogård",
    "value": "borgsjo-kyrkogard-ange"
},
{
    "id": "805",
    "label": "Borgsmo begravningsplats",
    "value": "borgsmo-begravningsplats-norrkoping"
},
{
    "id": "808",
    "label": "Borgstena kyrkogård",
    "value": "borgstena-kyrkogard-boras"
},
{
    "id": "810",
    "label": "Borgunda Kyrkogård",
    "value": "borgunda-kyrkogard-falkoping"
},
{
    "id": "813",
    "label": "Borgviks kyrkogård",
    "value": "borgviks-kyrkogard-grums"
},
{
    "id": "815",
    "label": "Börje kyrkogård",
    "value": "borje-kyrkogard-uppsala"
},
{
    "id": "818",
    "label": "Borlunda kyrkogård",
    "value": "borlunda-kyrkogard-eslov"
},
{
    "id": "821",
    "label": "Borrby Kyrkogård",
    "value": "borrby-kyrkogard-simrishamn"
},
{
    "id": "824",
    "label": "Borrie Kyrkogård",
    "value": "borrie-kyrkogard-ystad"
},
{
    "id": "826",
    "label": "Börringe Kyrkogård",
    "value": "borringe-kyrkogard-svedala"
},
{
    "id": "828",
    "label": "Börrums Kyrkogård",
    "value": "borrums-kyrkogard-soderkoping"
},
{
    "id": "831",
    "label": "Börstig kyrkogård",
    "value": "borstig-kyrkogard-falkoping"
},
{
    "id": "833",
    "label": "Börstigs Kyrkogård",
    "value": "borstigs-kyrkogard-falkoping"
},
{
    "id": "835",
    "label": "Börstils kyrkogård",
    "value": "borstils-kyrkogard-osthammar"
},
{
    "id": "838",
    "label": "Bosarps kyrkogård",
    "value": "bosarps-kyrkogard-eslov"
},
{
    "id": "839",
    "label": "Bösarps kyrkogård",
    "value": "bosarps-kyrkogard-trelleborg"
},
{
    "id": "842",
    "label": "Bosjöklosters kyrkogård",
    "value": "bosjoklosters-kyrkogard-hoor"
},
{
    "id": "848",
    "label": "Botilsäter Kyrkogård",
    "value": "botilsater-kyrkogard-saffle"
},
{
    "id": "851",
    "label": "Botilsäters kyrkogård",
    "value": "botilsaters-kyrkogard-saffle"
},
{
    "id": "852",
    "label": "Botkyrka kyrkogård",
    "value": "botkyrka-kyrkogard-botkyrka"
},
{
    "id": "856",
    "label": "Bottna kyrkogård",
    "value": "bottna-kyrkogard-tanum"
},
{
    "id": "858",
    "label": "Bottnaryds Kyrkogård",
    "value": "bottnaryds-kyrkogard-jonkoping"
},
{
    "id": "860",
    "label": "Bovallstrands kyrkogård",
    "value": "bovallstrands-kyrkogard-sotenas"
},
{
    "id": "863",
    "label": "Boxholms kyrkogård",
    "value": "boxholms-kyrkogard-odeshog"
},
{
    "id": "865",
    "label": "Bräcke kolerakyrkogård",
    "value": "bracke-kolerakyrkogard-goteborg"
},
{
    "id": "868",
    "label": "Brågarps kyrkogård",
    "value": "bragarps-kyrkogard-staffanstorp"
},
{
    "id": "870",
    "label": "Bräkne-Hoby kyrkogård",
    "value": "brakne-hoby-kyrkogard-ronneby"
},
{
    "id": "873",
    "label": "Brålanda kyrkogård",
    "value": "bralanda-kyrkogard-vanersborg"
},
{
    "id": "877",
    "label": "Brämhults kyrkogård",
    "value": "bramhults-kyrkogard-boras"
},
{
    "id": "881",
    "label": "Brandstads kyrkogård",
    "value": "brandstads-kyrkogard-sjobo"
},
{
    "id": "884",
    "label": "Brandstorps Kyrkogård",
    "value": "brandstorps-kyrkogard-habo"
},
{
    "id": "886",
    "label": "Bråneryds kyrkogård",
    "value": "braneryds-kyrkogard-jonkoping"
},
{
    "id": "888",
    "label": "Brännemo kyrkogård",
    "value": "brannemo-kyrkogard-skovde"
},
{
    "id": "890",
    "label": "Brännkyrka kyrkogård",
    "value": "brannkyrka-kyrkogard-stockholm"
},
{
    "id": "892",
    "label": "Brännö kyrkogård",
    "value": "branno-kyrkogard-goteborg"
},
{
    "id": "895",
    "label": "Bråttensby kyrkogård",
    "value": "brattensby-kyrkogard-herrljunga"
},
{
    "id": "897",
    "label": "Brattfors kyrkogård",
    "value": "brattfors-kyrkogard-filipstad"
},
{
    "id": "899",
    "label": "Breareds Kyrkogård",
    "value": "breareds-kyrkogard-halmstad"
},
{
    "id": "902",
    "label": "Bredåkra kyrkogård",
    "value": "bredakra-kyrkogard-ronneby"
},
{
    "id": "906",
    "label": "Bredareds kyrkogård",
    "value": "bredareds-kyrkogard-boras"
},
{
    "id": "908",
    "label": "Bredaryds Kyrkogård",
    "value": "bredaryds-kyrkogard-gnosjo"
},
{
    "id": "910",
    "label": "Bredestads kyrkogård",
    "value": "bredestads-kyrkogard-aneby"
},
{
    "id": "912",
    "label": "Breds kyrkogård",
    "value": "breds-kyrkogard-enkoping"
},
{
    "id": "914",
    "label": "Bredsäters kyrkogård",
    "value": "bredsaters-kyrkogard-mariestad"
},
{
    "id": "916",
    "label": "Bredsättra kyrkogård",
    "value": "bredsattra-kyrkogard-borgholm"
},
{
    "id": "919",
    "label": "Brevens kyrkogård",
    "value": "brevens-kyrkogard-orebro"
},
{
    "id": "923",
    "label": "Breviks kyrkogård",
    "value": "breviks-kyrkogard-karlsborg"
},
{
    "id": "924",
    "label": "Bringetofta Gamla Kyrkogård",
    "value": "bringetofta-gamla-kyrkogard-nassjo"
},
{
    "id": "926",
    "label": "Bringetofta Nya Kyrkogård",
    "value": "bringetofta-nya-kyrkogard-nassjo"
},
{
    "id": "928",
    "label": "Brismene Kyrkogård",
    "value": "brismene-kyrkogard-falkoping"
},
{
    "id": "934",
    "label": "Bro kyrkogård",
    "value": "bro-kyrkogard-saffle"
},
{
    "id": "935",
    "label": "Bro kyrkogård",
    "value": "bro-kyrkogard-gotland"
},
{
    "id": "936",
    "label": "Bro kyrkogård",
    "value": "bro-kyrkogard-upplands-bro"
},
{
    "id": "938",
    "label": "Broby kyrkogård",
    "value": "broby-kyrkogard-gotene"
},
{
    "id": "941",
    "label": "Broddarps kyrkogård",
    "value": "broddarps-kyrkogard-herrljunga"
},
{
    "id": "942",
    "label": "Broddetorp Kyrkogård",
    "value": "broddetorp-kyrkogard-falkoping"
},
{
    "id": "947",
    "label": "Bromma kyrkogård",
    "value": "bromma-kyrkogard-stockholm"
},
{
    "id": "948",
    "label": "Bromma Kyrkogård",
    "value": "bromma-kyrkogard-ystad"
},
{
    "id": "950",
    "label": "Brönnestads Kyrkogård",
    "value": "bronnestads-kyrkogard-hassleholm"
},
{
    "id": "951",
    "label": "Brösarp Kyrkogård",
    "value": "brosarp-kyrkogard-tomelilla"
},
{
    "id": "960",
    "label": "Brunflo kyrkogård",
    "value": "brunflo-kyrkogard-ostersund"
},
{
    "id": "962",
    "label": "Brunnby Kyrkogård",
    "value": "brunnby-kyrkogard-hoganas"
},
{
    "id": "964",
    "label": "Brunns kyrkogård",
    "value": "brunns-kyrkogard-ulricehamn"
},
{
    "id": "967",
    "label": "Brunskog Kyrkogård",
    "value": "brunskog-kyrkogard-arvika"
},
{
    "id": "972",
    "label": "Bunge kyrkogård",
    "value": "bunge-kyrkogard-gotland"
},
{
    "id": "976",
    "label": "Bunkeflo kyrkogård",
    "value": "bunkeflo-kyrkogard-malmo"
},
{
    "id": "981",
    "label": "Bureå Kyrkogård",
    "value": "burea-kyrkogard-skelleftea"
},
{
    "id": "984",
    "label": "Burlövs gamla kyrkogård",
    "value": "burlovs-gamla-kyrkogard-burlov"
},
{
    "id": "985",
    "label": "Burlövs Kyrkogård",
    "value": "burlovs-kyrkogard-burlov"
},
{
    "id": "988",
    "label": "Burseryds Kyrkogård",
    "value": "burseryds-kyrkogard-gislaved"
},
{
    "id": "990",
    "label": "Burträsks Kyrkogård",
    "value": "burtrasks-kyrkogard"
},
{
    "id": "993",
    "label": "Buttle kyrkogård",
    "value": "buttle-kyrkogard"
},
{
    "id": "996",
    "label": "By kyrkogård",
    "value": "by-kyrkogard"
},
{
    "id": "997",
    "label": "By Skogskyrkogård",
    "value": "by-skogskyrkogard"
},
{
    "id": "1000",
    "label": "Byarums Kyrkogård",
    "value": "byarums-kyrkogard"
},
{
    "id": "1002",
    "label": "Bydalens Skogskyrkogård",
    "value": "bydalens-skogskyrkogard-sundsvall"
},
{
    "id": "1009",
    "label": "Bygdsiljums Kyrkogård",
    "value": "bygdsiljums-kyrkogard-skelleftea"
},
{
    "id": "1011",
    "label": "Byns Kyrkogård",
    "value": "byns-kyrkogard-sundsvall"
},
{
    "id": "1020",
    "label": "Caroli kyrkogård",
    "value": "caroli-kyrkogard-boras"
},
{
    "id": "1022",
    "label": "Centrala griftegårdarna",
    "value": "centrala-griftegardarna-linkoping"
},
{
    "id": "1030",
    "label": "Cermoniplatsen Nya kyrkogården",
    "value": "cermoniplatsen-nya-kyrkogarden-alvsbyn"
},
{
    "id": "1037",
    "label": "Columbariet",
    "value": "columbariet-stockholm"
},
{
    "id": "1040",
    "label": "Dädesjö kyrkogård",
    "value": "dadesjo-kyrkogard-vaxjo"
},
{
    "id": "1044",
    "label": "Dagsås Kyrkogård",
    "value": "dagsas-kyrkogard-varberg"
},
{
    "id": "1046",
    "label": "Dagsbergs kyrkogård",
    "value": "dagsbergs-kyrkogard-norrkoping"
},
{
    "id": "1049",
    "label": "Dagstorps kyrkogård",
    "value": "dagstorps-kyrkogard-kavlinge"
},
{
    "id": "1051",
    "label": "Dala Kyrkogård",
    "value": "dala-kyrkogard-falkoping"
},
{
    "id": "1054",
    "label": "Dalarö Kyrkogård",
    "value": "dalaro-kyrkogard-haninge"
},
{
    "id": "1058",
    "label": "Dalby Kyrkogård",
    "value": "dalby-kyrkogard-lund"
},
{
    "id": "1059",
    "label": "Dalby Kyrkogård",
    "value": "dalby-kyrkogard-uppsala"
},
{
    "id": "1060",
    "label": "Dalby kyrkogård",
    "value": "dalby-kyrkogard-torsby"
},
{
    "id": "1065",
    "label": "Dalhems kyrkogård",
    "value": "dalhems-kyrkogard-gotland"
},
{
    "id": "1066",
    "label": "Dalhems kyrkogård",
    "value": "dalhems-kyrkogard-vastervik"
},
{
    "id": "1068",
    "label": "Dalköpinge kyrkogård",
    "value": "dalkopinge-kyrkogard-trelleborg"
},
{
    "id": "1073",
    "label": "Dalskogens Kyrkogård",
    "value": "dalskogens-kyrkogard-lysekil"
},
{
    "id": "1075",
    "label": "Dalskogs kyrkogård",
    "value": "dalskogs-kyrkogard-mellerud"
},
{
    "id": "1078",
    "label": "Dalstorps Kyrkogård",
    "value": "dalstorps-kyrkogard-tranemo"
},
{
    "id": "1080",
    "label": "Dalums Kyrkogård",
    "value": "dalums-kyrkogard-ulricehamn"
},
{
    "id": "1083",
    "label": "Danderyds kyrkogård",
    "value": "danderyds-kyrkogard-danderyd"
},
{
    "id": "1088",
    "label": "Dannäs kyrkogård",
    "value": "dannas-kyrkogard-varnamo"
},
{
    "id": "1093",
    "label": "Dannike kyrkogård",
    "value": "dannike-kyrkogard-tranemo"
},
{
    "id": "1094",
    "label": "Dänningelanda kyrkogård",
    "value": "danningelanda-kyrkogard-vaxjo"
},
{
    "id": "1098",
    "label": "Daretorps kyrkogård",
    "value": "daretorps-kyrkogard-tidaholm"
},
{
    "id": "1100",
    "label": "Degeberga Kyrkogård",
    "value": "degeberga-kyrkogard-kristianstad"
},
{
    "id": "1101",
    "label": "Degerfors kyrkogård",
    "value": "degerfors-kyrkogard-degefors"
},
{
    "id": "1110",
    "label": "Dikanäs kyrkogård",
    "value": "dikanas-kyrkogard-storuman"
},
{
    "id": "1112",
    "label": "Dillnäs kyrkogård",
    "value": "dillnas-kyrkogard-gnesta"
},
{
    "id": "1113",
    "label": "Dimbo kyrkogård",
    "value": "dimbo-kyrkogard-tidaholm"
},
{
    "id": "1118",
    "label": "Djura Kyrkogård",
    "value": "djura-kyrkogard-leksand"
},
{
    "id": "1121",
    "label": "Djurgårdskyrkogården",
    "value": "djurgardskyrkogarden-goteborg"
},
{
    "id": "1124",
    "label": "Djurö Kyrkogård",
    "value": "djuro-kyrkogard-varmdo"
},
{
    "id": "1126",
    "label": "Djurröds kyrkogård",
    "value": "djurrods-kyrkogard-kristianstad"
},
{
    "id": "1128",
    "label": "Djursdala kyrkogård",
    "value": "djursdala-kyrkogard-vimmerby"
},
{
    "id": "1129",
    "label": "Djursholms begravningsplats",
    "value": "djursholms-begravningsplats-danderyd"
},
{
    "id": "1134",
    "label": "Döderhults Kyrkogård",
    "value": "doderhults-kyrkogard-oskarshamn"
},
{
    "id": "1142",
    "label": "Domkyrkans kyrkogård",
    "value": "domkyrkans-kyrkogard-mariestad"
},
{
    "id": "1147",
    "label": "Donationskyrkogården",
    "value": "donationskyrkogarden-helsingborg"
},
{
    "id": "1149",
    "label": "Donsö kyrkogård",
    "value": "donso-kyrkogard-goteborg"
},
{
    "id": "1152",
    "label": "Dörby Kyrkogård",
    "value": "dorby-kyrkogard-kalmar"
},
{
    "id": "1156",
    "label": "Dragsmarks Kyrkogård",
    "value": "dragsmarks-kyrkogard-uddevalla"
},
{
    "id": "1158",
    "label": "Drängsereds Kyrkogård",
    "value": "drangsereds-kyrkogard-hylte"
},
{
    "id": "1159",
    "label": "Drev gamla kyrkogård",
    "value": "drev-gamla-kyrkogard-vaxjo"
},
{
    "id": "1160",
    "label": "Drevdagens Begravningsplats",
    "value": "drevdagens-begravningsplats-alvdalen"
},
{
    "id": "1162",
    "label": "Drev-Hornaryds kyrkogård",
    "value": "drev-hornaryds-kyrkogard-vaxjo"
},
{
    "id": "1164",
    "label": "Drörarps kyrkogård",
    "value": "drorarps-kyrkogard-ljungby"
},
{
    "id": "1166",
    "label": "Drothems Kyrkogård",
    "value": "drothems-kyrkogard-soderkoping"
},
{
    "id": "1168",
    "label": "Dunkehalla Kyrkogård",
    "value": "dunkehalla-kyrkogard-jonkoping"
},
{
    "id": "1170",
    "label": "Dunkers Kyrkogård",
    "value": "dunkers-kyrkogard-flen"
},
{
    "id": "1181",
    "label": "Eda kyrkogård",
    "value": "eda-kyrkogard-eda"
},
{
    "id": "1184",
    "label": "Edåsa Kyrkogård",
    "value": "edasa-kyrkogard-skovde"
},
{
    "id": "1186",
    "label": "Edebo kyrkogård",
    "value": "edebo-kyrkogard-norrtalje"
},
{
    "id": "1190",
    "label": "Edestads kyrkogård",
    "value": "edestads-kyrkogard-ronneby"
},
{
    "id": "1195",
    "label": "Eds kyrkogård",
    "value": "eds-kyrkogard-upplands-vasby"
},
{
    "id": "1196",
    "label": "Eds kyrkogård",
    "value": "eds-kyrkogard-grums"
},
{
    "id": "1199",
    "label": "Edsbergs kyrkogård",
    "value": "edsbergs-kyrkogard-orebro"
},
{
    "id": "1201",
    "label": "Edsbro kyrkogård",
    "value": "edsbro-kyrkogard-norrtalje"
},
{
    "id": "1204",
    "label": "Edshults kyrkogård",
    "value": "edshults-kyrkogard-nassjo"
},
{
    "id": "1206",
    "label": "Edsleskogs kyrkogård",
    "value": "edsleskogs-kyrkogard-amal"
},
{
    "id": "1208",
    "label": "Edsvära kyrkogård",
    "value": "edsvara-kyrkogard-vara"
},
{
    "id": "1215",
    "label": "Egby kyrkogård",
    "value": "egby-kyrkogard-borgholm"
},
{
    "id": "1217",
    "label": "Eggby kyrkogård",
    "value": "eggby-kyrkogard-skara"
},
{
    "id": "1219",
    "label": "Eggvena kyrkogård",
    "value": "eggvena-kyrkogard-herrljunga"
},
{
    "id": "1222",
    "label": "Ekby kyrkogård",
    "value": "ekby-kyrkogard-mariestad"
},
{
    "id": "1224",
    "label": "Eke kyrkogård",
    "value": "eke-kyrkogard-gotland"
},
{
    "id": "1226",
    "label": "Ekeberga kyrkogård",
    "value": "ekeberga-kyrkogard-lessebo"
},
{
    "id": "1232",
    "label": "Ekeby kyrkogård",
    "value": "ekeby-kyrkogard-boxholm"
},
{
    "id": "1233",
    "label": "Ekeby kyrkogård",
    "value": "ekeby-kyrkogard-gotland"
},
{
    "id": "1234",
    "label": "Ekeby Kyrkogård",
    "value": "ekeby-kyrkogard-bjuv"
},
{
    "id": "1235",
    "label": "Ekeby kyrkogård",
    "value": "ekeby-kyrkogard"
},
{
    "id": "1236",
    "label": "Ekeby Kyrkogård",
    "value": "ekeby-kyrkogard"
},
{
    "id": "1239",
    "label": "Ekebyborna kyrkogård",
    "value": "ekebyborna-kyrkogard"
},
{
    "id": "1248",
    "label": "Ekerö kyrkogård",
    "value": "ekero-kyrkogard-ekero"
},
{
    "id": "1251",
    "label": "Ekeskogs kyrkogård",
    "value": "ekeskogs-kyrkogard-gullspang"
},
{
    "id": "1254",
    "label": "Eks kyrkogård",
    "value": "eks-kyrkogard-mariestad"
},
{
    "id": "1256",
    "label": "Ekshärads kyrkogård",
    "value": "eksharads-kyrkogard-hagfors"
},
{
    "id": "1258",
    "label": "Eksjö Kyrkogård",
    "value": "eksjo-kyrkogard-nassjo"
},
{
    "id": "1260",
    "label": "Eksta kyrkogård",
    "value": "eksta-kyrkogard-gotland"
},
{
    "id": "1263",
    "label": "Eldsberga Kyrkogård",
    "value": "eldsberga-kyrkogard-halmstad"
},
{
    "id": "1268",
    "label": "Elings kyrkogård",
    "value": "elings-kyrkogard-vara"
},
{
    "id": "1269",
    "label": "Eljaröd Kyrkogård",
    "value": "eljarod-kyrkogard-tomelilla"
},
{
    "id": "1272",
    "label": "Elleholms kyrkogård",
    "value": "elleholms-kyrkogard-karlshamn"
},
{
    "id": "1275",
    "label": "Emmaboda Kyrkogård",
    "value": "emmaboda-kyrkogard-emmaboda"
},
{
    "id": "1279",
    "label": "Emmislövs Kyrkogård",
    "value": "emmislovs-kyrkogard-ostra-goinge"
},
{
    "id": "1281",
    "label": "Enåkers kyrkogård",
    "value": "enakers-kyrkogard-heby"
},
{
    "id": "1284",
    "label": "Enåsa Kyrkogård",
    "value": "enasa-kyrkogard-gullspang"
},
{
    "id": "1286",
    "label": "Endre kyrkogård",
    "value": "endre-kyrkogard-gotland"
},
{
    "id": "1293",
    "label": "Enköpings-Näs kyrkogård",
    "value": "enkopings-nas-kyrkogard-enkoping"
},
{
    "id": "1297",
    "label": "Enslövs Kyrkogård",
    "value": "enslovs-kyrkogard-halmstad"
},
{
    "id": "1299",
    "label": "Envikens gamla kyrkogård",
    "value": "envikens-gamla-kyrkogard-falu"
},
{
    "id": "1301",
    "label": "Envikens kyrkogård",
    "value": "envikens-kyrkogard-falu"
},
{
    "id": "1303",
    "label": "Eriksbergs gamla kyrkogård",
    "value": "eriksbergs-gamla-kyrkogard-herrljunga"
},
{
    "id": "1306",
    "label": "Eriksbergs nya kyrkogård",
    "value": "eriksbergs-nya-kyrkogard-herrljunga"
},
{
    "id": "1309",
    "label": "Erikstads kyrkogård",
    "value": "erikstads-kyrkogard-mellerud"
},
{
    "id": "1311",
    "label": "Eringsboda kyrkogård",
    "value": "eringsboda-kyrkogard-ronneby"
},
{
    "id": "1314",
    "label": "Erska Kyrkogård",
    "value": "erska-kyrkogard-trollhattan"
},
{
    "id": "1319",
    "label": "Ervalla Kyrkogård",
    "value": "ervalla-kyrkogard-orebro"
},
{
    "id": "1321",
    "label": "Esarps kyrkogård",
    "value": "esarps-kyrkogard-staffanstorp"
},
{
    "id": "1323",
    "label": "Eskelhems kyrkogård",
    "value": "eskelhems-kyrkogard-gotland"
},
{
    "id": "1324",
    "label": "Eskilsäter Kyrkogård",
    "value": "eskilsater-kyrkogard-saffle"
},
{
    "id": "1326",
    "label": "Eskilsäters kyrkogård",
    "value": "eskilsaters-kyrkogard-saffle"
},
{
    "id": "1328",
    "label": "Eskilstorps kyrkogård",
    "value": "eskilstorps-kyrkogard-vellinge"
},
{
    "id": "1331",
    "label": "Eslövs kyrkogård",
    "value": "eslovs-kyrkogard-eslov"
},
{
    "id": "1335",
    "label": "Essunga Kyrkogård",
    "value": "essunga-kyrkogard-essunga"
},
{
    "id": "1337",
    "label": "Estuna kyrkogård",
    "value": "estuna-kyrkogard-norrtalje"
},
{
    "id": "1338",
    "label": "Etelhem Kyrkogård",
    "value": "etelhem-kyrkogard-gotland"
},
{
    "id": "1341",
    "label": "Everlövs Kyrkogård",
    "value": "everlovs-kyrkogard-sjobo"
},
{
    "id": "1343",
    "label": "Everöds Kyrkogård",
    "value": "everods-kyrkogard-kristianstad"
},
{
    "id": "1348",
    "label": "Fågelfors kyrkogård",
    "value": "fagelfors-kyrkogard-hogsby"
},
{
    "id": "1350",
    "label": "Fågelö kyrkogård",
    "value": "fagelo-kyrkogard-mariestad"
},
{
    "id": "1352",
    "label": "Fågelsjö Kyrkogård",
    "value": "fagelsjo-kyrkogard-ljusdal"
},
{
    "id": "1354",
    "label": "Fågeltofta Kyrkogård",
    "value": "fageltofta-kyrkogard-tomelilla"
},
{
    "id": "1359",
    "label": "Fagerhults kyrkogård",
    "value": "fagerhults-kyrkogard-hogsby"
},
{
    "id": "1362",
    "label": "Fåglums Kyrkogård",
    "value": "faglums-kyrkogard-essunga"
},
{
    "id": "1364",
    "label": "Fägre kyrkogård",
    "value": "fagre-kyrkogard-gullspang"
},
{
    "id": "1369",
    "label": "Falsterbos kyrkogård",
    "value": "falsterbos-kyrkogard-vellinge"
},
{
    "id": "1375",
    "label": "Fänneslunda-Grovare Kyrkogård",
    "value": "fanneslunda-grovare-kyrkogard-ulricehamn"
},
{
    "id": "1377",
    "label": "Fardhems kyrkogård",
    "value": "fardhems-kyrkogard-gotland"
},
{
    "id": "1379",
    "label": "Färeds Kyrkogård",
    "value": "fareds-kyrkogard-gullspang"
},
{
    "id": "1382",
    "label": "Färentuna kyrkogård",
    "value": "farentuna-kyrkogard-ekero"
},
{
    "id": "1384",
    "label": "Färgaryds Kyrkogård",
    "value": "fargaryds-kyrkogard-hylte"
},
{
    "id": "1386",
    "label": "Färgelanda kyrkogård",
    "value": "fargelanda-kyrkogard-fargelanda"
},
{
    "id": "1388",
    "label": "Farhults Kyrkogård",
    "value": "farhults-kyrkogard-hoganas"
},
{
    "id": "1390",
    "label": "Färila Kyrkogård",
    "value": "farila-kyrkogard-ljusdal"
},
{
    "id": "1392",
    "label": "Faringe Kyrkogård",
    "value": "faringe-kyrkogard-uppsala"
},
{
    "id": "1394",
    "label": "Färingtofta Kyrkogård",
    "value": "faringtofta-kyrkogard-klippan"
},
{
    "id": "1396",
    "label": "Färlövs Kyrkogård",
    "value": "farlovs-kyrkogard-kristianstad"
},
{
    "id": "1398",
    "label": "Fårö kyrkogård",
    "value": "faro-kyrkogard-gotland"
},
{
    "id": "1403",
    "label": "Farstorps kyrkogård",
    "value": "farstorps-kyrkogard-hassleholm"
},
{
    "id": "1404",
    "label": "Farstorps Kyrkogård",
    "value": "farstorps-kyrkogard"
},
{
    "id": "1408",
    "label": "Fässbergs Kyrkogård",
    "value": "fassbergs-kyrkogard-molndal"
},
{
    "id": "1411",
    "label": "Fasterna Kyrkogård",
    "value": "fasterna-kyrkogard-norrtalje"
},
{
    "id": "1413",
    "label": "Fatmomakke kyrkogård",
    "value": "fatmomakke-kyrkogard-storuman"
},
{
    "id": "1416",
    "label": "Felestads Kyrkogård",
    "value": "felestads-kyrkogard-svalov"
},
{
    "id": "1418",
    "label": "Fellingsbro Kyrkogård",
    "value": "fellingsbro-kyrkogard-lindesberg"
},
{
    "id": "1420",
    "label": "Femsjö Kyrkogård",
    "value": "femsjo-kyrkogard-hylte"
},
{
    "id": "1422",
    "label": "Fide kyrkogård",
    "value": "fide-kyrkogard-gotland"
},
{
    "id": "1426",
    "label": "Filipstads kyrkogård",
    "value": "filipstads-kyrkogard-filipstad"
},
{
    "id": "1428",
    "label": "Films kyrkogård",
    "value": "films-kyrkogard-osthammar"
},
{
    "id": "1430",
    "label": "Finja kyrkogård",
    "value": "finja-kyrkogard-hassleholm"
},
{
    "id": "1432",
    "label": "Finnekumla kyrkogård",
    "value": "finnekumla-kyrkogard-ulricehamn"
},
{
    "id": "1434",
    "label": "Finnerödja kyrkogård",
    "value": "finnerodja-kyrkogard-laxa"
},
{
    "id": "1440",
    "label": "Fiskebäcks Kyrkogård",
    "value": "fiskebacks-kyrkogard-habo"
},
{
    "id": "1442",
    "label": "Fiskebäckskils Kyrkogård",
    "value": "fiskebackskils-kyrkogard-lysekil"
},
{
    "id": "1445",
    "label": "Fittja kyrkogård",
    "value": "fittja-kyrkogard-enkoping"
},
{
    "id": "1447",
    "label": "Fivelstads kyrkogård",
    "value": "fivelstads-kyrkogard-motala"
},
{
    "id": "1448",
    "label": "Fivlered Kyrkogård",
    "value": "fivlered-kyrkogard-falkoping"
},
{
    "id": "1451",
    "label": "Fjälkestads kyrkogård",
    "value": "fjalkestads-kyrkogard-kristianstad"
},
{
    "id": "1453",
    "label": "Fjälkinge Kyrkogård",
    "value": "fjalkinge-kyrkogard-kristianstad"
},
{
    "id": "1456",
    "label": "Fjällsjö Kyrkogård",
    "value": "fjallsjo-kyrkogard-stromsund"
},
{
    "id": "1458",
    "label": "Fjärås Kyrkogård",
    "value": "fjaras-kyrkogard-kungsbacka"
},
{
    "id": "1460",
    "label": "Fjärestads Kyrkogård",
    "value": "fjarestads-kyrkogard-landskrona"
},
{
    "id": "1462",
    "label": "Fjelie kyrkogård",
    "value": "fjelie-kyrkogard-lomma"
},
{
    "id": "1466",
    "label": "Flädie kyrkogård",
    "value": "fladie-kyrkogard-lomma"
},
{
    "id": "1468",
    "label": "Flakebergs kyrkogård",
    "value": "flakebergs-kyrkogard-grastorp"
},
{
    "id": "1470",
    "label": "Flatö kyrkogård",
    "value": "flato-kyrkogard-orust"
},
{
    "id": "1471",
    "label": "Flatöns kyrkogård",
    "value": "flatons-kyrkogard-orust"
},
{
    "id": "1476",
    "label": "Fleninge Kyrkogård",
    "value": "fleninge-kyrkogard-helsingborg"
},
{
    "id": "1479",
    "label": "Flens kyrkogård",
    "value": "flens-kyrkogard-flen"
},
{
    "id": "1481",
    "label": "Fleringe kyrkogård",
    "value": "fleringe-kyrkogard-gotland"
},
{
    "id": "1484",
    "label": "Fliseryds kyrkogård",
    "value": "fliseryds-kyrkogard"
},
{
    "id": "1488",
    "label": "Flistads kyrkogård",
    "value": "flistads-kyrkogard"
},
{
    "id": "1489",
    "label": "Flistads kyrkogård",
    "value": "flistads-kyrkogard"
},
{
    "id": "1491",
    "label": "Flo kyrkogård",
    "value": "flo-kyrkogard"
},
{
    "id": "1493",
    "label": "Floby Kyrkogård",
    "value": "floby-kyrkogard"
},
{
    "id": "1497",
    "label": "Floda kyrkogård",
    "value": "floda-kyrkogard-gagnef"
},
{
    "id": "1498",
    "label": "Floda kyrkogård",
    "value": "floda-kyrkogard-katrineholm"
},
{
    "id": "1501",
    "label": "Flymens Kyrkogård",
    "value": "flymens-kyrkogard-karlskrona"
},
{
    "id": "1503",
    "label": "Fogdö kyrkogård",
    "value": "fogdo-kyrkogard-strangnas"
},
{
    "id": "1506",
    "label": "Fole kyrkogård",
    "value": "fole-kyrkogard-gotland"
},
{
    "id": "1508",
    "label": "Fölene kyrkogård",
    "value": "folene-kyrkogard-herrljunga"
},
{
    "id": "1512",
    "label": "Follingbo kyrkogård",
    "value": "follingbo-kyrkogard-gotland"
},
{
    "id": "1517",
    "label": "Föra kyrkogård",
    "value": "fora-kyrkogard-borgholm"
},
{
    "id": "1519",
    "label": "Förkärla kyrkogård",
    "value": "forkarla-kyrkogard-ronneby"
},
{
    "id": "1521",
    "label": "Förlanda Kyrkogård",
    "value": "forlanda-kyrkogard-kungsbacka"
},
{
    "id": "1523",
    "label": "Förlösa kyrkogård",
    "value": "forlosa-kyrkogard-kalmar"
},
{
    "id": "1525",
    "label": "Fornåsa kyrkogård",
    "value": "fornasa-kyrkogard-motala"
},
{
    "id": "1531",
    "label": "Fors kyrkogård",
    "value": "fors-kyrkogard-trollhattan"
},
{
    "id": "1617",
    "label": "Forsbacka Kyrkogård",
    "value": "forsbacka-kyrkogard-gavle"
},
{
    "id": "1619",
    "label": "Forsby Kyrkogård",
    "value": "forsby-kyrkogard-skovde"
},
{
    "id": "1624",
    "label": "Forshaga kyrkogård",
    "value": "forshaga-kyrkogard-munkfors"
},
{
    "id": "1626",
    "label": "Forshälla kyrkogård",
    "value": "forshalla-kyrkogard-uddevalla"
},
{
    "id": "1629",
    "label": "Forsheda kyrkogård",
    "value": "forsheda-kyrkogard-varnamo"
},
{
    "id": "1632",
    "label": "Forshems kyrkogård",
    "value": "forshems-kyrkogard-gotene"
},
{
    "id": "1635",
    "label": "Förslövs Kyrkogård",
    "value": "forslovs-kyrkogard-bastad"
},
{
    "id": "1637",
    "label": "Forsmarks kyrkogård",
    "value": "forsmarks-kyrkogard-osthammar"
},
{
    "id": "1641",
    "label": "Forssa kyrkogård",
    "value": "forssa-kyrkogard-flen"
},
{
    "id": "1643",
    "label": "Forsviks kyrkogård",
    "value": "forsviks-kyrkogard-karlsborg"
},
{
    "id": "1647",
    "label": "Fosie kyrkogård",
    "value": "fosie-kyrkogard-malmo"
},
{
    "id": "1649",
    "label": "Foss Kyrkogård",
    "value": "foss-kyrkogard-munkedal"
},
{
    "id": "1651",
    "label": "Fotskäls Kyrkogård",
    "value": "fotskals-kyrkogard-mark"
},
{
    "id": "1653",
    "label": "Främmestads Kyrkogård",
    "value": "frammestads-kyrkogard-essunga"
},
{
    "id": "1659",
    "label": "Frändefors kyrkogård",
    "value": "frandefors-kyrkogard-vanersborg"
},
{
    "id": "1661",
    "label": "Fränninge kyrkogård",
    "value": "franninge-kyrkogard-sjobo"
},
{
    "id": "1662",
    "label": "Fredentorps begravningsplats",
    "value": "fredentorps-begravningsplats-lund"
},
{
    "id": "1669",
    "label": "Fredsbergs kyrkogård",
    "value": "fredsbergs-kyrkogard-gullspang"
},
{
    "id": "1671",
    "label": "Fresta kyrkogård",
    "value": "fresta-kyrkogard-upplands-vasby"
},
{
    "id": "1675",
    "label": "Fridene kyrkogård",
    "value": "fridene-kyrkogard-hjo"
},
{
    "id": "1678",
    "label": "Fridhems kyrkogård",
    "value": "fridhems-kyrkogard-grastorp"
},
{
    "id": "1679",
    "label": "Fridhems kyrkogård",
    "value": "fridhems-kyrkogard-goteborg"
},
{
    "id": "1682",
    "label": "Fridlevstads Kyrkogård",
    "value": "fridlevstads-kyrkogard-karlskrona"
},
{
    "id": "1683",
    "label": "Friedländers kyrkogård",
    "value": "friedlanders-kyrkogard-goteborg"
},
{
    "id": "1685",
    "label": "Friels Kyrkogård",
    "value": "friels-kyrkogard-lidkoping"
},
{
    "id": "1687",
    "label": "Friggeråkers Kyrkogård",
    "value": "friggerakers-kyrkogard-falkoping"
},
{
    "id": "1691",
    "label": "Frillesås Kyrkogård",
    "value": "frillesas-kyrkogard-kungsbacka"
},
{
    "id": "1693",
    "label": "Frillestads Kyrkogård",
    "value": "frillestads-kyrkogard-helsingborg"
},
{
    "id": "1696",
    "label": "Frinnaryds kyrkogård",
    "value": "frinnaryds-kyrkogard-aneby"
},
{
    "id": "1698",
    "label": "Fristads kyrkogård",
    "value": "fristads-kyrkogard-boras"
},
{
    "id": "1700",
    "label": "Fritsla kyrkogård",
    "value": "fritsla-kyrkogard-mark"
},
{
    "id": "1702",
    "label": "Fröderyds Kyrkogård",
    "value": "froderyds-kyrkogard-vetlanda"
},
{
    "id": "1704",
    "label": "Frödinge kyrkogård",
    "value": "frodinge-kyrkogard-vimmerby"
},
{
    "id": "1705",
    "label": "Fröjel kyrkogård",
    "value": "frojel-kyrkogard-gotland"
},
{
    "id": "1708",
    "label": "Fröjereds kyrkogård",
    "value": "frojereds-kyrkogard-tidaholm"
},
{
    "id": "1712",
    "label": "Fröskogs kyrkogård",
    "value": "froskogs-kyrkogard-amal"
},
{
    "id": "1714",
    "label": "Fröslunda kyrkogård",
    "value": "froslunda-kyrkogard-enkoping"
},
{
    "id": "1718",
    "label": "Frösthults Kyrkogård",
    "value": "frosthults-kyrkogard-enkoping"
},
{
    "id": "1720",
    "label": "Frösunda kyrkogård",
    "value": "frosunda-kyrkogard-vallentuna"
},
{
    "id": "1722",
    "label": "Frösve kyrkogård",
    "value": "frosve-kyrkogard-skovde"
},
{
    "id": "1724",
    "label": "Frötuna kyrkogård",
    "value": "frotuna-kyrkogard-norrtalje"
},
{
    "id": "1727",
    "label": "Fru Alstads Kyrkogård",
    "value": "fru-alstads-kyrkogard-trelleborg"
},
{
    "id": "1730",
    "label": "Frustuna Kyrkogård",
    "value": "frustuna-kyrkogard"
},
{
    "id": "1734",
    "label": "Fryele Kyrkogård",
    "value": "fryele-kyrkogard"
},
{
    "id": "1736",
    "label": "Frykeruds kyrkogård",
    "value": "frykeruds-kyrkogard"
},
{
    "id": "1738",
    "label": "Fryksände Kyrkogård",
    "value": "fryksande-kyrkogard"
},
{
    "id": "1741",
    "label": "Fuglie Kyrkogård",
    "value": "fuglie-kyrkogard-trelleborg"
},
{
    "id": "1742",
    "label": "Fullestad Kyrkogård",
    "value": "fullestad-kyrkogard-vargarda"
},
{
    "id": "1745",
    "label": "Fullösa kyrkogård",
    "value": "fullosa-kyrkogard-gotene"
},
{
    "id": "1747",
    "label": "Fulltofta Kyrkogård",
    "value": "fulltofta-kyrkogard-horby"
},
{
    "id": "1752",
    "label": "Furingstads kyrkogård",
    "value": "furingstads-kyrkogard-norrkoping"
},
{
    "id": "1755",
    "label": "Furuby kyrkogård",
    "value": "furuby-kyrkogard-vaxjo"
},
{
    "id": "1760",
    "label": "Fuxerna Kyrkogård",
    "value": "fuxerna-kyrkogard-lilla-edet"
},
{
    "id": "1765",
    "label": "Gäddede Kyrkogård",
    "value": "gaddede-kyrkogard-stromsund"
},
{
    "id": "1768",
    "label": "Gagnefs kyrkogård",
    "value": "gagnefs-kyrkogard-gagnef"
},
{
    "id": "1770",
    "label": "Galärvarvs&shy;kyrkogården",
    "value": "galarvarvskyrkogarden-stockholm"
},
{
    "id": "1774",
    "label": "Gällaryds Kyrkogård",
    "value": "gallaryds-kyrkogard-varnamo"
},
{
    "id": "1776",
    "label": "Gällersta kyrkogård",
    "value": "gallersta-kyrkogard-orebro"
},
{
    "id": "1779",
    "label": "Gällinge Kyrkogård",
    "value": "gallinge-kyrkogard-kungsbacka"
},
{
    "id": "1784",
    "label": "Gällstads och Södra Säms kyrkogård",
    "value": "gallstads-och-sodra-sams-kyrkogard-ulricehamn"
},
{
    "id": "1785",
    "label": "Gålsjö kyrkogård",
    "value": "galsjo-kyrkogard-solleftea"
},
{
    "id": "1787",
    "label": "Gamla Begravningsplatsen",
    "value": "gamla-begravningsplatsen-kristianstad"
},
{
    "id": "1788",
    "label": "Gamla Begravningsplatsen",
    "value": "gamla-begravningsplatsen-landskrona"
},
{
    "id": "1790",
    "label": "Gamla Griftegården",
    "value": "gamla-griftegarden-tranas"
},
{
    "id": "1791",
    "label": "Gamla Hjälmseryds kyrkogård",
    "value": "gamla-hjalmseryds-kyrkogard-savsjo"
},
{
    "id": "1798",
    "label": "Gamla kyrkogården",
    "value": "gamla-kyrkogarden-vaxjo"
},
{
    "id": "1799",
    "label": "Gamla kyrkogården",
    "value": "gamla-kyrkogarden-harnosand"
},
{
    "id": "1800",
    "label": "Gamla kyrkogården",
    "value": "gamla-kyrkogarden-tierp"
},
{
    "id": "1801",
    "label": "Gamla kyrkogården",
    "value": "gamla-kyrkogarden-uppsala"
},
{
    "id": "1802",
    "label": "Gamla Kyrkogården",
    "value": "gamla-kyrkogarden-ludvika"
},
{
    "id": "1803",
    "label": "Gamla Kyrkogården",
    "value": "gamla-kyrkogarden-gavle"
},
{
    "id": "1804",
    "label": "Gamla Kyrkogården",
    "value": "gamla-kyrkogarden-sandviken"
},
{
    "id": "1805",
    "label": "Gamla Kyrkogården",
    "value": "gamla-kyrkogarden-kungsbacka"
},
{
    "id": "1806",
    "label": "Gamla Kyrkogården",
    "value": "gamla-kyrkogarden-vetlanda"
},
{
    "id": "1807",
    "label": "Gamla Kyrkogården",
    "value": "gamla-kyrkogarden-oskarshamn"
},
{
    "id": "1808",
    "label": "Gamla Kyrkogården",
    "value": "gamla-kyrkogarden-helsingborg"
},
{
    "id": "1809",
    "label": "Gamla Kyrkogården",
    "value": "gamla-kyrkogarden-kavlinge"
},
{
    "id": "1810",
    "label": "Gamla kyrkogården",
    "value": "gamla-kyrkogarden-malmo"
},
{
    "id": "1811",
    "label": "Gamla kyrkogården",
    "value": "gamla-kyrkogarden-kristinehamn"
},
{
    "id": "1812",
    "label": "Gamla kyrkogården",
    "value": "gamla-kyrkogarden-kungalv"
},
{
    "id": "1813",
    "label": "Gamla Kyrkogården",
    "value": "gamla-kyrkogarden-lysekil"
},
{
    "id": "1814",
    "label": "Gamla kyrkogården",
    "value": "gamla-kyrkogarden-sotenas"
},
{
    "id": "1815",
    "label": "Gamla kyrkogården",
    "value": "gamla-kyrkogarden-ulricehamn"
},
{
    "id": "1816",
    "label": "Gamla Kyrkogården",
    "value": "gamla-kyrkogarden-karlskoga"
},
{
    "id": "1817",
    "label": "Gamla Kyrkogården",
    "value": "gamla-kyrkogarden-linkoping"
},
{
    "id": "1818",
    "label": "Gamla kyrkogården i Skara",
    "value": "gamla-kyrkogarden-i-skara-skara"
},
{
    "id": "1819",
    "label": "Gamla kyrkogården Öjebyn",
    "value": "gamla-kyrkogarden-ojebyn-pitea"
},
{
    "id": "1823",
    "label": "Gamla Uppsala kyrkogård",
    "value": "gamla-uppsala-kyrkogard-uppsala"
},
{
    "id": "1826",
    "label": "Gamleby kyrkogård",
    "value": "gamleby-kyrkogard-vastervik"
},
{
    "id": "1828",
    "label": "Gammalkils Kyrkogård",
    "value": "gammalkils-kyrkogard-linkoping"
},
{
    "id": "1830",
    "label": "Gammalstorps kyrkogård",
    "value": "gammalstorps-kyrkogard-solvesborg"
},
{
    "id": "1834",
    "label": "Gammelgarns kyrkogård",
    "value": "gammelgarns-kyrkogard-gotland"
},
{
    "id": "1838",
    "label": "Ganthems kyrkogård",
    "value": "ganthems-kyrkogard-gotland"
},
{
    "id": "1840",
    "label": "Gårdby Kyrkogård",
    "value": "gardby-kyrkogard-morbylanga"
},
{
    "id": "1842",
    "label": "Garde Kyrkogård",
    "value": "garde-kyrkogard-gotland"
},
{
    "id": "1844",
    "label": "Gårdeby kyrkogård",
    "value": "gardeby-kyrkogard-soderkoping"
},
{
    "id": "1847",
    "label": "Gärdhems kyrkogård",
    "value": "gardhems-kyrkogard-vanersborg"
},
{
    "id": "1849",
    "label": "Gärdnäs Kyrkogård",
    "value": "gardnas-kyrkogard-stromsund"
},
{
    "id": "1852",
    "label": "Gårdsby kyrkogård",
    "value": "gardsby-kyrkogard-vaxjo"
},
{
    "id": "1854",
    "label": "Gärdserums kyrkogård",
    "value": "gardserums-kyrkogard-atvidaberg"
},
{
    "id": "1859",
    "label": "Gärdslösa kyrkogård",
    "value": "gardslosa-kyrkogard-borgholm"
},
{
    "id": "1861",
    "label": "Gärdslövs Kyrkogård",
    "value": "gardslovs-kyrkogard-trelleborg"
},
{
    "id": "1864",
    "label": "Gårdstånga kyrkogård",
    "value": "gardstanga-kyrkogard-eslov"
},
{
    "id": "1866",
    "label": "Gargnäs Kyrkogård",
    "value": "gargnas-kyrkogard-mala"
},
{
    "id": "1871",
    "label": "Garpenbergs kyrkogård",
    "value": "garpenbergs-kyrkogard-hedemora"
},
{
    "id": "1876",
    "label": "Gåsborns kyrkogård",
    "value": "gasborns-kyrkogard-filipstad"
},
{
    "id": "1878",
    "label": "Gåsinge kyrkogård",
    "value": "gasinge-kyrkogard-gnesta"
},
{
    "id": "1879",
    "label": "Gåsös Kyrkogård",
    "value": "gasos-kyrkogard-lysekil"
},
{
    "id": "1881",
    "label": "Gåxsjö Kyrkogård",
    "value": "gaxsjo-kyrkogard-stromsund"
},
{
    "id": "1882",
    "label": "Genarp Häckeberga Kyrkogård",
    "value": "genarp-hackeberga-kyrkogard-lund"
},
{
    "id": "1884",
    "label": "Genarps Kyrkogård",
    "value": "genarps-kyrkogard-lund"
},
{
    "id": "1888",
    "label": "Gerums kyrkogård",
    "value": "gerums-kyrkogard-gotland"
},
{
    "id": "1890",
    "label": "Gessie gamla kyrkogård",
    "value": "gessie-gamla-kyrkogard-vellinge"
},
{
    "id": "1892",
    "label": "Gessie nya kyrkogård",
    "value": "gessie-nya-kyrkogard-vellinge"
},
{
    "id": "1894",
    "label": "Gestads kyrkogård",
    "value": "gestads-kyrkogard-vanersborg"
},
{
    "id": "1898",
    "label": "Getinge Kyrkogård",
    "value": "getinge-kyrkogard-halmstad"
},
{
    "id": "1901",
    "label": "Gideå Kyrkogård",
    "value": "gidea-kyrkogard-ornskoldsvik"
},
{
    "id": "1905",
    "label": "Gillberga kyrkogård",
    "value": "gillberga-kyrkogard-saffle"
},
{
    "id": "1906",
    "label": "Gillberga kyrkogård",
    "value": "gillberga-kyrkogard-eskilstuna"
},
{
    "id": "1910",
    "label": "Gillstad Kyrkogård",
    "value": "gillstad-kyrkogard-lidkoping"
},
{
    "id": "1913",
    "label": "Giresta kyrkogård",
    "value": "giresta-kyrkogard-enkoping"
},
{
    "id": "1916",
    "label": "Gislövs kyrkogård",
    "value": "gislovs-kyrkogard-trelleborg"
},
{
    "id": "1917",
    "label": "Gistad Kyrkogård",
    "value": "gistad-kyrkogard-linkoping"
},
{
    "id": "1923",
    "label": "Gladhammars Kyrkogård",
    "value": "gladhammars-kyrkogard-oskarshamn"
},
{
    "id": "1925",
    "label": "Gladsax Kyrkogård",
    "value": "gladsax-kyrkogard-simrishamn"
},
{
    "id": "1927",
    "label": "Glanshammars kyrkogård",
    "value": "glanshammars-kyrkogard-orebro"
},
{
    "id": "1932",
    "label": "Glava Kyrkogård",
    "value": "glava-kyrkogard-arvika"
},
{
    "id": "1934",
    "label": "Glemminge Kyrkogård",
    "value": "glemminge-kyrkogard-ystad"
},
{
    "id": "1937",
    "label": "Glimåkra Kyrkogård",
    "value": "glimakra-kyrkogard-ostra-goinge"
},
{
    "id": "1939",
    "label": "Glommersträsks Kyrkogård",
    "value": "glommerstrasks-kyrkogard-arvidsjaur"
},
{
    "id": "1941",
    "label": "Glömminge Kyrkogård",
    "value": "glomminge-kyrkogard-morbylanga"
},
{
    "id": "1944",
    "label": "Glostorps kyrkogård",
    "value": "glostorps-kyrkogard-malmo"
},
{
    "id": "1946",
    "label": "Glumslövs Kyrkogård",
    "value": "glumslovs-kyrkogard-landskrona"
},
{
    "id": "1949",
    "label": "Gnosjö kyrkogård",
    "value": "gnosjo-kyrkogard-gnosjo"
},
{
    "id": "1952",
    "label": "Godegårds kyrkogård",
    "value": "godegards-kyrkogard-motala"
},
{
    "id": "1954",
    "label": "Gödelövs Kyrkogård",
    "value": "godelovs-kyrkogard-lund"
},
{
    "id": "1956",
    "label": "Gödestads Kyrkogård",
    "value": "godestads-kyrkogard-varberg"
},
{
    "id": "1957",
    "label": "Gökhem Kyrkogård",
    "value": "gokhem-kyrkogard-falkoping"
},
{
    "id": "1960",
    "label": "Görslövs kyrkogård",
    "value": "gorslovs-kyrkogard-staffanstorp"
},
{
    "id": "1961",
    "label": "Görvälns griftegård",
    "value": "gorvalns-griftegard-jarfalla"
},
{
    "id": "1963",
    "label": "Gösslunda kyrkogård",
    "value": "gosslunda-kyrkogard-lidkoping"
},
{
    "id": "1965",
    "label": "Götalundens Kyrkogård",
    "value": "gotalundens-kyrkogard-trollhattan"
},
{
    "id": "1968",
    "label": "Götene kyrkogård",
    "value": "gotene-kyrkogard-gotene"
},
{
    "id": "1970",
    "label": "Göteryds kyrkogård",
    "value": "goteryds-kyrkogard-almhult"
},
{
    "id": "1972",
    "label": "Göteve Kyrkogård",
    "value": "goteve-kyrkogard-falkoping"
},
{
    "id": "1973",
    "label": "Gothem kyrkogård",
    "value": "gothem-kyrkogard-gotland"
},
{
    "id": "1977",
    "label": "Götlunda kyrkogård",
    "value": "gotlunda-kyrkogard-skovde"
},
{
    "id": "1980",
    "label": "Gottröra Kyrkogård",
    "value": "gottrora-kyrkogard"
},
{
    "id": "1983",
    "label": "Gråmanstorps Kyrkogård",
    "value": "gramanstorps-kyrkogard"
},
{
    "id": "1986",
    "label": "Granhults kyrkogård",
    "value": "granhults-kyrkogard"
},
{
    "id": "1992",
    "label": "Gränna Kyrkogård",
    "value": "granna-kyrkogard-jonkoping"
},
{
    "id": "1995",
    "label": "Gräsgårds Kyrkogård",
    "value": "grasgards-kyrkogard-morbylanga"
},
{
    "id": "1998",
    "label": "Gräsmarks kyrkogård",
    "value": "grasmarks-kyrkogard-sunne"
},
{
    "id": "2000",
    "label": "Gräsö kyrkogård",
    "value": "graso-kyrkogard-osthammar"
},
{
    "id": "2003",
    "label": "Gråträsk kyrkogård",
    "value": "gratrask-kyrkogard-pitea"
},
{
    "id": "2009",
    "label": "Grava kyrkogård",
    "value": "grava-kyrkogard-karlstad"
},
{
    "id": "2011",
    "label": "Gräve kyrkogård",
    "value": "grave-kyrkogard-orebro"
},
{
    "id": "2018",
    "label": "Grebbestads kyrkogård",
    "value": "grebbestads-kyrkogard-tanum"
},
{
    "id": "2020",
    "label": "Grebo kyrkogård",
    "value": "grebo-kyrkogard-atvidaberg"
},
{
    "id": "2025",
    "label": "Grevbäcks kyrkogård",
    "value": "grevbacks-kyrkogard-hjo"
},
{
    "id": "2028",
    "label": "Grevie Kyrkogård",
    "value": "grevie-kyrkogard-bastad"
},
{
    "id": "2030",
    "label": "Griftegården i Augerum",
    "value": "griftegarden-i-augerum-karlskrona"
},
{
    "id": "2031",
    "label": "Grimeton Kyrkogård",
    "value": "grimeton-kyrkogard-varberg"
},
{
    "id": "2035",
    "label": "Grimmareds Kyrkogård",
    "value": "grimmareds-kyrkogard-varberg"
},
{
    "id": "2038",
    "label": "Grinneröds kyrkogård",
    "value": "grinnerods-kyrkogard-uddevalla"
},
{
    "id": "2041",
    "label": "Grinstads kyrkogård",
    "value": "grinstads-kyrkogard-mellerud"
},
{
    "id": "2044",
    "label": "Grödinge kyrkogård",
    "value": "grodinge-kyrkogard-botkyrka"
},
{
    "id": "2047",
    "label": "Grolanda Kyrkogård",
    "value": "grolanda-kyrkogard-falkoping"
},
{
    "id": "2050",
    "label": "Grönahögs kyrkogård",
    "value": "gronahogs-kyrkogard-ulricehamn"
},
{
    "id": "2052",
    "label": "Grönby Kyrkogård",
    "value": "gronby-kyrkogard-trelleborg"
},
{
    "id": "2055",
    "label": "Grötlingbo kyrkogård",
    "value": "grotlingbo-kyrkogard-gotland"
},
{
    "id": "2057",
    "label": "Grude kyrkogård",
    "value": "grude-kyrkogard-herrljunga"
},
{
    "id": "2059",
    "label": "Grums kyrkogård",
    "value": "grums-kyrkogard-grums"
},
{
    "id": "2061",
    "label": "Grundsunds Gamla Kyrkogård",
    "value": "grundsunds-gamla-kyrkogard-lysekil"
},
{
    "id": "2064",
    "label": "Gruvbergets Kyrkogård",
    "value": "gruvbergets-kyrkogard-bollnas"
},
{
    "id": "2066",
    "label": "Grycksbo Kyrkogård",
    "value": "grycksbo-kyrkogard-falkenberg"
},
{
    "id": "2068",
    "label": "Gryta kyrkogård",
    "value": "gryta-kyrkogard-enkoping"
},
{
    "id": "2070",
    "label": "Gryteryds Kyrkogård",
    "value": "gryteryds-kyrkogard-gislaved"
},
{
    "id": "2072",
    "label": "Grythyttans kyrkogård",
    "value": "grythyttans-kyrkogard-hallefors"
},
{
    "id": "2077",
    "label": "Gryts Kyrkogård",
    "value": "gryts-kyrkogard-ostra-goinge"
},
{
    "id": "2078",
    "label": "Gryts kyrkogård",
    "value": "gryts-kyrkogard-gnesta"
},
{
    "id": "2079",
    "label": "Gryts kyrkogård",
    "value": "gryts-kyrkogard-valdemarsvik"
},
{
    "id": "2081",
    "label": "Gualövs kyrkogård",
    "value": "gualovs-kyrkogard-bromolla"
},
{
    "id": "2082",
    "label": "Gudhem Kyrkogård",
    "value": "gudhem-kyrkogard-falkoping"
},
{
    "id": "2087",
    "label": "Gudmundrå kyrkogård",
    "value": "gudmundra-kyrkogard-kramfors"
},
{
    "id": "2089",
    "label": "Gudmuntorps kyrkogård",
    "value": "gudmuntorps-kyrkogard-hoor"
},
{
    "id": "2092",
    "label": "Guldrupe kyrkogård",
    "value": "guldrupe-kyrkogard-gotland"
},
{
    "id": "2094",
    "label": "Guldsmedshyttans kyrkogård",
    "value": "guldsmedshyttans-kyrkogard-lindesberg"
},
{
    "id": "2100",
    "label": "Gullereds kyrkogård",
    "value": "gullereds-kyrkogard-ulricehamn"
},
{
    "id": "2101",
    "label": "Gullholmens kyrkogård",
    "value": "gullholmens-kyrkogard-orust"
},
{
    "id": "2103",
    "label": "Gumlösa kyrkogård",
    "value": "gumlosa-kyrkogard-hassleholm"
},
{
    "id": "2107",
    "label": "Gunnared kyrkogård",
    "value": "gunnared-kyrkogard-goteborg"
},
{
    "id": "2112",
    "label": "Gunnarsjö Kyrkogård",
    "value": "gunnarsjo-kyrkogard-varberg"
},
{
    "id": "2113",
    "label": "Gunnarskog Kyrkogård",
    "value": "gunnarskog-kyrkogard-arvika"
},
{
    "id": "2117",
    "label": "Gunnarsnäs kyrkogård",
    "value": "gunnarsnas-kyrkogard-mellerud"
},
{
    "id": "2120",
    "label": "Gunnilbos Kyrkogård",
    "value": "gunnilbos-kyrkogard-skinnskatteberg"
},
{
    "id": "2123",
    "label": "Gustafs Kyrkogård",
    "value": "gustafs-kyrkogard-sater"
},
{
    "id": "2132",
    "label": "Gustav Adolfs Kyrkogård",
    "value": "gustav-adolfs-kyrkogard-habo"
},
{
    "id": "2133",
    "label": "Gustav Adolfs Kyrkogård",
    "value": "gustav-adolfs-kyrkogard-kristianstad"
},
{
    "id": "2134",
    "label": "Gustav Adolfs kyrkogård",
    "value": "gustav-adolfs-kyrkogard-hagfors"
},
{
    "id": "2136",
    "label": "Gustavsbergs Kyrkogård",
    "value": "gustavsbergs-kyrkogard-varmdo"
},
{
    "id": "2138",
    "label": "Gustavsviks kyrkogård",
    "value": "gustavsviks-kyrkogard-kristinehamn"
},
{
    "id": "2140",
    "label": "Gusums kyrkogård",
    "value": "gusums-kyrkogard-valdemarsvik"
},
{
    "id": "2142",
    "label": "Gylle kyrkogård",
    "value": "gylle-kyrkogard-trelleborg"
},
{
    "id": "2145",
    "label": "Hablingbo kyrkogård",
    "value": "hablingbo-kyrkogard-gotland"
},
{
    "id": "2147",
    "label": "Habo Kyrkogård",
    "value": "habo-kyrkogard-habo"
},
{
    "id": "2150",
    "label": "Håbo-Tibble kyrkogård",
    "value": "habo-tibble-kyrkogard-upplands-bro"
},
{
    "id": "2152",
    "label": "Håby Kyrkogård",
    "value": "haby-kyrkogard-munkedal"
},
{
    "id": "2154",
    "label": "Hackås kyrka begravningsplats",
    "value": "hackas-kyrka-begravningsplats-berg"
},
{
    "id": "2157",
    "label": "Hacksta kyrkogård",
    "value": "hacksta-kyrkogard-enkoping"
},
{
    "id": "2159",
    "label": "Håcksviks kyrkogård",
    "value": "hacksviks-kyrkogard-tranemo"
},
{
    "id": "2162",
    "label": "Hackvads kyrkogård",
    "value": "hackvads-kyrkogard-lekeberg"
},
{
    "id": "2167",
    "label": "Haga Kyrkogård",
    "value": "haga-kyrkogard-sigtuna"
},
{
    "id": "2173",
    "label": "Hagby Kyrkogård",
    "value": "hagby-kyrkogard-kalmar"
},
{
    "id": "2174",
    "label": "Hagby Kyrkogård",
    "value": "hagby-kyrkogard-uppsala"
},
{
    "id": "2176",
    "label": "Hagebyhöga kyrkogård",
    "value": "hagebyhoga-kyrkogard-motala"
},
{
    "id": "2178",
    "label": "Hagelbergs Kyrkogård",
    "value": "hagelbergs-kyrkogard-skovde"
},
{
    "id": "2180",
    "label": "Hägerstads Kyrkogård",
    "value": "hagerstads-kyrkogard-kinda"
},
{
    "id": "2182",
    "label": "Hagfors kyrkogård",
    "value": "hagfors-kyrkogard-hagfors"
},
{
    "id": "2185",
    "label": "Häggdånger kyrkogård",
    "value": "haggdanger-kyrkogard-harnosand"
},
{
    "id": "2188",
    "label": "Häggeby Kyrkogård",
    "value": "haggeby-kyrkogard-habo"
},
{
    "id": "2189",
    "label": "Häggenås Begravningsplats",
    "value": "haggenas-begravningsplats-ostersund"
},
{
    "id": "2192",
    "label": "Häggesled Kyrkogård",
    "value": "haggesled-kyrkogard-lidkoping"
},
{
    "id": "2195",
    "label": "Häggums Kyrkogård",
    "value": "haggums-kyrkogard-skovde"
},
{
    "id": "2198",
    "label": "Häglinge Kyrkogård",
    "value": "haglinge-kyrkogard-hassleholm"
},
{
    "id": "2202",
    "label": "Hagshults kyrkogård",
    "value": "hagshults-kyrkogard-vaggeryd"
},
{
    "id": "2204",
    "label": "Hajoms Kyrkogård",
    "value": "hajoms-kyrkogard-mark"
},
{
    "id": "2205",
    "label": "Håjums Kyrkogård",
    "value": "hajums-kyrkogard-trollhattan"
},
{
    "id": "2206",
    "label": "Håkantorp Kyrkogård",
    "value": "hakantorp-kyrkogard-falkoping"
},
{
    "id": "2210",
    "label": "Hakarps kyrkogård",
    "value": "hakarps-kyrkogard-jonkoping"
},
{
    "id": "2214",
    "label": "Hålanda Kyrkogård",
    "value": "halanda-kyrkogard-ale"
},
{
    "id": "2216",
    "label": "Håle-Tängs kyrkogård",
    "value": "hale-tangs-kyrkogard-grastorp"
},
{
    "id": "2217",
    "label": "Hall kyrkogård",
    "value": "hall-kyrkogard-gotland"
},
{
    "id": "2220",
    "label": "Halla kyrkogård",
    "value": "halla-kyrkogard-gotland"
},
{
    "id": "2221",
    "label": "Halla kyrkogård",
    "value": "halla-kyrkogard-nykoping"
},
{
    "id": "2230",
    "label": "Hallaröds kyrkogård",
    "value": "hallarods-kyrkogard"
},
{
    "id": "2233",
    "label": "Hallaryds kyrkogård",
    "value": "hallaryds-kyrkogard"
},
{
    "id": "2236",
    "label": "Hällby skogskyrkogård",
    "value": "hallby-skogskyrkogard"
},
{
    "id": "2238",
    "label": "Hälleberga Kyrkogård",
    "value": "halleberga-kyrkogard"
},
{
    "id": "2240",
    "label": "Hällefors Norra Kyrkogård",
    "value": "hallefors-norra-kyrkogard-hallefors"
},
{
    "id": "2241",
    "label": "Hällefors Södra Kyrkogård",
    "value": "hallefors-sodra-kyrkogard-hallefors"
},
{
    "id": "2243",
    "label": "Hälleforsnäs Kyrkogård",
    "value": "halleforsnas-kyrkogard-flen"
},
{
    "id": "2247",
    "label": "Hallens Kyrkogård",
    "value": "hallens-kyrkogard-are"
},
{
    "id": "2249",
    "label": "Hällesjö kyrkogård",
    "value": "hallesjo-kyrkogard-bracke"
},
{
    "id": "2251",
    "label": "Hällestad kyrkogård",
    "value": "hallestad-kyrkogard-falkoping"
},
{
    "id": "2252",
    "label": "Hällestad Kyrkogård",
    "value": "hallestad-kyrkogard-lund"
},
{
    "id": "2259",
    "label": "Hallingebergs Kyrkogård",
    "value": "hallingebergs-kyrkogard-oskarshamn"
},
{
    "id": "2262",
    "label": "Hållnäs kyrkogård",
    "value": "hallnas-kyrkogard-tierp"
},
{
    "id": "2268",
    "label": "Hällstads Kyrkogård",
    "value": "hallstads-kyrkogard-ulricehamn"
},
{
    "id": "2271",
    "label": "Halltorps Kyrkogård",
    "value": "halltorps-kyrkogard-kalmar"
},
{
    "id": "2274",
    "label": "Halmstads Kyrkogård",
    "value": "halmstads-kyrkogard-svalov"
},
{
    "id": "2279",
    "label": "Hålta Kyrkogård",
    "value": "halta-kyrkogard-stenungsund"
},
{
    "id": "2285",
    "label": "Hammarby kyrkogård",
    "value": "hammarby-kyrkogard-upplands-vasby"
},
{
    "id": "2286",
    "label": "Hammarby kyrkogård",
    "value": "hammarby-kyrkogard-eskilstuna"
},
{
    "id": "2287",
    "label": "Hammarby kyrkogård",
    "value": "hammarby-kyrkogard-uppsala"
},
{
    "id": "2288",
    "label": "Hammarlöv Kyrkogård",
    "value": "hammarlov-kyrkogard-trelleborg"
},
{
    "id": "2292",
    "label": "Hammarlunda kyrkogård",
    "value": "hammarlunda-kyrkogard-eslov"
},
{
    "id": "2294",
    "label": "Hammarö kyrkogård",
    "value": "hammaro-kyrkogard-askersund"
},
{
    "id": "2297",
    "label": "Hammars kyrkogård",
    "value": "hammars-kyrkogard-askersund"
},
{
    "id": "2300",
    "label": "Hammenhögs Kyrkogård",
    "value": "hammenhogs-kyrkogard-simrishamn"
},
{
    "id": "2302",
    "label": "Hammerdals Kyrkogård",
    "value": "hammerdals-kyrkogard-stromsund"
},
{
    "id": "2305",
    "label": "Hamneda kyrkogård",
    "value": "hamneda-kyrkogard-ljungby"
},
{
    "id": "2308",
    "label": "Hamra kyrkogård",
    "value": "hamra-kyrkogard-gotland"
},
{
    "id": "2309",
    "label": "Hamra Kyrkogård",
    "value": "hamra-kyrkogard-ljusdal"
},
{
    "id": "2312",
    "label": "Händene kyrkogård",
    "value": "handene-kyrkogard-skara"
},
{
    "id": "2316",
    "label": "Hanebo Kyrkogård",
    "value": "hanebo-kyrkogard-bollnas"
},
{
    "id": "2318",
    "label": "Hangelösa kyrkogård",
    "value": "hangelosa-kyrkogard-gotene"
},
{
    "id": "2320",
    "label": "Hånger gamla kyrkogården",
    "value": "hanger-gamla-kyrkogarden-varnamo"
},
{
    "id": "2321",
    "label": "Hånger kyrkogård",
    "value": "hanger-kyrkogard-varnamo"
},
{
    "id": "2324",
    "label": "Hångsdala kyrkogård",
    "value": "hangsdala-kyrkogard-tidaholm"
},
{
    "id": "2325",
    "label": "Hangvar kyrkogård",
    "value": "hangvar-kyrkogard-gotland"
},
{
    "id": "2328",
    "label": "Hanhals Kyrkogård",
    "value": "hanhals-kyrkogard-kungsbacka"
},
{
    "id": "2331",
    "label": "Hannas Kyrkogård",
    "value": "hannas-kyrkogard-simrishamn"
},
{
    "id": "2332",
    "label": "Hannäs kyrkogård",
    "value": "hannas-kyrkogard-atvidaberg"
},
{
    "id": "2336",
    "label": "Haparanda Kyrkogård",
    "value": "haparanda-kyrkogard-haparanda"
},
{
    "id": "2338",
    "label": "Härads kyrkogård",
    "value": "harads-kyrkogard-strangnas"
},
{
    "id": "2340",
    "label": "Häradshammars kyrkogård",
    "value": "haradshammars-kyrkogard-norrkoping"
},
{
    "id": "2343",
    "label": "Harbo kyrkogård",
    "value": "harbo-kyrkogard-heby"
},
{
    "id": "2345",
    "label": "Hardeberga Kyrkogård",
    "value": "hardeberga-kyrkogard-lund"
},
{
    "id": "2347",
    "label": "Hardemo Kyrkogård",
    "value": "hardemo-kyrkogard-kumla"
},
{
    "id": "2350",
    "label": "Harestads kyrkogård",
    "value": "harestads-kyrkogard-kungalv"
},
{
    "id": "2352",
    "label": "Hargs kyrkogård",
    "value": "hargs-kyrkogard-osthammar"
},
{
    "id": "2355",
    "label": "Härja kyrkogård",
    "value": "harja-kyrkogard-tidaholm"
},
{
    "id": "2356",
    "label": "Härjevad gamla kyrkplats",
    "value": "harjevad-gamla-kyrkplats-lidkoping"
},
{
    "id": "2358",
    "label": "Härjevads kyrkogård",
    "value": "harjevads-kyrkogard-lidkoping"
},
{
    "id": "2360",
    "label": "Härkeberga kyrkogård",
    "value": "harkeberga-kyrkogard-enkoping"
},
{
    "id": "2363",
    "label": "Harlösa kyrkogård",
    "value": "harlosa-kyrkogard-eslov"
},
{
    "id": "2365",
    "label": "Härlövs kyrkogård",
    "value": "harlovs-kyrkogard-alvesta"
},
{
    "id": "2367",
    "label": "Härlunda kyrkogård",
    "value": "harlunda-kyrkogard-almhult"
},
{
    "id": "2370",
    "label": "Härmanö gamla kyrkogård",
    "value": "harmano-gamla-kyrkogard-orust"
},
{
    "id": "2371",
    "label": "Härmanö nya kyrkogård",
    "value": "harmano-nya-kyrkogard-orust"
},
{
    "id": "2373",
    "label": "Härna Kyrkogård",
    "value": "harna-kyrkogard-ulricehamn"
},
{
    "id": "2375",
    "label": "Härnevi Kyrkogård",
    "value": "harnevi-kyrkogard-enkoping"
},
{
    "id": "2376",
    "label": "Härnösands kyrkogård",
    "value": "harnosands-kyrkogard-harnosand"
},
{
    "id": "2382",
    "label": "Härryda Kyrkogård",
    "value": "harryda-kyrkogard-harryda"
},
{
    "id": "2384",
    "label": "Härslövs Kyrkogård",
    "value": "harslovs-kyrkogard-landskrona"
},
{
    "id": "2386",
    "label": "Håsjö gamla kyrkogård",
    "value": "hasjo-gamla-kyrkogard-bracke"
},
{
    "id": "2388",
    "label": "Håsjö nya kyrkogård",
    "value": "hasjo-nya-kyrkogard-bracke"
},
{
    "id": "2390",
    "label": "Håslövs Kyrkogård",
    "value": "haslovs-kyrkogard-vellinge"
},
{
    "id": "2392",
    "label": "Hässelby begravningsplats",
    "value": "hasselby-begravningsplats-stockholm"
},
{
    "id": "2400",
    "label": "Hassle Kyrkogård",
    "value": "hassle-kyrkogard-gullspang"
},
{
    "id": "2402",
    "label": "Hassle-Bösarps Kyrkogård",
    "value": "hassle-bosarps-kyrkogard-skurup"
},
{
    "id": "2404",
    "label": "Hässleby kyrkogård",
    "value": "hassleby-kyrkogard-nassjo"
},
{
    "id": "2409",
    "label": "Hässleholms norra begravningsplats",
    "value": "hassleholms-norra-begravningsplats-hassleholm"
},
{
    "id": "2410",
    "label": "Hässleholms östra begravningsplats",
    "value": "hassleholms-ostra-begravningsplats-hassleholm"
},
{
    "id": "2412",
    "label": "Hasslö kyrkogård",
    "value": "hasslo-kyrkogard-karlskrona"
},
{
    "id": "2414",
    "label": "Hasslösa kyrkogård",
    "value": "hasslosa-kyrkogard-lidkoping"
},
{
    "id": "2416",
    "label": "Hasslövs Kyrkogård",
    "value": "hasslovs-kyrkogard-laholm"
},
{
    "id": "2418",
    "label": "Hässlunda Kyrkogård",
    "value": "hasslunda-kyrkogard-helsingborg"
},
{
    "id": "2421",
    "label": "Håstads kyrkogård",
    "value": "hastads-kyrkogard-lund"
},
{
    "id": "2422",
    "label": "Hästbergs Kyrkogård",
    "value": "hastbergs-kyrkogard-falkenberg"
},
{
    "id": "2425",
    "label": "Hästveda kyrkogård",
    "value": "hastveda-kyrkogard-hassleholm"
},
{
    "id": "2427",
    "label": "Håtuna kyrkogård",
    "value": "hatuna-kyrkogard-upplands-bro"
},
{
    "id": "2430",
    "label": "Haurida kyrkogård",
    "value": "haurida-kyrkogard-aneby"
},
{
    "id": "2432",
    "label": "Havdhems kyrkogård",
    "value": "havdhems-kyrkogard-gotland"
},
{
    "id": "2435",
    "label": "Haverö Kyrkogård",
    "value": "havero-kyrkogard-ange"
},
{
    "id": "2436",
    "label": "Häverö kyrkogård",
    "value": "havero-kyrkogard-norrtalje"
},
{
    "id": "2438",
    "label": "Havstenssunds kyrkogård",
    "value": "havstenssunds-kyrkogard-tanum"
},
{
    "id": "2439",
    "label": "Hed Kyrkogård",
    "value": "hed-kyrkogard-skinnskatteberg"
},
{
    "id": "2441",
    "label": "Heda kyrkogård",
    "value": "heda-kyrkogard-odeshog"
},
{
    "id": "2442",
    "label": "Hedareds kyrkogård",
    "value": "hedareds-kyrkogard-boras"
},
{
    "id": "2446",
    "label": "Hede Kyrkogård",
    "value": "hede-kyrkogard-munkedal"
},
{
    "id": "2448",
    "label": "Hedemora kyrkogård",
    "value": "hedemora-kyrkogard-hedemora"
},
{
    "id": "2449",
    "label": "Hedens begravningsplats",
    "value": "hedens-begravningsplats-pitea"
},
{
    "id": "2452",
    "label": "Hedeskoga Kyrkogård",
    "value": "hedeskoga-kyrkogard-ystad"
},
{
    "id": "2457",
    "label": "Hedvig Eleonora kyrkogård",
    "value": "hedvig-eleonora-kyrkogard-stockholm"
},
{
    "id": "2461",
    "label": "Hejde kyrkogård",
    "value": "hejde-kyrkogard-gotland"
},
{
    "id": "2463",
    "label": "Hejdeby kyrkogård",
    "value": "hejdeby-kyrkogard-gotland"
},
{
    "id": "2464",
    "label": "Hejnum kyrkogård",
    "value": "hejnum-kyrkogard-gotland"
},
{
    "id": "2469",
    "label": "Helgarö kyrkogård",
    "value": "helgaro-kyrkogard-strangnas"
},
{
    "id": "2473",
    "label": "Helgesta kyrkogård",
    "value": "helgesta-kyrkogard-flen"
},
{
    "id": "2488",
    "label": "Hellvi kyrkogård",
    "value": "hellvi-kyrkogard"
},
{
    "id": "2498",
    "label": "Hemmesdynge Kyrkogård",
    "value": "hemmesdynge-kyrkogard-trelleborg"
},
{
    "id": "2501",
    "label": "Hemmesjö kyrkogård",
    "value": "hemmesjo-kyrkogard-vaxjo"
},
{
    "id": "2503",
    "label": "Hemse Kyrkogård",
    "value": "hemse-kyrkogard-gotland"
},
{
    "id": "2505",
    "label": "Hemsjö kyrkogård",
    "value": "hemsjo-kyrkogard-alingsas"
},
{
    "id": "2508",
    "label": "Hemsö kyrkogård",
    "value": "hemso-kyrkogard-harnosand"
},
{
    "id": "2510",
    "label": "Hermanö kyrkogård",
    "value": "hermano-kyrkogard-orust"
},
{
    "id": "2512",
    "label": "Herråkra kyrkogård",
    "value": "herrakra-kyrkogard-uppvidinge"
},
{
    "id": "2514",
    "label": "Herrberga kyrkogård",
    "value": "herrberga-kyrkogard-mjolby"
},
{
    "id": "2517",
    "label": "Herrestads kyrkogård",
    "value": "herrestads-kyrkogard-vadstena"
},
{
    "id": "2522",
    "label": "Herrljunga kyrkogård",
    "value": "herrljunga-kyrkogard-herrljunga"
},
{
    "id": "2526",
    "label": "Hidinge kyrkogård",
    "value": "hidinge-kyrkogard-lekeberg"
},
{
    "id": "2530",
    "label": "Hillareds kyrkogård",
    "value": "hillareds-kyrkogard-tranemo"
},
{
    "id": "2533",
    "label": "Hilleshögs kyrkogård",
    "value": "hilleshogs-kyrkogard-ekero"
},
{
    "id": "2539",
    "label": "Hinneryds kyrkogård",
    "value": "hinneryds-kyrkogard-markaryd"
},
{
    "id": "2541",
    "label": "Hishults Kyrkogård",
    "value": "hishults-kyrkogard-laholm"
},
{
    "id": "2546",
    "label": "Hjälmseryds kyrkogård",
    "value": "hjalmseryds-kyrkogard-savsjo"
},
{
    "id": "2548",
    "label": "Hjälsta kyrkogård",
    "value": "hjalsta-kyrkogard-enkoping"
},
{
    "id": "2551",
    "label": "Hjälstads kyrkogård",
    "value": "hjalstads-kyrkogard-gullspang"
},
{
    "id": "2553",
    "label": "Hjärnarps Kyrkogård",
    "value": "hjarnarps-kyrkogard-angelholm"
},
{
    "id": "2555",
    "label": "Hjärsås Kyrkogård",
    "value": "hjarsas-kyrkogard-ostra-goinge"
},
{
    "id": "2557",
    "label": "Hjärtlanda kyrkogård",
    "value": "hjartlanda-kyrkogard-savsjo"
},
{
    "id": "2558",
    "label": "Hjärtum Kyrkogård",
    "value": "hjartum-kyrkogard-lilla-edet"
},
{
    "id": "2563",
    "label": "Hjo kyrkogård",
    "value": "hjo-kyrkogard-hjo"
},
{
    "id": "2566",
    "label": "Hjorteds Kyrkogård",
    "value": "hjorteds-kyrkogard-oskarshamn"
},
{
    "id": "2569",
    "label": "Hjortkvarns kyrkogård",
    "value": "hjortkvarns-kyrkogard-hallsberg"
},
{
    "id": "2572",
    "label": "Hjortsberga kyrkogård",
    "value": "hjortsberga-kyrkogard-ronneby"
},
{
    "id": "2573",
    "label": "Hjortsberga kyrkogård",
    "value": "hjortsberga-kyrkogard-alvesta"
},
{
    "id": "2575",
    "label": "Hjulsjö Kyrkogård",
    "value": "hjulsjo-kyrkogard-hallefors"
},
{
    "id": "2579",
    "label": "Hofterups kyrkogård",
    "value": "hofterups-kyrkogard-kavlinge"
},
{
    "id": "2581",
    "label": "Högalids kyrkogård",
    "value": "hogalids-kyrkogard-stockholm"
},
{
    "id": "2584",
    "label": "Höganäs kyrkogård",
    "value": "hoganas-kyrkogard-hoganas"
},
{
    "id": "2588",
    "label": "Högås Kyrkogård",
    "value": "hogas-kyrkogard-uddevalla"
},
{
    "id": "2590",
    "label": "Högbo Kyrkogård",
    "value": "hogbo-kyrkogard-sandviken"
},
{
    "id": "2595",
    "label": "Högby kyrkogård",
    "value": "hogby-kyrkogard-mjolby"
},
{
    "id": "2596",
    "label": "Högby kyrkogård",
    "value": "hogby-kyrkogard-borgholm"
},
{
    "id": "2597",
    "label": "Högby kyrkogård",
    "value": "hogby-kyrkogard-odeshog"
},
{
    "id": "2599",
    "label": "Hogdals kyrkogård",
    "value": "hogdals-kyrkogard-stromstad"
},
{
    "id": "2602",
    "label": "Högeruds Kyrkogård",
    "value": "hogeruds-kyrkogard-arvika"
},
{
    "id": "2605",
    "label": "Högestad Kyrkogård",
    "value": "hogestad-kyrkogard-ystad"
},
{
    "id": "2610",
    "label": "Hogräns kyrkogård",
    "value": "hograns-kyrkogard-gotland"
},
{
    "id": "2613",
    "label": "Högs kyrkogård",
    "value": "hogs-kyrkogard-kavlinge"
},
{
    "id": "2615",
    "label": "Högsäters kyrkogård",
    "value": "hogsaters-kyrkogard-fargelanda"
},
{
    "id": "2618",
    "label": "Högsby kyrkogård",
    "value": "hogsby-kyrkogard-hogsby"
},
{
    "id": "2620",
    "label": "Högseröds kyrkogård",
    "value": "hogserods-kyrkogard-eslov"
},
{
    "id": "2625",
    "label": "Högsjö kyrkogård",
    "value": "hogsjo-kyrkogard-harnosand"
},
{
    "id": "2628",
    "label": "Högsrum kyrkogård",
    "value": "hogsrum-kyrkogard-borgholm"
},
{
    "id": "2632",
    "label": "Hogstad kyrkogård",
    "value": "hogstad-kyrkogard-mjolby"
},
{
    "id": "2634",
    "label": "Högstena Kyrkogård",
    "value": "hogstena-kyrkogard-falkoping"
},
{
    "id": "2636",
    "label": "Högstorps Kyrkogård",
    "value": "hogstorps-kyrkogard-lilla-edet"
},
{
    "id": "2640",
    "label": "Höja kyrkogård",
    "value": "hoja-kyrkogard-angelholm"
},
{
    "id": "2642",
    "label": "Hökhuvuds kyrkogård",
    "value": "hokhuvuds-kyrkogard-osthammar"
},
{
    "id": "2645",
    "label": "Hököpinge kyrkogård",
    "value": "hokopinge-kyrkogard-vellinge"
},
{
    "id": "2646",
    "label": "Hol Kyrkogård",
    "value": "hol-kyrkogard-vargarda"
},
{
    "id": "2649",
    "label": "Holm kyrkogård",
    "value": "holm-kyrkogard-enkoping"
},
{
    "id": "2651",
    "label": "Holmby kyrkogård",
    "value": "holmby-kyrkogard-eslov"
},
{
    "id": "2654",
    "label": "Holmedals kyrkogård",
    "value": "holmedals-kyrkogard-arjang"
},
{
    "id": "2656",
    "label": "Holmestads kyrkogård",
    "value": "holmestads-kyrkogard-gotene"
},
{
    "id": "2662",
    "label": "Holms kyrkogård",
    "value": "holms-kyrkogard-mellerud"
},
{
    "id": "2665",
    "label": "Hölö kyrkogård",
    "value": "holo-kyrkogard-sodertalje"
},
{
    "id": "2667",
    "label": "Holsjunga kyrkogård",
    "value": "holsjunga-kyrkogard-tranemo"
},
{
    "id": "2670",
    "label": "Hömbs kyrkogård",
    "value": "hombs-kyrkogard-tidaholm"
},
{
    "id": "2672",
    "label": "Hönö kyrkogård",
    "value": "hono-kyrkogard-ockero"
},
{
    "id": "2674",
    "label": "Hönsäters kyrkogård",
    "value": "honsaters-kyrkogard-gotene"
},
{
    "id": "2682",
    "label": "Hörby Kyrkogård",
    "value": "horby-kyrkogard-horby"
},
{
    "id": "2686",
    "label": "Höreda kyrkogård",
    "value": "horeda-kyrkogard-nassjo"
},
{
    "id": "2688",
    "label": "Hörja kyrkogård",
    "value": "horja-kyrkogard-hassleholm"
},
{
    "id": "2690",
    "label": "Hörkens kyrkogård",
    "value": "horkens-kyrkogard-ljusnarsberg"
},
{
    "id": "2692",
    "label": "Horla Kyrkogård",
    "value": "horla-kyrkogard-vargarda"
},
{
    "id": "2693",
    "label": "Hornaryds kyrkplats",
    "value": "hornaryds-kyrkplats-vaxjo"
},
{
    "id": "2697",
    "label": "Hörnefors Kyrkogård",
    "value": "hornefors-kyrkogard-umea"
},
{
    "id": "2701",
    "label": "Horns kyrkogård",
    "value": "horns-kyrkogard-skovde"
},
{
    "id": "2702",
    "label": "Horns Kyrkogård",
    "value": "horns-kyrkogard-kinda"
},
{
    "id": "2706",
    "label": "Horreds kyrkogård",
    "value": "horreds-kyrkogard-mark"
},
{
    "id": "2708",
    "label": "Hörröds Kyrkogård",
    "value": "horrods-kyrkogard-kristianstad"
},
{
    "id": "2710",
    "label": "Hörsne kyrkogård",
    "value": "horsne-kyrkogard-gotland"
},
{
    "id": "2712",
    "label": "Hortlax Kyrkogård",
    "value": "hortlax-kyrkogard-pitea"
},
{
    "id": "2713",
    "label": "Hörup Kyrkogård",
    "value": "horup-kyrkogard-ystad"
},
{
    "id": "2716",
    "label": "Hosjö Kyrkogård",
    "value": "hosjo-kyrkogard-falkenberg"
},
{
    "id": "2719",
    "label": "Hossmo Kyrkogård",
    "value": "hossmo-kyrkogard-kalmar"
},
{
    "id": "2721",
    "label": "Hössna kyrkogård",
    "value": "hossna-kyrkogard-ulricehamn"
},
{
    "id": "2725",
    "label": "Hova kyrkogård",
    "value": "hova-kyrkogard"
},
{
    "id": "2727",
    "label": "Hovby kyrkogård",
    "value": "hovby-kyrkogard"
},
{
    "id": "2728",
    "label": "Hovenäsets kyrkogårdar",
    "value": "hovenasets-kyrkogardar"
},
{
    "id": "2730",
    "label": "Hovmantorps kyrkogård",
    "value": "hovmantorps-kyrkogard"
},
{
    "id": "2736",
    "label": "Hovs kyrkogård",
    "value": "hovs-kyrkogard-bastad"
},
{
    "id": "2737",
    "label": "Hovs kyrkogård",
    "value": "hovs-kyrkogard-herrljunga"
},
{
    "id": "2738",
    "label": "Hovs kyrkogård",
    "value": "hovs-kyrkogard-vadstena"
},
{
    "id": "2739",
    "label": "Hovshaga begravningsplats",
    "value": "hovshaga-begravningsplats-vaxjo"
},
{
    "id": "2744",
    "label": "Hovsta Kyrkogård",
    "value": "hovsta-kyrkogard-orebro"
},
{
    "id": "2746",
    "label": "Huaröds Kyrkogård",
    "value": "huarods-kyrkogard-kristianstad"
},
{
    "id": "2749",
    "label": "Huddinge kyrkogård",
    "value": "huddinge-kyrkogard-huddinge"
},
{
    "id": "2751",
    "label": "Huddunge kyrkogård",
    "value": "huddunge-kyrkogard-heby"
},
{
    "id": "2754",
    "label": "Hudene kyrkogård",
    "value": "hudene-kyrkogard-herrljunga"
},
{
    "id": "2756",
    "label": "Huggenäs kyrkogård",
    "value": "huggenas-kyrkogard-saffle"
},
{
    "id": "2758",
    "label": "Hulareds Kyrkogård",
    "value": "hulareds-kyrkogard-tranemo"
},
{
    "id": "2760",
    "label": "Hulterstads Kyrkogård",
    "value": "hulterstads-kyrkogard-morbylanga"
},
{
    "id": "2762",
    "label": "Hults kyrkogård",
    "value": "hults-kyrkogard-nassjo"
},
{
    "id": "2764",
    "label": "Hultsfreds kyrkogård",
    "value": "hultsfreds-kyrkogard-hultsfred"
},
{
    "id": "2766",
    "label": "Hultsjö kyrkogård",
    "value": "hultsjo-kyrkogard-savsjo"
},
{
    "id": "2768",
    "label": "Humla Kyrkogård",
    "value": "humla-kyrkogard-ulricehamn"
},
{
    "id": "2771",
    "label": "Hunnebostrands kyrkogård",
    "value": "hunnebostrands-kyrkogard-sotenas"
},
{
    "id": "2774",
    "label": "Hunnestads Kyrkogård",
    "value": "hunnestads-kyrkogard-varberg"
},
{
    "id": "2776",
    "label": "Hurva kyrkogård",
    "value": "hurva-kyrkogard-hoor"
},
{
    "id": "2778",
    "label": "Husaby kyrkogård",
    "value": "husaby-kyrkogard-gotene"
},
{
    "id": "2781",
    "label": "Husby Sjuhundra Kyrkogård",
    "value": "husby-sjuhundra-kyrkogard-norrtalje"
},
{
    "id": "2784",
    "label": "Husby-Ärlinghundra Kyrkogård",
    "value": "husby-arlinghundra-kyrkogard-sigtuna"
},
{
    "id": "2786",
    "label": "Husby-Långhundra kyrkogård",
    "value": "husby-langhundra-kyrkogard-knivsta"
},
{
    "id": "2788",
    "label": "Husby-Oppunda kyrkogård",
    "value": "husby-oppunda-kyrkogard-nykoping"
},
{
    "id": "2790",
    "label": "Husby-Rekarne kyrkogård",
    "value": "husby-rekarne-kyrkogard-eskilstuna"
},
{
    "id": "2793",
    "label": "Husby-Sjutolfts kyrkogård",
    "value": "husby-sjutolfts-kyrkogard-enkoping"
},
{
    "id": "2796",
    "label": "Husie kyrkogård",
    "value": "husie-kyrkogard-malmo"
},
{
    "id": "2799",
    "label": "Huskvarna kyrkogård",
    "value": "huskvarna-kyrkogard-jonkoping"
},
{
    "id": "2801",
    "label": "Hvilans kyrkogård",
    "value": "hvilans-kyrkogard-karlshamn"
},
{
    "id": "2803",
    "label": "Hyby gamla kyrkogård",
    "value": "hyby-gamla-kyrkogard-svedala"
},
{
    "id": "2805",
    "label": "Hyby nya kyrkogård",
    "value": "hyby-nya-kyrkogard-svedala"
},
{
    "id": "2807",
    "label": "Hycklinge Kyrkogård",
    "value": "hycklinge-kyrkogard-kinda"
},
{
    "id": "2809",
    "label": "Hylletofta kyrkogård",
    "value": "hylletofta-kyrkogard-savsjo"
},
{
    "id": "2811",
    "label": "Hyllie kyrkogård",
    "value": "hyllie-kyrkogard-malmo"
},
{
    "id": "2816",
    "label": "Hyltinge kyrkogård",
    "value": "hyltinge-kyrkogard-flen"
},
{
    "id": "2818",
    "label": "Hyssna gamla kyrkogård",
    "value": "hyssna-gamla-kyrkogard-mark"
},
{
    "id": "2820",
    "label": "Hyssna kyrkogård",
    "value": "hyssna-kyrkogard-mark"
},
{
    "id": "2823",
    "label": "Idala Kyrkogård",
    "value": "idala-kyrkogard-kungsbacka"
},
{
    "id": "2825",
    "label": "Idre Kyrkogård",
    "value": "idre-kyrkogard-alvdalen"
},
{
    "id": "2827",
    "label": "Igelösa kyrkogård",
    "value": "igelosa-kyrkogard-lund"
},
{
    "id": "2830",
    "label": "Ignaberga gamla kyrkogård",
    "value": "ignaberga-gamla-kyrkogard-hassleholm"
},
{
    "id": "2832",
    "label": "Ignaberga nya kyrkogård",
    "value": "ignaberga-nya-kyrkogard-hassleholm"
},
{
    "id": "2835",
    "label": "Ilstorps Kyrkogård",
    "value": "ilstorps-kyrkogard-sjobo"
},
{
    "id": "2840",
    "label": "Ingarö Kyrkogård",
    "value": "ingaro-kyrkogard-varmdo"
},
{
    "id": "2842",
    "label": "Ingatorps kyrkogård",
    "value": "ingatorps-kyrkogard-nassjo"
},
{
    "id": "2843",
    "label": "Ingelstorp Kyrkogård",
    "value": "ingelstorp-kyrkogard-ystad"
},
{
    "id": "2847",
    "label": "Innerstadens Kyrkogård",
    "value": "innerstadens-kyrkogard-lulea"
},
{
    "id": "2852",
    "label": "Istorps kyrkogård",
    "value": "istorps-kyrkogard-mark"
},
{
    "id": "2854",
    "label": "Ivetofta kyrkogård",
    "value": "ivetofta-kyrkogard-bromolla"
},
{
    "id": "2856",
    "label": "Ivö Kyrkogård",
    "value": "ivo-kyrkogard-kristianstad"
},
{
    "id": "2858",
    "label": "Jäders kyrkogård",
    "value": "jaders-kyrkogard-eskilstuna"
},
{
    "id": "2866",
    "label": "Jäla Kyrkogård",
    "value": "jala-kyrkogard-falkoping"
},
{
    "id": "2868",
    "label": "Jällby kyrkogård",
    "value": "jallby-kyrkogard-herrljunga"
},
{
    "id": "2870",
    "label": "Jälluntofta Kyrkogård",
    "value": "jalluntofta-kyrkogard-hylte"
},
{
    "id": "2872",
    "label": "Jämjö kyrkogård",
    "value": "jamjo-kyrkogard-karlskrona"
},
{
    "id": "2874",
    "label": "Jämshögs kyrkogård",
    "value": "jamshogs-kyrkogard-olofstrom"
},
{
    "id": "2880",
    "label": "Järbo Kyrkogård",
    "value": "jarbo-kyrkogard-sandviken"
},
{
    "id": "2881",
    "label": "Järbo kyrkogård",
    "value": "jarbo-kyrkogard-fargelanda"
},
{
    "id": "2883",
    "label": "Järeda kyrkogård",
    "value": "jareda-kyrkogard-hultsfred"
},
{
    "id": "2885",
    "label": "Järfälla kyrkogård",
    "value": "jarfalla-kyrkogard-jarfalla"
},
{
    "id": "2888",
    "label": "Järlåsa kyrkogård",
    "value": "jarlasa-kyrkogard-uppsala"
},
{
    "id": "2890",
    "label": "Järna kyrkogård",
    "value": "jarna-kyrkogard-vansbro"
},
{
    "id": "2893",
    "label": "Järnboås Kyrkogård",
    "value": "jarnboas-kyrkogard-nora"
},
{
    "id": "2895",
    "label": "Järns kyrkogård",
    "value": "jarns-kyrkogard-mellerud"
},
{
    "id": "2897",
    "label": "Järnskogs kyrkogård",
    "value": "jarnskogs-kyrkogard-eda"
},
{
    "id": "2899",
    "label": "Järpås Kyrkogård",
    "value": "jarpas-kyrkogard-lidkoping"
},
{
    "id": "2902",
    "label": "Järrestad Kyrkogård",
    "value": "jarrestad-kyrkogard-simrishamn"
},
{
    "id": "2905",
    "label": "Järsnäs Kyrkogård",
    "value": "jarsnas-kyrkogard-jonkoping"
},
{
    "id": "2906",
    "label": "Järstad kyrkogård",
    "value": "jarstad-kyrkogard-odeshog"
},
{
    "id": "2908",
    "label": "Järstads kyrkogård",
    "value": "jarstads-kyrkogard-mjolby"
},
{
    "id": "2910",
    "label": "Järstorps Kyrkogård",
    "value": "jarstorps-kyrkogard-jonkoping"
},
{
    "id": "2913",
    "label": "Järvsö Kyrkogård",
    "value": "jarvso-kyrkogard-ljusdal"
},
{
    "id": "2915",
    "label": "Jäts gamla kyrkogård",
    "value": "jats-gamla-kyrkogard-vaxjo"
},
{
    "id": "2917",
    "label": "Jäts kyrkogård",
    "value": "jats-kyrkogard-vaxjo"
},
{
    "id": "2934",
    "label": "Jonsbergs kyrkogård",
    "value": "jonsbergs-kyrkogard-norrkoping"
},
{
    "id": "2935",
    "label": "Jonsereds Griftegård",
    "value": "jonsereds-griftegard-partille"
},
{
    "id": "2938",
    "label": "Jonstorps Kyrkogård",
    "value": "jonstorps-kyrkogard-hoganas"
},
{
    "id": "2942",
    "label": "Jörlanda Kyrkogård",
    "value": "jorlanda-kyrkogard-stenungsund"
},
{
    "id": "2945",
    "label": "Julita kyrkogård",
    "value": "julita-kyrkogard-katrineholm"
},
{
    "id": "2947",
    "label": "Jumkils kyrkogård",
    "value": "jumkils-kyrkogard-uppsala"
},
{
    "id": "2955",
    "label": "Kaga kyrkogård",
    "value": "kaga-kyrkogard-linkoping"
},
{
    "id": "2958",
    "label": "Kåge Kyrkogård",
    "value": "kage-kyrkogard-skelleftea"
},
{
    "id": "2960",
    "label": "Kågeröds Kyrkogård",
    "value": "kagerods-kyrkogard-svalov"
},
{
    "id": "2967",
    "label": "Källa kyrkogård",
    "value": "kalla-kyrkogard-borgholm"
},
{
    "id": "2969",
    "label": "Kållands-Åsaka Kyrkogård",
    "value": "kallands-asaka-kyrkogard-lidkoping"
},
{
    "id": "2971",
    "label": "Källby kyrkogård",
    "value": "kallby-kyrkogard-gotene"
},
{
    "id": "2972",
    "label": "Kallebäck kyrkogård",
    "value": "kalleback-kyrkogard-goteborg"
},
{
    "id": "2975",
    "label": "Kållereds kyrkogård",
    "value": "kallereds-kyrkogard"
},
{
    "id": "2976",
    "label": "Kållereds södra begravningsplats",
    "value": "kallereds-sodra-begravningsplats"
},
{
    "id": "2977",
    "label": "Kållerstad Kyrkogård",
    "value": "kallerstad-kyrkogard"
},
{
    "id": "2980",
    "label": "Källeryds kyrkogård",
    "value": "kalleryds-kyrkogard"
},
{
    "id": "2984",
    "label": "Källna Kyrkogård",
    "value": "kallna-kyrkogard-klippan"
},
{
    "id": "2991",
    "label": "Källstads kyrkogård",
    "value": "kallstads-kyrkogard-vadstena"
},
{
    "id": "2993",
    "label": "Källstorps Kyrkogård",
    "value": "kallstorps-kyrkogard-trelleborg"
},
{
    "id": "2995",
    "label": "Källunga kyrkogård",
    "value": "kallunga-kyrkogard-herrljunga"
},
{
    "id": "2997",
    "label": "Källunge kyrkogård",
    "value": "kallunge-kyrkogard-gotland"
},
{
    "id": "3000",
    "label": "Kalmar Kyrkogård",
    "value": "kalmar-kyrkogard-habo"
},
{
    "id": "3002",
    "label": "Kälvene Kyrkogård",
    "value": "kalvene-kyrkogard-falkoping"
},
{
    "id": "3004",
    "label": "Kalvs kyrkogård",
    "value": "kalvs-kyrkogard-tranemo"
},
{
    "id": "3006",
    "label": "Kalvsviks kyrkogård",
    "value": "kalvsviks-kyrkogard-vaxjo"
},
{
    "id": "3008",
    "label": "Kalvträsks Kyrkogård",
    "value": "kalvtrasks-kyrkogard-skelleftea"
},
{
    "id": "3009",
    "label": "Kämparvets Kyrkogård",
    "value": "kamparvets-kyrkogard-falkenberg"
},
{
    "id": "3010",
    "label": "Kämpinge kyrkplats",
    "value": "kampinge-kyrkplats-vellinge"
},
{
    "id": "3014",
    "label": "Kånna kyrkogård",
    "value": "kanna-kyrkogard-ljungby"
},
{
    "id": "3015",
    "label": "Kapellet Gamla kyrkogården",
    "value": "kapellet-gamla-kyrkogarden-simrishamn"
},
{
    "id": "3020",
    "label": "Kapellkyrkogården",
    "value": "kapellkyrkogarden-solvesborg"
},
{
    "id": "3021",
    "label": "Kapellkyrkogården",
    "value": "kapellkyrkogarden-falkenberg"
},
{
    "id": "3022",
    "label": "Kapellkyrkogården",
    "value": "kapellkyrkogarden-vetlanda"
},
{
    "id": "3023",
    "label": "Kapellkyrkogården",
    "value": "kapellkyrkogarden-ulricehamn"
},
{
    "id": "3024",
    "label": "Kapellkyrkogården",
    "value": "kapellkyrkogarden-vanersborg"
},
{
    "id": "3029",
    "label": "Karaby Kyrkogård",
    "value": "karaby-kyrkogard-lidkoping"
},
{
    "id": "3032",
    "label": "Kårböle Kyrkogård",
    "value": "karbole-kyrkogard-ljusdal"
},
{
    "id": "3037",
    "label": "Kärda kyrkogård",
    "value": "karda-kyrkogard-varnamo"
},
{
    "id": "3039",
    "label": "Kareby kyrkogård",
    "value": "kareby-kyrkogard-kungalv"
},
{
    "id": "3041",
    "label": "Karesuando Nye Kyrkogård",
    "value": "karesuando-nye-kyrkogard-kiruna"
},
{
    "id": "3042",
    "label": "Käringöns kyrkogård",
    "value": "karingons-kyrkogard-orust"
},
{
    "id": "3045",
    "label": "Karl Gustavs Kyrkogård",
    "value": "karl-gustavs-kyrkogard-varberg"
},
{
    "id": "3047",
    "label": "Karlanda kyrkogård",
    "value": "karlanda-kyrkogard-arjang"
},
{
    "id": "3049",
    "label": "Karleby Kyrkogård",
    "value": "karleby-kyrkogard-falkoping"
},
{
    "id": "3053",
    "label": "Karlsdals Kyrkogård",
    "value": "karlsdals-kyrkogard-karlskoga"
},
{
    "id": "3057",
    "label": "Karlslunda Kyrkogård",
    "value": "karlslunda-kyrkogard-kalmar"
},
{
    "id": "3059",
    "label": "Karlslunds kyrkogård",
    "value": "karlslunds-kyrkogard-nora"
},
{
    "id": "3060",
    "label": "Karlstorp Kyrkogård",
    "value": "karlstorp-kyrkogard-vetlanda"
},
{
    "id": "3063",
    "label": "Kärna kyrkogård",
    "value": "karna-kyrkogard-linkoping"
},
{
    "id": "3068",
    "label": "Kärråkra Kyrkogård",
    "value": "karrakra-kyrkogard-ulricehamn"
},
{
    "id": "3071",
    "label": "Kårsta kyrkogård",
    "value": "karsta-kyrkogard-vallentuna"
},
{
    "id": "3072",
    "label": "Karungi kyrkogård",
    "value": "karungi-kyrkogard-haparanda"
},
{
    "id": "3076",
    "label": "Kastlösa Kyrkogård",
    "value": "kastlosa-kyrkogard-morbylanga"
},
{
    "id": "3078",
    "label": "Katarina kyrkogård",
    "value": "katarina-kyrkogard-stockholm"
},
{
    "id": "3080",
    "label": "Katolska kyrkogården",
    "value": "katolska-kyrkogarden-stockholm"
},
{
    "id": "3083",
    "label": "Katrinebergs Kyrkogård",
    "value": "katrinebergs-kyrkogard-bollnas"
},
{
    "id": "3084",
    "label": "Katrinedals kyrkogård",
    "value": "katrinedals-kyrkogard-vanersborg"
},
{
    "id": "3086",
    "label": "Katrineholms kyrkogård",
    "value": "katrineholms-kyrkogard-katrineholm"
},
{
    "id": "3088",
    "label": "Katslösa kyrkogård",
    "value": "katslosa-kyrkogard-skurup"
},
{
    "id": "3091",
    "label": "Kattarps Kyrkogård",
    "value": "kattarps-kyrkogard-helsingborg"
},
{
    "id": "3093",
    "label": "Kättilstads Kyrkogård",
    "value": "kattilstads-kyrkogard-kinda"
},
{
    "id": "3095",
    "label": "Kattnäs Kyrkogård",
    "value": "kattnas-kyrkogard-gnesta"
},
{
    "id": "3097",
    "label": "Kattunga Kyrkogård",
    "value": "kattunga-kyrkogard-mark"
},
{
    "id": "3101",
    "label": "Kävsjö kyrkogård",
    "value": "kavsjo-kyrkogard-gnosjo"
},
{
    "id": "3103",
    "label": "Kestads kyrkogård",
    "value": "kestads-kyrkogard-gotene"
},
{
    "id": "3105",
    "label": "Kiaby Kyrkogård",
    "value": "kiaby-kyrkogard-kristianstad"
},
{
    "id": "3107",
    "label": "Kikås Kyrkogård",
    "value": "kikas-kyrkogard-molndal"
},
{
    "id": "3112",
    "label": "Kila kyrkogård",
    "value": "kila-kyrkogard-nykoping"
},
{
    "id": "3113",
    "label": "Kila kyrkogård",
    "value": "kila-kyrkogard-saffle"
},
{
    "id": "3114",
    "label": "Kila Kyrkogård",
    "value": "kila-kyrkogard"
},
{
    "id": "3118",
    "label": "Kilanda kyrkogård",
    "value": "kilanda-kyrkogard-ale"
},
{
    "id": "3119",
    "label": "Kils Kyrkogård",
    "value": "kils-kyrkogard-orebro"
},
{
    "id": "3122",
    "label": "Kimstads kyrkogård",
    "value": "kimstads-kyrkogard-norrkoping"
},
{
    "id": "3124",
    "label": "Kinna kyrkogård",
    "value": "kinna-kyrkogard-mark"
},
{
    "id": "3126",
    "label": "Kinnareds Kyrkogård",
    "value": "kinnareds-kyrkogard-hylte"
},
{
    "id": "3128",
    "label": "Kinnarumma kyrkogård",
    "value": "kinnarumma-kyrkogard-boras"
},
{
    "id": "3130",
    "label": "Kinne-Kleva kyrkogård",
    "value": "kinne-kleva-kyrkogard-gotene"
},
{
    "id": "3132",
    "label": "Kinneveds Kyrkogård",
    "value": "kinneveds-kyrkogard-falkoping"
},
{
    "id": "3134",
    "label": "Kinne-Vedums kyrkogård",
    "value": "kinne-vedums-kyrkogard-gotene"
},
{
    "id": "3136",
    "label": "Kiruna Krematorium",
    "value": "kiruna-krematorium-kiruna"
},
{
    "id": "3138",
    "label": "Kiruna Kyrkogård",
    "value": "kiruna-kyrkogard-kiruna"
},
{
    "id": "3142",
    "label": "Kisa Kyrkogård",
    "value": "kisa-kyrkogard-kinda"
},
{
    "id": "3147",
    "label": "Kjula kyrkogård",
    "value": "kjula-kyrkogard-eskilstuna"
},
{
    "id": "3150",
    "label": "Kläckeberga kyrkogård",
    "value": "klackeberga-kyrkogard-kalmar"
},
{
    "id": "3152",
    "label": "Klädesholmens Kyrkogård",
    "value": "kladesholmens-kyrkogard-tjorn"
},
{
    "id": "3154",
    "label": "Klinte kyrkogård",
    "value": "klinte-kyrkogard-gotland"
},
{
    "id": "3156",
    "label": "Klippans Kapellkyrkogård",
    "value": "klippans-kapellkyrkogard-klippan"
},
{
    "id": "3158",
    "label": "Klockarebackens kyrkogård",
    "value": "klockarebackens-kyrkogard-hoor"
},
{
    "id": "3174",
    "label": "Klockrike kyrkogård",
    "value": "klockrike-kyrkogard-motala"
},
{
    "id": "3178",
    "label": "Klosterkyrkogården",
    "value": "klosterkyrkogarden-eskilstuna"
},
{
    "id": "3179",
    "label": "Klosterkyrkogården",
    "value": "klosterkyrkogarden-vadstena"
},
{
    "id": "3183",
    "label": "Klövedals Kyrkogård",
    "value": "klovedals-kyrkogard-tjorn"
},
{
    "id": "3185",
    "label": "Klöveskogs kyrkogård",
    "value": "kloveskogs-kyrkogard-mellerud"
},
{
    "id": "3188",
    "label": "Knäreds Kyrkogård",
    "value": "knareds-kyrkogard-laholm"
},
{
    "id": "3190",
    "label": "Knästorps kyrkogård",
    "value": "knastorps-kyrkogard-staffanstorp"
},
{
    "id": "3192",
    "label": "Knätte Kyrkogård",
    "value": "knatte-kyrkogard-ulricehamn"
},
{
    "id": "3195",
    "label": "Knislinge Kyrkogård",
    "value": "knislinge-kyrkogard-ostra-goinge"
},
{
    "id": "3197",
    "label": "Knista kyrkogård",
    "value": "knista-kyrkogard-lekeberg"
},
{
    "id": "3199",
    "label": "Knutby Kyrkogård",
    "value": "knutby-kyrkogard-uppsala"
},
{
    "id": "3202",
    "label": "Köla kyrkogård",
    "value": "kola-kyrkogard-eda"
},
{
    "id": "3204",
    "label": "Kölaby Kyrkogård",
    "value": "kolaby-kyrkogard-ulricehamn"
},
{
    "id": "3208",
    "label": "Kolerakyrkogården",
    "value": "kolerakyrkogarden-lindesberg"
},
{
    "id": "3210",
    "label": "Kölingareds Kyrkogård",
    "value": "kolingareds-kyrkogard-ulricehamn"
},
{
    "id": "3215",
    "label": "Konga Kyrkogård",
    "value": "konga-kyrkogard-svalov"
},
{
    "id": "3217",
    "label": "Konungsunds kyrkogård",
    "value": "konungsunds-kyrkogard-norrkoping"
},
{
    "id": "3218",
    "label": "Koöns kyrkogård",
    "value": "koons-kyrkogard-kungalv"
},
{
    "id": "3219",
    "label": "Köping kyrkogård",
    "value": "koping-kyrkogard-borgholm"
},
{
    "id": "3221",
    "label": "Köpinge kyrkogård",
    "value": "kopinge-kyrkogard"
},
{
    "id": "3224",
    "label": "Kopparbergs Kyrka Kyrkogård",
    "value": "kopparbergs-kyrka-kyrkogard"
},
{
    "id": "3226",
    "label": "Korså kyrkogård",
    "value": "korsa-kyrkogard"
},
{
    "id": "3228",
    "label": "Korsbackakyrkans kyrkogård",
    "value": "korsbackakyrkans-kyrkogard"
},
{
    "id": "3233",
    "label": "Korsberga kyrkogård",
    "value": "korsberga-kyrkogard-vetlanda"
},
{
    "id": "3234",
    "label": "Korsberga kyrkogård",
    "value": "korsberga-kyrkogard-hjo"
},
{
    "id": "3238",
    "label": "Kosters kyrkogård",
    "value": "kosters-kyrkogard-stromstad"
},
{
    "id": "3242",
    "label": "Kräcklinge kyrkogård",
    "value": "kracklinge-kyrkogard-lekeberg"
},
{
    "id": "3244",
    "label": "Kräklingbo kyrkogård",
    "value": "kraklingbo-kyrkogard-gotland"
},
{
    "id": "3247",
    "label": "Kråkshults kyrkogård",
    "value": "krakshults-kyrkogard-nassjo"
},
{
    "id": "3249",
    "label": "Kråksmåla Kyrkogård",
    "value": "kraksmala-kyrkogard-nybro"
},
{
    "id": "3251",
    "label": "Krematoriekyrkogården",
    "value": "krematoriekyrkogarden-helsingborg"
},
{
    "id": "3252",
    "label": "Krematorielundens begravningsplats",
    "value": "krematorielundens-begravningsplats-norrkoping"
},
{
    "id": "3255",
    "label": "Kristbergs kyrkogård",
    "value": "kristbergs-kyrkogard-motala"
},
{
    "id": "3258",
    "label": "Kristdala Norra Kyrkogården",
    "value": "kristdala-norra-kyrkogarden-oskarshamn"
},
{
    "id": "3259",
    "label": "Kristdala Södra Kyrkogården",
    "value": "kristdala-sodra-kyrkogarden-oskarshamn"
},
{
    "id": "3261",
    "label": "Kristianopels kyrkogård",
    "value": "kristianopels-kyrkogard-karlskrona"
},
{
    "id": "3266",
    "label": "Kristine Kyrka Kyrkogård",
    "value": "kristine-kyrka-kyrkogard-falkenberg"
},
{
    "id": "3274",
    "label": "Kristvalla Kyrkogård",
    "value": "kristvalla-kyrkogard-nybro"
},
{
    "id": "3277",
    "label": "Krokeks kyrkogård",
    "value": "krokeks-kyrkogard-norrkoping"
},
{
    "id": "3278",
    "label": "Krokeks ödekyrkogård",
    "value": "krokeks-odekyrkogard-norrkoping"
},
{
    "id": "3283",
    "label": "Krokstad Kyrkogård",
    "value": "krokstad-kyrkogard-munkedal"
},
{
    "id": "3287",
    "label": "Krokstrands kyrkogård",
    "value": "krokstrands-kyrkogard-stromstad"
},
{
    "id": "3292",
    "label": "Kropps Kyrkogård",
    "value": "kropps-kyrkogard-helsingborg"
},
{
    "id": "3295",
    "label": "Kuddby kyrkogård",
    "value": "kuddby-kyrkogard-norrkoping"
},
{
    "id": "3299",
    "label": "Kulla kyrkogård",
    "value": "kulla-kyrkogard-enkoping"
},
{
    "id": "3302",
    "label": "Kullerstads gamla kyrkogård",
    "value": "kullerstads-gamla-kyrkogard-norrkoping"
},
{
    "id": "3304",
    "label": "Kullerstads skogskyrkogård",
    "value": "kullerstads-skogskyrkogard-norrkoping"
},
{
    "id": "3306",
    "label": "Kullings-Skövde Kyrkogård",
    "value": "kullings-skovde-kyrkogard-vargarda"
},
{
    "id": "3308",
    "label": "Kulltorps Kyrkogård",
    "value": "kulltorps-kyrkogard-gnosjo"
},
{
    "id": "3313",
    "label": "Kumla Kyrkogård",
    "value": "kumla-kyrkogard-kumla"
},
{
    "id": "3314",
    "label": "Kumla kyrkogård",
    "value": "kumla-kyrkogard-odeshog"
},
{
    "id": "3315",
    "label": "Kumlaby Kyrkogård",
    "value": "kumlaby-kyrkogard-jonkoping"
},
{
    "id": "3323",
    "label": "Kungsängens kyrkogård",
    "value": "kungsangens-kyrkogard-upplands-bro"
},
{
    "id": "3326",
    "label": "Kungsäters Kyrkogård",
    "value": "kungsaters-kyrkogard-varberg"
},
{
    "id": "3331",
    "label": "Kungsholms kyrkogård",
    "value": "kungsholms-kyrkogard-stockholm"
},
{
    "id": "3333",
    "label": "Kungs-Husby kyrkogård",
    "value": "kungs-husby-kyrkogard-enkoping"
},
{
    "id": "3336",
    "label": "Kungslena kyrkogård",
    "value": "kungslena-kyrkogard-tidaholm"
},
{
    "id": "3338",
    "label": "Kusmark Kyrkogård",
    "value": "kusmark-kyrkogard-skelleftea"
},
{
    "id": "3343",
    "label": "Kvänums kyrkogård",
    "value": "kvanums-kyrkogard-vara"
},
{
    "id": "3345",
    "label": "Kvarnberg Gamla Kyrkogård",
    "value": "kvarnberg-gamla-kyrkogard-orsa"
},
{
    "id": "3346",
    "label": "Kvarnberg Nya Kyrkogård",
    "value": "kvarnberg-nya-kyrkogard-orsa"
},
{
    "id": "3351",
    "label": "Kvarsebo kyrkogård",
    "value": "kvarsebo-kyrkogard-norrkoping"
},
{
    "id": "3353",
    "label": "Kvastekulla Griftegård",
    "value": "kvastekulla-griftegard-partille"
},
{
    "id": "3357",
    "label": "Kverrestads Kyrkogård",
    "value": "kverrestads-kyrkogard-tomelilla"
},
{
    "id": "3358",
    "label": "Kvibergs kyrkogård",
    "value": "kvibergs-kyrkogard-goteborg"
},
{
    "id": "3361",
    "label": "Kvibille Kyrkogård",
    "value": "kvibille-kyrkogard-halmstad"
},
{
    "id": "3363",
    "label": "Kvidinge Kyrkogård",
    "value": "kvidinge-kyrkogard-astorp"
},
{
    "id": "3365",
    "label": "Kviinge Kyrkogård",
    "value": "kviinge-kyrkogard-ostra-goinge"
},
{
    "id": "3368",
    "label": "Kville kyrkogård",
    "value": "kville-kyrkogard-tanum"
},
{
    "id": "3370",
    "label": "Kvillinge kyrkogård",
    "value": "kvillinge-kyrkogard-norrkoping"
},
{
    "id": "3372",
    "label": "Kvinnestads Kyrkogård",
    "value": "kvinnestads-kyrkogard-vargarda"
},
{
    "id": "3374",
    "label": "Kvistbro kyrkogård",
    "value": "kvistbro-kyrkogard-lekeberg"
},
{
    "id": "3376",
    "label": "Kvistofta Kyrkogård",
    "value": "kvistofta-kyrkogard-landskrona"
},
{
    "id": "3378",
    "label": "Kymbo kyrkogård",
    "value": "kymbo-kyrkogard-tidaholm"
},
{
    "id": "3418",
    "label": "Kyrkås Begravningsplats",
    "value": "kyrkas-begravningsplats-ostersund"
},
{
    "id": "3421",
    "label": "Kyrkås Kyrkogård",
    "value": "kyrkas-kyrkogard-essunga"
},
{
    "id": "3428",
    "label": "Kyrkefalla kyrkogård",
    "value": "kyrkefalla-kyrkogard-tibro"
},
{
    "id": "3431",
    "label": "Kyrkheddinge kyrkogård",
    "value": "kyrkheddinge-kyrkogard-staffanstorp"
},
{
    "id": "3434",
    "label": "Kyrkhults kyrkogård",
    "value": "kyrkhults-kyrkogard-olofstrom"
},
{
    "id": "3437",
    "label": "Kyrkogården i Skånes Fagerhult",
    "value": "kyrkogarden-i-skanes-fagerhult-orkelljunga"
},
{
    "id": "3438",
    "label": "Kyrkogården vid Carl Gustafs kyrka",
    "value": "kyrkogarden-vid-carl-gustafs-kyrka-karlshamn"
},
{
    "id": "3441",
    "label": "Kyrkoköpinge kyrkogård",
    "value": "kyrkokopinge-kyrkogard-trelleborg"
},
{
    "id": "3465",
    "label": "Läby kyrkogård",
    "value": "laby-kyrkogard-uppsala"
},
{
    "id": "3466",
    "label": "Lackalänga Kapells Kyrkogård",
    "value": "lackalanga-kapells-kyrkogard-kavlinge"
},
{
    "id": "3468",
    "label": "Lackalänga Kyrkogård",
    "value": "lackalanga-kyrkogard"
},
{
    "id": "3473",
    "label": "Lagga kyrkogård",
    "value": "lagga-kyrkogard"
},
{
    "id": "3474",
    "label": "Lagmansered Kyrkogård",
    "value": "lagmansered-kyrkogard"
},
{
    "id": "3477",
    "label": "Laholms Kyrkogård",
    "value": "laholms-kyrkogard"
},
{
    "id": "3478",
    "label": "Lainio Kyrkogård",
    "value": "lainio-kyrkogard"
},
{
    "id": "3483",
    "label": "Landa Kyrkogård",
    "value": "landa-kyrkogard-kungsbacka"
},
{
    "id": "3484",
    "label": "Landa Kyrkogård",
    "value": "landa-kyrkogard-vargarda"
},
{
    "id": "3485",
    "label": "Landa Nya Kyrkogård",
    "value": "landa-nya-kyrkogard-kungsbacka"
},
{
    "id": "3490",
    "label": "Landeryds Kyrkogård",
    "value": "landeryds-kyrkogard-hylte"
},
{
    "id": "3491",
    "label": "Landeryds Kyrkogård",
    "value": "landeryds-kyrkogard-linkoping"
},
{
    "id": "3497",
    "label": "Landskrona Kyrkogård",
    "value": "landskrona-kyrkogard-landskrona"
},
{
    "id": "3499",
    "label": "Landskyrkans Kyrkogård",
    "value": "landskyrkans-kyrkogard-skelleftea"
},
{
    "id": "3500",
    "label": "Landskyrkogården",
    "value": "landskyrkogarden-alingsas"
},
{
    "id": "3503",
    "label": "Landvetters Kyrkogård",
    "value": "landvetters-kyrkogard-harryda"
},
{
    "id": "3504",
    "label": "Lane Ryrs kyrkogård",
    "value": "lane-ryrs-kyrkogard-uddevalla"
},
{
    "id": "3508",
    "label": "Långared Kyrkogård",
    "value": "langared-kyrkogard-trollhattan"
},
{
    "id": "3511",
    "label": "Långaröd Kyrkogård",
    "value": "langarod-kyrkogard-horby"
},
{
    "id": "3515",
    "label": "Långaryds Kyrkogård",
    "value": "langaryds-kyrkogard-hylte"
},
{
    "id": "3517",
    "label": "Långasjö Kyrkogård",
    "value": "langasjo-kyrkogard-emmaboda"
},
{
    "id": "3520",
    "label": "Längbro kyrkogård",
    "value": "langbro-kyrkogard-orebro"
},
{
    "id": "3523",
    "label": "Långelanda kyrkogård",
    "value": "langelanda-kyrkogard-orust"
},
{
    "id": "3526",
    "label": "Långemåla kyrkogård",
    "value": "langemala-kyrkogard-hogsby"
},
{
    "id": "3529",
    "label": "Länghems kyrkogård",
    "value": "langhems-kyrkogard-tranemo"
},
{
    "id": "3532",
    "label": "Längjums kyrkogård",
    "value": "langjums-kyrkogard-vara"
},
{
    "id": "3533",
    "label": "Långlöt kyrkogård",
    "value": "langlot-kyrkogard-borgholm"
},
{
    "id": "3537",
    "label": "Långserud Kyrkogård",
    "value": "langserud-kyrkogard-saffle"
},
{
    "id": "3539",
    "label": "Långseruds kyrkogård",
    "value": "langseruds-kyrkogard-saffle"
},
{
    "id": "3545",
    "label": "Långtora kyrkogård",
    "value": "langtora-kyrkogard-enkoping"
},
{
    "id": "3547",
    "label": "Långträsk kyrkogård",
    "value": "langtrask-kyrkogard-pitea"
},
{
    "id": "3551",
    "label": "Länna kyrkogård",
    "value": "lanna-kyrkogard-norrtalje"
},
{
    "id": "3552",
    "label": "Länna Kyrkogård",
    "value": "lanna-kyrkogard-strangnas"
},
{
    "id": "3555",
    "label": "Lännäs kyrkogård",
    "value": "lannas-kyrkogard-orebro"
},
{
    "id": "3557",
    "label": "Lannaskede Gamla Kyrkogård",
    "value": "lannaskede-gamla-kyrkogard-vetlanda"
},
{
    "id": "3559",
    "label": "Lannaskede-Myresjö Kyrkogård",
    "value": "lannaskede-myresjo-kyrkogard-vetlanda"
},
{
    "id": "3560",
    "label": "Lannavaara\/Soppero Kyrkogård",
    "value": "lannavaarasoppero-kyrkogard-kiruna"
},
{
    "id": "3567",
    "label": "Lappträsks Kyrkogård",
    "value": "lapptrasks-kyrkogard-haparanda"
},
{
    "id": "3569",
    "label": "Lärbro kyrkogård",
    "value": "larbro-kyrkogard-gotland"
},
{
    "id": "3572",
    "label": "Larvs kyrkogård",
    "value": "larvs-kyrkogard-vara"
},
{
    "id": "3580",
    "label": "Låssa kyrkogård",
    "value": "lassa-kyrkogard-upplands-bro"
},
{
    "id": "3582",
    "label": "Låstads kyrkogård",
    "value": "lastads-kyrkogard-mariestad"
},
{
    "id": "3584",
    "label": "Lästringe kyrkogård",
    "value": "lastringe-kyrkogard-nykoping"
},
{
    "id": "3586",
    "label": "Latikbergs kyrkogård",
    "value": "latikbergs-kyrkogard-storuman"
},
{
    "id": "3589",
    "label": "Lavads Kyrkogård",
    "value": "lavads-kyrkogard-lidkoping"
},
{
    "id": "3591",
    "label": "Laxarby kyrkogård",
    "value": "laxarby-kyrkogard-bengtsfors"
},
{
    "id": "3595",
    "label": "Ledbergs kyrkogård",
    "value": "ledbergs-kyrkogard-linkoping"
},
{
    "id": "3597",
    "label": "Ledsjö kyrkogård",
    "value": "ledsjo-kyrkogard-gotene"
},
{
    "id": "3600",
    "label": "Lekaryds kyrkogård",
    "value": "lekaryds-kyrkogard-alvesta"
},
{
    "id": "3602",
    "label": "Lekåsa Kyrkogård",
    "value": "lekasa-kyrkogard-essunga"
},
{
    "id": "3604",
    "label": "Lekeryds Kyrkogård",
    "value": "lekeryds-kyrkogard-jonkoping"
},
{
    "id": "3606",
    "label": "Leksands kyrkogård",
    "value": "leksands-kyrkogard-leksand"
},
{
    "id": "3609",
    "label": "Leksbergs kyrkogård",
    "value": "leksbergs-kyrkogard-mariestad"
},
{
    "id": "3611",
    "label": "Lekvattnets Kyrkogård",
    "value": "lekvattnets-kyrkogard-torsby"
},
{
    "id": "3613",
    "label": "Lemnhults kyrkogård",
    "value": "lemnhults-kyrkogard-vetlanda"
},
{
    "id": "3616",
    "label": "Lena kyrkogård",
    "value": "lena-kyrkogard-uppsala"
},
{
    "id": "3617",
    "label": "Lena Kyrkogård",
    "value": "lena-kyrkogard-vargarda"
},
{
    "id": "3620",
    "label": "Lenhovda kyrkogård",
    "value": "lenhovda-kyrkogard-uppvidinge"
},
{
    "id": "3623",
    "label": "Lerbäcks kyrkogård",
    "value": "lerbacks-kyrkogard-askersund"
},
{
    "id": "3626",
    "label": "Lerbo kyrkogård",
    "value": "lerbo-kyrkogard-katrineholm"
},
{
    "id": "3628",
    "label": "Lerdala kyrkogård",
    "value": "lerdala-kyrkogard-skovde"
},
{
    "id": "3630",
    "label": "Lerdals kyrkogård",
    "value": "lerdals-kyrkogard-fargelanda"
},
{
    "id": "3632",
    "label": "Lerums gamla kyrkogård",
    "value": "lerums-gamla-kyrkogard-lerum"
},
{
    "id": "3634",
    "label": "Lerums östra kyrkogård",
    "value": "lerums-ostra-kyrkogard-lerum"
},
{
    "id": "3635",
    "label": "Lerums västra kyrkogård",
    "value": "lerums-vastra-kyrkogard-lerum"
},
{
    "id": "3637",
    "label": "Lesjöfors urnlund",
    "value": "lesjofors-urnlund-filipstad"
},
{
    "id": "3642",
    "label": "Levene kyrkogård",
    "value": "levene-kyrkogard-vara"
},
{
    "id": "3644",
    "label": "Levide kyrkogård",
    "value": "levide-kyrkogard-gotland"
},
{
    "id": "3647",
    "label": "Liareds Kyrkogård",
    "value": "liareds-kyrkogard-ulricehamn"
},
{
    "id": "3654",
    "label": "Lidhults gamla kyrkogård",
    "value": "lidhults-gamla-kyrkogard-ljungby"
},
{
    "id": "3656",
    "label": "Lidhults kyrkogård",
    "value": "lidhults-kyrkogard-ljungby"
},
{
    "id": "3658",
    "label": "Lidingö ḱyrkogård",
    "value": "lidingo-kyrkogard-lidingo"
},
{
    "id": "3661",
    "label": "Lids kyrkogård",
    "value": "lids-kyrkogard-nykoping"
},
{
    "id": "3670",
    "label": "Lilla Aska Griftegård",
    "value": "lilla-aska-griftegard-linkoping"
},
{
    "id": "3671",
    "label": "Lilla Aska Kyrkogård",
    "value": "lilla-aska-kyrkogard-linkoping"
},
{
    "id": "3672",
    "label": "Lilla Askeröns Kyrkogård",
    "value": "lilla-askerons-kyrkogard-tjorn"
},
{
    "id": "3674",
    "label": "Lilla Beddinge Kyrkogård",
    "value": "lilla-beddinge-kyrkogard-trelleborg"
},
{
    "id": "3675",
    "label": "Lilla Dalens begravningsplats",
    "value": "lilla-dalens-begravningsplats-botkyrka"
},
{
    "id": "3678",
    "label": "Lilla Harrie kyrkogård",
    "value": "lilla-harrie-kyrkogard-kavlinge"
},
{
    "id": "3680",
    "label": "Lilla Isie Kyrkogård",
    "value": "lilla-isie-kyrkogard-trelleborg"
},
{
    "id": "3683",
    "label": "Lilla Malma Kyrkogård",
    "value": "lilla-malma-kyrkogard-flen"
},
{
    "id": "3686",
    "label": "Lilla Slågarps Kyrkogård",
    "value": "lilla-slagarps-kyrkogard-trelleborg"
},
{
    "id": "3690",
    "label": "Lillkyrka kyrkogård",
    "value": "lillkyrka-kyrkogard-enkoping"
},
{
    "id": "3691",
    "label": "Lillkyrka kyrkogård",
    "value": "lillkyrka-kyrkogard-orebro"
},
{
    "id": "3692",
    "label": "Lillkyrka Kyrkogård",
    "value": "lillkyrka-kyrkogard-linkoping"
},
{
    "id": "3696",
    "label": "Limhamns kyrkogård",
    "value": "limhamns-kyrkogard-malmo"
},
{
    "id": "3703",
    "label": "Lindärva kyrkogård",
    "value": "lindarva-kyrkogard-lidkoping"
},
{
    "id": "3704",
    "label": "Lindberg Kyrkogård",
    "value": "lindberg-kyrkogard-varberg"
},
{
    "id": "3707",
    "label": "Linde kyrkogård",
    "value": "linde-kyrkogard-gotland"
},
{
    "id": "3710",
    "label": "Linderås Kyrkogård",
    "value": "linderas-kyrkogard-tranas"
},
{
    "id": "3712",
    "label": "Linderöds kyrkogård",
    "value": "linderods-kyrkogard-kristianstad"
},
{
    "id": "3718",
    "label": "Lindome kyrkogård",
    "value": "lindome-kyrkogard"
},
{
    "id": "3723",
    "label": "Linneryds kyrkogård",
    "value": "linneryds-kyrkogard"
},
{
    "id": "3727",
    "label": "Lista kyrkogård",
    "value": "lista-kyrkogard-eskilstuna"
},
{
    "id": "3730",
    "label": "Listerby kyrkogård",
    "value": "listerby-kyrkogard-ronneby"
},
{
    "id": "3731",
    "label": "Lits Begravningsplats",
    "value": "lits-begravningsplats-ostersund"
},
{
    "id": "3736",
    "label": "Litslena kyrkogård",
    "value": "litslena-kyrkogard-enkoping"
},
{
    "id": "3739",
    "label": "Ljuders kyrkogård",
    "value": "ljuders-kyrkogard-lessebo"
},
{
    "id": "3745",
    "label": "Ljungarums Kyrkogård",
    "value": "ljungarums-kyrkogard-jonkoping"
},
{
    "id": "3747",
    "label": "Ljungby gamla kyrkogård",
    "value": "ljungby-gamla-kyrkogard-ljungby"
},
{
    "id": "3751",
    "label": "Ljungby Kyrkogård",
    "value": "ljungby-kyrkogard-falkenberg"
},
{
    "id": "3752",
    "label": "Ljungby Kyrkogård",
    "value": "ljungby-kyrkogard-kalmar"
},
{
    "id": "3753",
    "label": "Ljungby skogskyrkogård",
    "value": "ljungby-skogskyrkogard-ljungby"
},
{
    "id": "3761",
    "label": "Ljungs kyrkogård",
    "value": "ljungs-kyrkogard-uddevalla"
},
{
    "id": "3762",
    "label": "Ljungs kyrkogård",
    "value": "ljungs-kyrkogard-linkoping"
},
{
    "id": "3764",
    "label": "Ljungsarps Kyrkogård",
    "value": "ljungsarps-kyrkogard-tranemo"
},
{
    "id": "3776",
    "label": "Ljushults kyrkogård",
    "value": "ljushults-kyrkogard-tranemo"
},
{
    "id": "3777",
    "label": "Ljusnarsbergs gamla kyrkogård",
    "value": "ljusnarsbergs-gamla-kyrkogard-ljusnarsberg"
},
{
    "id": "3779",
    "label": "Ljusnarsbergs nya kyrkogård",
    "value": "ljusnarsbergs-nya-kyrkogard-ljusnarsberg"
},
{
    "id": "3783",
    "label": "Ljusterö kyrkogård",
    "value": "ljustero-kyrkogard-osteraker"
},
{
    "id": "3785",
    "label": "Lockarps kyrkogård",
    "value": "lockarps-kyrkogard-malmo"
},
{
    "id": "3787",
    "label": "Locketorps kyrkogård",
    "value": "locketorps-kyrkogard-skovde"
},
{
    "id": "3789",
    "label": "Lockne kyrkogård",
    "value": "lockne-kyrkogard-ostersund"
},
{
    "id": "3791",
    "label": "Locknevi kyrkogård",
    "value": "locknevi-kyrkogard-vimmerby"
},
{
    "id": "3793",
    "label": "Löddeköpinge kyrkogård",
    "value": "loddekopinge-kyrkogard-kavlinge"
},
{
    "id": "3796",
    "label": "Löderups Kyrkogård",
    "value": "loderups-kyrkogard-ystad"
},
{
    "id": "3800",
    "label": "Lofta kyrkogård",
    "value": "lofta-kyrkogard-vastervik"
},
{
    "id": "3803",
    "label": "Loftahammars kyrkogård",
    "value": "loftahammars-kyrkogard-vastervik"
},
{
    "id": "3807",
    "label": "Lohärads kyrkogård",
    "value": "loharads-kyrkogard-norrtalje"
},
{
    "id": "3809",
    "label": "Lojsta kyrkogård",
    "value": "lojsta-kyrkogard-gotland"
},
{
    "id": "3811",
    "label": "Lokrume kyrkogård",
    "value": "lokrume-kyrkogard-gotland"
},
{
    "id": "3814",
    "label": "Lomma kyrkogård",
    "value": "lomma-kyrkogard-lomma"
},
{
    "id": "3817",
    "label": "Lommaryds kyrkogård",
    "value": "lommaryds-kyrkogard-aneby"
},
{
    "id": "3818",
    "label": "Lommelanda kulles kyrkogård",
    "value": "lommelanda-kulles-kyrkogard-stromstad"
},
{
    "id": "3820",
    "label": "Lommelands kyrkogård",
    "value": "lommelands-kyrkogard-stromstad"
},
{
    "id": "3822",
    "label": "Longs kyrkogård",
    "value": "longs-kyrkogard-vara"
},
{
    "id": "3824",
    "label": "Lönneberga kyrkogård",
    "value": "lonneberga-kyrkogard-hultsfred"
},
{
    "id": "3826",
    "label": "Lönsås kyrkogård",
    "value": "lonsas-kyrkogard-motala"
},
{
    "id": "3830",
    "label": "Los Kyrkogård",
    "value": "los-kyrkogard-ljusdal"
},
{
    "id": "3832",
    "label": "Lösens Kyrkogård",
    "value": "losens-kyrkogard-karlskrona"
},
{
    "id": "3834",
    "label": "Loshults Kyrkogård",
    "value": "loshults-kyrkogard-osby"
},
{
    "id": "3835",
    "label": "Löt kyrkogård",
    "value": "lot-kyrkogard-borgholm"
},
{
    "id": "3839",
    "label": "Löts kyrkogård",
    "value": "lots-kyrkogard-enkoping"
},
{
    "id": "3842",
    "label": "Lövångers Gamla Kyrkogård",
    "value": "lovangers-gamla-kyrkogard-skelleftea"
},
{
    "id": "3844",
    "label": "Lövångers Nya Kyrkogård",
    "value": "lovangers-nya-kyrkogard-skelleftea"
},
{
    "id": "3845",
    "label": "Lövestads Gamla Kyrkogård",
    "value": "lovestads-gamla-kyrkogard-sjobo"
},
{
    "id": "3847",
    "label": "Lövestads Nya Kyrkogård",
    "value": "lovestads-nya-kyrkogard-sjobo"
},
{
    "id": "3852",
    "label": "Lovö kyrkogård",
    "value": "lovo-kyrkogard-ekero"
},
{
    "id": "3856",
    "label": "Ludgo kyrkogård",
    "value": "ludgo-kyrkogard-nykoping"
},
{
    "id": "3859",
    "label": "Lugnås kyrkogård",
    "value": "lugnas-kyrkogard-mariestad"
},
{
    "id": "3860",
    "label": "Lugnet Kyrkogård",
    "value": "lugnet-kyrkogard-storuman"
},
{
    "id": "3864",
    "label": "Lummelunda kyrkogård",
    "value": "lummelunda-kyrkogard-gotland"
},
{
    "id": "3866",
    "label": "Lumshedens Kyrkogård",
    "value": "lumshedens-kyrkogard-falu"
},
{
    "id": "3870",
    "label": "Lunda kyrkogård",
    "value": "lunda-kyrkogard-nykoping"
},
{
    "id": "3871",
    "label": "Lunda Kyrkogård",
    "value": "lunda-kyrkogard-sigtuna"
},
{
    "id": "3875",
    "label": "Lundby Gamla kyrkogård",
    "value": "lundby-gamla-kyrkogard-goteborg"
},
{
    "id": "3879",
    "label": "Lundby Nya kyrkogård",
    "value": "lundby-nya-kyrkogard-goteborg"
},
{
    "id": "3881",
    "label": "Lunds Kyrkogård",
    "value": "lunds-kyrkogard-skelleftea"
},
{
    "id": "3884",
    "label": "Lungö kyrkogård",
    "value": "lungo-kyrkogard-harnosand"
},
{
    "id": "3885",
    "label": "Lungsunds Kyrkogård",
    "value": "lungsunds-kyrkogard-storfors"
},
{
    "id": "3887",
    "label": "Lurs kyrkogård",
    "value": "lurs-kyrkogard-tanum"
},
{
    "id": "3889",
    "label": "Luttra Kyrkogård",
    "value": "luttra-kyrkogard-falkoping"
},
{
    "id": "3892",
    "label": "Lyby Kyrkogård",
    "value": "lyby-kyrkogard-horby"
},
{
    "id": "3894",
    "label": "Lycke kyrkogård",
    "value": "lycke-kyrkogard-kungalv"
},
{
    "id": "3896",
    "label": "Lycksele Kyrkogård",
    "value": "lycksele-kyrkogard-storuman"
},
{
    "id": "3899",
    "label": "Lye Kyrkogård",
    "value": "lye-kyrkogard-gotland"
},
{
    "id": "3902",
    "label": "Lyngby Kyrkogård",
    "value": "lyngby-kyrkogard-lund"
},
{
    "id": "3904",
    "label": "Lyngsjö Kyrkogård",
    "value": "lyngsjo-kyrkogard-kristianstad"
},
{
    "id": "3905",
    "label": "Lyrestad Kyrkogård",
    "value": "lyrestad-kyrkogard-gullspang"
},
{
    "id": "3908",
    "label": "Lyse Kyrkogård",
    "value": "lyse-kyrkogard-lysekil"
},
{
    "id": "3910",
    "label": "Lyshults Kyrkogård",
    "value": "lyshults-kyrkogard-lysekil"
},
{
    "id": "3912",
    "label": "Lysviks kyrkogård",
    "value": "lysviks-kyrkogard-sunne"
},
{
    "id": "3914",
    "label": "Lyvikskyrkogården",
    "value": "lyvikskyrkogarden-ludvika"
},
{
    "id": "3916",
    "label": "Madesjö Kyrkogård",
    "value": "madesjo-kyrkogard-nybro"
},
{
    "id": "3917",
    "label": "Maglarp Gamla Kyrkogård",
    "value": "maglarp-gamla-kyrkogard-trelleborg"
},
{
    "id": "3918",
    "label": "Maglarp Nya Kyrkogård",
    "value": "maglarp-nya-kyrkogard-trelleborg"
},
{
    "id": "3921",
    "label": "Maglehems Kyrkogård",
    "value": "maglehems-kyrkogard-kristianstad"
},
{
    "id": "3924",
    "label": "Magra Kyrkogård",
    "value": "magra-kyrkogard-trollhattan"
},
{
    "id": "3926",
    "label": "Malå Gamla Kyrkogård",
    "value": "mala-gamla-kyrkogard-mala"
},
{
    "id": "3930",
    "label": "Malexanders kyrkogård",
    "value": "malexanders-kyrkogard-boxholm"
},
{
    "id": "3932",
    "label": "Målilla-Gårdveda kyrkogård",
    "value": "malilla-gardveda-kyrkogard-hultsfred"
},
{
    "id": "3935",
    "label": "Malmbäcks Gamla Kyrkogård",
    "value": "malmbacks-gamla-kyrkogard-nassjo"
},
{
    "id": "3937",
    "label": "Malmbäcks Nya Kyrkogård",
    "value": "malmbacks-nya-kyrkogard-nassjo"
},
{
    "id": "3940",
    "label": "Malmöns kyrkogårdar",
    "value": "malmons-kyrkogardar-sotenas"
},
{
    "id": "3944",
    "label": "Malsta kyrkogård",
    "value": "malsta-kyrkogard-norrtalje"
},
{
    "id": "3946",
    "label": "Mangskog Kyrkogård",
    "value": "mangskog-kyrkogard-arvika"
},
{
    "id": "3951",
    "label": "Månsarps Kyrkogård",
    "value": "mansarps-kyrkogard-jonkoping"
},
{
    "id": "3955",
    "label": "Månstads kyrkogård",
    "value": "manstads-kyrkogard-tranemo"
},
{
    "id": "3958",
    "label": "Marbäcks kyrkogård",
    "value": "marbacks-kyrkogard-aneby"
},
{
    "id": "3959",
    "label": "Marbäcks kyrkogård",
    "value": "marbacks-kyrkogard-ulricehamn"
},
{
    "id": "3961",
    "label": "Marby Kyrkogård",
    "value": "marby-kyrkogard-are"
},
{
    "id": "3964",
    "label": "Mårdaklevs kyrkogård",
    "value": "mardaklevs-kyrkogard"
},
{
    "id": "3975",
    "label": "Maria Magdalena kyrkogård",
    "value": "maria-magdalena-kyrkogard-stockholm"
},
{
    "id": "4005",
    "label": "Marie kyrkogård",
    "value": "marie-kyrkogard-skara"
},
{
    "id": "4006",
    "label": "Mariebergs kyrkogård",
    "value": "mariebergs-kyrkogard-goteborg"
},
{
    "id": "4008",
    "label": "Marieby kyrkogård",
    "value": "marieby-kyrkogard-ostersund"
},
{
    "id": "4011",
    "label": "Mariefreds kyrkogård",
    "value": "mariefreds-kyrkogard-strangnas"
},
{
    "id": "4020",
    "label": "Marka Kyrkogård",
    "value": "marka-kyrkogard-falkoping"
},
{
    "id": "4022",
    "label": "Markaryds kyrkogård",
    "value": "markaryds-kyrkogard-markaryd"
},
{
    "id": "4024",
    "label": "Markims kyrkogård",
    "value": "markims-kyrkogard-vallentuna"
},
{
    "id": "4030",
    "label": "Marma Kyrkogård",
    "value": "marma-kyrkogard-alvkarleby"
},
{
    "id": "4037",
    "label": "Marsvinsholms Kyrkogård",
    "value": "marsvinsholms-kyrkogard-ystad"
},
{
    "id": "4039",
    "label": "Martebo kyrkogård",
    "value": "martebo-kyrkogard-gotland"
},
{
    "id": "4042",
    "label": "Marums kyrkogård",
    "value": "marums-kyrkogard-skara"
},
{
    "id": "4044",
    "label": "Mästerby kyrkogård",
    "value": "masterby-kyrkogard-gotland"
},
{
    "id": "4047",
    "label": "Mästocka Kyrkogård",
    "value": "mastocka-kyrkogard-laholm"
},
{
    "id": "4049",
    "label": "Matteröds kyrkogård",
    "value": "matterods-kyrkogard-hassleholm"
},
{
    "id": "4050",
    "label": "Matteus begravningsplats",
    "value": "matteus-begravningsplats-norrkoping"
},
{
    "id": "4056",
    "label": "Mattmars Kyrkogård",
    "value": "mattmars-kyrkogard-are"
},
{
    "id": "4058",
    "label": "Mattsmyra Kyrkogård",
    "value": "mattsmyra-kyrkogard-ovanaker"
},
{
    "id": "4061",
    "label": "Medelplana kyrkogård",
    "value": "medelplana-kyrkogard-gotene"
},
{
    "id": "4067",
    "label": "Mellan-Grevie kyrkogård",
    "value": "mellan-grevie-kyrkogard-vellinge"
},
{
    "id": "4068",
    "label": "Mellby begravningsplats",
    "value": "mellby-begravningsplats-stromstad"
},
{
    "id": "4069",
    "label": "Mellby Gamla Kyrkogård",
    "value": "mellby-gamla-kyrkogard-lidkoping"
},
{
    "id": "4072",
    "label": "Mellby kyrkogård",
    "value": "mellby-kyrkogard-nassjo"
},
{
    "id": "4073",
    "label": "Mellby kyrkogård",
    "value": "mellby-kyrkogard-stromstad"
},
{
    "id": "4074",
    "label": "Mellby Nya Kyrkogård",
    "value": "mellby-nya-kyrkogard-lidkoping"
},
{
    "id": "4079",
    "label": "Mellösa Kyrkogård",
    "value": "mellosa-kyrkogard-flen"
},
{
    "id": "4094",
    "label": "Millesvik Kyrkogård",
    "value": "millesvik-kyrkogard-saffle"
},
{
    "id": "4096",
    "label": "Millesviks kyrkogård",
    "value": "millesviks-kyrkogard-saffle"
},
{
    "id": "4097",
    "label": "Minneslund",
    "value": "minneslund-vetlanda"
},
{
    "id": "4107",
    "label": "Mistelås kyrkogård",
    "value": "mistelas-kyrkogard-alvesta"
},
{
    "id": "4109",
    "label": "Misterhults kyrkogård",
    "value": "misterhults-kyrkogard-oskarshamn"
},
{
    "id": "4111",
    "label": "Mjäldrunga kyrkogård",
    "value": "mjaldrunga-kyrkogard-herrljunga"
},
{
    "id": "4113",
    "label": "Mjällby kyrkogård",
    "value": "mjallby-kyrkogard-solvesborg"
},
{
    "id": "4115",
    "label": "Mjöbäcks kyrkogård",
    "value": "mjobacks-kyrkogard-tranemo"
},
{
    "id": "4118",
    "label": "Mjölbys gamla kyrkogård",
    "value": "mjolbys-gamla-kyrkogard-odeshog"
},
{
    "id": "4119",
    "label": "Mjölbys nya kyrkogård",
    "value": "mjolbys-nya-kyrkogard-odeshog"
},
{
    "id": "4128",
    "label": "Mo kyrkogård",
    "value": "mo-kyrkogard-ornskoldsvik"
},
{
    "id": "4129",
    "label": "Mo kyrkogård",
    "value": "mo-kyrkogard-tanum"
},
{
    "id": "4130",
    "label": "Mo kyrkogård",
    "value": "mo-kyrkogard-toreboda"
},
{
    "id": "4131",
    "label": "Mo kyrkogård",
    "value": "mo-kyrkogard-amal"
},
{
    "id": "4137",
    "label": "Mockfjärds kyrkogård",
    "value": "mockfjards-kyrkogard-gagnef"
},
{
    "id": "4139",
    "label": "Mofalla kyrkogård",
    "value": "mofalla-kyrkogard-hjo"
},
{
    "id": "4141",
    "label": "Mogatas Kyrkogård",
    "value": "mogatas-kyrkogard-soderkoping"
},
{
    "id": "4143",
    "label": "Moheda kyrkogård",
    "value": "moheda-kyrkogard-alvesta"
},
{
    "id": "4145",
    "label": "Möja Kyrkogård",
    "value": "moja-kyrkogard-varmdo"
},
{
    "id": "4151",
    "label": "Molla kyrkogård",
    "value": "molla-kyrkogard-herrljunga"
},
{
    "id": "4154",
    "label": "Mölleberga kyrkogård",
    "value": "molleberga-kyrkogard-staffanstorp"
},
{
    "id": "4155",
    "label": "Mollösunds gamla kyrkogård",
    "value": "mollosunds-gamla-kyrkogard-orust"
},
{
    "id": "4158",
    "label": "Mölltorps kyrkogård",
    "value": "molltorps-kyrkogard-karlsborg"
},
{
    "id": "4160",
    "label": "Möne Kyrkogård",
    "value": "mone-kyrkogard-ulricehamn"
},
{
    "id": "4161",
    "label": "Mönsterås gamla kyrkogård",
    "value": "monsteras-gamla-kyrkogard-mosteras"
},
{
    "id": "4163",
    "label": "Mora Gamla Kyrkogård",
    "value": "mora-gamla-kyrkogard-mora"
},
{
    "id": "4166",
    "label": "Mora Nya Begravningsplats",
    "value": "mora-nya-begravningsplats-mora"
},
{
    "id": "4169",
    "label": "Mörarps Kyrkogård",
    "value": "morarps-kyrkogard-helsingborg"
},
{
    "id": "4171",
    "label": "Mörbylånga Kyrkogård",
    "value": "morbylanga-kyrkogard-morbylanga"
},
{
    "id": "4175",
    "label": "Morkarla kyrkogård",
    "value": "morkarla-kyrkogard-osthammar"
},
{
    "id": "4177",
    "label": "Mörkö kyrkogård",
    "value": "morko-kyrkogard-sodertalje"
},
{
    "id": "4179",
    "label": "Morlanda kyrkogård",
    "value": "morlanda-kyrkogard-orust"
},
{
    "id": "4181",
    "label": "Mörlunda kyrkogård",
    "value": "morlunda-kyrkogard-hultsfred"
},
{
    "id": "4185",
    "label": "Mörrums kyrkogård",
    "value": "morrums-kyrkogard-karlshamn"
},
{
    "id": "4187",
    "label": "Mörsils Kyrkogård",
    "value": "morsils-kyrkogard-are"
},
{
    "id": "4189",
    "label": "Mortorps Kyrkogård",
    "value": "mortorps-kyrkogard-kalmar"
},
{
    "id": "4191",
    "label": "Mosaiska begravningsplatsen",
    "value": "mosaiska-begravningsplatsen-kalmar"
},
{
    "id": "4192",
    "label": "Mosaiska kyrkogården",
    "value": "mosaiska-kyrkogarden-karlstad"
},
{
    "id": "4194",
    "label": "Mosjö kyrkogård",
    "value": "mosjo-kyrkogard-orebro"
},
{
    "id": "4197",
    "label": "Moskosels Kyrkogård",
    "value": "moskosels-kyrkogard-arvidsjaur"
},
{
    "id": "4200",
    "label": "Mossebo Kyrkogård",
    "value": "mossebo-kyrkogard-tranemo"
},
{
    "id": "4202",
    "label": "Motala griftegård",
    "value": "motala-griftegard-motala"
},
{
    "id": "4206",
    "label": "Mularps Kyrkogård",
    "value": "mularps-kyrkogard"
},
{
    "id": "4208",
    "label": "Mullhyttans kyrkogård",
    "value": "mullhyttans-kyrkogard"
},
{
    "id": "4212",
    "label": "Mulseryds Kyrkogård",
    "value": "mulseryds-kyrkogard"
},
{
    "id": "4216",
    "label": "Munka-Ljungby Kyrkogård",
    "value": "munka-ljungby-kyrkogard-angelholm"
},
{
    "id": "4218",
    "label": "Munkarps kyrkogård",
    "value": "munkarps-kyrkogard-hoor"
},
{
    "id": "4223",
    "label": "Munkfors Kyrkogård",
    "value": "munkfors-kyrkogard-munkfors"
},
{
    "id": "4226",
    "label": "Munsö kyrkogård",
    "value": "munso-kyrkogard-ekero"
},
{
    "id": "4231",
    "label": "Murums Kyrkogård",
    "value": "murums-kyrkogard-ulricehamn"
},
{
    "id": "4233",
    "label": "Muskö Kyrkogård",
    "value": "musko-kyrkogard-haninge"
},
{
    "id": "4235",
    "label": "Muslimska begravningsplatsen",
    "value": "muslimska-begravningsplatsen-kalmar"
},
{
    "id": "4239",
    "label": "Myckleby kyrkogård",
    "value": "myckleby-kyrkogard-orust"
},
{
    "id": "4240",
    "label": "Myggsjö Kyrkogård",
    "value": "myggsjo-kyrkogard-orsa"
},
{
    "id": "4241",
    "label": "Myrens kyrkogård",
    "value": "myrens-kyrkogard-stromstad"
},
{
    "id": "4243",
    "label": "Myresjö Gamla Kyrkogård",
    "value": "myresjo-gamla-kyrkogard-vetlanda"
},
{
    "id": "4249",
    "label": "Nacka norra kyrkogård",
    "value": "nacka-norra-kyrkogard-nacka"
},
{
    "id": "4250",
    "label": "Nacka södra kyrkogård",
    "value": "nacka-sodra-kyrkogard-nacka"
},
{
    "id": "4251",
    "label": "Näckådalen Kyrkogård",
    "value": "nackadalen-kyrkogard-orsa"
},
{
    "id": "4255",
    "label": "Nämdö Kyrkogård",
    "value": "namdo-kyrkogard-varmdo"
},
{
    "id": "4262",
    "label": "Närtuna Kyrkogård",
    "value": "nartuna-kyrkogard-norrtalje"
},
{
    "id": "4264",
    "label": "Nårunga Kyrkogård",
    "value": "narunga-kyrkogard-vargarda"
},
{
    "id": "4265",
    "label": "Näs gamla kyrkogård",
    "value": "nas-gamla-kyrkogard-ostersund"
},
{
    "id": "4271",
    "label": "Näs kyrkogård",
    "value": "nas-kyrkogard-gotland"
},
{
    "id": "4272",
    "label": "Näs kyrkogård",
    "value": "nas-kyrkogard-eslov"
},
{
    "id": "4273",
    "label": "Näs Kyrkogård",
    "value": "nas-kyrkogard-falkoping"
},
{
    "id": "4274",
    "label": "Nås kyrkogård",
    "value": "nas-kyrkogard-vansbro"
},
{
    "id": "4275",
    "label": "Näs nya kyrkogård",
    "value": "nas-nya-kyrkogard-ostersund"
},
{
    "id": "4279",
    "label": "Näsby Kyrkogård",
    "value": "nasby-kyrkogard-vetlanda"
},
{
    "id": "4280",
    "label": "Näsby Kyrkogård",
    "value": "nasby-kyrkogard-lindesberg"
},
{
    "id": "4284",
    "label": "Näshulta kyrkogård",
    "value": "nashulta-kyrkogard-eskilstuna"
},
{
    "id": "4287",
    "label": "Näshults kyrkogård",
    "value": "nashults-kyrkogard-vetlanda"
},
{
    "id": "4290",
    "label": "Näsinge Östra kyrkogård",
    "value": "nasinge-ostra-kyrkogard-stromstad"
},
{
    "id": "4291",
    "label": "Näsinge Västra kyrkogård",
    "value": "nasinge-vastra-kyrkogard-stromstad"
},
{
    "id": "4293",
    "label": "Näskotts Kyrkogård",
    "value": "naskotts-kyrkogard-krokom"
},
{
    "id": "4295",
    "label": "Nässja kyrkogård",
    "value": "nassja-kyrkogard-vadstena"
},
{
    "id": "4299",
    "label": "Näsums kyrkogård",
    "value": "nasums-kyrkogard-bromolla"
},
{
    "id": "4305",
    "label": "Nättraby kyrkogård",
    "value": "nattraby-kyrkogard-karlskrona"
},
{
    "id": "4307",
    "label": "Naums kyrkogård",
    "value": "naums-kyrkogard-vara"
},
{
    "id": "4309",
    "label": "Nävelsjö Kyrkogård",
    "value": "navelsjo-kyrkogard-vetlanda"
},
{
    "id": "4311",
    "label": "Naverstads kyrkogård",
    "value": "naverstads-kyrkogard-tanum"
},
{
    "id": "4315",
    "label": "Nävlinge kyrkogård",
    "value": "navlinge-kyrkogard-hassleholm"
},
{
    "id": "4317",
    "label": "Nederluleå kyrkogård",
    "value": "nederlulea-kyrkogard-lulea"
},
{
    "id": "4319",
    "label": "Nedre Ulleruds kyrkogård",
    "value": "nedre-ulleruds-kyrkogard-munkfors"
},
{
    "id": "4320",
    "label": "Nelsonska begravningsplatsen",
    "value": "nelsonska-begravningsplatsen-norrkoping"
},
{
    "id": "4322",
    "label": "Nevishögs kyrkogård",
    "value": "nevishogs-kyrkogard-staffanstorp"
},
{
    "id": "4325",
    "label": "Nikolai kyrkogård",
    "value": "nikolai-kyrkogard-orebro"
},
{
    "id": "4332",
    "label": "Nittorps Kyrkogård",
    "value": "nittorps-kyrkogard-tranemo"
},
{
    "id": "4336",
    "label": "Nöbbele kyrkogård",
    "value": "nobbele-kyrkogard-vaxjo"
},
{
    "id": "4338",
    "label": "Nödinge kyrkogård",
    "value": "nodinge-kyrkogard-ale"
},
{
    "id": "4339",
    "label": "Nolby begravningsplats",
    "value": "nolby-begravningsplats-alingsas"
},
{
    "id": "4348",
    "label": "Nora kyrkogård",
    "value": "nora-kyrkogard-heby"
},
{
    "id": "4349",
    "label": "Nora Norra Kyrkogård",
    "value": "nora-norra-kyrkogard-nora"
},
{
    "id": "4350",
    "label": "Nora Södra Kyrkogård",
    "value": "nora-sodra-kyrkogard-nora"
},
{
    "id": "4353",
    "label": "Norbergs kyrkogård",
    "value": "norbergs-kyrkogard-norberg"
},
{
    "id": "4359",
    "label": "Nordmarks kyrkogård",
    "value": "nordmarks-kyrkogard-filipstad"
},
{
    "id": "4362",
    "label": "Normlösa kyrkogård",
    "value": "normlosa-kyrkogard-odeshog"
},
{
    "id": "4366",
    "label": "Norra Åkarps Kyrkogård",
    "value": "norra-akarps-kyrkogard-hassleholm"
},
{
    "id": "4369",
    "label": "Norra Åsums Kyrkogård",
    "value": "norra-asums-kyrkogard-kristianstad"
},
{
    "id": "4370",
    "label": "Norra begravningsplatsen",
    "value": "norra-begravningsplatsen-solna"
},
{
    "id": "4371",
    "label": "Norra begravningsplatsen",
    "value": "norra-begravningsplatsen-hassleholm"
},
{
    "id": "4372",
    "label": "Norra begravningsplatsen",
    "value": "norra-begravningsplatsen-ostersund"
},
{
    "id": "4373",
    "label": "Norra Begravningsplatsen",
    "value": "norra-begravningsplatsen-lidkoping"
},
{
    "id": "4374",
    "label": "Norra begravningsplatsen",
    "value": "norra-begravningsplatsen-norrkoping"
},
{
    "id": "4378",
    "label": "Norra Björke kyrkogård",
    "value": "norra-bjorke-kyrkogard-vanersborg"
},
{
    "id": "4381",
    "label": "Norra Fågelås kyrkogård",
    "value": "norra-fagelas-kyrkogard-hjo"
},
{
    "id": "4383",
    "label": "Norra Finnskoga kyrkogård",
    "value": "norra-finnskoga-kyrkogard-torsby"
},
{
    "id": "4384",
    "label": "Norra Griftegården",
    "value": "norra-griftegarden-borlange"
},
{
    "id": "4385",
    "label": "Norra griftesgården",
    "value": "norra-griftesgarden-borlange"
},
{
    "id": "4387",
    "label": "Norra Härene kyrkogård",
    "value": "norra-harene-kyrkogard-lidkoping"
},
{
    "id": "4389",
    "label": "Norra Hestra kyrkogård",
    "value": "norra-hestra-kyrkogard-gislaved"
},
{
    "id": "4392",
    "label": "Norra Kedums Kyrkogård",
    "value": "norra-kedums-kyrkogard-lidkoping"
},
{
    "id": "4395",
    "label": "Norra Kyrketorps Kyrkogård",
    "value": "norra-kyrketorps-kyrkogard-skovde"
},
{
    "id": "4396",
    "label": "Norra kyrkogården",
    "value": "norra-kyrkogarden-nora"
},
{
    "id": "4397",
    "label": "Norra kyrkogården",
    "value": "norra-kyrkogarden-lund"
},
{
    "id": "4398",
    "label": "Norra kyrkogården",
    "value": "norra-kyrkogarden-umea"
},
{
    "id": "4399",
    "label": "Norra kyrkogården",
    "value": "norra-kyrkogarden-kalmar"
},
{
    "id": "4400",
    "label": "Norra kyrkogården",
    "value": "norra-kyrkogarden"
},
{
    "id": "4401",
    "label": "Norra Kyrkogården",
    "value": "norra-kyrkogarden-trelleborg"
},
{
    "id": "4402",
    "label": "Norra Kyrkogården",
    "value": "norra-kyrkogarden"
},
{
    "id": "4403",
    "label": "Norra kyrkogården",
    "value": "norra-kyrkogarden-tidaholm"
},
{
    "id": "4404",
    "label": "Norra kyrkogården",
    "value": "norra-kyrkogarden-uddevalla"
},
{
    "id": "4405",
    "label": "Norra kyrkogården",
    "value": "norra-kyrkogarden-amal"
},
{
    "id": "4406",
    "label": "Norra kyrkogården",
    "value": "norra-kyrkogarden-askersund"
},
{
    "id": "4407",
    "label": "Norra Kyrkogården",
    "value": "norra-kyrkogarden-linkoping"
},
{
    "id": "4408",
    "label": "Norra kyrkogården Karlsborg",
    "value": "norra-kyrkogarden-karlsborg-karlsborg"
},
{
    "id": "4410",
    "label": "Norra Ljunga kyrkogård",
    "value": "norra-ljunga-kyrkogard-savsjo"
},
{
    "id": "4412",
    "label": "Norra Lundby kyrkogård",
    "value": "norra-lundby-kyrkogard-skara"
},
{
    "id": "4414",
    "label": "Norra Mellby Kyrkogård",
    "value": "norra-mellby-kyrkogard-hassleholm"
},
{
    "id": "4416",
    "label": "Norra Möckleby Kyrkogård",
    "value": "norra-mockleby-kyrkogard-morbylanga"
},
{
    "id": "4418",
    "label": "Norra Nöbbelövs kyrkogård",
    "value": "norra-nobbelovs-kyrkogard-lund"
},
{
    "id": "4420",
    "label": "Norra Ny kyrkogård",
    "value": "norra-ny-kyrkogard-torsby"
},
{
    "id": "4423",
    "label": "Norra Rörums kyrkogård",
    "value": "norra-rorums-kyrkogard-hoor"
},
{
    "id": "4425",
    "label": "Norra Sandby kyrkogård",
    "value": "norra-sandby-kyrkogard-hassleholm"
},
{
    "id": "4427",
    "label": "Norra Sandsjö Kyrkogård",
    "value": "norra-sandsjo-kyrkogard-nassjo"
},
{
    "id": "4430",
    "label": "Norra Solberga kyrkogård",
    "value": "norra-solberga-kyrkogard-nassjo"
},
{
    "id": "4432",
    "label": "Norra Strö Kyrkogård",
    "value": "norra-stro-kyrkogard-kristianstad"
},
{
    "id": "4434",
    "label": "Norra Unnaryds Kyrkogård",
    "value": "norra-unnaryds-kyrkogard-jonkoping"
},
{
    "id": "4436",
    "label": "Norra Vånga kyrkogård",
    "value": "norra-vanga-kyrkogard-vara"
},
{
    "id": "4439",
    "label": "Norra Vi kyrkogård",
    "value": "norra-vi-kyrkogard-ydre"
},
{
    "id": "4441",
    "label": "Norra Vings kyrkogård",
    "value": "norra-vings-kyrkogard-skara"
},
{
    "id": "4443",
    "label": "Norra Vrams Kyrkogård",
    "value": "norra-vrams-kyrkogard-bjuv"
},
{
    "id": "4446",
    "label": "Norrahammars kyrkogård",
    "value": "norrahammars-kyrkogard-jonkoping"
},
{
    "id": "4453",
    "label": "Norrbyås kyrkogård",
    "value": "norrbyas-kyrkogard-orebro"
},
{
    "id": "4458",
    "label": "Norrlanda kyrkogård",
    "value": "norrlanda-kyrkogard"
},
{
    "id": "4464",
    "label": "Norrsunda Kyrkogård",
    "value": "norrsunda-kyrkogard"
},
{
    "id": "4466",
    "label": "Norrtälje kyrkas kyrkogård",
    "value": "norrtalje-kyrkas-kyrkogard-norrtalje"
},
{
    "id": "4467",
    "label": "Norrtälje kyrkogård",
    "value": "norrtalje-kyrkogard-norrtalje"
},
{
    "id": "4471",
    "label": "Nors kyrkogård",
    "value": "nors-kyrkogard-karlstad"
},
{
    "id": "4473",
    "label": "Norslunds Kyrkogård",
    "value": "norslunds-kyrkogard-falkenberg"
},
{
    "id": "4476",
    "label": "Norums Kyrkogård",
    "value": "norums-kyrkogard-stenungsund"
},
{
    "id": "4478",
    "label": "Nosaby kyrkogård",
    "value": "nosaby-kyrkogard-kristianstad"
},
{
    "id": "4482",
    "label": "Nösslinge Kyrkogård",
    "value": "nosslinge-kyrkogard-varberg"
},
{
    "id": "4485",
    "label": "Nottebäcks kyrkogård",
    "value": "nottebacks-kyrkogard-uppvidinge"
},
{
    "id": "4487",
    "label": "Nöttja kyrkogård",
    "value": "nottja-kyrkogard-ljungby"
},
{
    "id": "4492",
    "label": "Nya Griftegården",
    "value": "nya-griftegarden-tranas"
},
{
    "id": "4493",
    "label": "Nya Hästbergs Kyrkogård",
    "value": "nya-hastbergs-kyrkogard-falkenberg"
},
{
    "id": "4494",
    "label": "Nya kyrkogården",
    "value": "nya-kyrkogarden-harnosand"
},
{
    "id": "4495",
    "label": "Nya Kyrkogården",
    "value": "nya-kyrkogarden-oskarshamn"
},
{
    "id": "4496",
    "label": "Nya Kyrkogården",
    "value": "nya-kyrkogarden-helsingborg"
},
{
    "id": "4497",
    "label": "Nya Kyrkogården",
    "value": "nya-kyrkogarden-nykoping"
},
{
    "id": "4498",
    "label": "Nya kyrkogården",
    "value": "nya-kyrkogarden-kristinehamn"
},
{
    "id": "4499",
    "label": "Nya Kyrkogården",
    "value": "nya-kyrkogarden-vadstena"
},
{
    "id": "4500",
    "label": "Nya Kyrkogården Öjebyn",
    "value": "nya-kyrkogarden-ojebyn-pitea"
},
{
    "id": "4502",
    "label": "Nya Norra Kyrkogården",
    "value": "nya-norra-kyrkogarden-linkoping"
},
{
    "id": "4503",
    "label": "Nya Varvets kyrkogård",
    "value": "nya-varvets-kyrkogard-goteborg"
},
{
    "id": "4506",
    "label": "Nybro Kyrkogård",
    "value": "nybro-kyrkogard-nybro"
},
{
    "id": "4509",
    "label": "Nydala Kyrkogård",
    "value": "nydala-kyrkogard-varnamo"
},
{
    "id": "4513",
    "label": "Nye kyrkogård",
    "value": "nye-kyrkogard-vetlanda"
},
{
    "id": "4518",
    "label": "Nyeds kyrkogård",
    "value": "nyeds-kyrkogard-karlstad"
},
{
    "id": "4523",
    "label": "Nykils Kyrkogård",
    "value": "nykils-kyrkogard-linkoping"
},
{
    "id": "4526",
    "label": "Nykroppa kyrkogård",
    "value": "nykroppa-kyrkogard-filipstad"
},
{
    "id": "4529",
    "label": "Nykyrka kyrkogård",
    "value": "nykyrka-kyrkogard-mullsjo"
},
{
    "id": "4530",
    "label": "Nykyrka kyrkogård",
    "value": "nykyrka-kyrkogard-nykoping"
},
{
    "id": "4532",
    "label": "Nylöse hospitalkyrkogård",
    "value": "nylose-hospitalkyrkogard-goteborg"
},
{
    "id": "4535",
    "label": "Nymö Kyrkogård",
    "value": "nymo-kyrkogard-kristianstad"
},
{
    "id": "4538",
    "label": "Nynäshamns kyrkogård",
    "value": "nynashamns-kyrkogard-nynashamn"
},
{
    "id": "4543",
    "label": "Nysätra kyrkogård",
    "value": "nysatra-kyrkogard-enkoping"
},
{
    "id": "4545",
    "label": "Nyskoga kyrkogård",
    "value": "nyskoga-kyrkogard-torsby"
},
{
    "id": "4546",
    "label": "Nysunds kyrkogård",
    "value": "nysunds-kyrkogard-degefors"
},
{
    "id": "4549",
    "label": "Ö Vemmerlövs Kyrkogård",
    "value": "o-vemmerlovs-kyrkogard-simrishamn"
},
{
    "id": "4550",
    "label": "Ö:A Fågelviks kyrkogård",
    "value": "oa-fagelviks-kyrkogard-karlstad"
},
{
    "id": "4554",
    "label": "Öckerö kyrkogård",
    "value": "ockero-kyrkogard-ockero"
},
{
    "id": "4558",
    "label": "Odarslövs kyrkogård",
    "value": "odarslovs-kyrkogard-lund"
},
{
    "id": "4561",
    "label": "Ödeborgs kyrkogård",
    "value": "odeborgs-kyrkogard-fargelanda"
},
{
    "id": "4563",
    "label": "Ödeby kyrkogård",
    "value": "odeby-kyrkogard-orebro"
},
{
    "id": "4565",
    "label": "Ödenäs kyrkogård",
    "value": "odenas-kyrkogard-alingsas"
},
{
    "id": "4567",
    "label": "Odensåkers kyrkogård",
    "value": "odensakers-kyrkogard-mariestad"
},
{
    "id": "4571",
    "label": "Odensala Kyrkogård",
    "value": "odensala-kyrkogard-sigtuna"
},
{
    "id": "4574",
    "label": "Odensjö kyrkogård",
    "value": "odensjo-kyrkogard-ljungby"
},
{
    "id": "4576",
    "label": "Odensvi kyrkogård",
    "value": "odensvi-kyrkogard-vastervik"
},
{
    "id": "4579",
    "label": "Oderljunga Kyrkogård",
    "value": "oderljunga-kyrkogard-perstorp"
},
{
    "id": "4580",
    "label": "Ödeshög gamla kyrkogård",
    "value": "odeshog-gamla-kyrkogard-odeshog"
},
{
    "id": "4581",
    "label": "Ödeshög nya kyrkogård",
    "value": "odeshog-nya-kyrkogard-odeshog"
},
{
    "id": "4583",
    "label": "Ödeshögs kyrkogård",
    "value": "odeshogs-kyrkogard-odeshog"
},
{
    "id": "4585",
    "label": "Ödestugu Kyrkogård",
    "value": "odestugu-kyrkogard-jonkoping"
},
{
    "id": "4587",
    "label": "Ods kyrkogård",
    "value": "ods-kyrkogard-herrljunga"
},
{
    "id": "4590",
    "label": "Ödskölts kyrkogård",
    "value": "odskolts-kyrkogard-bengtsfors"
},
{
    "id": "4593",
    "label": "Ödsmåls Kyrkogård",
    "value": "odsmals-kyrkogard-stenungsund"
},
{
    "id": "4597",
    "label": "Öggestorps kyrkogård",
    "value": "oggestorps-kyrkogard-jonkoping"
},
{
    "id": "4599",
    "label": "Öglunda kyrkogård",
    "value": "oglunda-kyrkogard-skara"
},
{
    "id": "4605",
    "label": "Öja kyrkogård",
    "value": "oja-kyrkogard-gotland"
},
{
    "id": "4606",
    "label": "Öja kyrkogård",
    "value": "oja-kyrkogard-vaxjo"
},
{
    "id": "4607",
    "label": "Öja Kyrkogård",
    "value": "oja-kyrkogard-ystad"
},
{
    "id": "4608",
    "label": "Öja kyrkogård",
    "value": "oja-kyrkogard-eskilstuna"
},
{
    "id": "4610",
    "label": "Öjaby kyrkogård",
    "value": "ojaby-kyrkogard-vaxjo"
},
{
    "id": "4615",
    "label": "Öjungs Kyrkogård",
    "value": "ojungs-kyrkogard-ovanaker"
},
{
    "id": "4617",
    "label": "Ökna Kyrkogård",
    "value": "okna-kyrkogard-vetlanda"
},
{
    "id": "4623",
    "label": "Olaus Petri kyrkogård",
    "value": "olaus-petri-kyrkogard-orebro"
},
{
    "id": "4625",
    "label": "Öljehults kyrkogård",
    "value": "oljehults-kyrkogard-ronneby"
},
{
    "id": "4628",
    "label": "Ölme kyrkogård",
    "value": "olme-kyrkogard-kristinehamn"
},
{
    "id": "4633",
    "label": "Ölmevalla Kyrkogård",
    "value": "olmevalla-kyrkogard-kungsbacka"
},
{
    "id": "4635",
    "label": "Ölmstad Kyrkogård",
    "value": "olmstad-kyrkogard-jonkoping"
},
{
    "id": "4639",
    "label": "Ölserud Kyrkogård",
    "value": "olserud-kyrkogard-saffle"
},
{
    "id": "4641",
    "label": "Ölseruds kyrkogård",
    "value": "olseruds-kyrkogard-saffle"
},
{
    "id": "4644",
    "label": "Ölsremma Kyrkogård",
    "value": "olsremma-kyrkogard-tranemo"
},
{
    "id": "4646",
    "label": "Önnarps Kyrkogård",
    "value": "onnarps-kyrkogard-trelleborg"
},
{
    "id": "4648",
    "label": "Önnestads Kyrkogård",
    "value": "onnestads-kyrkogard-kristianstad"
},
{
    "id": "4651",
    "label": "Onsala Kyrkogård",
    "value": "onsala-kyrkogard-kungsbacka"
},
{
    "id": "4654",
    "label": "Onslunda Kyrkogård",
    "value": "onslunda-kyrkogard-tomelilla"
},
{
    "id": "4657",
    "label": "Önums kyrkogård",
    "value": "onums-kyrkogard-vara"
},
{
    "id": "4660",
    "label": "Oppeby Kyrkogård",
    "value": "oppeby-kyrkogard-kinda"
},
{
    "id": "4663",
    "label": "Oppmanna kyrkogård",
    "value": "oppmanna-kyrkogard-kristianstad"
},
{
    "id": "4665",
    "label": "Ör kyrkogård",
    "value": "or-kyrkogard-vaxjo"
},
{
    "id": "4667",
    "label": "Öra kyrkogård",
    "value": "ora-kyrkogard-herrljunga"
},
{
    "id": "4669",
    "label": "Örberga kyrkogård",
    "value": "orberga-kyrkogard-vadstena"
},
{
    "id": "4671",
    "label": "Örby kyrkogård",
    "value": "orby-kyrkogard-mark"
},
{
    "id": "4674",
    "label": "Ore Kyrkogård",
    "value": "ore-kyrkogard-rattvik"
},
{
    "id": "4676",
    "label": "Öregrunds kyrkogård",
    "value": "oregrunds-kyrkogard-osthammar"
},
{
    "id": "4678",
    "label": "Öreryds kyrkogård",
    "value": "oreryds-kyrkogard-gislaved"
},
{
    "id": "4680",
    "label": "Örgryte Gamla kyrkogård",
    "value": "orgryte-gamla-kyrkogard-goteborg"
},
{
    "id": "4682",
    "label": "Örgryte Nya kyrkogård",
    "value": "orgryte-nya-kyrkogard-goteborg"
},
{
    "id": "4683",
    "label": "Örja Kyrkogård",
    "value": "orja-kyrkogard-landskrona"
},
{
    "id": "4686",
    "label": "Örkelljunga kyrkogård",
    "value": "orkelljunga-kyrkogard-orkelljunga"
},
{
    "id": "4688",
    "label": "Örkeneds kyrkogård",
    "value": "orkeneds-kyrkogard-osby"
},
{
    "id": "4690",
    "label": "Orkesta kyrkogård",
    "value": "orkesta-kyrkogard-vallentuna"
},
{
    "id": "4692",
    "label": "Orlunda kyrkogård",
    "value": "orlunda-kyrkogard-motala"
},
{
    "id": "4694",
    "label": "Ormesberga kyrkogård",
    "value": "ormesberga-kyrkogard-vaxjo"
},
{
    "id": "4697",
    "label": "Örnäskyrkogården",
    "value": "ornaskyrkogarden-lulea"
},
{
    "id": "4699",
    "label": "Ornö Kyrkogård",
    "value": "orno-kyrkogard-haninge"
},
{
    "id": "4704",
    "label": "Ornunga Kyrkogård",
    "value": "ornunga-kyrkogard"
},
{
    "id": "4707",
    "label": "Örs kyrkogård",
    "value": "ors-kyrkogard"
},
{
    "id": "4708",
    "label": "Orsa Gamla Kyrkogård",
    "value": "orsa-gamla-kyrkogard"
},
{
    "id": "4710",
    "label": "Orsa Nya Kyrkogård",
    "value": "orsa-nya-kyrkogard-orsa"
},
{
    "id": "4713",
    "label": "Örsås kyrkogård",
    "value": "orsas-kyrkogard-tranemo"
},
{
    "id": "4717",
    "label": "Örsjö Kyrkogård",
    "value": "orsjo-kyrkogard-nybro"
},
{
    "id": "4718",
    "label": "Örsjö kyrkogård",
    "value": "orsjo-kyrkogard-skurup"
},
{
    "id": "4720",
    "label": "Örslösa Kyrkogård",
    "value": "orslosa-kyrkogard-lidkoping"
},
{
    "id": "4725",
    "label": "Örtofta kyrkogård",
    "value": "ortofta-kyrkogard-eslov"
},
{
    "id": "4728",
    "label": "Örtomta Kyrkogård",
    "value": "ortomta-kyrkogard-linkoping"
},
{
    "id": "4730",
    "label": "Örträsk Kyrkogård",
    "value": "ortrask-kyrkogard-storuman"
},
{
    "id": "4733",
    "label": "Osby Kyrkogård",
    "value": "osby-kyrkogard-osby"
},
{
    "id": "4737",
    "label": "Oskar Kyrkogård",
    "value": "oskar-kyrkogard-nybro"
},
{
    "id": "4739",
    "label": "Oskarshamns Gamla Kyrkogård",
    "value": "oskarshamns-gamla-kyrkogard-oskarshamn"
},
{
    "id": "4743",
    "label": "Oskarshamns Västra Begravningsplats",
    "value": "oskarshamns-vastra-begravningsplats-oskarshamn"
},
{
    "id": "4746",
    "label": "Oskarströms Kyrkogård",
    "value": "oskarstroms-kyrkogard-halmstad"
},
{
    "id": "4748",
    "label": "Ösmo kyrkogård",
    "value": "osmo-kyrkogard-nynashamn"
},
{
    "id": "4751",
    "label": "Össeby-Garns kyrkogård",
    "value": "osseby-garns-kyrkogard-vallentuna"
},
{
    "id": "4753",
    "label": "Össjö Kyrkogård",
    "value": "ossjo-kyrkogard-angelholm"
},
{
    "id": "4756",
    "label": "Östad kyrkogård",
    "value": "ostad-kyrkogard-lerum"
},
{
    "id": "4761",
    "label": "Österåkers kyrkogård",
    "value": "osterakers-kyrkogard-osteraker"
},
{
    "id": "4762",
    "label": "Österåkers Kyrkogård",
    "value": "osterakers-kyrkogard-vingaker"
},
{
    "id": "4768",
    "label": "Österfärnebo kyrkogård",
    "value": "osterfarnebo-kyrkogard-sandviken"
},
{
    "id": "4770",
    "label": "Östergarns kyrkogård",
    "value": "ostergarns-kyrkogard-gotland"
},
{
    "id": "4771",
    "label": "Österhaninge begravningsplats",
    "value": "osterhaninge-begravningsplats-haninge"
},
{
    "id": "4774",
    "label": "Österheds Kyrkogård",
    "value": "osterheds-kyrkogard-gavle"
},
{
    "id": "4778",
    "label": "Österlövsta kyrkogård",
    "value": "osterlovsta-kyrkogard-tierp"
},
{
    "id": "4780",
    "label": "Österplana kyrkogård",
    "value": "osterplana-kyrkogard-gotene"
},
{
    "id": "4782",
    "label": "Österslövs kyrkogård",
    "value": "osterslovs-kyrkogard-kristianstad"
},
{
    "id": "4786",
    "label": "Österunda Kyrkogård",
    "value": "osterunda-kyrkogard-enkoping"
},
{
    "id": "4788",
    "label": "Östervåla kyrkogård",
    "value": "ostervala-kyrkogard-heby"
},
{
    "id": "4790",
    "label": "Östervallskogs kyrkogård",
    "value": "ostervallskogs-kyrkogard-arjang"
},
{
    "id": "4793",
    "label": "Östhammars kyrkogård",
    "value": "osthammars-kyrkogard-osthammar"
},
{
    "id": "4795",
    "label": "Östmarks Kyrkogård",
    "value": "ostmarks-kyrkogard-torsby"
},
{
    "id": "4797",
    "label": "Östra Ämterviks kyrkogård",
    "value": "ostra-amterviks-kyrkogard-sunne"
},
{
    "id": "4798",
    "label": "Östra begravningsplatsen",
    "value": "ostra-begravningsplatsen-hassleholm"
},
{
    "id": "4799",
    "label": "Östra begravningsplatsen",
    "value": "ostra-begravningsplatsen-ostersund"
},
{
    "id": "4800",
    "label": "Östra Begravningsplatsen",
    "value": "ostra-begravningsplatsen-kristianstad"
},
{
    "id": "4801",
    "label": "Östra begravningsplatsen",
    "value": "ostra-begravningsplatsen-mariestad"
},
{
    "id": "4803",
    "label": "Östra Broby Kyrkogård",
    "value": "ostra-broby-kyrkogard-ostra-goinge"
},
{
    "id": "4805",
    "label": "Östra Eds kyrkogård",
    "value": "ostra-eds-kyrkogard-valdemarsvik"
},
{
    "id": "4811",
    "label": "Östra Eneby kyrkogård",
    "value": "ostra-eneby-kyrkogard-norrkoping"
},
{
    "id": "4814",
    "label": "Östra Frölunda kyrkogård",
    "value": "ostra-frolunda-kyrkogard-tranemo"
},
{
    "id": "4816",
    "label": "Östra Gerums kyrkogård",
    "value": "ostra-gerums-kyrkogard-tidaholm"
},
{
    "id": "4819",
    "label": "Östra Grevie kyrkogård",
    "value": "ostra-grevie-kyrkogard-vellinge"
},
{
    "id": "4820",
    "label": "Östra Harg Kyrkogård",
    "value": "ostra-harg-kyrkogard-linkoping"
},
{
    "id": "4823",
    "label": "Östra Herrestad Kyrkogård",
    "value": "ostra-herrestad-kyrkogard-simrishamn"
},
{
    "id": "4826",
    "label": "Östra Hoby Kyrkogård",
    "value": "ostra-hoby-kyrkogard-simrishamn"
},
{
    "id": "4828",
    "label": "Östra Husby kyrkogård",
    "value": "ostra-husby-kyrkogard-norrkoping"
},
{
    "id": "4830",
    "label": "Östra Ingelstads Kyrkogård",
    "value": "ostra-ingelstads-kyrkogard-tomelilla"
},
{
    "id": "4833",
    "label": "Östra Karaby Kyrkogård",
    "value": "ostra-karaby-kyrkogard-eslov"
},
{
    "id": "4836",
    "label": "Östra Kärrstorps kyrkogård",
    "value": "ostra-karrstorps-kyrkogard-sjobo"
},
{
    "id": "4838",
    "label": "Östra Karups Kyrkogård",
    "value": "ostra-karups-kyrkogard-bastad"
},
{
    "id": "4839",
    "label": "Östra Klagstorp Kyrkogård",
    "value": "ostra-klagstorp-kyrkogard-trelleborg"
},
{
    "id": "4841",
    "label": "Östra kyrkogården",
    "value": "ostra-kyrkogarden-filipstad"
},
{
    "id": "4842",
    "label": "Östra kyrkogården",
    "value": "ostra-kyrkogarden-goteborg"
},
{
    "id": "4843",
    "label": "Östra kyrkogården",
    "value": "ostra-kyrkogarden-solvesborg"
},
{
    "id": "4844",
    "label": "Östra Kyrkogården",
    "value": "ostra-kyrkogarden-varberg"
},
{
    "id": "4845",
    "label": "Östra Kyrkogården",
    "value": "ostra-kyrkogarden-jonkoping"
},
{
    "id": "4846",
    "label": "Östra kyrkogården",
    "value": "ostra-kyrkogarden-lund"
},
{
    "id": "4847",
    "label": "Östra kyrkogården",
    "value": "ostra-kyrkogarden-malmo"
},
{
    "id": "4848",
    "label": "Östra Kyrkogården",
    "value": "ostra-kyrkogarden-nykoping"
},
{
    "id": "4849",
    "label": "Östra kyrkogården",
    "value": "ostra-kyrkogarden-karlstad"
},
{
    "id": "4850",
    "label": "Östra kyrkogården",
    "value": "ostra-kyrkogarden-tidaholm"
},
{
    "id": "4851",
    "label": "Östra kyrkogården",
    "value": "ostra-kyrkogarden-uddevalla"
},
{
    "id": "4852",
    "label": "Östra kyrkogården",
    "value": "ostra-kyrkogarden-vanersborg"
},
{
    "id": "4853",
    "label": "Östra Kyrkogården",
    "value": "ostra-kyrkogarden-karlskoga"
},
{
    "id": "4854",
    "label": "Östra Kyrkogården",
    "value": "ostra-kyrkogarden-linkoping"
},
{
    "id": "4858",
    "label": "Östra Ljungby Kyrkogård",
    "value": "ostra-ljungby-kyrkogard-klippan"
},
{
    "id": "4859",
    "label": "Östra Nöbbelöv Kyrkogård",
    "value": "ostra-nobbelov-kyrkogard-simrishamn"
},
{
    "id": "4862",
    "label": "Östra Ny kyrkogård",
    "value": "ostra-ny-kyrkogard-norrkoping"
},
{
    "id": "4866",
    "label": "Östra Ryds kyrkogård",
    "value": "ostra-ryds-kyrkogard-osteraker"
},
{
    "id": "4867",
    "label": "Östra Ryds kyrkogård",
    "value": "ostra-ryds-kyrkogard-soderkoping"
},
{
    "id": "4870",
    "label": "Östra Sallerups Kyrkogård",
    "value": "ostra-sallerups-kyrkogard-horby"
},
{
    "id": "4874",
    "label": "Östra Skrukeby Kyrkogård",
    "value": "ostra-skrukeby-kyrkogard-linkoping"
},
{
    "id": "4875",
    "label": "Östra Sönnarslöv Kyrkogård",
    "value": "ostra-sonnarslov-kyrkogard-kristianstad"
},
{
    "id": "4878",
    "label": "Östra Stenby kyrkogård",
    "value": "ostra-stenby-kyrkogard-norrkoping"
},
{
    "id": "4880",
    "label": "Östra Strö kyrkogård",
    "value": "ostra-stro-kyrkogard-eslov"
},
{
    "id": "4882",
    "label": "Östra Tollstads kyrkogård",
    "value": "ostra-tollstads-kyrkogard-odeshog"
},
{
    "id": "4884",
    "label": "Östra Tommarps Kyrkogård",
    "value": "ostra-tommarps-kyrkogard-simrishamn"
},
{
    "id": "4885",
    "label": "Östra Torp Kyrkogård",
    "value": "ostra-torp-kyrkogard-trelleborg"
},
{
    "id": "4888",
    "label": "Östra Torsås kyrkogård",
    "value": "ostra-torsas-kyrkogard-vaxjo"
},
{
    "id": "4890",
    "label": "Östra Tunhems Kyrkogård",
    "value": "ostra-tunhems-kyrkogard-falkoping"
},
{
    "id": "4892",
    "label": "Östra Vemmenhögs Kyrkogård",
    "value": "ostra-vemmenhogs-kyrkogard-skurup"
},
{
    "id": "4895",
    "label": "Östra Vingåkers kyrkogård",
    "value": "ostra-vingakers-kyrkogard-katrineholm"
},
{
    "id": "4897",
    "label": "Östra Vrams kyrkogård",
    "value": "ostra-vrams-kyrkogard-kristianstad"
},
{
    "id": "4899",
    "label": "Östraby Kyrkogård",
    "value": "ostraby-kyrkogard-horby"
},
{
    "id": "4901",
    "label": "Östuna kyrkogård",
    "value": "ostuna-kyrkogard-knivsta"
},
{
    "id": "4902",
    "label": "Othem kyrkogård",
    "value": "othem-kyrkogard-gotland"
},
{
    "id": "4905",
    "label": "Ottarps Kyrkogård",
    "value": "ottarps-kyrkogard-landskrona"
},
{
    "id": "4911",
    "label": "Öttums kyrkogård",
    "value": "ottums-kyrkogard-vara"
},
{
    "id": "4913",
    "label": "Ova kyrkogård",
    "value": "ova-kyrkogard-gotene"
},
{
    "id": "4915",
    "label": "Ovanåkers Kyrkogård",
    "value": "ovanakers-kyrkogard-ovanaker"
},
{
    "id": "4917",
    "label": "Ovansjö Kyrkogård",
    "value": "ovansjo-kyrkogard-sandviken"
},
{
    "id": "4919",
    "label": "Öveds kyrkogård",
    "value": "oveds-kyrkogard-sjobo"
},
{
    "id": "4921",
    "label": "Överenhörna kyrkogård",
    "value": "overenhorna-kyrkogard-sodertalje"
},
{
    "id": "4923",
    "label": "Övergrans Kyrkogård",
    "value": "overgrans-kyrkogard-habo"
},
{
    "id": "4928",
    "label": "Överjärna kyrkogård",
    "value": "overjarna-kyrkogard-sodertalje"
},
{
    "id": "4937",
    "label": "Överselö kyrkogård",
    "value": "overselo-kyrkogard-strangnas"
},
{
    "id": "4945",
    "label": "Övraby Kyrkogård",
    "value": "ovraby-kyrkogard-tomelilla"
},
{
    "id": "4949",
    "label": "Övre Ulleruds kyrkogård",
    "value": "ovre-ulleruds-kyrkogard-munkfors"
},
{
    "id": "4953",
    "label": "Öxabäcks Kyrkogård",
    "value": "oxabacks-kyrkogard-mark"
},
{
    "id": "4955",
    "label": "Oxbergs Kyrkogård",
    "value": "oxbergs-kyrkogard"
},
{
    "id": "4956",
    "label": "Oxelösunds kyrkogård",
    "value": "oxelosunds-kyrkogard"
},
{
    "id": "4958",
    "label": "Oxie kyrkogård",
    "value": "oxie-kyrkogard"
},
{
    "id": "4961",
    "label": "Öxnevalla kyrkogård",
    "value": "oxnevalla-kyrkogard"
},
{
    "id": "4968",
    "label": "Pålsjö Kyrkogård",
    "value": "palsjo-kyrkogard-helsingborg"
},
{
    "id": "4969",
    "label": "Pålsjö Västra begravningsplats",
    "value": "palsjo-vastra-begravningsplats-helsingborg"
},
{
    "id": "4971",
    "label": "Parkkyrkogården",
    "value": "parkkyrkogarden-vetlanda"
},
{
    "id": "4973",
    "label": "Partille Kyrkogård",
    "value": "partille-kyrkogard-partille"
},
{
    "id": "4974",
    "label": "Partille Södra Griftegård",
    "value": "partille-sodra-griftegard-partille"
},
{
    "id": "4976",
    "label": "Påskallaviks Kyrkogård",
    "value": "paskallaviks-kyrkogard-oskarshamn"
},
{
    "id": "4981",
    "label": "Pelarne kyrkogård",
    "value": "pelarne-kyrkogard-vimmerby"
},
{
    "id": "4985",
    "label": "Persnäs kyrkogård",
    "value": "persnas-kyrkogard-borgholm"
},
{
    "id": "4987",
    "label": "Perstorps Kyrkogård",
    "value": "perstorps-kyrkogard-perstorp"
},
{
    "id": "5003",
    "label": "Piteå kyrkogård",
    "value": "pitea-kyrkogard-pitea"
},
{
    "id": "5006",
    "label": "Pjätteryds kyrkogård",
    "value": "pjatteryds-kyrkogard-almhult"
},
{
    "id": "5007",
    "label": "Poikkijärvi Kyrkogård",
    "value": "poikkijarvi-kyrkogard-kiruna"
},
{
    "id": "5030",
    "label": "Råå Kyrkogård",
    "value": "raa-kyrkogard-helsingborg"
},
{
    "id": "5033",
    "label": "Råbergets Kyrkogård",
    "value": "rabergets-kyrkogard-ljusdal"
},
{
    "id": "5036",
    "label": "Råby-Rekarne kyrkogård",
    "value": "raby-rekarne-kyrkogard-eskilstuna"
},
{
    "id": "5038",
    "label": "Råby-Rönö kyrkogård",
    "value": "raby-rono-kyrkogard-nykoping"
},
{
    "id": "5040",
    "label": "Rackeby kyrkogård",
    "value": "rackeby-kyrkogard-lidkoping"
},
{
    "id": "5041",
    "label": "Råcksta begravningsplats",
    "value": "racksta-begravningsplats-stockholm"
},
{
    "id": "5044",
    "label": "Råda Kyrkogård",
    "value": "rada-kyrkogard-harryda"
},
{
    "id": "5045",
    "label": "Råda Östra Kyrkogård",
    "value": "rada-ostra-kyrkogard-lidkoping"
},
{
    "id": "5046",
    "label": "Råda Västra Kyrkogård",
    "value": "rada-vastra-kyrkogard-lidkoping"
},
{
    "id": "5049",
    "label": "Rådmansö kyrkogård",
    "value": "radmanso-kyrkogard-norrtalje"
},
{
    "id": "5052",
    "label": "Råggärds kyrkogård",
    "value": "raggards-kyrkogard-fargelanda"
},
{
    "id": "5058",
    "label": "Ramdala kyrkogård",
    "value": "ramdala-kyrkogard-karlskrona"
},
{
    "id": "5061",
    "label": "Ramkvilla Kyrkogård",
    "value": "ramkvilla-kyrkogard-vetlanda"
},
{
    "id": "5063",
    "label": "Rämmens kyrkogård",
    "value": "rammens-kyrkogard-filipstad"
},
{
    "id": "5065",
    "label": "Ramnäs Kyrkogård",
    "value": "ramnas-kyrkogard-surahammar"
},
{
    "id": "5066",
    "label": "Ramneröds kyrkogård",
    "value": "ramnerods-kyrkogard-uddevalla"
},
{
    "id": "5068",
    "label": "Ramsåsa Kyrkogård",
    "value": "ramsasa-kyrkogard-tomelilla"
},
{
    "id": "5069",
    "label": "Ramsåsa Nya Kyrkogård",
    "value": "ramsasa-nya-kyrkogard-tomelilla"
},
{
    "id": "5075",
    "label": "Ramsta Kyrkogård",
    "value": "ramsta-kyrkogard-uppsala"
},
{
    "id": "5078",
    "label": "Rånäs Kyrkogård",
    "value": "ranas-kyrkogard-norrtalje"
},
{
    "id": "5080",
    "label": "Råneå kyrkogård",
    "value": "ranea-kyrkogard-lulea"
},
{
    "id": "5082",
    "label": "Rångedala kyrkogård",
    "value": "rangedala-kyrkogard-boras"
},
{
    "id": "5084",
    "label": "Rängs Kyrkogård",
    "value": "rangs-kyrkogard-vellinge"
},
{
    "id": "5086",
    "label": "Rännelanda kyrkogård",
    "value": "rannelanda-kyrkogard-fargelanda"
},
{
    "id": "5088",
    "label": "Ränneslövs Kyrkogård",
    "value": "ranneslovs-kyrkogard-laholm"
},
{
    "id": "5090",
    "label": "Ransäters Kyrkogård",
    "value": "ransaters-kyrkogard-munkfors"
},
{
    "id": "5092",
    "label": "Ransbergs gamla kyrkogård",
    "value": "ransbergs-gamla-kyrkogard-tibro"
},
{
    "id": "5094",
    "label": "Ransbergs nya kyrkogård",
    "value": "ransbergs-nya-kyrkogard-tibro"
},
{
    "id": "5096",
    "label": "Rappestads kyrkogård",
    "value": "rappestads-kyrkogard-linkoping"
},
{
    "id": "5098",
    "label": "Räpplinge kyrkogård",
    "value": "rapplinge-kyrkogard-borgholm"
},
{
    "id": "5101",
    "label": "Rasbo kyrkogård",
    "value": "rasbo-kyrkogard-uppsala"
},
{
    "id": "5103",
    "label": "Rasbokils kyrkogård",
    "value": "rasbokils-kyrkogard-uppsala"
},
{
    "id": "5111",
    "label": "Rättviks Kyrkogård",
    "value": "rattviks-kyrkogard-rattvik"
},
{
    "id": "5113",
    "label": "Raus Kyrkogård",
    "value": "raus-kyrkogard-helsingborg"
},
{
    "id": "5115",
    "label": "Rävinge Kyrkogård",
    "value": "ravinge-kyrkogard-halmstad"
},
{
    "id": "5117",
    "label": "Ravlunda Kyrkogård",
    "value": "ravlunda-kyrkogard-simrishamn"
},
{
    "id": "5120",
    "label": "Rebbelberga Kyrkogård",
    "value": "rebbelberga-kyrkogard-angelholm"
},
{
    "id": "5122",
    "label": "Redslareds kyrkogård",
    "value": "redslareds-kyrkogard-tranemo"
},
{
    "id": "5124",
    "label": "Reftele Kyrkogård",
    "value": "reftele-kyrkogard-gislaved"
},
{
    "id": "5126",
    "label": "Regna Kyrkogård",
    "value": "regna-kyrkogard-finspang"
},
{
    "id": "5128",
    "label": "Rejmyre Kyrkogård",
    "value": "rejmyre-kyrkogard-finspang"
},
{
    "id": "5129",
    "label": "Remmarlövs gamla kyrkogård",
    "value": "remmarlovs-gamla-kyrkogard-eslov"
},
{
    "id": "5131",
    "label": "Remmarlövs kyrkogård",
    "value": "remmarlovs-kyrkogard-eslov"
},
{
    "id": "5133",
    "label": "Remmene kyrkogård",
    "value": "remmene-kyrkogard-herrljunga"
},
{
    "id": "5135",
    "label": "Rengsjö Kyrkogård",
    "value": "rengsjo-kyrkogard-bollnas"
},
{
    "id": "5140",
    "label": "Reslövs Gamla kyrkogård",
    "value": "reslovs-gamla-kyrkogard-eslov"
},
{
    "id": "5142",
    "label": "Reslövs Nya Kyrkogård",
    "value": "reslovs-nya-kyrkogard-eslov"
},
{
    "id": "5145",
    "label": "Resmo Kyrkogård",
    "value": "resmo-kyrkogard-morbylanga"
},
{
    "id": "5147",
    "label": "Resö kyrkogård",
    "value": "reso-kyrkogard-tanum"
},
{
    "id": "5148",
    "label": "Restads kyrkogård",
    "value": "restads-kyrkogard-vanersborg"
},
{
    "id": "5150",
    "label": "Resteröds kyrkogård",
    "value": "resterods-kyrkogard-uddevalla"
},
{
    "id": "5152",
    "label": "Revesjö kyrkogård",
    "value": "revesjo-kyrkogard-tranemo"
},
{
    "id": "5154",
    "label": "Revinge Kyrkogård",
    "value": "revinge-kyrkogard-lund"
},
{
    "id": "5158",
    "label": "Riala kyrkogård",
    "value": "riala-kyrkogard-norrtalje"
},
{
    "id": "5159",
    "label": "Rimbo Johannes Kyrkogård",
    "value": "rimbo-johannes-kyrkogard-norrtalje"
},
{
    "id": "5161",
    "label": "Rimbo Kyrkogård",
    "value": "rimbo-kyrkogard-norrtalje"
},
{
    "id": "5163",
    "label": "Ringamåla kyrkogård",
    "value": "ringamala-kyrkogard-karlshamn"
},
{
    "id": "5165",
    "label": "Ringarums kyrkogård",
    "value": "ringarums-kyrkogard-valdemarsvik"
},
{
    "id": "5169",
    "label": "Rinkaby Kyrkogård",
    "value": "rinkaby-kyrkogard-kristianstad"
},
{
    "id": "5170",
    "label": "Rinkaby kyrkogård",
    "value": "rinkaby-kyrkogard-orebro"
},
{
    "id": "5174",
    "label": "Rinna kyrkogård",
    "value": "rinna-kyrkogard-boxholm"
},
{
    "id": "5177",
    "label": "Ripsa kyrkogård",
    "value": "ripsa-kyrkogard-nykoping"
},
{
    "id": "5180",
    "label": "Riseberga Kyrkogård",
    "value": "riseberga-kyrkogard-klippan"
},
{
    "id": "5182",
    "label": "Risekatslösa Kyrkogård",
    "value": "risekatslosa-kyrkogard-bjuv"
},
{
    "id": "5186",
    "label": "Rö Kyrkogård",
    "value": "ro-kyrkogard-norrtalje"
},
{
    "id": "5188",
    "label": "Roasjö kyrkogård",
    "value": "roasjo-kyrkogard-tranemo"
},
{
    "id": "5191",
    "label": "Röbäcks kyrkogård",
    "value": "robacks-kyrkogard-umea"
},
{
    "id": "5195",
    "label": "Rockesholms Kyrkogård",
    "value": "rockesholms-kyrkogard-nora"
},
{
    "id": "5197",
    "label": "Rödaleds Begravningsplats",
    "value": "rodaleds-begravningsplats-kristianstad"
},
{
    "id": "5200",
    "label": "Rödbo kyrkogård",
    "value": "rodbo-kyrkogard-goteborg"
},
{
    "id": "5202",
    "label": "Röddinge Kyrkogård",
    "value": "roddinge-kyrkogard"
},
{
    "id": "5204",
    "label": "Rödeby Kyrkogård",
    "value": "rodeby-kyrkogard"
},
{
    "id": "5207",
    "label": "Rödöns Kyrkogård",
    "value": "rodons-kyrkogard"
},
{
    "id": "5210",
    "label": "Röds Kyrkogård",
    "value": "rods-kyrkogard"
},
{
    "id": "5212",
    "label": "Rogberga kyrkogård",
    "value": "rogberga-kyrkogard"
},
{
    "id": "5214",
    "label": "Rogslösa kyrkogård",
    "value": "rogslosa-kyrkogard-vadstena"
},
{
    "id": "5219",
    "label": "Röke kyrkogård",
    "value": "roke-kyrkogard-hassleholm"
},
{
    "id": "5221",
    "label": "Röks kyrkogård",
    "value": "roks-kyrkogard-odeshog"
},
{
    "id": "5225",
    "label": "Rolfstorps Kyrkogård",
    "value": "rolfstorps-kyrkogard-varberg"
},
{
    "id": "5227",
    "label": "Roma kyrkogård",
    "value": "roma-kyrkogard-gotland"
},
{
    "id": "5229",
    "label": "Romelanda kyrkogård",
    "value": "romelanda-kyrkogard-kungalv"
},
{
    "id": "5232",
    "label": "Rommele kyrkogård",
    "value": "rommele-kyrkogard-trollhattan"
},
{
    "id": "5235",
    "label": "Rone Kyrkogård",
    "value": "rone-kyrkogard-gotland"
},
{
    "id": "5237",
    "label": "Rönnängs Kyrkogård",
    "value": "ronnangs-kyrkogard-tjorn"
},
{
    "id": "5241",
    "label": "Rönö kyrkogård",
    "value": "rono-kyrkogard-norrkoping"
},
{
    "id": "5245",
    "label": "Röra kyrkogård",
    "value": "rora-kyrkogard-orust"
},
{
    "id": "5252",
    "label": "Rosentorps Kyrkogård",
    "value": "rosentorps-kyrkogard-orsa"
},
{
    "id": "5254",
    "label": "Roslags-Kulla kyrkogård",
    "value": "roslags-kulla-kyrkogard-osteraker"
},
{
    "id": "5256",
    "label": "Roslagsbro kyrkogård",
    "value": "roslagsbro-kyrkogard-norrtalje"
},
{
    "id": "5260",
    "label": "Röstångas Kyrkogård",
    "value": "rostangas-kyrkogard-svalov"
},
{
    "id": "5264",
    "label": "Ruds kyrkogård",
    "value": "ruds-kyrkogard-karlstad"
},
{
    "id": "5266",
    "label": "Rudskoga kyrkogård",
    "value": "rudskoga-kyrkogard-kristinehamn"
},
{
    "id": "5267",
    "label": "Rullbo Kyrkogård",
    "value": "rullbo-kyrkogard-ljusdal"
},
{
    "id": "5269",
    "label": "Rumskulla kyrkogård",
    "value": "rumskulla-kyrkogard-vimmerby"
},
{
    "id": "5272",
    "label": "Runmarö Kyrkogård",
    "value": "runmaro-kyrkogard-varmdo"
},
{
    "id": "5274",
    "label": "Runsten kyrkogård",
    "value": "runsten-kyrkogard-borgholm"
},
{
    "id": "5280",
    "label": "Runtuna kyrkogård",
    "value": "runtuna-kyrkogard-nykoping"
},
{
    "id": "5282",
    "label": "Rute kyrkogård",
    "value": "rute-kyrkogard-gotland"
},
{
    "id": "5286",
    "label": "Rya kyrkogård",
    "value": "rya-kyrkogard-orkelljunga"
},
{
    "id": "5288",
    "label": "Ryda kyrkogård",
    "value": "ryda-kyrkogard-vara"
},
{
    "id": "5290",
    "label": "Rydaholms Kyrkogård",
    "value": "rydaholms-kyrkogard-varnamo"
},
{
    "id": "5294",
    "label": "Rydöbruks Kyrkogård",
    "value": "rydobruks-kyrkogard-hylte"
},
{
    "id": "5299",
    "label": "Ryssby kyrkogård",
    "value": "ryssby-kyrkogard-kalmar"
},
{
    "id": "5300",
    "label": "Ryssby kyrkogård",
    "value": "ryssby-kyrkogard-ljungby"
},
{
    "id": "5302",
    "label": "Rystad Kyrkogård",
    "value": "rystad-kyrkogard-linkoping"
},
{
    "id": "5306",
    "label": "S.t Eskils Kyrkogård",
    "value": "s-t-eskils-kyrkogard-eskilstuna"
},
{
    "id": "5317",
    "label": "S:t Anna Kyrkogård",
    "value": "st-anna-kyrkogard-soderkoping"
},
{
    "id": "5321",
    "label": "S:t Ansgars griftegård",
    "value": "st-ansgars-griftegard-boras"
},
{
    "id": "5323",
    "label": "S:t Botvids begravningsplats",
    "value": "st-botvids-begravningsplats-huddinge"
},
{
    "id": "5328",
    "label": "S:t Clemens Kyrkogård",
    "value": "st-clemens-kyrkogard-laholm"
},
{
    "id": "5360",
    "label": "S:t Johannes kyrkogård",
    "value": "st-johannes-kyrkogard-norrkoping"
},
{
    "id": "5361",
    "label": "S:t Jörgens Kyrkogård",
    "value": "st-jorgens-kyrkogard-varberg"
},
{
    "id": "5362",
    "label": "S:t Jörgens Kyrkogård Stenåsa",
    "value": "st-jorgens-kyrkogard-stenasa-varberg"
},
{
    "id": "5373",
    "label": "S:t Lars kyrkogård",
    "value": "st-lars-kyrkogard-eksjo"
},
{
    "id": "5374",
    "label": "S:t Lars Kyrkogård",
    "value": "st-lars-kyrkogard-bollnas"
},
{
    "id": "5380",
    "label": "S:t Laurentii Kyrkogård",
    "value": "st-laurentii-kyrkogard-falkenberg"
},
{
    "id": "5381",
    "label": "S:t Laurentii Kyrkogård",
    "value": "st-laurentii-kyrkogard-soderkoping"
},
{
    "id": "5428",
    "label": "S:t Olofs Kyrkogård",
    "value": "st-olofs-kyrkogard-vellinge"
},
{
    "id": "5429",
    "label": "S:t Olofs kyrkogård",
    "value": "st-olofs-kyrkogard-enkoping"
},
{
    "id": "5430",
    "label": "S:t Olofs Kyrkogård",
    "value": "st-olofs-kyrkogard-sundsvall"
},
{
    "id": "5431",
    "label": "S:t Olofs Kyrkogård",
    "value": "st-olofs-kyrkogard-falkoping"
},
{
    "id": "5444",
    "label": "S:t Pauli mellersta kyrkogård",
    "value": "st-pauli-mellersta-kyrkogard-malmo"
},
{
    "id": "5445",
    "label": "S:t Pauli norra kyrkogård",
    "value": "st-pauli-norra-kyrkogard-malmo"
},
{
    "id": "5446",
    "label": "S:t Pauli södra kyrkogård",
    "value": "st-pauli-sodra-kyrkogard-malmo"
},
{
    "id": "5448",
    "label": "S:t Peder Kyrkogård",
    "value": "st-peder-kyrkogard-ale"
},
{
    "id": "5458",
    "label": "S:t Peters Kloster kyrkogård",
    "value": "st-peters-kloster-kyrkogard"
},
{
    "id": "5470",
    "label": "S:t Sigfrids griftegård",
    "value": "st-sigfrids-griftegard-boras"
},
{
    "id": "5474",
    "label": "S:t Sigfrids Kyrkogård",
    "value": "st-sigfrids-kyrkogard-nybro"
},
{
    "id": "5475",
    "label": "S:t Sigfrids Kyrkogård",
    "value": "st-sigfrids-kyrkogard-skovde"
},
{
    "id": "5496",
    "label": "S:ta Birgittas griftegård",
    "value": "sta-birgittas-griftegard-boras"
},
{
    "id": "5499",
    "label": "S:ta Birgittas Kyrkogård",
    "value": "sta-birgittas-kyrkogard-skovde"
},
{
    "id": "5501",
    "label": "S:ta Elins Kyrkogård",
    "value": "sta-elins-kyrkogard-skovde"
},
{
    "id": "5529",
    "label": "Säbrå Kyrkogård",
    "value": "sabra-kyrkogard-harnosand"
},
{
    "id": "5530",
    "label": "Säby begravningsplats",
    "value": "saby-begravningsplats-salem"
},
{
    "id": "5536",
    "label": "Säby Kyrkogård",
    "value": "saby-kyrkogard-tranas"
},
{
    "id": "5537",
    "label": "Säby Kyrkogård",
    "value": "saby-kyrkogard-landskrona"
},
{
    "id": "5553",
    "label": "Saleby kyrkogård",
    "value": "saleby-kyrkogard-lidkoping"
},
{
    "id": "5556",
    "label": "Salems kyrkogård",
    "value": "salems-kyrkogard-salem"
},
{
    "id": "5559",
    "label": "Sällstorps Kyrkogård",
    "value": "sallstorps-kyrkogard-varberg"
},
{
    "id": "5561",
    "label": "Sals kyrkogård",
    "value": "sals-kyrkogard-grastorp"
},
{
    "id": "5572",
    "label": "Sanda kyrkogård",
    "value": "sanda-kyrkogard-gotland"
},
{
    "id": "5575",
    "label": "Sandarne Kyrkogård",
    "value": "sandarne-kyrkogard-soderhamn"
},
{
    "id": "5577",
    "label": "Sandbogens kyrkogård",
    "value": "sandbogens-kyrkogard-sotenas"
},
{
    "id": "5579",
    "label": "Sandby Kyrkogård",
    "value": "sandby-kyrkogard-morbylanga"
},
{
    "id": "5581",
    "label": "Sandhamns Kyrkogård",
    "value": "sandhamns-kyrkogard-varmdo"
},
{
    "id": "5584",
    "label": "Sandhems kyrkogård",
    "value": "sandhems-kyrkogard-mullsjo"
},
{
    "id": "5586",
    "label": "Sandhults kyrkogård",
    "value": "sandhults-kyrkogard-boras"
},
{
    "id": "5587",
    "label": "Sandö kyrkogård",
    "value": "sando-kyrkogard-sotenas"
},
{
    "id": "5589",
    "label": "Sandsborgskyrkogården",
    "value": "sandsborgskyrkogarden-stockholm"
},
{
    "id": "5593",
    "label": "Sandseryds kyrkogård",
    "value": "sandseryds-kyrkogard-jonkoping"
},
{
    "id": "5594",
    "label": "Sandsjö Kyrkogård",
    "value": "sandsjo-kyrkogard-ljusdal"
},
{
    "id": "5599",
    "label": "Sandviks Kyrkogård",
    "value": "sandviks-kyrkogard-gislaved"
},
{
    "id": "5603",
    "label": "Sånga kyrkogård",
    "value": "sanga-kyrkogard-ekero"
},
{
    "id": "5606",
    "label": "Sankt Halvards kyrkogård",
    "value": "sankt-halvards-kyrkogard-kungalv"
},
{
    "id": "5607",
    "label": "Sankt Ibbs Kyrkogård",
    "value": "sankt-ibbs-kyrkogard-landskrona"
},
{
    "id": "5608",
    "label": "Sankt Jörgens kyrkogård",
    "value": "sankt-jorgens-kyrkogard-goteborg"
},
{
    "id": "5612",
    "label": "Sanne Kyrkogård",
    "value": "sanne-kyrkogard-munkedal"
},
{
    "id": "5615",
    "label": "Särestad-Bjärby kyrkogård",
    "value": "sarestad-bjarby-kyrkogard-grastorp"
},
{
    "id": "5620",
    "label": "Särslövs kyrkogård",
    "value": "sarslovs-kyrkogard-staffanstorp"
},
{
    "id": "5625",
    "label": "Säters kyrkogård",
    "value": "saters-kyrkogard-sater"
},
{
    "id": "5626",
    "label": "Säters Kyrkogård",
    "value": "saters-kyrkogard"
},
{
    "id": "5627",
    "label": "Säters kyrkogård",
    "value": "saters-kyrkogard-skovde"
},
{
    "id": "5629",
    "label": "Sätila kyrkogård",
    "value": "satila-kyrkogard-mark"
},
{
    "id": "5634",
    "label": "Sättersta kyrkogård",
    "value": "sattersta-kyrkogard-nykoping"
},
{
    "id": "5639",
    "label": "Sävare kyrkogård",
    "value": "savare-kyrkogard-lidkoping"
},
{
    "id": "5641",
    "label": "Säve kyrkogård",
    "value": "save-kyrkogard-goteborg"
},
{
    "id": "5647",
    "label": "Saxemara kyrkogård",
    "value": "saxemara-kyrkogard-ronneby"
},
{
    "id": "5649",
    "label": "Saxnäs kyrkogård",
    "value": "saxnas-kyrkogard-storuman"
},
{
    "id": "5651",
    "label": "Saxtorps kyrkogård",
    "value": "saxtorps-kyrkogard-landskrona"
},
{
    "id": "5655",
    "label": "Segersta Kyrkogård",
    "value": "segersta-kyrkogard-bollnas"
},
{
    "id": "5656",
    "label": "Segerstad Kyrkogård",
    "value": "segerstad-kyrkogard-falkoping"
},
{
    "id": "5660",
    "label": "Segerstads Kyrkogård",
    "value": "segerstads-kyrkogard-morbylanga"
},
{
    "id": "5661",
    "label": "Segerstads kyrkogård",
    "value": "segerstads-kyrkogard-karlstad"
},
{
    "id": "5663",
    "label": "Seglora kyrkogård",
    "value": "seglora-kyrkogard-boras"
},
{
    "id": "5665",
    "label": "Selångers Kyrkogård",
    "value": "selangers-kyrkogard-sundsvall"
},
{
    "id": "5668",
    "label": "Seskarö Kyrkogård",
    "value": "seskaro-kyrkogard-haparanda"
},
{
    "id": "5671",
    "label": "Sexdrega kyrkogård",
    "value": "sexdrega-kyrkogard-tranemo"
},
{
    "id": "5673",
    "label": "Sibbarps Kyrkogård",
    "value": "sibbarps-kyrkogard-varberg"
},
{
    "id": "5675",
    "label": "Sibbhults Kyrkogård",
    "value": "sibbhults-kyrkogard-ostra-goinge"
},
{
    "id": "5680",
    "label": "Siene Kyrkogård",
    "value": "siene-kyrkogard-vargarda"
},
{
    "id": "5681",
    "label": "Sigelhults kyrkogård",
    "value": "sigelhults-kyrkogard-uddevalla"
},
{
    "id": "5682",
    "label": "Sigtuna Kyrkogård",
    "value": "sigtuna-kyrkogard-sigtuna"
},
{
    "id": "5684",
    "label": "Siknäs Kyrkogård",
    "value": "siknas-kyrkogard-mora"
},
{
    "id": "5686",
    "label": "Silbodals kyrkogård",
    "value": "silbodals-kyrkogard-arjang"
},
{
    "id": "5689",
    "label": "Siljansnäs kyrkogård",
    "value": "siljansnas-kyrkogard-leksand"
},
{
    "id": "5691",
    "label": "Silleruds kyrkogård",
    "value": "silleruds-kyrkogard-arjang"
},
{
    "id": "5694",
    "label": "Sillhövda Kyrkogård",
    "value": "sillhovda-kyrkogard-karlskrona"
},
{
    "id": "5696",
    "label": "Silte kyrkogård",
    "value": "silte-kyrkogard-gotland"
},
{
    "id": "5699",
    "label": "Silvåkra kyrkogård",
    "value": "silvakra-kyrkogard-lund"
},
{
    "id": "5701",
    "label": "Silvbergs Kyrkogård",
    "value": "silvbergs-kyrkogard-sater"
},
{
    "id": "5702",
    "label": "Silverdals griftegård",
    "value": "silverdals-griftegard"
},
{
    "id": "5705",
    "label": "Simlinge kyrkogård",
    "value": "simlinge-kyrkogard"
},
{
    "id": "5708",
    "label": "Simonstorps kyrkogård",
    "value": "simonstorps-kyrkogard"
},
{
    "id": "5710",
    "label": "Simris Kyrkogård",
    "value": "simris-kyrkogard"
},
{
    "id": "5711",
    "label": "Simrishamn G:a",
    "value": "simrishamn-ga"
},
{
    "id": "5712",
    "label": "Simrishamns Norra Kyrkogård",
    "value": "simrishamns-norra-kyrkogard"
},
{
    "id": "5714",
    "label": "Simtuna Kyrkogård",
    "value": "simtuna-kyrkogard-enkoping"
},
{
    "id": "5716",
    "label": "Singö kyrkogård",
    "value": "singo-kyrkogard-norrtalje"
},
{
    "id": "5718",
    "label": "Sireköpinge Kyrkogård",
    "value": "sirekopinge-kyrkogard-svalov"
},
{
    "id": "5723",
    "label": "Själevads Kyrkogård",
    "value": "sjalevads-kyrkogard-ornskoldsvik"
},
{
    "id": "5727",
    "label": "Sjogerstads Kyrkogård",
    "value": "sjogerstads-kyrkogard-skovde"
},
{
    "id": "5729",
    "label": "Sjögestads kyrkogård",
    "value": "sjogestads-kyrkogard-linkoping"
},
{
    "id": "5733",
    "label": "Sjonhems kyrkogård",
    "value": "sjonhems-kyrkogard-gotland"
},
{
    "id": "5735",
    "label": "Sjörups Kyrkogård",
    "value": "sjorups-kyrkogard-ystad"
},
{
    "id": "5738",
    "label": "Sjösås gamla kyrkogård",
    "value": "sjosas-gamla-kyrkogard-vaxjo"
},
{
    "id": "5739",
    "label": "Sjösås kyrkogård",
    "value": "sjosas-kyrkogard-vaxjo"
},
{
    "id": "5743",
    "label": "Sjötofta Kyrkogård",
    "value": "sjotofta-kyrkogard-tranemo"
},
{
    "id": "5745",
    "label": "Sjötorps Kyrkogård",
    "value": "sjotorps-kyrkogard-gullspang"
},
{
    "id": "5768",
    "label": "Skå kyrkogård",
    "value": "ska-kyrkogard-ekero"
},
{
    "id": "5770",
    "label": "Skabersjö kyrkogård",
    "value": "skabersjo-kyrkogard-svedala"
},
{
    "id": "5772",
    "label": "Skäfthammars kyrkogård",
    "value": "skafthammars-kyrkogard-osthammar"
},
{
    "id": "5778",
    "label": "Skagershults kyrkogård",
    "value": "skagershults-kyrkogard-laxa"
},
{
    "id": "5782",
    "label": "Skålleruds kyrkogård",
    "value": "skalleruds-kyrkogard-mellerud"
},
{
    "id": "5785",
    "label": "Skällinge Kyrkogård",
    "value": "skallinge-kyrkogard-varberg"
},
{
    "id": "5787",
    "label": "Skallmeja kyrkogård",
    "value": "skallmeja-kyrkogard-skara"
},
{
    "id": "5790",
    "label": "Skallsjö kyrkogård",
    "value": "skallsjo-kyrkogard-lerum"
},
{
    "id": "5792",
    "label": "Skällviks Kyrkogård",
    "value": "skallviks-kyrkogard-soderkoping"
},
{
    "id": "5795",
    "label": "Skalunda kyrkogård",
    "value": "skalunda-kyrkogard-lidkoping"
},
{
    "id": "5797",
    "label": "Skälvums kyrkogård",
    "value": "skalvums-kyrkogard-gotene"
},
{
    "id": "5799",
    "label": "Skånela Kyrkogård",
    "value": "skanela-kyrkogard-sigtuna"
},
{
    "id": "5805",
    "label": "Skånings-Åsaka kyrkogård",
    "value": "skanings-asaka-kyrkogard-skara"
},
{
    "id": "5806",
    "label": "Skänninge kyrkogård",
    "value": "skanninge-kyrkogard-odeshog"
},
{
    "id": "5808",
    "label": "Skanörs kyrkogård",
    "value": "skanors-kyrkogard-vellinge"
},
{
    "id": "5814",
    "label": "Skårby Kyrkogård",
    "value": "skarby-kyrkogard-ystad"
},
{
    "id": "5820",
    "label": "Skarhults kyrkogård",
    "value": "skarhults-kyrkogard-eslov"
},
{
    "id": "5823",
    "label": "Skärkinds kyrkogård",
    "value": "skarkinds-kyrkogard-norrkoping"
},
{
    "id": "5825",
    "label": "Skärstad Kyrkogård",
    "value": "skarstad-kyrkogard-jonkoping"
},
{
    "id": "5828",
    "label": "Skarstads kyrkogård",
    "value": "skarstads-kyrkogard-vara"
},
{
    "id": "5831",
    "label": "Skärvs kyrkogård",
    "value": "skarvs-kyrkogard-skara"
},
{
    "id": "5833",
    "label": "Skatelövs kyrkogård",
    "value": "skatelovs-kyrkogard-alvesta"
},
{
    "id": "5835",
    "label": "Skattunge Kyrkogård",
    "value": "skattunge-kyrkogard-orsa"
},
{
    "id": "5840",
    "label": "Skavnäsets kyrkogård",
    "value": "skavnasets-kyrkogard-filipstad"
},
{
    "id": "5842",
    "label": "Skeby kyrkogård",
    "value": "skeby-kyrkogard-gotene"
},
{
    "id": "5844",
    "label": "Skeda kyrkogård",
    "value": "skeda-kyrkogard-linkoping"
},
{
    "id": "5847",
    "label": "Skede Kyrkogård",
    "value": "skede-kyrkogard-vetlanda"
},
{
    "id": "5849",
    "label": "Skederids Kyrkogård",
    "value": "skederids-kyrkogard-norrtalje"
},
{
    "id": "5851",
    "label": "Skedevi Kyrkogård",
    "value": "skedevi-kyrkogard-finspang"
},
{
    "id": "5854",
    "label": "Skee kyrkogård",
    "value": "skee-kyrkogard-stromstad"
},
{
    "id": "5857",
    "label": "Skegrie Kyrkogård",
    "value": "skegrie-kyrkogard-trelleborg"
},
{
    "id": "5859",
    "label": "Skellefteå S:t Örjan Kyrkogård",
    "value": "skelleftea-st-orjan-kyrkogard-skelleftea"
},
{
    "id": "5862",
    "label": "Skephults kyrkogård",
    "value": "skephults-kyrkogard-mark"
},
{
    "id": "5864",
    "label": "Skepparslövs kyrkogård",
    "value": "skepparslovs-kyrkogard-kristianstad"
},
{
    "id": "5867",
    "label": "Skepperstads kyrkogård",
    "value": "skepperstads-kyrkogard-savsjo"
},
{
    "id": "5870",
    "label": "Skepplanda Kyrkogård",
    "value": "skepplanda-kyrkogard-ale"
},
{
    "id": "5872",
    "label": "Skeppsås kyrkogård",
    "value": "skeppsas-kyrkogard-odeshog"
},
{
    "id": "5874",
    "label": "Skepptuna Kyrkogård",
    "value": "skepptuna-kyrkogard-sigtuna"
},
{
    "id": "5880",
    "label": "Skillingmarks kyrkogård",
    "value": "skillingmarks-kyrkogard-eda"
},
{
    "id": "5881",
    "label": "Skinnskatteberg Kyrkogård",
    "value": "skinnskatteberg-kyrkogard-skinnskatteberg"
},
{
    "id": "5885",
    "label": "Skirö Kyrkogård",
    "value": "skiro-kyrkogard-vetlanda"
},
{
    "id": "5887",
    "label": "Skivarps Kyrkogård",
    "value": "skivarps-kyrkogard-skurup"
},
{
    "id": "5890",
    "label": "Skogaby Kyrkogård",
    "value": "skogaby-kyrkogard-laholm"
},
{
    "id": "5909",
    "label": "Skogskyrkogården",
    "value": "skogskyrkogarden-stockholm"
},
{
    "id": "5910",
    "label": "Skogskyrkogården",
    "value": "skogskyrkogarden-eksjo"
},
{
    "id": "5911",
    "label": "Skogskyrkogården",
    "value": "skogskyrkogarden-kalix"
},
{
    "id": "5912",
    "label": "Skogskyrkogården",
    "value": "skogskyrkogarden-gavle"
},
{
    "id": "5913",
    "label": "Skogskyrkogården",
    "value": "skogskyrkogarden-falkenberg"
},
{
    "id": "5914",
    "label": "Skogskyrkogården",
    "value": "skogskyrkogarden"
},
{
    "id": "5915",
    "label": "Skogskyrkogården",
    "value": "skogskyrkogarden-jonkoping"
},
{
    "id": "5916",
    "label": "Skogskyrkogården",
    "value": "skogskyrkogarden-vaggeryd"
},
{
    "id": "5917",
    "label": "Skogskyrkogården",
    "value": "skogskyrkogarden-vetlanda"
},
{
    "id": "5918",
    "label": "Skogskyrkogården",
    "value": "skogskyrkogarden-kalmar"
},
{
    "id": "5919",
    "label": "Skogskyrkogården",
    "value": "skogskyrkogarden-mosteras"
},
{
    "id": "5920",
    "label": "Skogskyrkogården",
    "value": "skogskyrkogarden-pitea"
},
{
    "id": "5921",
    "label": "Skogskyrkogården",
    "value": "skogskyrkogarden-katrineholm"
},
{
    "id": "5922",
    "label": "Skogskyrkogården",
    "value": "skogskyrkogarden-kungalv"
},
{
    "id": "5923",
    "label": "Skogskyrkogården",
    "value": "skogskyrkogarden-karlskoga"
},
{
    "id": "5924",
    "label": "Skogskyrkogården Malå",
    "value": "skogskyrkogarden-mala-mala"
},
{
    "id": "5926",
    "label": "Skogslyckans kyrkogård",
    "value": "skogslyckans-kyrkogard-vaxjo"
},
{
    "id": "5928",
    "label": "Skogsö kyrkogård",
    "value": "skogso-kyrkogard-nacka"
},
{
    "id": "5929",
    "label": "Skogsö Kyrkogård",
    "value": "skogso-kyrkogard-laxa"
},
{
    "id": "5932",
    "label": "Skogs-Tibble kyrkogård",
    "value": "skogs-tibble-kyrkogard-uppsala"
},
{
    "id": "5933",
    "label": "Skokloster Kyrkogård",
    "value": "skokloster-kyrkogard-habo"
},
{
    "id": "5936",
    "label": "Sköldinge kyrkogård",
    "value": "skoldinge-kyrkogard-katrineholm"
},
{
    "id": "5938",
    "label": "Sköllersta kyrkogård",
    "value": "skollersta-kyrkogard-hallsberg"
},
{
    "id": "5940",
    "label": "Skölvene kyrkogård",
    "value": "skolvene-kyrkogard-herrljunga"
},
{
    "id": "5943",
    "label": "Skönberga Kyrkogård",
    "value": "skonberga-kyrkogard-soderkoping"
},
{
    "id": "5945",
    "label": "Sköns Norra Kyrkogård",
    "value": "skons-norra-kyrkogard-sundsvall"
},
{
    "id": "5946",
    "label": "Sköns Södra Kyrkogård",
    "value": "skons-sodra-kyrkogard-sundsvall"
},
{
    "id": "5950",
    "label": "Skörstorp Kyrkogård",
    "value": "skorstorp-kyrkogard-falkoping"
},
{
    "id": "5955",
    "label": "Skrea Kyrkogård",
    "value": "skrea-kyrkogard"
},
{
    "id": "5958",
    "label": "Skredsviks Kyrkogård",
    "value": "skredsviks-kyrkogard"
},
{
    "id": "5962",
    "label": "Skummeslövs Kyrkogård",
    "value": "skummeslovs-kyrkogard"
},
{
    "id": "5964",
    "label": "Skurups Kyrkogård",
    "value": "skurups-kyrkogard"
},
{
    "id": "5965",
    "label": "Skutskär Kyrkogård",
    "value": "skutskar-kyrkogard-alvkarleby"
},
{
    "id": "5969",
    "label": "Skuttunge Kyrkogård",
    "value": "skuttunge-kyrkogard-uppsala"
},
{
    "id": "5972",
    "label": "Slädene kyrkogård",
    "value": "sladene-kyrkogard-vara"
},
{
    "id": "5974",
    "label": "Slagnäs Kyrkogård",
    "value": "slagnas-kyrkogard-arjeplog"
},
{
    "id": "5976",
    "label": "Slaka kyrkogård",
    "value": "slaka-kyrkogard-linkoping"
},
{
    "id": "5978",
    "label": "Släps Kyrkogård",
    "value": "slaps-kyrkogard-kungsbacka"
},
{
    "id": "5981",
    "label": "Slättåkra Kyrkogård",
    "value": "slattakra-kyrkogard-halmstad"
},
{
    "id": "5983",
    "label": "Slätthögs kyrkogård",
    "value": "slatthogs-kyrkogard-alvesta"
},
{
    "id": "5985",
    "label": "Slimminge kyrkogård",
    "value": "slimminge-kyrkogard-skurup"
},
{
    "id": "5990",
    "label": "Slöta Kyrkogård",
    "value": "slota-kyrkogard-falkoping"
},
{
    "id": "5995",
    "label": "Slottskyrkogården",
    "value": "slottskyrkogarden-solvesborg"
},
{
    "id": "5996",
    "label": "Slottskyrkogården",
    "value": "slottskyrkogarden-jonkoping"
},
{
    "id": "5998",
    "label": "Smålandsstenars Kyrkogård",
    "value": "smalandsstenars-kyrkogard-gislaved"
},
{
    "id": "6000",
    "label": "Smälla Griftegård",
    "value": "smalla-griftegard-borlange"
},
{
    "id": "6001",
    "label": "Smälla griftesgård",
    "value": "smalla-griftesgard-borlange"
},
{
    "id": "6004",
    "label": "Smedby Kyrkogård",
    "value": "smedby-kyrkogard-morbylanga"
},
{
    "id": "6006",
    "label": "Smedstorps Kyrkogård",
    "value": "smedstorps-kyrkogard-tomelilla"
},
{
    "id": "6009",
    "label": "Snårestads Kyrkogård",
    "value": "snarestads-kyrkogard-ystad"
},
{
    "id": "6011",
    "label": "Snavlunda kyrkogård",
    "value": "snavlunda-kyrkogard-askersund"
},
{
    "id": "6015",
    "label": "Snöstorps Kyrkogård",
    "value": "snostorps-kyrkogard-halmstad"
},
{
    "id": "6016",
    "label": "Sockenskyrkans kyrkogård",
    "value": "sockenskyrkans-kyrkogard-hallsberg"
},
{
    "id": "6039",
    "label": "Söderbykarls kyrkogård",
    "value": "soderbykarls-kyrkogard-norrtalje"
},
{
    "id": "6041",
    "label": "Söderfors kyrkogård",
    "value": "soderfors-kyrkogard-tierp"
},
{
    "id": "6042",
    "label": "Söderhamns Kyrkogård",
    "value": "soderhamns-kyrkogard-soderhamn"
},
{
    "id": "6047",
    "label": "Södertälje kyrkogård",
    "value": "sodertalje-kyrkogard-sodertalje"
},
{
    "id": "6049",
    "label": "Södervidinge kyrkogård",
    "value": "sodervidinge-kyrkogard-kavlinge"
},
{
    "id": "6051",
    "label": "Södra Åby Kyrkogård",
    "value": "sodra-aby-kyrkogard-trelleborg"
},
{
    "id": "6053",
    "label": "Södra Åkarps kyrkogård",
    "value": "sodra-akarps-kyrkogard-vellinge"
},
{
    "id": "6056",
    "label": "Södra Åsarps kyrkogård",
    "value": "sodra-asarps-kyrkogard-tranemo"
},
{
    "id": "6058",
    "label": "Södra Åsums Gamla Kyrkogård",
    "value": "sodra-asums-gamla-kyrkogard-sjobo"
},
{
    "id": "6060",
    "label": "Södra Åsums Nya Kyrkogård",
    "value": "sodra-asums-nya-kyrkogard-sjobo"
},
{
    "id": "6061",
    "label": "Södra begravningsplatsen",
    "value": "sodra-begravningsplatsen-lidkoping"
},
{
    "id": "6062",
    "label": "Södra begravningsplatsen",
    "value": "sodra-begravningsplatsen-mariestad"
},
{
    "id": "6063",
    "label": "Södra begravningsplatsen",
    "value": "sodra-begravningsplatsen-norrkoping"
},
{
    "id": "6065",
    "label": "Södra Bergnäs Kyrkogård",
    "value": "sodra-bergnas-kyrkogard-arjeplog"
},
{
    "id": "6067",
    "label": "Södra Björke kyrkogård",
    "value": "sodra-bjorke-kyrkogard-herrljunga"
},
{
    "id": "6070",
    "label": "Södra Fågelås kyrkogård",
    "value": "sodra-fagelas-kyrkogard-hjo"
},
{
    "id": "6072",
    "label": "Södra Finnskoga kyrkogård",
    "value": "sodra-finnskoga-kyrkogard-torsby"
},
{
    "id": "6074",
    "label": "Södra Härene Kyrkogård",
    "value": "sodra-harene-kyrkogard-vargarda"
},
{
    "id": "6076",
    "label": "Södra Hestra Kyrkogård",
    "value": "sodra-hestra-kyrkogard-gislaved"
},
{
    "id": "6079",
    "label": "Södra Kedums kyrkogård",
    "value": "sodra-kedums-kyrkogard-vara"
},
{
    "id": "6080",
    "label": "Södra kyrkogården",
    "value": "sodra-kyrkogarden-nora"
},
{
    "id": "6081",
    "label": "Södra Kyrkogården",
    "value": "sodra-kyrkogarden-sandviken"
},
{
    "id": "6082",
    "label": "Södra Kyrkogården",
    "value": "sodra-kyrkogarden-kalmar"
},
{
    "id": "6083",
    "label": "Södra kyrkogården",
    "value": "sodra-kyrkogarden-amal"
},
{
    "id": "6084",
    "label": "Södra kyrkogården",
    "value": "sodra-kyrkogarden-askersund"
},
{
    "id": "6085",
    "label": "Södra kyrkogården",
    "value": "sodra-kyrkogarden-lindesberg"
},
{
    "id": "6086",
    "label": "Södra Kyrkogården",
    "value": "sodra-kyrkogarden-linkoping"
},
{
    "id": "6087",
    "label": "Södra kyrkogården Karlsborg",
    "value": "sodra-kyrkogarden-karlsborg-karlsborg"
},
{
    "id": "6090",
    "label": "Södra Ljunga kyrkogård",
    "value": "sodra-ljunga-kyrkogard-ljungby"
},
{
    "id": "6092",
    "label": "Södra Lundby kyrkogård",
    "value": "sodra-lundby-kyrkogard-vara"
},
{
    "id": "6094",
    "label": "Södra Mellby Kyrkogård",
    "value": "sodra-mellby-kyrkogard-simrishamn"
},
{
    "id": "6096",
    "label": "Södra Möckleby Kyrkogård",
    "value": "sodra-mockleby-kyrkogard-morbylanga"
},
{
    "id": "6098",
    "label": "Södra Ny kyrkogård",
    "value": "sodra-ny-kyrkogard-saffle"
},
{
    "id": "6100",
    "label": "Södra Råda Kyrkogård",
    "value": "sodra-rada-kyrkogard-gullspang"
},
{
    "id": "6104",
    "label": "Södra Rörums Kyrkogård",
    "value": "sodra-rorums-kyrkogard-horby"
},
{
    "id": "6106",
    "label": "Södra Sallerups kyrkogård",
    "value": "sodra-sallerups-kyrkogard-malmo"
},
{
    "id": "6108",
    "label": "Södra Sandby Kyrkogård",
    "value": "sodra-sandby-kyrkogard-lund"
},
{
    "id": "6109",
    "label": "Södra Sandsjö kyrkogård",
    "value": "sodra-sandsjo-kyrkogard-tingsryd"
},
{
    "id": "6111",
    "label": "Södra Solberga kyrkogård",
    "value": "sodra-solberga-kyrkogard-vetlanda"
},
{
    "id": "6114",
    "label": "Södra Vi kyrkogård",
    "value": "sodra-vi-kyrkogard-vimmerby"
},
{
    "id": "6116",
    "label": "Södra Vings Kyrkogård",
    "value": "sodra-vings-kyrkogard-ulricehamn"
},
{
    "id": "6127",
    "label": "Solberga kyrkogård",
    "value": "solberga-kyrkogard-skurup"
},
{
    "id": "6128",
    "label": "Solberga Kyrkogård",
    "value": "solberga-kyrkogard-falkoping"
},
{
    "id": "6129",
    "label": "Solberga Kyrkogård",
    "value": "solberga-kyrkogard-stenungsund"
},
{
    "id": "6139",
    "label": "Sollentuna kyrkogård",
    "value": "sollentuna-kyrkogard-sollentuna"
},
{
    "id": "6140",
    "label": "Sollerö Kyrkogård",
    "value": "sollero-kyrkogard-mora"
},
{
    "id": "6146",
    "label": "Solna kyrkogård",
    "value": "solna-kyrkogard-solna"
},
{
    "id": "6148",
    "label": "Sommens Skogskyrkog",
    "value": "sommens-skogskyrkog-tranas"
},
{
    "id": "6151",
    "label": "Söne Kyrkogård",
    "value": "sone-kyrkogard-lidkoping"
},
{
    "id": "6154",
    "label": "Söraby kyrkogård",
    "value": "soraby-kyrkogard-vaxjo"
},
{
    "id": "6159",
    "label": "Sörby kyrkogård",
    "value": "sorby-kyrkogard-hassleholm"
},
{
    "id": "6160",
    "label": "Sörby Kyrkogård",
    "value": "sorby-kyrkogard-falkoping"
},
{
    "id": "6163",
    "label": "Sorsele Gamla Kyrkogård",
    "value": "sorsele-gamla-kyrkogard-mala"
},
{
    "id": "6165",
    "label": "Sorsele Skogskyrkogård",
    "value": "sorsele-skogskyrkogard-mala"
},
{
    "id": "6168",
    "label": "Sorunda Kyrkogård",
    "value": "sorunda-kyrkogard-nynashamn"
},
{
    "id": "6171",
    "label": "Sövde Kyrkogård",
    "value": "sovde-kyrkogard-sjobo"
},
{
    "id": "6174",
    "label": "Sövestads Kyrkogård",
    "value": "sovestads-kyrkogard-ystad"
},
{
    "id": "6177",
    "label": "Spånga kyrkogård",
    "value": "spanga-kyrkogard-stockholm"
},
{
    "id": "6180",
    "label": "Spannarboda Kyrkogård",
    "value": "spannarboda-kyrkogard-lindesberg"
},
{
    "id": "6182",
    "label": "Spannarps Kyrkogård",
    "value": "spannarps-kyrkogard-varberg"
},
{
    "id": "6184",
    "label": "Sparlösa kyrkogård",
    "value": "sparlosa-kyrkogard-vara"
},
{
    "id": "6187",
    "label": "Sparrsätra kyrkogård",
    "value": "sparrsatra-kyrkogard-enkoping"
},
{
    "id": "6190",
    "label": "Spekeröds kyrkogård",
    "value": "spekerods-kyrkogard-stenungsund"
},
{
    "id": "6194",
    "label": "Spjutstorp Kyrkogård",
    "value": "spjutstorp-kyrkogard-tomelilla"
},
{
    "id": "6197",
    "label": "Sproge kyrkogård",
    "value": "sproge-kyrkogard-gotland"
},
{
    "id": "6203",
    "label": "St. Herrestads Nya Kyrkogård",
    "value": "st-herrestads-nya-kyrkogard-ystad"
},
{
    "id": "6204",
    "label": "St. Köpinge Nya Kyrkogård",
    "value": "st-kopinge-nya-kyrkogard"
},
{
    "id": "6206",
    "label": "Stadskyrkogården",
    "value": "stadskyrkogarden"
},
{
    "id": "6214",
    "label": "Stala kyrkogård",
    "value": "stala-kyrkogard"
},
{
    "id": "6218",
    "label": "Stamnared Kyrkogård",
    "value": "stamnared-kyrkogard-varberg"
},
{
    "id": "6222",
    "label": "Stampens kyrkogård",
    "value": "stampens-kyrkogard-goteborg"
},
{
    "id": "6225",
    "label": "Stångby kyrkogård",
    "value": "stangby-kyrkogard-lund"
},
{
    "id": "6227",
    "label": "Starby Kyrkogård",
    "value": "starby-kyrkogard-angelholm"
},
{
    "id": "6229",
    "label": "Starrkärrs kyrkogård",
    "value": "starrkarrs-kyrkogard-ale"
},
{
    "id": "6231",
    "label": "Stavby kyrkogård",
    "value": "stavby-kyrkogard-uppsala"
},
{
    "id": "6233",
    "label": "Stävie Kyrkogård",
    "value": "stavie-kyrkogard-kavlinge"
},
{
    "id": "6235",
    "label": "Stavnäs Kyrkogård",
    "value": "stavnas-kyrkogard-arvika"
},
{
    "id": "6242",
    "label": "Stehags kyrkogård",
    "value": "stehags-kyrkogard-eslov"
},
{
    "id": "6246",
    "label": "Stenåsa Kyrkogård",
    "value": "stenasa-kyrkogard-morbylanga"
},
{
    "id": "6249",
    "label": "Stenberga kyrkogård",
    "value": "stenberga-kyrkogard-vetlanda"
},
{
    "id": "6253",
    "label": "Stenbrohults Kyrkogård",
    "value": "stenbrohults-kyrkogard-almhult"
},
{
    "id": "6256",
    "label": "Steneby kyrkogård",
    "value": "steneby-kyrkogard-bengtsfors"
},
{
    "id": "6258",
    "label": "Stenestads Kyrkogård",
    "value": "stenestads-kyrkogard-svalov"
},
{
    "id": "6260",
    "label": "Stengårdshults kyrkogård",
    "value": "stengardshults-kyrkogard-gislaved"
},
{
    "id": "6266",
    "label": "Stenkumla kyrkogård",
    "value": "stenkumla-kyrkogard-gotland"
},
{
    "id": "6269",
    "label": "Stenkvista kyrkogård",
    "value": "stenkvista-kyrkogard-eskilstuna"
},
{
    "id": "6270",
    "label": "Stenkyrka kyrkogård",
    "value": "stenkyrka-kyrkogard-gotland"
},
{
    "id": "6271",
    "label": "Stenkyrka Kyrkogård",
    "value": "stenkyrka-kyrkogard-tjorn"
},
{
    "id": "6275",
    "label": "Stensele kyrkogård",
    "value": "stensele-kyrkogard-storuman"
},
{
    "id": "6278",
    "label": "Stenstorp Kyrkogård",
    "value": "stenstorp-kyrkogard-falkoping"
},
{
    "id": "6281",
    "label": "Stenums kyrkogård",
    "value": "stenums-kyrkogard-skara"
},
{
    "id": "6284",
    "label": "Stiby Kyrkogård",
    "value": "stiby-kyrkogard-simrishamn"
},
{
    "id": "6290",
    "label": "Stigsjö kyrkogård",
    "value": "stigsjo-kyrkogard-harnosand"
},
{
    "id": "6292",
    "label": "Stigtomta kyrkogård",
    "value": "stigtomta-kyrkogard-nykoping"
},
{
    "id": "6299",
    "label": "Stjärnorps kyrkogård",
    "value": "stjarnorps-kyrkogard-linkoping"
},
{
    "id": "6302",
    "label": "Stoby begravningsplats",
    "value": "stoby-begravningsplats-hassleholm"
},
{
    "id": "6305",
    "label": "Stoby kyrkogård",
    "value": "stoby-kyrkogard-hassleholm"
},
{
    "id": "6307",
    "label": "Stockaryds kyrkogård",
    "value": "stockaryds-kyrkogard-savsjo"
},
{
    "id": "6312",
    "label": "Stöde Kyrkogård",
    "value": "stode-kyrkogard-sundsvall"
},
{
    "id": "6315",
    "label": "Stora Åby kyrkogård",
    "value": "stora-aby-kyrkogard-odeshog"
},
{
    "id": "6316",
    "label": "Stora Askeröns Kyrkogård",
    "value": "stora-askerons-kyrkogard-stenungsund"
},
{
    "id": "6319",
    "label": "Stora Hammars Gamla Kyrkogård",
    "value": "stora-hammars-gamla-kyrkogard-vellinge"
},
{
    "id": "6321",
    "label": "Stora Hammars Kyrkogård",
    "value": "stora-hammars-kyrkogard-vellinge"
},
{
    "id": "6323",
    "label": "Stora Harrie kyrkogård",
    "value": "stora-harrie-kyrkogard-kavlinge"
},
{
    "id": "6327",
    "label": "Stora Kils kyrkogård",
    "value": "stora-kils-kyrkogard-kil"
},
{
    "id": "6333",
    "label": "Stora Lundby kyrkogård",
    "value": "stora-lundby-kyrkogard-lerum"
},
{
    "id": "6336",
    "label": "Stora Malms kyrkogård",
    "value": "stora-malms-kyrkogard-katrineholm"
},
{
    "id": "6338",
    "label": "Stora Mellby Kyrkogård",
    "value": "stora-mellby-kyrkogard-trollhattan"
},
{
    "id": "6340",
    "label": "Stora Mellösa G:a kyrkogård",
    "value": "stora-mellosa-ga-kyrkogard-orebro"
},
{
    "id": "6342",
    "label": "Stora Mellösa N:a kyrkogård",
    "value": "stora-mellosa-na-kyrkogard-orebro"
},
{
    "id": "6344",
    "label": "Stora Råby kyrkogård",
    "value": "stora-raby-kyrkogard-lund"
},
{
    "id": "6347",
    "label": "Stora Skedvi Kyrkogård",
    "value": "stora-skedvi-kyrkogard-sater"
},
{
    "id": "6351",
    "label": "Stora Slågarps Kyrkogård",
    "value": "stora-slagarps-kyrkogard-trelleborg"
},
{
    "id": "6354",
    "label": "Stora Tuna kyrkogård",
    "value": "stora-tuna-kyrkogard-borlange"
},
{
    "id": "6355",
    "label": "Stora Tuna Östra",
    "value": "stora-tuna-ostra-borlange"
},
{
    "id": "6357",
    "label": "Storbo Begravningsplats",
    "value": "storbo-begravningsplats-alvdalen"
},
{
    "id": "6359",
    "label": "Storfjätens Begravningsplats",
    "value": "storfjatens-begravningsplats-alvdalen"
},
{
    "id": "6361",
    "label": "Storfors Kyrkogård",
    "value": "storfors-kyrkogard-storfors"
},
{
    "id": "6364",
    "label": "Storkällans begravningsplats",
    "value": "storkallans-begravningsplats-nacka"
},
{
    "id": "6367",
    "label": "Storsäterns Begravningsplats",
    "value": "storsaterns-begravningsplats-alvdalen"
},
{
    "id": "6377",
    "label": "Strå kyrkogård",
    "value": "stra-kyrkogard-vadstena"
},
{
    "id": "6380",
    "label": "Strandkyrkogården",
    "value": "strandkyrkogarden-tyreso"
},
{
    "id": "6381",
    "label": "Strandkyrkogården",
    "value": "strandkyrkogarden-vanersborg"
},
{
    "id": "6383",
    "label": "Strängnäs gamla kyrkogård",
    "value": "strangnas-gamla-kyrkogard-strangnas"
},
{
    "id": "6384",
    "label": "Strängnäs södra kyrkogård",
    "value": "strangnas-sodra-kyrkogard-strangnas"
},
{
    "id": "6386",
    "label": "Strängsereds kyrkogård",
    "value": "strangsereds-kyrkogard-ulricehamn"
},
{
    "id": "6390",
    "label": "Strö kyrkogård",
    "value": "stro-kyrkogard-lidkoping"
},
{
    "id": "6402",
    "label": "Strömstads kyrkogård",
    "value": "stromstads-kyrkogard-stromstad"
},
{
    "id": "6404",
    "label": "Strömsunds Kyrkogård",
    "value": "stromsunds-kyrkogard-stromsund"
},
{
    "id": "6406",
    "label": "Strövelstorp Kyrkogård",
    "value": "strovelstorp-kyrkogard-angelholm"
},
{
    "id": "6412",
    "label": "Stuguns nya kyrkogård",
    "value": "stuguns-nya-kyrkogard-ragunda"
},
{
    "id": "6415",
    "label": "Sturkö kyrkogård",
    "value": "sturko-kyrkogard-karlskrona"
},
{
    "id": "6419",
    "label": "Styrsö kyrkogård",
    "value": "styrso-kyrkogard-goteborg"
},
{
    "id": "6423",
    "label": "Styrstads kyrkogård",
    "value": "styrstads-kyrkogard-norrkoping"
},
{
    "id": "6427",
    "label": "Sundals-Ryrs gamla kyrkogård",
    "value": "sundals-ryrs-gamla-kyrkogard-vanersborg"
},
{
    "id": "6429",
    "label": "Sundals-Ryrs nya kyrkogård",
    "value": "sundals-ryrs-nya-kyrkogard-vanersborg"
},
{
    "id": "6433",
    "label": "Sundby kyrkogård",
    "value": "sundby-kyrkogard-eskilstuna"
},
{
    "id": "6434",
    "label": "Sundbybergs begravningsplats",
    "value": "sundbybergs-begravningsplats-sundbyberg"
},
{
    "id": "6438",
    "label": "Sundre kyrkogård",
    "value": "sundre-kyrkogard-gotland"
},
{
    "id": "6440",
    "label": "Sunds kyrkogård",
    "value": "sunds-kyrkogard-ydre"
},
{
    "id": "6445",
    "label": "Sundsvalls Gustav Adolfs Kyrkogård",
    "value": "sundsvalls-gustav-adolfs-kyrkogard-sundsvall"
},
{
    "id": "6446",
    "label": "Sundsvalls skogskyrkogård",
    "value": "sundsvalls-skogskyrkogard-sundsvall"
},
{
    "id": "6450",
    "label": "Sunne kyrkogård",
    "value": "sunne-kyrkogard-sunne"
},
{
    "id": "6452",
    "label": "Sunnemo kyrkogård",
    "value": "sunnemo-kyrkogard-hagfors"
},
{
    "id": "6454",
    "label": "Sunnersbergs kyrkogård",
    "value": "sunnersbergs-kyrkogard-lidkoping"
},
{
    "id": "6458",
    "label": "Suntaks kyrkogård",
    "value": "suntaks-kyrkogard"
},
{
    "id": "6462",
    "label": "Surte kyrkogård",
    "value": "surte-kyrkogard"
},
{
    "id": "6464",
    "label": "Surteby Kyrkogård",
    "value": "surteby-kyrkogard"
},
{
    "id": "6466",
    "label": "Svabensverks Kyrkogård",
    "value": "svabensverks-kyrkogard"
},
{
    "id": "6470",
    "label": "Svalövs Kyrkogård",
    "value": "svalovs-kyrkogard-svalov"
},
{
    "id": "6475",
    "label": "Svanshals kyrkogård",
    "value": "svanshals-kyrkogard-odeshog"
},
{
    "id": "6476",
    "label": "Svanskog Kyrkogård",
    "value": "svanskog-kyrkogard-saffle"
},
{
    "id": "6480",
    "label": "Svanskogs kyrkogård",
    "value": "svanskogs-kyrkogard-saffle"
},
{
    "id": "6483",
    "label": "Svappavaara Kyrkogård",
    "value": "svappavaara-kyrkogard-kiruna"
},
{
    "id": "6485",
    "label": "Svärdsjö Kyrkogård",
    "value": "svardsjo-kyrkogard-falu"
},
{
    "id": "6487",
    "label": "Svärta kyrkogård",
    "value": "svarta-kyrkogard-nykoping"
},
{
    "id": "6490",
    "label": "Svarteborgs Kyrkogård",
    "value": "svarteborgs-kyrkogard-munkedal"
},
{
    "id": "6493",
    "label": "Svartnäs Kyrkogård",
    "value": "svartnas-kyrkogard-falu"
},
{
    "id": "6497",
    "label": "Svarttorps Kyrkogård",
    "value": "svarttorps-kyrkogard-jonkoping"
},
{
    "id": "6500",
    "label": "Svedala Kyrkogård",
    "value": "svedala-kyrkogard-svedala"
},
{
    "id": "6504",
    "label": "Svenarums Kyrkogård",
    "value": "svenarums-kyrkogard-vaggeryd"
},
{
    "id": "6507",
    "label": "Sveneby kyrkogård",
    "value": "sveneby-kyrkogard-gullspang"
},
{
    "id": "6510",
    "label": "Svenljunga kyrkogård",
    "value": "svenljunga-kyrkogard-tranemo"
},
{
    "id": "6512",
    "label": "Svenneby gamla kyrkogård",
    "value": "svenneby-gamla-kyrkogard-tanum"
},
{
    "id": "6514",
    "label": "Svenneby nya kyrkogård",
    "value": "svenneby-nya-kyrkogard-tanum"
},
{
    "id": "6516",
    "label": "Svennevads kyrkogård",
    "value": "svennevads-kyrkogard-hallsberg"
},
{
    "id": "6520",
    "label": "Svenska högarnas kyrkogård",
    "value": "svenska-hogarnas-kyrkogard-norrtalje"
},
{
    "id": "6524",
    "label": "Svensköps Kyrkogård",
    "value": "svenskops-kyrkogard-horby"
},
{
    "id": "6526",
    "label": "Svenstorps Kyrkogård",
    "value": "svenstorps-kyrkogard-skurup"
},
{
    "id": "6528",
    "label": "Sventorps Kyrkogård",
    "value": "sventorps-kyrkogard-skovde"
},
{
    "id": "6531",
    "label": "Svinhults kyrkogård",
    "value": "svinhults-kyrkogard-ydre"
},
{
    "id": "6533",
    "label": "Svinnegarns kyrkogård",
    "value": "svinnegarns-kyrkogard-enkoping"
},
{
    "id": "6535",
    "label": "Sya kyrkogård",
    "value": "sya-kyrkogard-odeshog"
},
{
    "id": "6537",
    "label": "Synnerby kyrkogård",
    "value": "synnerby-kyrkogard-skara"
},
{
    "id": "6542",
    "label": "Täby kyrkogård",
    "value": "taby-kyrkogard-orebro"
},
{
    "id": "6543",
    "label": "Tåby kyrkogård",
    "value": "taby-kyrkogard-norrkoping"
},
{
    "id": "6544",
    "label": "Täby kyrkogård ",
    "value": "taby-kyrkogard-taby"
},
{
    "id": "6549",
    "label": "Tådene Kyrkogård",
    "value": "tadene-kyrkogard-lidkoping"
},
{
    "id": "6554",
    "label": "Tämta kyrkogård",
    "value": "tamta-kyrkogard-boras"
},
{
    "id": "6556",
    "label": "Tandsjöborg Kyrkogård",
    "value": "tandsjoborg-kyrkogard-ljusdal"
},
{
    "id": "6558",
    "label": "Tångens kyrkogård",
    "value": "tangens-kyrkogard-orust"
},
{
    "id": "6561",
    "label": "Tångeråsa kyrkogård",
    "value": "tangerasa-kyrkogard-lekeberg"
},
{
    "id": "6562",
    "label": "Tångeråsa kyrkogård",
    "value": "tangerasa-kyrkogard"
},
{
    "id": "6564",
    "label": "Tannåkers kyrkogård",
    "value": "tannakers-kyrkogard-ljungby"
},
{
    "id": "6568",
    "label": "Tånnö Kyrkogård",
    "value": "tanno-kyrkogard-varnamo"
},
{
    "id": "6571",
    "label": "Tåns kyrkogård",
    "value": "tans-kyrkogard-goteborg"
},
{
    "id": "6573",
    "label": "Tanums kyrkogård",
    "value": "tanums-kyrkogard-tanum"
},
{
    "id": "6575",
    "label": "Tärby kyrkogård",
    "value": "tarby-kyrkogard-boras"
},
{
    "id": "6581",
    "label": "Tåsjö Kyrkogård",
    "value": "tasjo-kyrkogard-stromsund"
},
{
    "id": "6583",
    "label": "Tåssjö Kyrkogård",
    "value": "tassjo-kyrkogard-angelholm"
},
{
    "id": "6585",
    "label": "Tåstarps Kyrkogård",
    "value": "tastarps-kyrkogard-angelholm"
},
{
    "id": "6587",
    "label": "Tävelsås kyrkogård",
    "value": "tavelsas-kyrkogard-vaxjo"
},
{
    "id": "6589",
    "label": "Tavelsjö gamla kyrkogård",
    "value": "tavelsjo-gamla-kyrkogard-umea"
},
{
    "id": "6591",
    "label": "Tavelsjö kyrkogård",
    "value": "tavelsjo-kyrkogard-umea"
},
{
    "id": "6594",
    "label": "Taxinge kyrkogård",
    "value": "taxinge-kyrkogard-nykvarn"
},
{
    "id": "6597",
    "label": "Teda kyrkogård",
    "value": "teda-kyrkogard-enkoping"
},
{
    "id": "6599",
    "label": "Tegelsmora kyrkogård",
    "value": "tegelsmora-kyrkogard-tierp"
},
{
    "id": "6600",
    "label": "Tegnaby kyrkplats",
    "value": "tegnaby-kyrkplats-vaxjo"
},
{
    "id": "6603",
    "label": "Tegneby kyrkogård",
    "value": "tegneby-kyrkogard-orust"
},
{
    "id": "6605",
    "label": "Tegnérkyrkogården",
    "value": "tegnerkyrkogarden-vaxjo"
},
{
    "id": "6609",
    "label": "Tengene kyrkogård",
    "value": "tengene-kyrkogard-grastorp"
},
{
    "id": "6613",
    "label": "Tensta kyrkogård",
    "value": "tensta-kyrkogard-uppsala"
},
{
    "id": "6617",
    "label": "Tiarps Kyrkogård",
    "value": "tiarps-kyrkogard-falkoping"
},
{
    "id": "6620",
    "label": "Tibro nya kyrkogård",
    "value": "tibro-nya-kyrkogard-tibro"
},
{
    "id": "6621",
    "label": "Tidaholms gamla kyrkogård",
    "value": "tidaholms-gamla-kyrkogard-tidaholm"
},
{
    "id": "6624",
    "label": "Tidavads kyrkogård",
    "value": "tidavads-kyrkogard-mariestad"
},
{
    "id": "6626",
    "label": "Tidersrums Kyrkogård",
    "value": "tidersrums-kyrkogard-kinda"
},
{
    "id": "6628",
    "label": "Tierps kyrkogård",
    "value": "tierps-kyrkogard-tierp"
},
{
    "id": "6632",
    "label": "Tillinge kyrkogård",
    "value": "tillinge-kyrkogard-enkoping"
},
{
    "id": "6634",
    "label": "Timmele kyrkogård",
    "value": "timmele-kyrkogard-ulricehamn"
},
{
    "id": "6639",
    "label": "Timmersdala kyrkogård",
    "value": "timmersdala-kyrkogard-skovde"
},
{
    "id": "6641",
    "label": "Timmerviks kyrkogård",
    "value": "timmerviks-kyrkogard-vanersborg"
},
{
    "id": "6645",
    "label": "Tingsås kyrkogård",
    "value": "tingsas-kyrkogard-tingsryd"
},
{
    "id": "6647",
    "label": "Tingstäde kyrkogård",
    "value": "tingstade-kyrkogard-gotland"
},
{
    "id": "6650",
    "label": "Tingstads kyrkogård",
    "value": "tingstads-kyrkogard-norrkoping"
},
{
    "id": "6652",
    "label": "Tirups Kyrkogård",
    "value": "tirups-kyrkogard-svalov"
},
{
    "id": "6656",
    "label": "Tisselskogs kyrkogård",
    "value": "tisselskogs-kyrkogard-bengtsfors"
},
{
    "id": "6658",
    "label": "Tiveds kyrkogård",
    "value": "tiveds-kyrkogard-laxa"
},
{
    "id": "6660",
    "label": "Tjällmo kyrkogård",
    "value": "tjallmo-kyrkogard-motala"
},
{
    "id": "6662",
    "label": "Tjärby Kyrkogård",
    "value": "tjarby-kyrkogard-laholm"
},
{
    "id": "6663",
    "label": "Tjärnö kyrkogård",
    "value": "tjarno-kyrkogard-stromstad"
},
{
    "id": "6666",
    "label": "Tjärstad Kyrkogård",
    "value": "tjarstad-kyrkogard-kinda"
},
{
    "id": "6669",
    "label": "Tjörnarps kyrkogård",
    "value": "tjornarps-kyrkogard-hoor"
},
{
    "id": "6672",
    "label": "Tjureda kyrkogård",
    "value": "tjureda-kyrkogard-vaxjo"
},
{
    "id": "6676",
    "label": "Toarps kyrkogård",
    "value": "toarps-kyrkogard-orust"
},
{
    "id": "6678",
    "label": "Töcksmarks kyrkogård",
    "value": "tocksmarks-kyrkogard-arjang"
},
{
    "id": "6681",
    "label": "Tofta kyrkogård",
    "value": "tofta-kyrkogard-gotland"
},
{
    "id": "6682",
    "label": "Tofta kyrkogård",
    "value": "tofta-kyrkogard-landskrona"
},
{
    "id": "6685",
    "label": "Tofteryds kyrkogård",
    "value": "tofteryds-kyrkogard-vaggeryd"
},
{
    "id": "6686",
    "label": "Tolånga Gamla Kyrkogård",
    "value": "tolanga-gamla-kyrkogard-sjobo"
},
{
    "id": "6688",
    "label": "Tolånga Nya Kyrkogård",
    "value": "tolanga-nya-kyrkogard-sjobo"
},
{
    "id": "6691",
    "label": "Tolfta kyrkogård",
    "value": "tolfta-kyrkogard-tierp"
},
{
    "id": "6692",
    "label": "Tolgs gamla kyrkogård",
    "value": "tolgs-gamla-kyrkogard-vaxjo"
},
{
    "id": "6694",
    "label": "Tolgs kyrkogård",
    "value": "tolgs-kyrkogard-vaxjo"
},
{
    "id": "6705",
    "label": "Tomelilla Kyrkogård",
    "value": "tomelilla-kyrkogard-tomelilla"
},
{
    "id": "6707",
    "label": "Tomtberga kyrkogård",
    "value": "tomtberga-kyrkogard-huddinge"
},
{
    "id": "6709",
    "label": "Tönnersjö Kyrkogård",
    "value": "tonnersjo-kyrkogard"
},
{
    "id": "6711",
    "label": "Torbjörntorps Kyrkogård",
    "value": "torbjorntorps-kyrkogard"
},
{
    "id": "6715",
    "label": "Töreboda kyrkogård",
    "value": "toreboda-kyrkogard"
},
{
    "id": "6718",
    "label": "Torekovs kyrkogård",
    "value": "torekovs-kyrkogard-bastad"
},
{
    "id": "6720",
    "label": "Torestorps Kyrkogård",
    "value": "torestorps-kyrkogard-mark"
},
{
    "id": "6722",
    "label": "Toresunds kyrkogård",
    "value": "toresunds-kyrkogard-strangnas"
},
{
    "id": "6724",
    "label": "Torhamns kyrkogård",
    "value": "torhamns-kyrkogard-karlskrona"
},
{
    "id": "6726",
    "label": "Tornehamns Kyrkogård",
    "value": "tornehamns-kyrkogard-kiruna"
},
{
    "id": "6729",
    "label": "Törnevalla Kyrkogård",
    "value": "tornevalla-kyrkogard-linkoping"
},
{
    "id": "6732",
    "label": "Törnsfalls Kyrkogård",
    "value": "tornsfalls-kyrkogard-oskarshamn"
},
{
    "id": "6734",
    "label": "Torö kyrkogård",
    "value": "toro-kyrkogard-nynashamn"
},
{
    "id": "6740",
    "label": "Torpa Kyrkogård",
    "value": "torpa-kyrkogard-varberg"
},
{
    "id": "6741",
    "label": "Torpa kyrkogård",
    "value": "torpa-kyrkogard-ljungby"
},
{
    "id": "6742",
    "label": "Torpa kyrkogård",
    "value": "torpa-kyrkogard-ydre"
},
{
    "id": "6747",
    "label": "Torps Kyrkogård",
    "value": "torps-kyrkogard-ange"
},
{
    "id": "6748",
    "label": "Torps kyrkogård",
    "value": "torps-kyrkogard-fargelanda"
},
{
    "id": "6749",
    "label": "Torps kyrkogård",
    "value": "torps-kyrkogard-orust"
},
{
    "id": "6751",
    "label": "Torpshammars Kyrkogård",
    "value": "torpshammars-kyrkogard-ange"
},
{
    "id": "6753",
    "label": "Törringe Kyrkogård",
    "value": "torringe-kyrkogard-svedala"
},
{
    "id": "6755",
    "label": "Torrlösa Kyrkogård",
    "value": "torrlosa-kyrkogard-svalov"
},
{
    "id": "6758",
    "label": "Torrskogs Kyrkogård",
    "value": "torrskogs-kyrkogard-bengtsfors"
},
{
    "id": "6764",
    "label": "Torsåkers Kyrkogård",
    "value": "torsakers-kyrkogard-hofors"
},
{
    "id": "6765",
    "label": "Torsåkers Kyrkogård",
    "value": "torsakers-kyrkogard-gnesta"
},
{
    "id": "6767",
    "label": "Torsångs Griftegård",
    "value": "torsangs-griftegard-borlange"
},
{
    "id": "6769",
    "label": "Torsångs Kyrkogård",
    "value": "torsangs-kyrkogard-borlange"
},
{
    "id": "6772",
    "label": "Torsåsby kyrkogård",
    "value": "torsasby-kyrkogard-alvesta"
},
{
    "id": "6774",
    "label": "Torsby kyrkogård",
    "value": "torsby-kyrkogard-kungalv"
},
{
    "id": "6776",
    "label": "Torshälla kyrkogård",
    "value": "torshalla-kyrkogard-eskilstuna"
},
{
    "id": "6779",
    "label": "Torskinge kyrkogård",
    "value": "torskinge-kyrkogard-varnamo"
},
{
    "id": "6782",
    "label": "Torslanda kyrkogård",
    "value": "torslanda-kyrkogard-goteborg"
},
{
    "id": "6784",
    "label": "Torslunda Kyrkogård",
    "value": "torslunda-kyrkogard-morbylanga"
},
{
    "id": "6787",
    "label": "Torsö kyrkogård",
    "value": "torso-kyrkogard-mariestad"
},
{
    "id": "6789",
    "label": "Torstuna Kyrkogård",
    "value": "torstuna-kyrkogard-enkoping"
},
{
    "id": "6791",
    "label": "Torsvi kyrkogård",
    "value": "torsvi-kyrkogard-enkoping"
},
{
    "id": "6794",
    "label": "Torups Kyrkogård",
    "value": "torups-kyrkogard-hylte"
},
{
    "id": "6797",
    "label": "Tösse kyrkogård",
    "value": "tosse-kyrkogard-amal"
},
{
    "id": "6798",
    "label": "Tossene gamla kyrkogård",
    "value": "tossene-gamla-kyrkogard-sotenas"
},
{
    "id": "6800",
    "label": "Tossene nya kyrkogård",
    "value": "tossene-nya-kyrkogard-sotenas"
},
{
    "id": "6802",
    "label": "Tostareds Kyrkogård",
    "value": "tostareds-kyrkogard-mark"
},
{
    "id": "6804",
    "label": "Tosterups Kyrkogård",
    "value": "tosterups-kyrkogard-tomelilla"
},
{
    "id": "6807",
    "label": "Tottarps kyrkogård",
    "value": "tottarps-kyrkogard-staffanstorp"
},
{
    "id": "6810",
    "label": "Träkumla kyrkogård",
    "value": "trakumla-kyrkogard-gotland"
},
{
    "id": "6813",
    "label": "Tranås Kyrkogård",
    "value": "tranas-kyrkogard-tomelilla"
},
{
    "id": "6815",
    "label": "Träne kyrkogård",
    "value": "trane-kyrkogard-kristianstad"
},
{
    "id": "6818",
    "label": "Tranemo Kyrkogård",
    "value": "tranemo-kyrkogard-tranemo"
},
{
    "id": "6821",
    "label": "Trångsunds begravningsplats",
    "value": "trangsunds-begravningsplats-huddinge"
},
{
    "id": "6823",
    "label": "Trankils kyrkogård",
    "value": "trankils-kyrkogard-arjang"
},
{
    "id": "6825",
    "label": "Transval kyrkogård",
    "value": "transval-kyrkogard-kristianstad"
},
{
    "id": "6827",
    "label": "Tranums Kyrkogård",
    "value": "tranums-kyrkogard-lidkoping"
},
{
    "id": "6830",
    "label": "Traryds kyrkogård",
    "value": "traryds-kyrkogard-markaryd"
},
{
    "id": "6832",
    "label": "Träslövs Kyrkogård",
    "value": "traslovs-kyrkogard-varberg"
},
{
    "id": "6835",
    "label": "Trässbergs kyrkogård",
    "value": "trassbergs-kyrkogard-lidkoping"
},
{
    "id": "6837",
    "label": "Trästena kyrkogård",
    "value": "trastena-kyrkogard-gullspang"
},
{
    "id": "6839",
    "label": "Tråvads kyrkogård",
    "value": "travads-kyrkogard-vara"
},
{
    "id": "6841",
    "label": "Trävattna Kyrkogård",
    "value": "travattna-kyrkogard-falkoping"
},
{
    "id": "6849",
    "label": "Trehörna kyrkogård",
    "value": "trehorna-kyrkogard-odeshog"
},
{
    "id": "6852",
    "label": "Trehörningsjö Kyrkogård",
    "value": "trehorningsjo-kyrkogard-ornskoldsvik"
},
{
    "id": "6857",
    "label": "Trökörna kyrkogård",
    "value": "trokorna-kyrkogard-grastorp"
},
{
    "id": "6861",
    "label": "Trolle Ljungby Kyrkogård",
    "value": "trolle-ljungby-kyrkogard-kristianstad"
},
{
    "id": "6864",
    "label": "Trollenäs kyrkogård",
    "value": "trollenas-kyrkogard-eslov"
},
{
    "id": "6868",
    "label": "Trönninge Kyrkogård",
    "value": "tronninge-kyrkogard-halmstad"
},
{
    "id": "6876",
    "label": "Trosa begravningsplats",
    "value": "trosa-begravningsplats-trosa"
},
{
    "id": "6879",
    "label": "Trosa landskyrkogård",
    "value": "trosa-landskyrkogard-trosa"
},
{
    "id": "6882",
    "label": "Trosa stadskyrkogård",
    "value": "trosa-stadskyrkogard-trosa"
},
{
    "id": "6887",
    "label": "Tryde Kyrkogård",
    "value": "tryde-kyrkogard-tomelilla"
},
{
    "id": "6889",
    "label": "Tryserums kyrkogård",
    "value": "tryserums-kyrkogard-valdemarsvik"
},
{
    "id": "6893",
    "label": "Tullstorp Kyrkogård",
    "value": "tullstorp-kyrkogard-trelleborg"
},
{
    "id": "6897",
    "label": "Tumbo kyrkogård",
    "value": "tumbo-kyrkogard-eskilstuna"
},
{
    "id": "6903",
    "label": "Tuna kyrkogård",
    "value": "tuna-kyrkogard-nykoping"
},
{
    "id": "6904",
    "label": "Tuna kyrkogård",
    "value": "tuna-kyrkogard-vimmerby"
},
{
    "id": "6905",
    "label": "Tuna kyrkogård",
    "value": "tuna-kyrkogard"
},
{
    "id": "6906",
    "label": "Tuna kyrkogård",
    "value": "tuna-kyrkogard-uppsala"
},
{
    "id": "6910",
    "label": "Tunabergs kyrkogård",
    "value": "tunabergs-kyrkogard-nykoping"
},
{
    "id": "6912",
    "label": "Tunge Kyrkogård",
    "value": "tunge-kyrkogard-ale"
},
{
    "id": "6915",
    "label": "Tungelsta Kyrkogård",
    "value": "tungelsta-kyrkogard-haninge"
},
{
    "id": "6917",
    "label": "Tuns Kyrkogård",
    "value": "tuns-kyrkogard-lidkoping"
},
{
    "id": "6922",
    "label": "Turinge kyrkogård",
    "value": "turinge-kyrkogard-nykvarn"
},
{
    "id": "6925",
    "label": "Tutaryds kyrkogård",
    "value": "tutaryds-kyrkogard-ljungby"
},
{
    "id": "6928",
    "label": "Tuve kyrkogård",
    "value": "tuve-kyrkogard-goteborg"
},
{
    "id": "6931",
    "label": "Tvååkers Kyrkogård",
    "value": "tvaakers-kyrkogard-varberg"
},
{
    "id": "6934",
    "label": "Tvärreds kyrkogård",
    "value": "tvarreds-kyrkogard-ulricehamn"
},
{
    "id": "6936",
    "label": "Tveta begravningsplats",
    "value": "tveta-begravningsplats-sodertalje"
},
{
    "id": "6941",
    "label": "Tveta kyrkogård",
    "value": "tveta-kyrkogard-hultsfred"
},
{
    "id": "6942",
    "label": "Tveta Kyrkogård",
    "value": "tveta-kyrkogard-saffle"
},
{
    "id": "6945",
    "label": "Tvings Kyrkogård",
    "value": "tvings-kyrkogard-karlskrona"
},
{
    "id": "6947",
    "label": "Tydje kyrkogård",
    "value": "tydje-kyrkogard-amal"
},
{
    "id": "6950",
    "label": "Tygelsjö kyrkogård",
    "value": "tygelsjo-kyrkogard-malmo"
},
{
    "id": "6957",
    "label": "Tyresö kyrkogård",
    "value": "tyreso-kyrkogard-tyreso"
},
{
    "id": "6963",
    "label": "Tysslinge kyrkogård",
    "value": "tysslinge-kyrkogard"
},
{
    "id": "6965",
    "label": "Tystberga kyrkogård",
    "value": "tystberga-kyrkogard"
},
{
    "id": "6968",
    "label": "Ucklums kyrkogård",
    "value": "ucklums-kyrkogard"
},
{
    "id": "6971",
    "label": "Ugglum Kyrkogård",
    "value": "ugglum-kyrkogard"
},
{
    "id": "6975",
    "label": "Ukna kyrkogård",
    "value": "ukna-kyrkogard-vastervik"
},
{
    "id": "6982",
    "label": "Ullene Kyrkogård",
    "value": "ullene-kyrkogard-falkoping"
},
{
    "id": "6983",
    "label": "Ulleråker kyrkogård",
    "value": "ulleraker-kyrkogard-uppsala"
},
{
    "id": "6985",
    "label": "Ullervads kyrkogård",
    "value": "ullervads-kyrkogard-mariestad"
},
{
    "id": "6987",
    "label": "Ullstorps Kyrkogård",
    "value": "ullstorps-kyrkogard-tomelilla"
},
{
    "id": "6992",
    "label": "Ulrika Kyrkogård",
    "value": "ulrika-kyrkogard-linkoping"
},
{
    "id": "6994",
    "label": "Ulriksdals begravningsplats",
    "value": "ulriksdals-begravningsplats-solna"
},
{
    "id": "6996",
    "label": "Ulvsjö Skogskyrkogård",
    "value": "ulvsjo-skogskyrkogard-mora"
},
{
    "id": "7000",
    "label": "Umnäs kyrkogård",
    "value": "umnas-kyrkogard-storuman"
},
{
    "id": "7002",
    "label": "Undenäs kyrkogård",
    "value": "undenas-kyrkogard-karlsborg"
},
{
    "id": "7006",
    "label": "Undersviks Kyrkogård",
    "value": "undersviks-kyrkogard-bollnas"
},
{
    "id": "7009",
    "label": "Unnaryds Kyrkogård",
    "value": "unnaryds-kyrkogard-hylte"
},
{
    "id": "7010",
    "label": "Untorp Kyrkogård",
    "value": "untorp-kyrkogard-orsa"
},
{
    "id": "7012",
    "label": "Ununge kyrkogård",
    "value": "ununge-kyrkogard-norrtalje"
},
{
    "id": "7014",
    "label": "Uppåkra kyrkogård",
    "value": "uppakra-kyrkogard-staffanstorp"
},
{
    "id": "7018",
    "label": "Upphärads kyrkogård",
    "value": "uppharads-kyrkogard-trollhattan"
},
{
    "id": "7020",
    "label": "Uppsala-Näs Kyrkogård",
    "value": "uppsala-nas-kyrkogard-uppsala"
},
{
    "id": "7033",
    "label": "Uråsa kyrkogård",
    "value": "urasa-kyrkogard-vaxjo"
},
{
    "id": "7034",
    "label": "Urnlunden vid Mönsterås kyrka",
    "value": "urnlunden-vid-monsteras-kyrka-mosteras"
},
{
    "id": "7037",
    "label": "Urshults kyrkogård",
    "value": "urshults-kyrkogard-tingsryd"
},
{
    "id": "7042",
    "label": "Utby kyrkogård",
    "value": "utby-kyrkogard-mariestad"
},
{
    "id": "7044",
    "label": "Utö Kyrkogård",
    "value": "uto-kyrkogard-haninge"
},
{
    "id": "7045",
    "label": "Utterkobbens kyrkogård",
    "value": "utterkobbens-kyrkogard-norrtalje"
},
{
    "id": "7047",
    "label": "Utvängstorps kyrkogård",
    "value": "utvangstorps-kyrkogard-mullsjo"
},
{
    "id": "7048",
    "label": "Uvered Kyrkogård",
    "value": "uvered-kyrkogard-lidkoping"
},
{
    "id": "7051",
    "label": "Vä kyrkogård",
    "value": "va-kyrkogard-kristianstad"
},
{
    "id": "7053",
    "label": "Väckelsångs kyrkogård",
    "value": "vackelsangs-kyrkogard-tingsryd"
},
{
    "id": "7056",
    "label": "Vada kyrkogård",
    "value": "vada-kyrkogard-vallentuna"
},
{
    "id": "7059",
    "label": "Vadensjö Kyrkogård",
    "value": "vadensjo-kyrkogard-landskrona"
},
{
    "id": "7062",
    "label": "Väderstad kyrkogård",
    "value": "vaderstad-kyrkogard-odeshog"
},
{
    "id": "7064",
    "label": "Vads kyrkogård",
    "value": "vads-kyrkogard-skovde"
},
{
    "id": "7066",
    "label": "Vadsbro kyrkogård",
    "value": "vadsbro-kyrkogard-flen"
},
{
    "id": "7069",
    "label": "Vägga kyrkogård",
    "value": "vagga-kyrkogard-sotenas"
},
{
    "id": "7072",
    "label": "Vagnhärads kyrkogård",
    "value": "vagnharads-kyrkogard-trosa"
},
{
    "id": "7076",
    "label": "Vaksala kyrkogård",
    "value": "vaksala-kyrkogard-uppsala"
},
{
    "id": "7078",
    "label": "Väla Kyrkogård",
    "value": "vala-kyrkogard-lidkoping"
},
{
    "id": "7080",
    "label": "Valbo Kyrkogård",
    "value": "valbo-kyrkogard-gavle"
},
{
    "id": "7082",
    "label": "Valbo-Ryrs Kyrkogård",
    "value": "valbo-ryrs-kyrkogard-munkedal"
},
{
    "id": "7084",
    "label": "Valdemarsviks kyrkogård",
    "value": "valdemarsviks-kyrkogard-valdemarsvik"
},
{
    "id": "7086",
    "label": "Valdshults kyrkogård",
    "value": "valdshults-kyrkogard-gislaved"
},
{
    "id": "7089",
    "label": "Valinge Kyrkogård",
    "value": "valinge-kyrkogard-varberg"
},
{
    "id": "7090",
    "label": "Välinge Kyrkogård",
    "value": "valinge-kyrkogard-helsingborg"
},
{
    "id": "7093",
    "label": "Valla Kyrkogård",
    "value": "valla-kyrkogard-tjorn"
},
{
    "id": "7099",
    "label": "Vallby Kyrkogård",
    "value": "vallby-kyrkogard-simrishamn"
},
{
    "id": "7100",
    "label": "Vallby kyrkogård",
    "value": "vallby-kyrkogard-eskilstuna"
},
{
    "id": "7101",
    "label": "Vallby kyrkogård",
    "value": "vallby-kyrkogard-enkoping"
},
{
    "id": "7105",
    "label": "Vallda Kyrkogård",
    "value": "vallda-kyrkogard-kungsbacka"
},
{
    "id": "7107",
    "label": "Valleberga Kyrkogård",
    "value": "valleberga-kyrkogard-ystad"
},
{
    "id": "7110",
    "label": "Vallentuna kyrkogård",
    "value": "vallentuna-kyrkogard-vallentuna"
},
{
    "id": "7111",
    "label": "Vallerstad kyrkogård",
    "value": "vallerstad-kyrkogard-odeshog"
},
{
    "id": "7115",
    "label": "Vallkärra kyrkogård",
    "value": "vallkarra-kyrkogard-lund"
},
{
    "id": "7117",
    "label": "Vallösa Kyrkogård",
    "value": "vallosa-kyrkogard-ystad"
},
{
    "id": "7119",
    "label": "Valls kyrkogård",
    "value": "valls-kyrkogard-gotland"
},
{
    "id": "7121",
    "label": "Vallsjö gamla kyrkogård",
    "value": "vallsjo-gamla-kyrkogard-savsjo"
},
{
    "id": "7123",
    "label": "Vallsjö kyrkogård",
    "value": "vallsjo-kyrkogard-savsjo"
},
{
    "id": "7125",
    "label": "Vallstena kyrkogård",
    "value": "vallstena-kyrkogard-gotland"
},
{
    "id": "7127",
    "label": "Välluvs Kyrkogård",
    "value": "valluvs-kyrkogard-helsingborg"
},
{
    "id": "7129",
    "label": "Valö kyrkogård",
    "value": "valo-kyrkogard-osthammar"
},
{
    "id": "7134",
    "label": "Valstads kyrkogård",
    "value": "valstads-kyrkogard-tidaholm"
},
{
    "id": "7136",
    "label": "Valtorps Kyrkogård",
    "value": "valtorps-kyrkogard-falkoping"
},
{
    "id": "7139",
    "label": "Våmbs Kyrkogård",
    "value": "vambs-kyrkogard-skovde"
},
{
    "id": "7141",
    "label": "Våmhus Kyrkogård",
    "value": "vamhus-kyrkogard-mora"
},
{
    "id": "7143",
    "label": "Vamlingbo kyrkogård",
    "value": "vamlingbo-kyrkogard-gotland"
},
{
    "id": "7144",
    "label": "Vämö kyrkogård",
    "value": "vamo-kyrkogard-karlskrona"
},
{
    "id": "7146",
    "label": "Väne Åsaka kyrkogård",
    "value": "vane-asaka-kyrkogard-vanersborg"
},
{
    "id": "7151",
    "label": "Vänersnäs kyrkogård",
    "value": "vanersnas-kyrkogard-vanersborg"
},
{
    "id": "7153",
    "label": "Väne-Ryrs kyrkogård",
    "value": "vane-ryrs-kyrkogard-vanersborg"
},
{
    "id": "7158",
    "label": "Vänga kyrkogård",
    "value": "vanga-kyrkogard-boras"
},
{
    "id": "7159",
    "label": "Vånga kyrkogård",
    "value": "vanga-kyrkogard-kristianstad"
},
{
    "id": "7160",
    "label": "Vånga kyrkogård",
    "value": "vanga-kyrkogard-norrkoping"
},
{
    "id": "7163",
    "label": "Vänge kyrkogård",
    "value": "vange-kyrkogard-gotland"
},
{
    "id": "7164",
    "label": "Vänge kyrkogård",
    "value": "vange-kyrkogard-uppsala"
},
{
    "id": "7167",
    "label": "Vankiva Kyrkogård",
    "value": "vankiva-kyrkogard-hassleholm"
},
{
    "id": "7170",
    "label": "Vännäs Östra Kyrkogård",
    "value": "vannas-ostra-kyrkogard-bjurholm"
},
{
    "id": "7171",
    "label": "Vännäs Västra Kyrkogård",
    "value": "vannas-vastra-kyrkogard-bjurholm"
},
{
    "id": "7173",
    "label": "Vansbro kyrkogård",
    "value": "vansbro-kyrkogard-vansbro"
},
{
    "id": "7175",
    "label": "Vansö kyrkogård",
    "value": "vanso-kyrkogard-strangnas"
},
{
    "id": "7177",
    "label": "Vanstads G:a Kyrkogård",
    "value": "vanstads-ga-kyrkogard-sjobo"
},
{
    "id": "7179",
    "label": "Vanstads Nya Kyrkogård",
    "value": "vanstads-nya-kyrkogard-sjobo"
},
{
    "id": "7185",
    "label": "Vara kyrkogård",
    "value": "vara-kyrkogard-vara"
},
{
    "id": "7194",
    "label": "Vårdinge kyrkogård",
    "value": "vardinge-kyrkogard-sodertalje"
},
{
    "id": "7197",
    "label": "Vårdnäs kyrkogård",
    "value": "vardnas-kyrkogard-linkoping"
},
{
    "id": "7199",
    "label": "Vårdsbergs Kyrkogård",
    "value": "vardsbergs-kyrkogard-linkoping"
},
{
    "id": "7203",
    "label": "Vårfrukyrkogården",
    "value": "varfrukyrkogarden-enkoping"
},
{
    "id": "7208",
    "label": "Värings kyrkogård",
    "value": "varings-kyrkogard-skovde"
},
{
    "id": "7210",
    "label": "Vårkumla Kyrkogård",
    "value": "varkumla-kyrkogard-falkoping"
},
{
    "id": "7213",
    "label": "Värmdö Kyrkogård",
    "value": "varmdo-kyrkogard-varmdo"
},
{
    "id": "7217",
    "label": "Värmskogs kyrkogård",
    "value": "varmskogs-kyrkogard"
},
{
    "id": "7218",
    "label": "Värna gamla kyrkogård",
    "value": "varna-gamla-kyrkogard"
},
{
    "id": "7220",
    "label": "Värna nya kyrkogård",
    "value": "varna-nya-kyrkogard"
},
{
    "id": "7222",
    "label": "Värnamo Kyrkogård",
    "value": "varnamo-kyrkogard"
},
{
    "id": "7225",
    "label": "Varnhems kyrkogård",
    "value": "varnhems-kyrkogard"
},
{
    "id": "7227",
    "label": "Varnums Kyrkogård",
    "value": "varnums-kyrkogard-ulricehamn"
},
{
    "id": "7230",
    "label": "Varola Kyrkogård",
    "value": "varola-kyrkogard-skovde"
},
{
    "id": "7232",
    "label": "Värsås Kyrkogård",
    "value": "varsas-kyrkogard-skovde"
},
{
    "id": "7237",
    "label": "Vartofta-Åsaka Kyrkogård",
    "value": "vartofta-asaka-kyrkogard-falkoping"
},
{
    "id": "7239",
    "label": "Varv och Styra kyrkogård",
    "value": "varv-och-styra-kyrkogard-motala"
},
{
    "id": "7240",
    "label": "Vårvik kyrkogård",
    "value": "varvik-kyrkogard-bengtsfors"
},
{
    "id": "7244",
    "label": "Varvs kyrkogård",
    "value": "varvs-kyrkogard-tidaholm"
},
{
    "id": "7248",
    "label": "Väsby Kyrkogård",
    "value": "vasby-kyrkogard-hoganas"
},
{
    "id": "7250",
    "label": "Väse kyrkogård",
    "value": "vase-kyrkogard-karlstad"
},
{
    "id": "7252",
    "label": "Väskinde kyrkogård",
    "value": "vaskinde-kyrkogard-gotland"
},
{
    "id": "7254",
    "label": "Vassända-Naglums kyrkogård",
    "value": "vassanda-naglums-kyrkogard-vanersborg"
},
{
    "id": "7256",
    "label": "Vassunda kyrkogård",
    "value": "vassunda-kyrkogard-knivsta"
},
{
    "id": "7259",
    "label": "Västberga begravningsplats",
    "value": "vastberga-begravningsplats-stockholm"
},
{
    "id": "7263",
    "label": "Västeråkers Kyrkogård",
    "value": "vasterakers-kyrkogard-uppsala"
},
{
    "id": "7268",
    "label": "Västergarn kyrkogård",
    "value": "vastergarn-kyrkogard-gotland"
},
{
    "id": "7273",
    "label": "Västerhaninge Kyrkogård",
    "value": "vasterhaninge-kyrkogard-haninge"
},
{
    "id": "7275",
    "label": "Västerhejde kyrkogård",
    "value": "vasterhejde-kyrkogard-gotland"
},
{
    "id": "7279",
    "label": "Västerlanda Kyrkogård",
    "value": "vasterlanda-kyrkogard-lilla-edet"
},
{
    "id": "7282",
    "label": "Västerljungs kyrkogård",
    "value": "vasterljungs-kyrkogard-trosa"
},
{
    "id": "7284",
    "label": "Västerlösa kyrkogård",
    "value": "vasterlosa-kyrkogard-linkoping"
},
{
    "id": "7286",
    "label": "Västerlövsta kyrkogård",
    "value": "vasterlovsta-kyrkogard-heby"
},
{
    "id": "7288",
    "label": "Västermo kyrkogård",
    "value": "vastermo-kyrkogard-eskilstuna"
},
{
    "id": "7290",
    "label": "Västerplana kyrkogård",
    "value": "vasterplana-kyrkogard-gotene"
},
{
    "id": "7294",
    "label": "Västerstads Kyrkogård",
    "value": "vasterstads-kyrkogard-horby"
},
{
    "id": "7299",
    "label": "Västlands kyrkogård",
    "value": "vastlands-kyrkogard-tierp"
},
{
    "id": "7303",
    "label": "Västra Alstad Kyrkogård",
    "value": "vastra-alstad-kyrkogard-trelleborg"
},
{
    "id": "7306",
    "label": "Västra Ämterviks kyrkogård",
    "value": "vastra-amterviks-kyrkogard-sunne"
},
{
    "id": "7307",
    "label": "Västra Broby",
    "value": "vastra-broby-astorp"
},
{
    "id": "7310",
    "label": "Västra Eds kyrkogård",
    "value": "vastra-eds-kyrkogard-vastervik"
},
{
    "id": "7312",
    "label": "Västra Eneby Kyrkogård",
    "value": "vastra-eneby-kyrkogard-kinda"
},
{
    "id": "7314",
    "label": "Västra Fågelviks kyrkogård",
    "value": "vastra-fagelviks-kyrkogard-arjang"
},
{
    "id": "7316",
    "label": "Västra Frölundas kyrkogård",
    "value": "vastra-frolundas-kyrkogard-goteborg"
},
{
    "id": "7318",
    "label": "Västra Gerums kyrkogård",
    "value": "vastra-gerums-kyrkogard-skara"
},
{
    "id": "7319",
    "label": "Västra Harg kyrkogård",
    "value": "vastra-harg-kyrkogard-odeshog"
},
{
    "id": "7322",
    "label": "Västra Hoby kyrkogård",
    "value": "vastra-hoby-kyrkogard-lund"
},
{
    "id": "7325",
    "label": "Västra Husby kyrkogård",
    "value": "vastra-husby-kyrkogard-soderkoping"
},
{
    "id": "7328",
    "label": "Västra Ingelstads kyrkogård",
    "value": "vastra-ingelstads-kyrkogard-vellinge"
},
{
    "id": "7330",
    "label": "Västra Karaby kyrkogård",
    "value": "vastra-karaby-kyrkogard-kavlinge"
},
{
    "id": "7332",
    "label": "Västra Kärrstorps Kyrkogård",
    "value": "vastra-karrstorps-kyrkogard-svedala"
},
{
    "id": "7335",
    "label": "Västra Karups kyrkogård",
    "value": "vastra-karups-kyrkogard-bastad"
},
{
    "id": "7337",
    "label": "Västra Klagstorps kyrkogård",
    "value": "vastra-klagstorps-kyrkogard-malmo"
},
{
    "id": "7338",
    "label": "Västra kyrkogården",
    "value": "vastra-kyrkogarden-bromolla"
},
{
    "id": "7339",
    "label": "Västra kyrkogården",
    "value": "vastra-kyrkogarden-halmstad"
},
{
    "id": "7340",
    "label": "Västra kyrkogården",
    "value": "vastra-kyrkogarden-umea"
},
{
    "id": "7341",
    "label": "Västra Kyrkogården",
    "value": "vastra-kyrkogarden-trelleborg"
},
{
    "id": "7342",
    "label": "Västra Kyrkogården",
    "value": "vastra-kyrkogarden-nykoping"
},
{
    "id": "7343",
    "label": "Västra kyrkogården",
    "value": "vastra-kyrkogarden-karlstad"
},
{
    "id": "7344",
    "label": "Västra kyrkogården",
    "value": "vastra-kyrkogarden-goteborg"
},
{
    "id": "7345",
    "label": "Västra kyrkogården",
    "value": "vastra-kyrkogarden-kungalv"
},
{
    "id": "7346",
    "label": "Västra kyrkogården",
    "value": "vastra-kyrkogarden-amal"
},
{
    "id": "7347",
    "label": "Västra Kyrkogården",
    "value": "vastra-kyrkogarden-linkoping"
},
{
    "id": "7350",
    "label": "Västra Nöbbelövs Kyrkogård",
    "value": "vastra-nobbelovs-kyrkogard-skurup"
},
{
    "id": "7352",
    "label": "Västra Ny kyrkogård",
    "value": "vastra-ny-kyrkogard-motala"
},
{
    "id": "7355",
    "label": "Västra Ryds kyrkogård",
    "value": "vastra-ryds-kyrkogard-upplands-bro"
},
{
    "id": "7356",
    "label": "Västra Ryds kyrkogård",
    "value": "vastra-ryds-kyrkogard-ydre"
},
{
    "id": "7358",
    "label": "Västra Sallerups kyrkogård",
    "value": "vastra-sallerups-kyrkogard-eslov"
},
{
    "id": "7363",
    "label": "Västra Skrävlinge kyrkogård",
    "value": "vastra-skravlinge-kyrkogard-malmo"
},
{
    "id": "7364",
    "label": "Västra Sönnarslöv Kyrkogård",
    "value": "vastra-sonnarslov-kyrkogard-astorp"
},
{
    "id": "7367",
    "label": "Västra Sönnarslövs Kyrkogård",
    "value": "vastra-sonnarslovs-kyrkogard-klippan"
},
{
    "id": "7369",
    "label": "Västra Stenby kyrkogård",
    "value": "vastra-stenby-kyrkogard-motala"
},
{
    "id": "7371",
    "label": "Västra Strö kyrkogård",
    "value": "vastra-stro-kyrkogard-eslov"
},
{
    "id": "7374",
    "label": "Västra Tollstads kyrkogård",
    "value": "vastra-tollstads-kyrkogard-odeshog"
},
{
    "id": "7375",
    "label": "Västra Tommarp Kyrkogård",
    "value": "vastra-tommarp-kyrkogard-trelleborg"
},
{
    "id": "7379",
    "label": "Västra Torsås kyrkogård",
    "value": "vastra-torsas-kyrkogard-alvesta"
},
{
    "id": "7381",
    "label": "Västra Torups kyrkogård",
    "value": "vastra-torups-kyrkogard-hassleholm"
},
{
    "id": "7383",
    "label": "Västra Tunhems kyrkogård",
    "value": "vastra-tunhems-kyrkogard-vanersborg"
},
{
    "id": "7385",
    "label": "Västra Vemmenhögs Kyrkogård",
    "value": "vastra-vemmenhogs-kyrkogard-skurup"
},
{
    "id": "7387",
    "label": "Västra Vemmerlövs Gamla Kyrkogård",
    "value": "vastra-vemmerlovs-gamla-kyrkogard-trelleborg"
},
{
    "id": "7389",
    "label": "Västra Vemmerlövs Nya Kyrkogård",
    "value": "vastra-vemmerlovs-nya-kyrkogard-trelleborg"
},
{
    "id": "7392",
    "label": "Västra Vrams kyrkogård",
    "value": "vastra-vrams-kyrkogard-kristianstad"
},
{
    "id": "7396",
    "label": "Västrums Kyrkogård",
    "value": "vastrums-kyrkogard-oskarshamn"
},
{
    "id": "7398",
    "label": "Väte kyrkogård",
    "value": "vate-kyrkogard-gotland"
},
{
    "id": "7401",
    "label": "Vätö kyrkogård",
    "value": "vato-kyrkogard-norrtalje"
},
{
    "id": "7403",
    "label": "Vättaks kyrkogård",
    "value": "vattaks-kyrkogard-tidaholm"
},
{
    "id": "7405",
    "label": "Vättlösa kyrkogård",
    "value": "vattlosa-kyrkogard-gotene"
},
{
    "id": "7407",
    "label": "Väversunda kyrkogård",
    "value": "vaversunda-kyrkogard-vadstena"
},
{
    "id": "7410",
    "label": "Vaxholms kyrkogård",
    "value": "vaxholms-kyrkogard-vaxholm"
},
{
    "id": "7414",
    "label": "Våxtorps Kyrkogård",
    "value": "vaxtorps-kyrkogard-laholm"
},
{
    "id": "7416",
    "label": "Veberöds kyrkogård",
    "value": "veberods-kyrkogard-lund"
},
{
    "id": "7418",
    "label": "Veckholms kyrkogård",
    "value": "veckholms-kyrkogard-enkoping"
},
{
    "id": "7420",
    "label": "Vedby Kyrkogård",
    "value": "vedby-kyrkogard-klippan"
},
{
    "id": "7422",
    "label": "Veddige Kyrkogård",
    "value": "veddige-kyrkogard-varberg"
},
{
    "id": "7425",
    "label": "Vederslövs kyrkogård",
    "value": "vederslovs-kyrkogard-vaxjo"
},
{
    "id": "7429",
    "label": "Vedums kyrkogård",
    "value": "vedums-kyrkogard-vara"
},
{
    "id": "7431",
    "label": "Veinge Kyrkogård",
    "value": "veinge-kyrkogard-laholm"
},
{
    "id": "7434",
    "label": "Velinga kyrkogård",
    "value": "velinga-kyrkogard-tidaholm"
},
{
    "id": "7435",
    "label": "Vellinge gamla kyrkogård",
    "value": "vellinge-gamla-kyrkogard-vellinge"
},
{
    "id": "7437",
    "label": "Vellinge nya kyrkogård",
    "value": "vellinge-nya-kyrkogard-vellinge"
},
{
    "id": "7441",
    "label": "Vena kyrkogård",
    "value": "vena-kyrkogard-hultsfred"
},
{
    "id": "7443",
    "label": "Vendels kyrkogård",
    "value": "vendels-kyrkogard-tierp"
},
{
    "id": "7446",
    "label": "Venjans Kyrkogård",
    "value": "venjans-kyrkogard-mora"
},
{
    "id": "7448",
    "label": "Ventlinge Kyrkogård",
    "value": "ventlinge-kyrkogard-morbylanga"
},
{
    "id": "7451",
    "label": "Verums kyrkogård",
    "value": "verums-kyrkogard-hassleholm"
},
{
    "id": "7453",
    "label": "Vesene kyrkogård",
    "value": "vesene-kyrkogard-herrljunga"
},
{
    "id": "7456",
    "label": "Veta kyrkogård",
    "value": "veta-kyrkogard-odeshog"
},
{
    "id": "7458",
    "label": "Vetlanda Kyrkogård",
    "value": "vetlanda-kyrkogard-vetlanda"
},
{
    "id": "7463",
    "label": "Viby kyrkogård",
    "value": "viby-kyrkogard-hallsberg"
},
{
    "id": "7464",
    "label": "Viby kyrkogård",
    "value": "viby-kyrkogard-odeshog"
},
{
    "id": "7470",
    "label": "Vickleby Kyrkogård",
    "value": "vickleby-kyrkogard-morbylanga"
},
{
    "id": "7475",
    "label": "Vidbo Kyrkogård",
    "value": "vidbo-kyrkogard"
},
{
    "id": "7481",
    "label": "Vika Kyrkogård",
    "value": "vika-kyrkogard"
},
{
    "id": "7483",
    "label": "Vikens Gamla Kyrkogård",
    "value": "vikens-gamla-kyrkogard-hoganas"
},
{
    "id": "7485",
    "label": "Vikens Kapells Kyrkogård",
    "value": "vikens-kapells-kyrkogard-stromsund"
},
{
    "id": "7487",
    "label": "Vikens Nya Kyrkogård",
    "value": "vikens-nya-kyrkogard-hoganas"
},
{
    "id": "7490",
    "label": "Vikers Kyrkogård",
    "value": "vikers-kyrkogard-nora"
},
{
    "id": "7492",
    "label": "Vikingstads kyrkogård",
    "value": "vikingstads-kyrkogard-linkoping"
},
{
    "id": "7494",
    "label": "Viklau kyrkogård",
    "value": "viklau-kyrkogard-gotland"
},
{
    "id": "7501",
    "label": "Viksjö kyrkogård",
    "value": "viksjo-kyrkogard-harnosand"
},
{
    "id": "7506",
    "label": "Viksta Kyrkogård",
    "value": "viksta-kyrkogard-uppsala"
},
{
    "id": "7508",
    "label": "Viktoriakyrkans Kyrkogård",
    "value": "viktoriakyrkans-kyrkogard-mala"
},
{
    "id": "7511",
    "label": "Vilhelmina kyrkogård",
    "value": "vilhelmina-kyrkogard-storuman"
},
{
    "id": "7513",
    "label": "Villberga kyrkogård",
    "value": "villberga-kyrkogard-enkoping"
},
{
    "id": "7517",
    "label": "Villie kyrkogård",
    "value": "villie-kyrkogard-skurup"
},
{
    "id": "7519",
    "label": "Villstads Kyrkogård",
    "value": "villstads-kyrkogard-gislaved"
},
{
    "id": "7521",
    "label": "Vilske-Kleva Kyrkogård",
    "value": "vilske-kleva-kyrkogard-falkoping"
},
{
    "id": "7526",
    "label": "Vinbergs Kyrkogård",
    "value": "vinbergs-kyrkogard-falkenberg"
},
{
    "id": "7531",
    "label": "Vingåkers Kyrkogård",
    "value": "vingakers-kyrkogard-vingaker"
},
{
    "id": "7533",
    "label": "Vinköls kyrkogård",
    "value": "vinkols-kyrkogard-skara"
},
{
    "id": "7534",
    "label": "Vinnerstad kyrkogård",
    "value": "vinnerstad-kyrkogard-motala"
},
{
    "id": "7537",
    "label": "Vinslövs kyrkogård",
    "value": "vinslovs-kyrkogard-hassleholm"
},
{
    "id": "7539",
    "label": "Vintrosa kyrkogård",
    "value": "vintrosa-kyrkogard-orebro"
},
{
    "id": "7541",
    "label": "Vireda kyrkogård",
    "value": "vireda-kyrkogard-aneby"
},
{
    "id": "7544",
    "label": "Virestads kyrkogård",
    "value": "virestads-kyrkogard-almhult"
},
{
    "id": "7546",
    "label": "Virke kyrkogård",
    "value": "virke-kyrkogard-kavlinge"
},
{
    "id": "7548",
    "label": "Virsbo Kyrkogård",
    "value": "virsbo-kyrkogard-surahammar"
},
{
    "id": "7550",
    "label": "Virserums kyrkogård",
    "value": "virserums-kyrkogard-hultsfred"
},
{
    "id": "7554",
    "label": "Visby Norra Kyrkogård",
    "value": "visby-norra-kyrkogard-gotland"
},
{
    "id": "7555",
    "label": "Visby Östra Kyrkogård",
    "value": "visby-ostra-kyrkogard-gotland"
},
{
    "id": "7556",
    "label": "Visby Södra Kyrkogård",
    "value": "visby-sodra-kyrkogard-gotland"
},
{
    "id": "7558",
    "label": "Visingsö Kyrkogård",
    "value": "visingso-kyrkogard-jonkoping"
},
{
    "id": "7562",
    "label": "Vislanda kyrkogård",
    "value": "vislanda-kyrkogard-alvesta"
},
{
    "id": "7564",
    "label": "Visnums kyrkogård",
    "value": "visnums-kyrkogard-kristinehamn"
},
{
    "id": "7566",
    "label": "Visnums-Kils kyrkogård",
    "value": "visnums-kils-kyrkogard-kristinehamn"
},
{
    "id": "7568",
    "label": "Vissefjärda Kyrkogård",
    "value": "vissefjarda-kyrkogard-emmaboda"
},
{
    "id": "7570",
    "label": "Visseltofta Kyrkogård",
    "value": "visseltofta-kyrkogard-osby"
},
{
    "id": "7573",
    "label": "Vist kyrkogård",
    "value": "vist-kyrkogard-linkoping"
},
{
    "id": "7575",
    "label": "Vistorps Kyrkogård",
    "value": "vistorps-kyrkogard-falkoping"
},
{
    "id": "7578",
    "label": "Vists kyrkogård",
    "value": "vists-kyrkogard-ulricehamn"
},
{
    "id": "7582",
    "label": "Vitaby Kyrkogård",
    "value": "vitaby-kyrkogard-simrishamn"
},
{
    "id": "7584",
    "label": "Vitsands Kyrkogård",
    "value": "vitsands-kyrkogard-torsby"
},
{
    "id": "7585",
    "label": "Vittangi Gamla Kyrkogård",
    "value": "vittangi-gamla-kyrkogard-kiruna"
},
{
    "id": "7587",
    "label": "Vittangi Nya Kyrkogård",
    "value": "vittangi-nya-kyrkogard-kiruna"
},
{
    "id": "7589",
    "label": "Vittaryds kyrkogård",
    "value": "vittaryds-kyrkogard-ljungby"
},
{
    "id": "7591",
    "label": "Vittinge kyrkogård",
    "value": "vittinge-kyrkogard-heby"
},
{
    "id": "7594",
    "label": "Vittsjö kyrkogård",
    "value": "vittsjo-kyrkogard-hassleholm"
},
{
    "id": "7596",
    "label": "Vittskövle Kyrkogård",
    "value": "vittskovle-kyrkogard-kristianstad"
},
{
    "id": "7599",
    "label": "Volgsjö kyrkogård",
    "value": "volgsjo-kyrkogard-storuman"
},
{
    "id": "7601",
    "label": "Vollsjö kyrkogård",
    "value": "vollsjo-kyrkogard-sjobo"
},
{
    "id": "7603",
    "label": "Vombs kyrkogård",
    "value": "vombs-kyrkogard-lund"
},
{
    "id": "7605",
    "label": "Voxna Kyrkogård",
    "value": "voxna-kyrkogard-ovanaker"
},
{
    "id": "7608",
    "label": "Voxtorps Kyrkogård",
    "value": "voxtorps-kyrkogard-varnamo"
},
{
    "id": "7609",
    "label": "Voxtorps Kyrkogård",
    "value": "voxtorps-kyrkogard-kalmar"
},
{
    "id": "7611",
    "label": "Vrå kyrkogård",
    "value": "vra-kyrkogard-ljungby"
},
{
    "id": "7613",
    "label": "Vrångö Gamla kyrkogård",
    "value": "vrango-gamla-kyrkogard-goteborg"
},
{
    "id": "7615",
    "label": "Vrångö Nya kyrkogård",
    "value": "vrango-nya-kyrkogard-goteborg"
},
{
    "id": "7617",
    "label": "Vrena kyrkogård",
    "value": "vrena-kyrkogard-nykoping"
},
{
    "id": "7619",
    "label": "Vreta klosters kyrkogård",
    "value": "vreta-klosters-kyrkogard-linkoping"
},
{
    "id": "7621",
    "label": "Vretens Kyrkogård",
    "value": "vretens-kyrkogard-skovde"
},
{
    "id": "7623",
    "label": "Vrigstads kyrkogård",
    "value": "vrigstads-kyrkogard-savsjo"
},
{
    "id": "7631",
    "label": "Yllestads Kyrkogård",
    "value": "yllestads-kyrkogard-falkoping"
},
{
    "id": "7635",
    "label": "Ysane kyrkogård",
    "value": "ysane-kyrkogard-solvesborg"
},
{
    "id": "7638",
    "label": "Ysby Kyrkogård",
    "value": "ysby-kyrkogard-laholm"
},
{
    "id": "7641",
    "label": "Ytterby kyrkogård",
    "value": "ytterby-kyrkogard-kungalv"
},
{
    "id": "7643",
    "label": "Ytterenhörna kyrkogård",
    "value": "ytterenhorna-kyrkogard-sodertalje"
},
{
    "id": "7646",
    "label": "Yttergrans Kyrkogård",
    "value": "yttergrans-kyrkogard-habo"
},
{
    "id": "7649",
    "label": "Ytterjärna kyrkogård",
    "value": "ytterjarna-kyrkogard-sodertalje"
},
{
    "id": "7656",
    "label": "Ytterselö kyrkogård",
    "value": "ytterselo-kyrkogard-strangnas"
},
{
    "id": "7659",
    "label": "Yxnerums kyrkogård",
    "value": "yxnerums-kyrkogard-atvidaberg"
},
{
    "id": "7725",
    "label": "S:t Eriks begravningsplats",
    "value": "st-eriks-begravningsplats"
},
{
    "id": "7726",
    "label": "Garnvikens begravningsplats",
    "value": "garnsvikens-begravningsplats-osteraker"
},
{
    "id": "7727",
    "label": "Tyresö begravningsplats",
    "value": "tyreso-begravningsplats-tyreso"
},
{
    "id": "7728",
    "label": "Boo kyrkogård",
    "value": "boo-kyrkogard-nacka"
},
{
    "id": "7729",
    "label": "Tullinge parkkyrkogård",
    "value": "tullinge-parkkyrkogard-botkyrka"
},
{
    "id": "7730",
    "label": "Katolska kyrkogården",
    "value": "katolska-kyrkogarden-solna"
},
{
    "id": "7731",
    "label": "Täby norra begravningsplats",
    "value": "taby-norra-begravningsplats-taby"
},
{
    "id": "7732",
    "label": "Täby södra begravningsplats",
    "value": "taby-sodra-begravningsplats-taby"
},
{
    "id": "7733",
    "label": "Engelbrekts columbarium",
    "value": "engelbrekts-columbarium-stockholm"
},
{
    "id": "7734",
    "label": "Adolf Fredriks kyrkogård",
    "value": "adolf-fredriks-kyrkogard-stockholm"
},
{
    "id": "7735",
    "label": "S:ta Clara kyrkogård",
    "value": "sta-clara-kyrkogard-stockholm"
}
]
