import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

// eslint-disable-next-line import/namespace
import { menu } from "../assets/data/datalist";
import { useUserContext } from "../context/UserContext";
import { useMediaQuery } from "../services/hooks/useMediaQuery";
import { useOnClickOutside } from "../services/hooks/useOnClickOutside";
import {
  getBankIdIcon,
  getButtonArrow,
  getFenixFamilyLogo,
  getFenixFamilyMobileLogo,
} from "../services/icons";

export default function HeadMenu({
  color,
  theme,
  showButton,
  buttonClass,
  sticky,
  hideMenu,
  hideLogin,
  affiliateLogo,
  loginTitle,
  loginUrl,
  onClick,
}: {
  color?: string;
  theme?: string;
  showButton?: boolean;
  buttonClass?: string;
  sticky?: boolean;
  hideMenu?: boolean;
  hideLogin?: boolean;
  affiliateLogo?: string;
  loginTitle?: string;
  loginUrl?: string;
  onClick?: () => void;
}) {
  const [navbar, setNavbar] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 1025px)");
  const ref = useRef<null | HTMLDivElement>(null);

  const { user, logout } = useUserContext();
  const navigate = useNavigate();
  const iconsColor = navbar ? "#fff" : color ? color : "#0C2632";
  const textColor = "text-[#fff]";
  const performNavigate = (to: any, check = true) => {
    if (to === "logout") {
      logout();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      navigate(to);
      window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    }
  };

  const renderLoginButton = (buttonClass?: string) => {
    return (
      <Link
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        to={loginUrl ? loginUrl : "/login"}
        className={`flex min-h-[34px] min-w-[141px] flex-row items-center gap-2 self-center rounded border-[1px] px-2 py-1.5 font-intermedium text-[14px] uppercase leading-none tracking-widest text-white lg:min-h-[52px] lg:min-w-[175px] lg:gap-3 lg:px-4 lg:py-3.5 ${
          navbar
            ? textColor +
              "border-[#fff] bg-deep-blue hover:border-[#CECABA] hover:text-[#CECABA]"
            : sticky || theme === "dark"
            ? textColor +
              "  border-deep-blue bg-deep-blue hover:border-[#6F86A0] hover:bg-[#6F86A0]"
            : color
            ? textColor +
              "  border-[#fff] bg-transparent hover:border-[#CECABA] hover:text-[#CECABA]"
            : textColor +
              "  border-[#0C2632] bg-deep-blue hover:border-[#6F86A0] hover:bg-[#6F86A0]"
        }`}
      >
        {getBankIdIcon(
          isHover && navbar
            ? "#CECABA"
            : (sticky && isHover) || theme === "dark"
            ? "#fff"
            : isHover && color
            ? "#CECABA"
            : "#fff",
          "20",
          "100%"
        )}
        {loginTitle ? loginTitle : "Kom igång"}
      </Link>
    );
  };

  const handleClickOutside = () => {
    setNavbar(false);
  };

  const handleClickInside = () => {};

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div className="h-full" ref={ref} onClick={handleClickInside}>
      <div className="mx-auto h-full ">
        <div className="flex h-full flex-col items-center justify-between">
          <div id="header" className={`w-full ${navbar ? "bg-deep-blue" : ""}`}>
            <nav className="mx-auto flex w-full flex-col items-center justify-between px-5 lg:px-14">
              <div className="flex w-full items-center justify-between py-4">
                <div className="flex items-center">
                  <a
                    onClick={() => performNavigate("/")}
                    className="cursor-pointer"
                  >
                    {isDesktop
                      ? getFenixFamilyLogo(iconsColor)
                      : getFenixFamilyMobileLogo(iconsColor)}
                  </a>
                  {affiliateLogo && isDesktop && (
                    <img
                      src={affiliateLogo}
                      alt="partner logo"
                      width={195}
                      height={50}
                      className="ml-3 max-h-[30px] max-w-[90px] object-contain lg:ml-10 lg:max-h-[50px] lg:max-w-fit"
                    />
                  )}
                </div>
                <div className="flex items-center lg:mr-5">
                  {!hideLogin && renderLoginButton()}
                  {!hideMenu && (
                    <div className="ml-6 lg:ml-9">
                      <svg
                        className="cursor-pointer"
                        onClick={() => setNavbar(!navbar)}
                        width={isDesktop ? "40" : "32"}
                        height={isDesktop ? "40" : "32"}
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {navbar ? (
                          <path
                            d="M5 35.625L35 5.62502M5 5.625L35 35.625"
                            stroke="#FFFEFB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        ) : sticky ? (
                          <path
                            d="M2 9H38M14 20.5H38M2 32H38"
                            stroke="#0C2632"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        ) : theme === "light" ? (
                          <path
                            d="M2 9H38M14 20.5H38M2 32H38"
                            stroke="#fff"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        ) : (
                          <path
                            d="M2 9H38M14 20.5H38M2 32H38"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        )}
                      </svg>
                    </div>
                  )}
                  {showButton && (
                    <button
                      className={`flex min-h-[34px] min-w-[141px] flex-row items-center gap-2 self-center rounded border-[1px] px-2 py-1.5 font-intermedium text-[14px] uppercase leading-none tracking-widest text-white lg:min-h-[52px] lg:min-w-[175px] lg:gap-3 lg:px-4 lg:py-3.5 ${
                        sticky || theme === "dark"
                          ? textColor +
                            "  border-deep-blue bg-deep-blue hover:border-[#6F86A0] hover:bg-[#6F86A0] " +
                            buttonClass
                          : buttonClass
                      }`}
                      onClick={onClick}
                    >
                      skriv ditt brev
                    </button>
                  )}
                </div>
              </div>
              <div className="max-h-screen w-full overflow-y-scroll lg:overflow-hidden">
                <div
                  className={`mt-12 mb-12 flex flex-col pb-24 lg:mt-24 lg:mb-7 lg:flex-row lg:pb-4 ${
                    navbar ? "block" : "hidden"
                  }`}
                >
                  <ul className="mb-6 flex flex-1 flex-col space-y-6">
                    {menu[0].firstColumn.map((item: any, index: number) => (
                      <li
                        key={index}
                        className="font-intermedium uppercase tracking-widest"
                      >
                        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access */}
                        <Link
                          to={item.link}
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "auto",
                            });
                          }}
                          className="flex text-base text-[#FFFEFB] hover:text-[#6F86A0]"
                        >
                          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                          {item.title}
                          <div className="ml-3">
                            {getButtonArrow("#ffffff", "18", "100%")}
                          </div>
                        </Link>
                        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                        {item.childMenu && (
                          <ul className="mt-6 flex flex-col space-y-6">
                            {
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                              item.childMenu.map(
                                (child: any, index: number) => (
                                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,react/jsx-key
                                  <li
                                    key={index}
                                    className="ml-5 uppercase tracking-widest text-[#E8E3D2] hover:text-[#6F86A0]"
                                  >
                                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access */}
                                    <HashLink
                                      to={child.link}
                                      onClick={() => {
                                        handleClickOutside();
                                      }}
                                      scroll={(el) =>
                                        el.scrollIntoView({
                                          behavior: "auto",
                                          block: "center",
                                        })
                                      }
                                    >
                                      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                                      {child.title}
                                    </HashLink>
                                  </li>
                                )
                              )
                            }
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                  <ul className="mb-6 flex flex-1 flex-col space-y-6 ">
                    {menu[0].secondColumn.map((item: any, index: number) => (
                      <li key={index} className="font-intermedium uppercase">
                        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access */}
                        <Link
                          to={item.link}
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "auto",
                            });
                          }}
                          className="flex text-base tracking-widest text-[#FFFEFB] hover:text-[#6F86A0]"
                        >
                          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                          {item.title}
                          <div className="ml-3">
                            {getButtonArrow("#ffffff", "18", "100%")}
                          </div>
                        </Link>
                        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                        {item.childMenu && (
                          <ul className="mt-6 flex flex-col space-y-6">
                            {
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                              item.childMenu.map(
                                (child: any, index: number) => (
                                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,react/jsx-key
                                  <li
                                    key={index}
                                    className="ml-5 uppercase tracking-widest text-[#E8E3D2] hover:text-[#6F86A0]"
                                  >
                                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access */}
                                    <HashLink
                                      to={child.link}
                                      onClick={() => {
                                        handleClickOutside();
                                      }}
                                      scroll={(el) =>
                                        el.scrollIntoView({
                                          behavior: "auto",
                                          block: "center",
                                        })
                                      }
                                    >
                                      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                                      {child.title}
                                    </HashLink>
                                  </li>
                                )
                              )
                            }
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                  <ul className="mb-6 flex flex-1 flex-col space-y-6">
                    {menu[0].thirdColumn.map((item: any, index: number) => (
                      <li key={index} className="font-intermedium uppercase">
                        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access */}
                        <Link
                          to={item.link}
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "auto",
                            });
                          }}
                          className="flex text-base tracking-widest text-[#FFFEFB] hover:text-[#6F86A0]"
                        >
                          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                          {item.title}
                        </Link>
                        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                        {item.childMenu && (
                          <ul className="mt-6 flex flex-col space-y-6">
                            {
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                              item.childMenu.map(
                                (child: any, index: number) => (
                                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,react/jsx-key
                                  <li
                                    key={index}
                                    className="ml-5 uppercase tracking-widest text-[#E8E3D2] hover:text-[#6F86A0]"
                                  >
                                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access */}
                                    <HashLink
                                      to={child.link}
                                      onClick={() => {
                                        handleClickOutside();
                                      }}
                                      scroll={(el) =>
                                        el.scrollIntoView({
                                          behavior: "auto",
                                          block: "center",
                                        })
                                      }
                                    >
                                      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                                      {child.title}
                                    </HashLink>
                                  </li>
                                )
                              )
                            }
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
