// Import Swiper styles
import "swiper/swiper-bundle.css";

import React, { useState } from "react";
import { Grid, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import ArticleGridItem from "../ArticleGridItem";

export default function ArticlesRowsSlider({
  articles,
  childCategories,
}: {
  articles: any;
  childCategories?: any;
}) {
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);
  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return (
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        '<span class="' +
        className +
        ' text-[11px] swiper-pagination-bullet--fraction !w-[24px] !h-[24px] !rounded-[20px] !p-[4px] !bg-transparent border-0 !opacity-100">' +
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        (index + 1) +
        "</span>"
      );
    },
  };

  return (
    <>
      <div>
        <Swiper
          modules={[Pagination, Navigation, Grid]}
          grabCursor={true}
          keyboard={true}
          navigation={{ prevEl, nextEl }}
          allowTouchMove={true}
          simulateTouch={true}
          edgeSwipeDetection={true}
          touchReleaseOnEdges={true}
          pagination={pagination}
          mousewheel={{
            releaseOnEdges: true,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
              grid: {
                rows: 8,
                fill: "row",
              },
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
              grid: {
                rows: 3,
                fill: "row",
              },
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
              grid: {
                rows: 3,
                fill: "row",
              },
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 30,
              grid: {
                rows: 3,
                fill: "row",
              },
            },
          }}
          className="swiper-articles-filter swiper-rows !lg:pb-12 -mr-5 !pb-28 md:-mr-0"
        >
          {articles?.map((item: any, index: number) => {
            const hasParentCategory = childCategories?.find(
              (element: any) =>
                element.attributes.slug ===
                item.attributes.article_category.data.attributes.slug
            );
            return (
              <SwiperSlide key={index} className="!mt-0">
                <ArticleGridItem item={item} hasParentCat={hasParentCategory} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="navigation-wrapper relative hidden">
          <div
            ref={(node) => setPrevEl(node)}
            className="swiper-button-prev swiper-button-prev-advisor swiper-button-advisor relative !right-20 !top-[140%] !left-auto !h-[32px] !w-[32px] rounded-full bg-deep-blue !text-transparent after:absolute after:top-2.5 after:left-3 after:h-[11px] after:w-[11px] after:rotate-45 after:border-b after:border-l after:border-[#fff] after:content-[''] hover:bg-[#6F86A0] lg:!top-[45%] lg:!left-10 lg:bg-transparent lg:after:border-[#467655]"
          ></div>
          <div
            ref={(node) => setNextEl(node)}
            className="swiper-button-next swiper-button-next-advisor swiper-button-advisor relative !top-[140%] !right-2 !h-[32px] !w-[32px] rounded-full bg-deep-blue !text-transparent after:absolute after:top-2.5 after:left-2 after:h-[11px] after:w-[11px] after:rotate-[-130deg] after:border-b after:border-l after:border-[#fff] after:content-[''] hover:bg-[#6F86A0] lg:!right-10 lg:!top-[45%] lg:bg-transparent lg:after:border-[#467655]"
          ></div>
        </div>
      </div>
    </>
  );
}
