import { Document, Font, Page, StyleSheet, Text } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";

const styles = StyleSheet.create({
  body: {
    paddingTop: 60,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 20,
    textAlign: "center",
    fontFamily: "InterMedium",
    color: "#002733",
    marginBottom: 20,
  },
  subtitle: {
    fontSize: 24,
    marginBottom: 12,
    marginTop: 12,
    textAlign: "left",
    fontFamily: "Cormorant-Italic",
    color: "#002733",
    textTransform: "capitalize",
  },
  text: {
    marginBottom: 10,
    fontSize: 16,
    textAlign: "justify",
    fontFamily: "Cormorant-Italic",
  },
  textServices: {
    marginBottom: 10,
    fontSize: 14,
    textAlign: "left",
    fontFamily: "InterMedium",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 80,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

Font.register({
  family: "Cormorant-Italic",
  src: new URL(
    "../assets/fonts/Cormorant-Italic-VariableFont_wght.ttf",
    import.meta.url
  ).href,
});

Font.register({
  family: "InterNormal",
  src: new URL("../assets/fonts/Inter-Regular.ttf", import.meta.url).href,
});

export default function PdfPreplanPreview({
  name = "test",
  auto = true,
  ceremony = "",
  type = "",
  burialPlace = "",
  color = "",
  flower = "",
  music = "",
  poem1 = "",
}) {
  const [textArray, setTextArray] = useState<string[]>([]);

  useEffect(() => {
    setTextArray(poem1.split(". "));
  }, [poem1]);

  return (
    <Document>
      <Page style={styles.body} wrap>
        <Text style={styles.subtitle}>Älskade {name},</Text>
        <Text style={styles.text}>
          {auto ? textArray[0] + ". " + textArray[1] + "." : poem1}
        </Text>
        <Text style={styles.text}>
          Nu önskar jag din hjälp.{" "}
          {ceremony === "yes"
            ? "Jag vill ha en begravningsceremoni."
            : "Jag vill inte ha en begravningsceremoni."}{" "}
          {type === "cremation"
            ? "Jag vill kremeras och spridas"
            : "Jag vill begravas i kista"}
          {burialPlace
            ? ` på en plats som ligger mig nära, ${burialPlace}`
            : ""}
          .
        </Text>
        <Text style={styles.text}>{auto ? textArray[2] + "." : ""}</Text>
        {color.trim().length !== 0 ||
        flower.trim().length !== 0 ||
        music.trim().length !== 0 ? (
          <Text style={styles.text}>
            Jag älskar {color ? `färgen ${color}` : ""}
            {flower && color && !music
              ? " och "
              : flower && music && color
              ? ", "
              : ""}
            {flower ? `blomman ${flower}` : ""}
            {music ? ` och likaså låten ${music}` : ""}.
          </Text>
        ) : null}
        <Text style={styles.text}>
          {auto
            ? textArray[3] + ". " + textArray[4] + ". " + textArray[5] + ". "
            : ""}
        </Text>
        <Text style={styles.text}>
          Se alla övriga detaljer i mitt digitala kassaskåp hos Fenix Family.
        </Text>
        {/*<Text className="hidden" style={styles.text}>*/}
        {/*  *Copywrite poem*/}
        {/*</Text>*/}
      </Page>
    </Document>
  );
}
