import React from "react";

const gifThumb = new URL("../assets/images/conversation_image.jpg", import.meta.url);

const GifSection = ({ buttonOnClick }: { buttonOnClick: () => void }) => {
  return (
    <div className="mx-auto px-5 pb-10 pt-20 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pt-36 xl:max-w-[1360px]">
      <div className="ml-auto flex max-w-[1205px] flex-col items-center justify-between gap-5 lg:flex-row lg:gap-10">
        <div className="max-w-[530px]">
          <h2 className="mb-5 font-interblack text-[28px] italic leading-[31px]  lg:text-[48px] lg:leading-[53px]">
            Hur mycket livsplanering behöver du?
          </h2>
          <p className="font-interlight text-base leading-[22px]">
            Kanske har du redan allt på plats, kanske har du inte ens tänkt tanken att planera ditt liv lite noggrannare. För att vi ska kunna förstå bättre vad dina behov är, behöver vi lära känna varandra lite. Börja därför med att svara på några frågor om din livssituation och hur du vill förbättra den med hjälp av juridik och tydliga avtal. Tryck på knappen här nedanför för att sätta igång!
          </p>
          <div className="mt-10 mb-10 inline-block lg:mb-0">
            <button
              className="min-w-[260px] justify-center rounded-[4px] border-[#0C2632] bg-deep-blue px-5 py-4 font-internormal text-[14px] font-semibold uppercase tracking-[0.12em] text-[#fff] hover:border-[#6F86A0] hover:bg-[#6F86A0] lg:gap-3 lg:p-4 lg:px-4 lg:py-[14px]"
              onClick={buttonOnClick}
              title="Gör vår behovsanalys"
              id="conv-start-home"
            >Gör vår behovsanalys</button>
          </div>
        </div>
        <div className="max-w-[530px]">
          <img
            src={gifThumb.href}
            alt="conversation flow"
            className="min-h-[510px] object-cover drop-shadow-[4px_2px_35px_0px_rgba(0,0,0,0.08)]"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default GifSection;
