import {
  createContext,
  PropsWithChildren,
  useContext,
  useLayoutEffect,
  useState,
} from "react";

const ServiceSectionContext = createContext({});

export const ServiceSectionProvider = ({ children }: PropsWithChildren) => {
  const [focusItem, setFocusItem] = useState<number>();
  const [compactMode, setCompactMode] = useState(true);

  const toggleMode = (ref: number, mode = false) => {
    setFocusItem(ref);
    setCompactMode(mode);
  };

  useLayoutEffect(() => {
    if (focusItem === undefined) {
      return;
    }

    let int: NodeJS.Timeout;
    const el = document.getElementById(`el-${focusItem}`);
    if (el) {
      int = setTimeout(() => {
        window.scrollTo({
          top: el.offsetTop - 50,
          behavior: "smooth",
        });
      }, 50);
    } else {
      int = setTimeout(() => {
        const serviceContainer = document.getElementById("services-list");
        if (serviceContainer) {
          window.scrollTo({
            top: serviceContainer.offsetTop - 50,
            behavior: "smooth",
          });
        }
      }, 50);
    }
    if (int) {
      return () => {
        clearTimeout(int);
      };
    }
  }, [focusItem]);

  return (
    <ServiceSectionContext.Provider
      value={{ focusItem, toggleMode, compactMode }}
    >
      {children}
    </ServiceSectionContext.Provider>
  );
};

export function useServiceSectionContext() {
  return useContext(ServiceSectionContext);
}
