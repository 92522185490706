import { ConvItem } from "../types/conversation";

export const conversationPublicData: ConvItem[] = [
  {
    id: "1",
    title: "Vilken livssituation stämmer bäst in på dig?",
    name: "life_status",
    input: false,
    select: false,
    options: [
      {
        value: "1",
        label: "Gift med barn",
      },
      {
        value: "2",
        label: "Sambo med barn",
      },
      { value: "3", label: "Ensamstående med barn" },
      {
        value: "4",
        label: "Jag har inga barn",
      },
    ],
    multiple: false,
    type: "question",
  },
  {
    id: "1.1.1",
    title:
      "Som gifta delas era tillgångar lika vilket kan vara bara att ha i åtanke om du äger något som ska vara enskild egendom. Tjänar och äger ni lika mycket?",
    name: "l1.1.1",
    input: false,
    select: false,
    multiple: false,
    type: "line",
    dependencies: [{ type: "question", id: "1", values: ["1"], op: "and" }],
  },
  {
    id: "1.1.2",
    title:
      "Alla föräldrar är villiga att göra allt för att hjälpa sina barn, vissa kan behöva mer hjälp i olika skeden av livet. Ekonomisk orättvisa är en vanlig anledning till att konflikter uppstår syskon emellan. Vad är rättvist för dig?",
    name: "l1.1.2",
    input: false,
    select: false,
    multiple: false,
    type: "line",
    dependencies: [{ type: "question", id: "1", values: ["1"], op: "and" }],
  },
  {
    id: "1.1.3",
    title:
      "Att du är gift innebär inte att din make blir fullmaktsinnehavare om du inte längre kan ta hand om dig själv. Vem tar besluten åt dig när du inte längre kan göra det själv?",
    name: "l1.1.3",
    input: false,
    select: false,
    multiple: false,
    type: "line",
    dependencies: [{ type: "question", id: "1", values: ["1"], op: "and" }],
  },
  {
    id: "1.1.4",
    title:
      "Din make ärver allt efter din bortgång och kan alltså förbruka arvet och därmed göra dina barn arvslösa. Vill du säkra dina barns framtida arv?",
    name: "l1.1.4",
    input: false,
    select: false,
    multiple: false,
    type: "line",
    dependencies: [{ type: "question", id: "1", values: ["1"], op: "and" }],
  },
  {
    id: "1.2.1",
    title:
      "Som sambo delas endast bostad och bohag som skaffats för gemensamt bruk lika. Tjänar och äger ni lika mycket?",
    name: "l1.2.1",
    input: false,
    select: false,
    multiple: false,
    type: "line",
    dependencies: [{ type: "question", id: "1", values: ["2"], op: "and" }],
  },
  {
    id: "1.2.2",
    title:
      "Alla föräldrar är villiga att göra allt för att hjälpa sina barn, vissa kan behöva mer hjälp i olika skeden av livet. Ekonomisk orättvisa är en vanlig anledning till att konflikter uppstår syskon emellan. Vad är rättvist för dig?",
    name: "l1.2.2",
    input: false,
    select: false,
    multiple: false,
    type: "line",
    dependencies: [{ type: "question", id: "1", values: ["2"], op: "and" }],
  },
  {
    id: "1.2.3",
    title:
      "Att du är sambo innebär inte att din partner blir fullmaktsinnehavare om du inte längre kan ta hand om dig själv. Vem tar besluten åt dig när du inte längre kan göra det själv?",
    name: "l1.2.3",
    input: false,
    select: false,
    multiple: false,
    type: "line",
    dependencies: [{ type: "question", id: "1", values: ["2"], op: "and" }],
  },
  {
    id: "1.2.4",
    title:
      "Dina barn ärver allt efter din bortgång till skillnad från din sambo som istället blir arvslös. Är du säker på att din sambo kan bo kvar i er bostad om du går bort?",
    name: "l1.2.4",
    input: false,
    select: false,
    multiple: false,
    type: "line",
    dependencies: [{ type: "question", id: "1", values: ["2"], op: "and" }],
  },
  {
    id: "1.3.1",
    title:
      "Alla föräldrar är villiga att göra allt för att hjälpa sina barn, vissa kan behöva mer hjälp i olika skeden av livet. Ekonomisk orättvisa är en vanlig anledning till att konflikter uppstår syskon emellan. Vad är rättvist för dig?",
    name: "l1.3.1",
    input: false,
    select: false,
    multiple: false,
    type: "line",
    dependencies: [{ type: "question", id: "1", values: ["3"], op: "and" }],
  },
  {
    id: "1.3.2",
    title:
      "Som ensamstående kommer du bli tilldelad en fullmaktsinnehavare om du inte längre kan ta hand om dig själv. Vem tar besluten åt dig när du inte längre kan göra det själv?",
    name: "l1.3.2",
    input: false,
    select: false,
    multiple: false,
    type: "line",
    dependencies: [{ type: "question", id: "1", values: ["3"], op: "and" }],
  },
  {
    id: "1.3.3",
    title:
      "Dina barn ärver allt efter din bortgång som giftorättsgods vilket innebär att de, vid en separation, kan tvingas ge halva arvet till den de separerar från. Vill du säkerställa att ditt arv kommer dina barn till nytta?",
    name: "l1.3.3",
    input: false,
    select: false,
    multiple: false,
    type: "line",
    dependencies: [{ type: "question", id: "1", values: ["3"], op: "and" }],
  },
  {
    id: "1.4.1",
    title:
      "Du kommer bli tilldelad en förvaltare om du inte längre kan ta hand om dig själv ifall du inte utsett någon sedan tidigare. Vem tar besluten åt dig när du inte längre kan göra det själv?",
    name: "l1.4.1",
    input: false,
    select: false,
    multiple: false,
    type: "line",
    dependencies: [{ type: "question", id: "1", values: ["4"], op: "and" }],
  },
  {
    id: "1.4.2",
    title:
      "Dina föräldrar, syskon eller syskonbarn kommer att ärva allt efter din bortgång. Är du säker på att ditt arv fördelas enligt dina önskemål?",
    name: "l1.4.2",
    input: false,
    select: false,
    multiple: false,
    type: "line",
    dependencies: [{ type: "question", id: "1", values: ["4"], op: "and" }],
  },
  {
    id: "2",
    title: "Äger du din bostad?",
    name: "house_status",
    input: false,
    select: false,
    options: [
      { value: "1", label: "Jag äger min bostad" },
      { value: "2", label: "Jag hyr min bostad" },
    ],
    multiple: false,
    type: "question",
  },
  {
    id: "2.1",
    title:
      "Chockerande nog saknar över 20% av Sveriges hushåll en hemförsäkring vilket kan bli en mycket dyr historia. Är du en av dom?",
    name: "l2.1",
    input: false,
    select: false,
    multiple: false,
    type: "line",
  },
  {
    id: "2.1.1",
    title: "Din försäkringssituation förändras när ditt liv förändras.",
    name: "l2.1.1",
    input: false,
    select: false,
    multiple: false,
    type: "line",
  },
  {
    id: "3.1",
    title: "Låt oss ta tag i detta tillsammans.",
    name: "l3.1",
    input: false,
    select: false,
    multiple: false,
    type: "line",
  },
  {
    id: "3.2",
    title:
      "Klicka på knappen nedan för att logga in tryggt och säkert med ditt BankID.",
    name: "l3.2",
    input: false,
    select: false,
    multiple: false,
    type: "line",
    delay: 300,
  },
];

export const conversationData: ConvItem[] = [
  {
    id: "1.0",
    title: "Låt oss börja med det grundläggande.",
    name: "l1",
    input: false,
    select: false,
    multiple: false,
    type: "line",
    skip: true,
  },
  {
    id: "1.1.1",
    title: "Du kan redigera dina svar när du svarat på alla frågor.",
    name: "l1",
    input: false,
    select: false,
    multiple: false,
    type: "line",
    skip: true,
  },
  {
    id: "2.1",
    title: "Vad vill du uppnå tillsammans med oss på Fenix Family?",
    name: "goal",
    input: false,
    select: false,
    options: [
      {
        value: "4",
        label: "vill skapa ordning och trygghet, nu och inför framtiden",
      },
      {
        value: "5",
        label:
          "vill att en närstående får ärva så mycket eller så lite som möjligt",
      },
      { value: "6", label: "vet inte riktigt vad jag har för behov" },
      { value: "1", label: "vill ge bort fastighet 🏠" },
      { value: "2", label: "vill ge bort lös egendom ⛵🚗💍" },
      { value: "3", label: "vill låna ut pengar" },
    ],
    multiple: true,
    type: "question",
  },
  {
    id: "3.1",
    title: "Vad är din civilstatus?",
    name: "marital_status",
    input: false,
    select: false,
    options: [
      { value: "6", label: "gift" },
      { value: "4", label: "sambo" },
      { value: "1", label: "singel eller särbo" },
      { value: "7", label: "skild" },
      { value: "3", label: "på väg att bli sambo" },
      { value: "8", label: "änka eller änkling" },
    ],
    multiple: true,
    type: "question",
  },
  {
    id: "3.1.1",
    title: "Har din avlidne maka eller make fått ut sitt arv?",
    name: "spouse_inheritance",
    input: false,
    select: false,
    options: [
      { value: "1", label: "Ja" },
      { value: "2", label: "Nej" },
    ],
    multiple: false,
    dependencies: [{ type: "question", id: "3.1", values: ["8"], op: "and" }],
    type: "question",
  },
  {
    id: "3.2",
    title: "Har du barn?",
    name: "kids",
    input: false,
    select: false,
    options: [
      { value: "1", label: "har inga barn" },
      { value: "2", label: "har barn i nuvarande relation" },
      { value: "3", label: "har barn från en tidigare relation (Särkullbarn)" },
      { value: "4", label: "har bonusbarn" },
      { value: "5", label: "väntar barn" },
    ],
    multiple: true,
    type: "question",
  },
  {
    id: "3.3",
    title: "Är dina barn försäkrade?",
    name: "kids_insurance",
    input: false,
    select: false,
    options: [
      { value: "1", label: "ja, barnen är försäkrade" },
      { value: "2", label: "nej, barnen är inte försäkrade" },
      { value: "3", label: "jag vet inte om barnen är försäkrade" },
    ],
    multiple: false,
    dependencies: [
      { type: "question", id: "3.2", values: ["2", "3", "4"], op: "or" },
    ],
    type: "question",
  },
  {
    id: "3.4",
    title: "Vilka av dina närmaste är i livet?",
    name: "family_alive",
    options: [
      { value: "1", label: "mina föräldrar" },
      { value: "2", label: "ett eller flera syskon" },
      { value: "3", label: "ett eller flera syskonbarn" },
      { value: "4", label: "en eller flera mor- och farföräldrar" },
      {
        value: "5",
        label: "en eller flera mostrar, morbröder, fastrar eller farbröder",
      },
      {
        value: "6",
        label: "Ingen av ovanstående",
      },
    ],
    multiple: true,
    input: false,
    select: false,
    type: "question",
  },
  {
    id: "3.4.1",
    title:
      "Bra. Utifrån de svar du gett kan vi redan nu se att du skulle ha nytta av ett testamente.",
    name: "l3",
    input: false,
    select: false,
    multiple: false,
    type: "line",
  },
  {
    id: "3.5",
    title: "Har du någon du litar på som kan ta hand om dig på ålderns höst?",
    name: "trust_person",
    options: [
      {
        value: "1",
        label:
          "har någon som kan hjälpa mig om jag får svårt att ta hand om mig själv",
      },
      {
        value: "2",
        label:
          "har just nu ingen som kan hjälpa mig om jag får svårt att ta hand om mig själv",
      },
    ],
    multiple: false,
    input: false,
    select: false,
    dependencies: [
      { type: "question", id: "3.1", values: ["1", "8"], op: "or" },
      { type: "age", value: 50, condition: "greater" },
    ],
    type: "question",
  },
  {
    id: "3.6",
    title: "Har du några husdjur?",
    name: "pets",
    options: [
      { value: "1", label: "har husdjur" },
      { value: "2", label: "har inga husdjur" },
    ],
    multiple: false,
    input: false,
    select: false,
    type: "question",
  },
  {
    id: "4.0",
    title: "Har du några tillgångar?",
    name: "assets",
    input: false,
    select: false,
    multiple: false,
    options: [
      { value: "1", label: "ja" },
      { value: "2", label: "nej" },
    ],
    type: "question",
  },
  {
    id: "4.2",
    title: "Vad består dina tillgångar av?",
    name: "assets_desc",
    input: false,
    select: false,
    multiple: true,
    options: [
      { value: "1", label: "sparande 💶" },
      { value: "2", label: "fastighet eller bostadsrätt 🏠" },
      {
        value: "3",
        label: "lös egendom ⛵🚗💍",
      },
      {
        value: "4",
        label: "värdepapper (aktier eller obligationer) 📈",
      },
    ],
    dependencies: [{ type: "question", id: "4.0", values: ["1"], op: "and" }],
    type: "question",
  },
  {
    id: "4.1",
    title: "Hur stora är dina tillgångar?",
    name: "assets_amount",
    input: false,
    select: true,
    options: [
      { value: "1", label: "0 - 10 000 kr" },
      { value: "2", label: "11 000 - 25 000 kr" },
      { value: "3", label: "26 000 - 50 000 kr" },
      { value: "4", label: "51 000 - 100 000 kr" },
      { value: "5", label: "101 000 - 250 000 kr" },
      { value: "6", label: "250 001 - 500 000 kr" },
      { value: "7", label: "500 001 - 750 000 kr" },
      { value: "8", label: "750 001 - 1 000 000 kr" },
      { value: "9", label: "mer än 1 000 000 kr" },
    ],
    inputPlaceholder: "Ange dina tillgångar",
    multiple: false,
    dependencies: [{ type: "question", id: "4.0", values: ["1"], op: "and" }],
    type: "question",
  },
  {
    id: "4.3",
    title: "Planerar du att börja spara eller göra investeringar i någon form?",
    name: "investing",
    options: [
      {
        value: "1",
        label: "kortsiktigt (till exempel kontantbuffert eller för konsumtion)",
      },
      {
        value: "2",
        label: "långsiktigt (till exempel fonder eller aktier)",
      },
      {
        value: "3",
        label: "både kortsiktigt och långsiktigt",
      },
      { value: "4", label: "nej" },
    ],
    input: false,
    select: false,
    multiple: false,
    type: "question",
  },
  {
    id: "4.4",
    title: "Har du några lån? (bortse från studielån)",
    name: "debt",
    input: false,
    select: false,
    multiple: false,
    options: [
      { value: "1", label: "har lån" },
      { value: "2", label: "har inte lån" },
    ],
    type: "question",
  },
  {
    id: "4.6",
    title: "Vad har du för lån?",
    name: "debt_desc",
    input: false,
    select: false,
    multiple: true,
    options: [
      { value: "1", label: "bolån" },
      { value: "2", label: "övriga lån" },
    ],
    dependencies: [{ type: "question", id: "4.4", values: ["1"], op: "and" }],
    type: "question",
  },
  {
    id: "4.5",
    title: "Hur stora är dina lån?",
    name: "debt_amount",
    input: false,
    select: true,
    options: [
      { value: "1", label: "0 - 10 000 kr" },
      { value: "2", label: "11 000 - 25 000 kr" },
      { value: "3", label: "26 000 - 50 000 kr" },
      { value: "4", label: "51 000 - 100 000 kr" },
      { value: "5", label: "101 000 - 250 000 kr" },
      { value: "6", label: "250 001 - 500 000 kr" },
      { value: "7", label: "500 001 - 750 000 kr" },
      { value: "8", label: "750 001 - 1 000 000 kr" },
      { value: "9", label: "mer än 1 000 000 kr" },
    ],
    inputPlaceholder: "Ange dina skulder",
    multiple: false,
    dependencies: [{ type: "question", id: "4.4", values: ["1"], op: "and" }],
    type: "question",
  },
  {
    id: "4.5.1",
    title:
      "Skriv in din mailadress så att vi kan kontakta dig med råd och rekommendationer.",
    name: "email",
    input: true,
    inputType: "email",
    select: false,
    inputPlaceholder: "Skriv din mailadress här",
    multiple: false,
    type: "question",
    skip: true,
  },
  {
    id: "4.6.1",
    title: "Tack, det var alla frågor.",
    name: "l4",
    input: false,
    select: false,
    multiple: false,
    type: "line",
    delay: 2000,
  },
];
