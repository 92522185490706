import React from "react";

export default function ErrorFormMessage({
  message,
}: {
  message: string | boolean;
}) {
  if (!message) {
    return null;
  }
  return <div className="mt-2 text-[13px] text-red-500">{message}</div>;
}
