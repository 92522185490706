import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { productAffiliateList, steps } from "../assets/data/datalist";
import AdvisorToggleSection from "../components/AdvisorToggleSection";
import ConversationEmbedded from "../components/ConversationEmbedded";
import Footer from "../components/Footer";
import GifSection from "../components/GifSection";
import HeadMenu from "../components/HeadMenu";
import PackageSection from "../components/PackageSection";
import PartnersSection from "../components/PartnersSection";
import ServicesSingleSection from "../components/ServicesSingleSection";
import ArticlesSlider from "../components/slider/ArticlesSlider";
import ReviewsSlider from "../components/slider/ReviewsSlider";
import TrustBox from "../components/TrustBox";
import { ServiceSectionProvider } from "../context/ServiceSectionContext";
import { useDevice } from "../services/hooks/useDevice";
import useFetchPosts from "../services/hooks/useFetchPosts";
import {
  getBankIdIcon,
  getButtonArrow,
  getChatIcon,
  getDownArrowIcon,
} from "../services/icons";
import { defaultPackagePrice } from "../services/partners";
import { homeRedirectKey, setInStorage } from "../services/storage";
import { useScrollPosition } from "../services/utils";

const gifThumb = new URL(
  "../assets/video/image_thumb_tn.jpg",
  import.meta.url
);
const videoMain = "https://static.fenixbegravning.se/fenix_family_main.mp4";
const videoMainThumb = new URL("../assets/video/main.jpg", import.meta.url);
const aboutUsImg = new URL("../assets/images/about_Fenix.jpg", import.meta.url);

export default function HomePage({ title }: { title?: string }) {
  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }
  const [showConversationModal, setShowConversationModal] = useState(false);
  const refVideo = useRef();
  const [isHover, setIsHover] = useState(false);
  const scrollPosition = useScrollPosition();

  const renderLoginButton = (buttonClass: string, iconColor: string) => {
    return (
      <Link
        to="/login"
        className={
          buttonClass +
          ` inline-flex items-center gap-2 rounded border-[1px] border-deep-blue bg-deep-blue py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] text-white lg:gap-3 lg:self-end`
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover
          ? getBankIdIcon("#fff", "20", "100%")
          : getBankIdIcon(iconColor, "20", "100%")}
        Kom igång
      </Link>
    );
  };

  const postData = useFetchPosts(
    `${process.env.STRAPI_API_URL}/api/articles?populate=*`,
    `${process.env.STRAPI_API_URL}/api/secondary-articles?populate=*`,
    `${process.env.STRAPI_API_URL}/api/article-categories?populate=*`
  );

  const childCategories = postData.categoriesList?.data
    ?.filter((item: any) => item?.attributes.parent?.data.some((it: any) => it))
    ?.sort((a: any, b: any) =>
      a.attributes.slug > b.attributes.slug
        ? 1
        : b.attributes.slug > a.attributes.slug
        ? -1
        : 0
    );

  useEffect(() => {
    setInStorage(homeRedirectKey, window.location.pathname);
    document.title = title!;
  }, []);

  useEffect(() => {
    if (showConversationModal) {
      setShowConversationModal(false);
    }
  }, [showConversationModal]);

  return (
    <ServiceSectionProvider>
      <div className="relative overflow-hidden bg-[#FFFEFB]">
        <div
          className={classNames(
            scrollPosition > 84 ? "bg-white" : "bg-transparent",
            "fixed z-30 w-full transition-colors"
          )}
        >
          <HeadMenu
            sticky={scrollPosition > 84}
            theme={"light"}
            color={scrollPosition > 84 ? "#0C2632" : "#fff"}
          />
        </div>
        <div className="relative w-full text-white">
          <div className="absolute top-0 z-10 h-[80px] w-full lg:h-[120px]"></div>
          <video
            ref={refVideo}
            autoPlay
            playsInline
            muted
            loop
            src={videoMain}
            className="h-full min-h-screen w-screen object-cover"
            style={{ transform: "scale(1.0)" }}
            preload="auto"
            poster={videoMainThumb.href}
          />
          <div className="absolute top-0 z-20 w-full">
            <div className="px-5">
              <div className="container mx-auto pb-10">
                <div
                  className={`relative flex ${
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useDevice().isMobile && useDevice().mobileOS === "ios"
                      ? "h-[100dvh]"
                      : "h-screen"
                  } flex-col items-center justify-between`}
                >
                  <div className="flex grow flex-col justify-center">
                    <h1 className="max-w-[750px] text-center font-interblack text-[36px] italic leading-tight text-[#FFFEFB] drop-shadow-xl lg:text-[64px]">
                      Livsplanering - tryggt och enkelt
                    </h1>
                  </div>
                  <div className="absolute bottom-6 left-2/4 flex w-full -translate-x-2/4 flex-row justify-center uppercase tracking-[0.25em] text-[#FFFEFB]">
                    <div className="text-center">
                      välkommen till <br />
                      fenix family
                    </div>
                    <div className="self-end">{getDownArrowIcon()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full bg-white text-deep-blue lg:pb-20">
          <div className="mx-auto px-5 pb-16 pt-20 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-20 lg:pt-24 xl:max-w-[1360px]">
            <div className="max-w-[1000px]">
              <h2 className="mb-4 font-interblack text-[28px] italic leading-[31px] tracking-wide lg:text-[48px] lg:leading-[53px]">
                Gör upp en plan utifrån din nuvarande och framtida
                livssituation.
              </h2>
              <p className="mb-10 font-interlight text-[18px] leading-[24px]">
                Vi vill hjälpa dig att göra upp en plan för framtiden baserat på
                din livssituation. Vi kallar det Livsplanering. Det innebär att
                vi utifrån ett testamente tar fram en unik livsplan för dig, din
                familj, dina tillgångar och eventuella lån. Planen innefattar
                juridiska avtal som testamente och framtidsfullmakt samt
                praktiska förberedelser för att du ska få lugn och ro här och nu
                och i framtiden. Alla dina avtal finns sedan säkert förvarade i
                ditt digitala kassaskåp. Vi förvarar originalet av ditt
                testamente fysiskt och övervakar det så att allt blir precis som
                du önskar när testamentet väl ska verkställas. En livsplanering
                kan spara dig och de dina mycket besvär, både nu och i
                framtiden.
              </p>
              <div className="flex flex-col items-start lg:flex-row lg:items-end">
                {renderLoginButton(
                  `bg-deep-blue text-white hover:border-[#6F86A0] hover:bg-[#6F86A0]`,
                  `#fff`
                )}
                <div className="-ml-4 mt-5 lg:ml-0 lg:mt-0">
                  <TrustBox
                    template={"5419b732fbfb950b10de65e5"}
                    height={"24px"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            id="vara-jurister"
            className="mx-auto px-5 pt-6 pb-16 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-10 xl:max-w-[1360px]"
          >
            <AdvisorToggleSection />
          </div>
          <div className="pb-216 mx-auto px-5 pt-12 pb-10 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pt-16 lg:pb-40 xl:max-w-[1360px]">
            <ServicesSingleSection />
          </div>
          <PackageSection
            price={defaultPackagePrice}
            displayTrustWidget={true}
            productButton={true}
            list={productAffiliateList}
          />
        </div>
        <div className="relative w-full bg-[#F3F0E680] text-deep-blue">
          <div className="mx-auto px-5 pt-16 pb-10 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-20 lg:pt-24 xl:max-w-[1360px]">
            <div className="flex flex-col gap-3 lg:flex-row lg:items-end lg:justify-between">
              <div className="w-full max-w-[760px]">
                <img
                  src={gifThumb.href}
                  alt="conversation flow"
                  className="min-h-[510px] object-cover drop-shadow-[4px_2px_35px_0px_rgba(0,0,0,0.08)]"
                  loading="lazy"
                />
              </div>
              <div className="max-w-[530px]">
                <h3 className="mb-3 mt-10 font-interblack text-[22px] font-extrabold italic leading-[22px] lg:text-[36px] lg:leading-[40px]">
                  Ett snabbt, tryggt och enkelt sätt att reda ut svåra saker.
                </h3>
                <p className="font-interlight text-base leading-[22px]">
                  Korrekt skrivna avtal för livets olika skeden kan bespara dig 
                  och din familj mycket besvär. I vår värld är de här sakerna inte 
                  heller något dyrt eller krångligt, utan går nästan lika snabbt 
                  och enkelt som att dricka en kopp kaffe.
                </p>
              </div>
            </div>
          </div>
          <div className="mx-auto px-5 pt-16 pb-28 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-28 lg:pt-16 xl:max-w-[1360px]">
            <div className="mx-auto mb-10 flex max-w-[1100px] flex-col items-center justify-around gap-10 lg:flex-row lg:items-start">
              {steps.map((step, index) => (
                <div
                  key={step.title}
                  className="flex shrink-0 grow basis-0 flex-col items-center justify-center gap-5"
                >
                  <div className="flex h-[152px] w-[152px] items-center justify-center rounded-full bg-white">
                    <img
                      src={step.icon.href}
                      className="w-[50px]"
                      loading="lazy"
                      alt="Fenix"
                    />
                  </div>
                  <div className="flex max-w-[310px] flex-col text-center text-dark-blue">
                    <p className="mb-4 font-intermedium text-base uppercase tracking-[0.25em]">
                      Steg {index + 1}
                    </p>
                    <p className="font-internormal text-[13px] leading-[15px] ">
                      {step.title}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center">
              {renderLoginButton(
                `bg-deep-blue text-white hover:border-[#6F86A0] hover:bg-[#6F86A0]`,
                `#fff`
              )}
            </div>
          </div>
          <GifSection buttonOnClick={() => setShowConversationModal(true)} />
        </div>
        <div className="relative w-full bg-white text-deep-blue">
          <div className="mx-auto flex flex-col items-center px-5 pt-28 pb-16 sm:max-w-[640px] md:max-w-[700px] lg:max-w-[1000px] lg:pb-10 lg:pt-24 xl:max-w-[1000px]">
            <h2 className="text-center font-intermedium text-base font-semibold uppercase tracking-[0.25em]">
              {title}
            </h2>
            <PartnersSection />
            <ReviewsSlider />
            <Link
              className="mt-8 flex min-w-[260px] justify-center space-x-3 rounded-[4px] border-[#0C2632] bg-deep-blue px-2 py-4 font-internormal text-[14px] font-semibold uppercase tracking-[0.12em] text-[#fff] hover:border-[#6F86A0] hover:bg-[#6F86A0] lg:gap-3 lg:space-x-1 lg:px-5 lg:py-[16px] lg:pb-4"
              to="#"
              onClick={() =>
                (window.location =
                  "mailto:pre-need@fenixfamily.se?subject=Bli partner med Fenix Family&body=Hej,%0D%0A%0D%0AJag är intresserad av att veta mer om ett framtida partnerskap med Fenix Family och önskar att ni kontaktar mig på denna mailadress inom kort.%0D%0A%0D%0ATack på förhand!%0D%0A%0D%0AMed vänlig hälsning,")
              }
            >
              {getChatIcon("#FFFEFB", "18px", "18px")}{" "}
              <span>Bli vår nya partner</span>
            </Link>
          </div>
        </div>
        <div className="relative w-full text-deep-blue">
          <div className="mx-auto px-5 pt-10 pb-12 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-36 xl:max-w-[1360px]">
            <div className="pt-2 pb-20 lg:pt-14 lg:pb-20">
              <h2 className="mb-6 max-w-[400px] font-intermedium text-base uppercase tracking-[0.25em] lg:mb-3">
                Här vi samlat svar på vanliga frågor
              </h2>
              <ArticlesSlider
                articles={postData?.posts?.data.slice(0, 4)}
                init={false}
                quantity={4}
                childCategories={childCategories}
              />
            </div>
            <div className="relative flex flex-col overflow-hidden rounded-[8px] bg-[#f3f1e8] text-dark-blue lg:flex-row lg:justify-between">
              <div className="flex flex-1 flex-col items-start justify-center px-6 pt-10 pb-10 lg:px-10 lg:pt-10 lg:pb-6 xl:px-28 xl:py-24">
                <h2 className="mb-3 max-w-lg font-interblack text-[32px] italic leading-none lg:mb-5 lg:text-[48px] lg:leading-[53px]">
                  Empati, erfarenhet och kunskap
                </h2>
                <div
                  className="max-w-lg font-interlight"
                  dangerouslySetInnerHTML={{
                    __html:
                      "Fenix Family föddes ur Fenix Begravning där vi ofta fick frågor om andra saker än själva begravningsceremonin. För i livets slutskede ställs ofta ekonomi, juridik och hur man planerat både sitt liv och framtiden för sina närmaste, på sin spets. \n" +
                      "Idag kan vi hjälpa alla som vill skapa testamenten, bouppteckningar, äktenskapsförord, avtal och fullmakter, med en enkel, trygg och digital lösning på problem som ofta kan upplevas som känsliga och svåra. ",
                  }}
                />
                <Link
                  to={"/om-oss"}
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "auto",
                    });
                  }}
                  className="mt-7 flex justify-center border-transparent bg-transparent !px-0 py-4 font-intermedium !text-[14px] uppercase tracking-[0.12em] !text-deep-blue hover:underline lg:mt-9 lg:px-0 lg:py-[16px]"
                >
                  läs mer om fenix family{" "}
                  {getButtonArrow("#0C2632", "20", "100%")}
                </Link>
              </div>
              <div className="relative max-h-[325px] flex-1 overflow-hidden object-cover lg:block lg:max-h-[655px] lg:max-w-[535px] lg:rounded-r-[8px] lg:rounded-t-[0]">
                <img
                  src={aboutUsImg.href}
                  alt="Fenix"
                  className="relative h-full w-full object-cover object-right-top"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ConversationEmbedded
        showInline={false}
        showModalOverride={showConversationModal}
      />
    </ServiceSectionProvider>
  );
}
