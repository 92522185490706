import { BlobProvider, usePDF } from "@react-pdf/renderer";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useConvContext } from "../../context/ConversationContext";
import { useUserContext } from "../../context/UserContext";
import {
  getPriceForAllContracts,
  getRecommendedContracts,
} from "../../services/contracts";
import { getPackageById } from "../../services/ida";
import { setUserPackage } from "../../services/ida";
import { sendCmpEvent } from "../../services/tracking";
import { encodeBase64, isDesktopView } from "../../services/utils";
import Button from "../Button";
import LoaderScreen from "../LoaderScreen";
import Modal from "../Modal";
import PdfPreview from "../PdfPreview";
import PdfWillPreview from "../PdfWillPreview";
import { RecommendationCard } from "../RecommendationCard";

const docPreview = new URL(
  "../../assets/images/doc-preview.png",
  import.meta.url
);

const cardShadow = new URL(
  "../../assets/images/card-shadow.svg",
  import.meta.url
);

export default function Package() {
  const [contracts, setContracts] = useState();
  const [colWidth, setColWidth] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [paket, setPaket] = useState();
  const [previewModal, setPreviewModal] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);

  const navigate = useNavigate();
  const cardWrpRef = useRef<HTMLDivElement>(null);
  const { user } = useUserContext();
  const { conversation } = useConvContext();

  const [pdfIns] = usePDF({
    document: (
      <PdfWillPreview
        name={`${user.info?.firstName} ${user.info?.lastName}`}
        personNumber={user.profile ? user.profile.personNumber : ""}
      />
    ),
  });

  useEffect(() => {
    const recCont = getRecommendedContracts(
      conversation.values,
      user.info?.age
    );
    setContracts(recCont);
  }, []);

  useEffect(() => {
    if (!user.accessToken) {
      return;
    }
    if (user.accessToken && user.pkg) {
      getPackageById(user.accessToken, user.pkg)
        .then((data) => {
          setPaket({
            price: parseInt(data.totalDetails.total),
          });
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user]);

  useEffect(() => {
    if (!cardWrpRef.current || !paket) {
      return;
    }

    setColWidth(cardWrpRef.current.clientWidth);
  }, [paket]);

  useEffect(() => {
    if (pdfIns.blob && !pdfIns.loading) {
      const reader = new FileReader();
      reader.readAsDataURL(pdfIns.blob);
      reader.onloadend = function () {
        const base64String = reader.result;
        setPreviewFile({
          content: base64String,
          type: pdfIns.blob.type,
          filename: "",
        });
      };
    }
  }, [pdfIns]);

  const submitUserPackageAndPay = () => {
    if (!user.accessToken) {
      return;
    }
    if (user.accessToken && user.pkg) {
      setLoading(true);
      if (user.profile) {
        void sendCmpEvent("Mail_4", user.profile.email);
      }

      setUserPackage(user.accessToken, user.pkg)
        .then((data) => {
          const { stripeSubscriptionId, stripeSubscriptionStatus, id } = data;
          if (
            stripeSubscriptionId === null &&
            stripeSubscriptionStatus === "Active"
          ) {
            navigate("/vault");
          } else {
            const dt = encodeBase64({ s: stripeSubscriptionId, p: id });
            navigate(`/payment/${dt}`);
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setLoading(false);
        });
    }
  };

  if (loading) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }

  return (
    <div className="mx-auto mt-2 max-w-6xl lg:mt-10">
      <div className="mb-4 flex flex-col gap-7 lg:mb-8 lg:flex-row lg:gap-14">
        <div ref={cardWrpRef} className="relative">
          <div className="absolute hidden lg:block">
            <img src={cardShadow.href} className="w-auto lg:w-[340px]" />
          </div>
          {contracts &&
            contracts.map((contract, index) => {
              if (index > 1) return;

              let cl =
                "absolute top-[15px] left-[0] z-20 w-full lg:top-[25px] lg:left-[25px]";
              if (index === 0) {
                cl = "relative z-30 mt-[30px] ml-[0] lg:mt-[75px] lg:ml-[75px]";
              }
              if (contracts.length === 1) {
                cl = "relative z-30 mt-[30px] ml-[0] lg:mt-[25px] lg:ml-[30px]";
              }

              return (
                <div
                  key={index}
                  className={`w-auto !max-w-[100%] lg:w-[340px] ${cl} `}
                >
                  <RecommendationCard
                    theme={contract.bgColor}
                    title={contract.title}
                    subtitle={contract.subtitle}
                    price={contract.price}
                    desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  />
                </div>
              );
            })}
        </div>
        <div className="flex flex-col gap-5 lg:flex-row lg:gap-10">
          <div>
            <div className="mb-2 font-intermedium text-[24px]">
              Lugn&Ro-paket {paket?.price} kr
            </div>
            <ul className="list-outside list-disc pl-4">
              <li className="pb-2">
                Tillgång till hela vårt digitala avtalsutbud, till exempel
                samboavtal, äktenskapsförord, testamente och framtidsfullmakt.
              </li>
              <li className="pb-2">
                Trygg förvaring av alla dina viktiga avtal och dokument i ditt
                digitala kassaskåp.
              </li>
              <li className="pb-2">
                Ditt testamente skickas till dig för påskrift samt förvaras och
                övervakas därefter tryggt hos oss.
              </li>
              <li className="pb-2">
                Vägledning och erbjudanden inom sparande, pension och
                försäkringar baserat på våra digitala rekommendationer.
              </li>
              <li className="pb-2">
                12 månaders medlemskap (värde 449:-) ger dig tillgång till allt
                ovan.
              </li>
            </ul>
          </div>
          <div
            className="flex cursor-pointer flex-row items-center gap-2 lg:flex-col lg:gap-1"
            onClick={() => setPreviewModal(true)}
          >
            <img
              className="mx-auto w-[60px] lg:w-[220px]"
              src={docPreview.href}
            />
            <div className="text-[11px] uppercase tracking-[0.1em] underline lg:px-3 lg:text-center">
              förhandsvisning av ditt testamente
            </div>
          </div>
        </div>
      </div>
      <hr className="mb-6 lg:mb-8" />
      <div className="flex flex-row lg:gap-14">
        <div
          className="hidden lg:block"
          style={{ width: colWidth ? `${colWidth}px` : "initial" }}
        ></div>
        <div className="grow">
          <div className="mb-3 font-intermedium text-[20px] lg:mb-6 lg:text-[24px]">
            {user.info?.firstName}'s Lugn&Ro-paket:
          </div>
          <div className="mb-10 rounded-[5px] border-[#CFCAB8] lg:border-[1px] lg:bg-white lg:py-14 lg:px-20">
            <div className="flex flex-row items-center justify-between">
              <div className="font-interlight lg:text-[18px]">
                Hela Fenix avtalsutbud (värde)
              </div>
              <div className="font-intermedium lg:text-[18px]">
                {getPriceForAllContracts()} kr
              </div>
            </div>
            <div className="flex flex-row items-center justify-between">
              <div className="font-interlight lg:text-[18px]">
                Dina rekommendationer
              </div>
              <div className="font-intermedium lg:text-[18px]">0 kr</div>
            </div>
            <div className="flex flex-row items-center justify-between">
              <div className="font-interlight lg:text-[18px]">
                Digitalt kassaskåp
              </div>
              <div className="font-intermedium lg:text-[18px]">0 kr</div>
            </div>
            <div className="flex flex-row items-center justify-between">
              <div className="font-interlight lg:text-[18px]">
                12 mån medlemskap*
              </div>
              <div className="font-intermedium lg:text-[18px]">449 kr</div>
            </div>
            <div className="mt-6 flex flex-row items-center justify-between">
              <div className="font-intermedium text-[20px] uppercase lg:text-[22px]">
                du betalar
              </div>
              <div className="font-intermedium lg:text-[18px]">
                <span className="font-intermedium text-[20px] uppercase underline lg:text-[24px]">
                  {paket?.price}
                </span>{" "}
                kr
              </div>
            </div>
          </div>
          <Button
            onClick={submitUserPackageAndPay}
            title="KÖP DITT LUGN&RO-PAKET HÄR"
            cssClasses="mx-auto w-full !justify-center"
            id="buy-package"
          />
        </div>
      </div>
      {previewModal && (
        <Modal onClose={() => setPreviewModal(false)}>
          {previewFile && (
            <div className="flex justify-center">
              <PdfPreview file={previewFile.content} />
              <div className="absolute top-[240px] w-full lg:top-[340px]">
                <Button
                  onClick={submitUserPackageAndPay}
                  title="Färdigställ ditt testamente"
                  cssClasses="mx-auto max-w-[80%]"
                  id="buy-package-on-preview"
                />
              </div>
            </div>
          )}
        </Modal>
      )}
    </div>
  );
}
