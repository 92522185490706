import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
} from "react";

import { conversationData } from "../services/conversation";
import { getUserData, getUserDataByKey, setUserData } from "../services/ida";
import {
  conversationKey,
  getFromStorage,
  setInStorage,
} from "../services/storage";
import { dataDeepCopy } from "../services/utils";
import {
  ConvActionType,
  ConvContextType,
  ConvType,
} from "../types/conversation";
import { useUserContext } from "./UserContext";

const defaultConv = {
  index: 0,
  items: [],
  values: [],
  status: "init",
  restarted: false,
  public: false,
};
const initialConv = getFromStorage(conversationKey, defaultConv);

const ConversationContext = createContext({} as ConvContextType);
const ConversationDispatchContext =
  createContext<Dispatch<ConvActionType> | null>(null);

const conversationReducer = (state: ConvType, action: ConvActionType) => {
  switch (action.type) {
    case "set-status": {
      const newState = {
        ...state,
        status: action.payload.status,
      };

      if (action.payload.accessToken) {
        void setUserData(action.payload.accessToken, [
          {
            key: "conv",
            value: JSON.stringify(newState),
          },
        ]);
      }

      return newState;
    }
    case "set-recommendation-status-public": {
      return {
        ...state,
        status: "recommendation",
        public: true,
      };
    }
    case "update-values-for-item": {
      const itemId = action.payload.itemId;
      const itemValues = action.payload.itemValues;

      const prevValues = [...state.values];
      prevValues.map((pv) => {
        if (pv.id === itemId) {
          pv.value = itemValues;
        }
      });

      return {
        ...state,
        values: prevValues,
      };
    }
    case "set-values": {
      const prevValues = state.values.filter(
        (el) => el.id !== action.payload.obj.id
      );
      if (action.payload.answers.length === 0) {
        return {
          ...state,
          values: [...prevValues],
        };
      }
      return {
        ...state,
        values: [...prevValues, action.payload.obj],
      };
    }
    case "set-items":
      return {
        ...state,
        index: action.payload.index,
        items: [action.payload.item, ...state.items],
      };
    case "set-finished": {
      const newState = {
        ...state,
        status: action.payload.status,
        index: 0,
      };
      if (action.payload.accessToken) {
        void setUserData(action.payload.accessToken, [
          {
            key: "conv",
            value: JSON.stringify(newState),
          },
        ]);
      }
      return newState;
    }
    case "set-data":
      return {
        index: action.payload.index,
        items: action.payload.items,
        values: action.payload.values,
        status: action.payload.status,
      };
    case "set-from-storage":
      return {
        ...state,
        ...action.payload,
      };
    case "reset-to-finished-or-started":
      if (state.status === "started") {
        return state;
      }
      if (state.status === "init") {
        return { ...defaultConv, status: "started", restarted: true };
      }
      return { ...state, status: "finished" };
    case "set-packet-status":
      return { ...state, status: "packet" };
    case "clear":
      return { ...defaultConv, status: "started", restarted: true };
    case "start-clean":
      return { ...defaultConv, status: "started" };
  }
  return state;
};

export const ConversationProvider = ({ children }: PropsWithChildren) => {
  const [conversation, dispatch] = useReducer(conversationReducer, initialConv);
  const { user, setPackageId } = useUserContext();

  useEffect(() => {
    saveToStorage();
  }, [conversation]);
  useEffect(() => {
    if (conversation?.values) {
      setPackageId(conversation.values);
    }
  }, [conversation.values]);

  const populateFromStorage = () => {
    const localConv = getFromStorage(conversationKey, initialConv);
    if (localConv) {
      const data: ConvType = dataDeepCopy(localConv);
      dispatch({ type: "set-from-storage", payload: data });
    }
  };
  const saveToStorage = () => {
    setInStorage(conversationKey, conversation);
  };
  const nextItem = (nextIndex = null): void => {
    if (conversation.values.length !== conversation.items.length) {
      return;
    }
    const ind = nextIndex !== null ? nextIndex : conversation.index;
    const question = conversationData[ind];
    if (conversation.restarted && question?.skip) {
      nextItem(ind + 1);
      return;
    }
    if (!question) {
      dispatch({
        type: "set-finished",
        payload: {
          status: "finished",
          accessToken: user.accessToken,
        },
      });
      return;
    }

    if (question.dependencies) {
      const metDependencies = [];
      question.dependencies.map((dep) => {
        if (dep.type === "question") {
          const res = conversation.values.find((el) => {
            if (el.id === dep.id) {
              const metValues = [];
              const depValues = dep.values;
              const depOperator = dep.op;

              depValues.map((depValue) => {
                if (el.value.includes(depValue)) {
                  metValues.push(depValue);
                }
              });

              if (
                depOperator === "and" &&
                depValues.length === metValues.length
              ) {
                return true;
              }

              if (depOperator === "or" && metValues.length > 0) {
                return true;
              }

              return false;
            }
          });
          if (res) {
            metDependencies.push(dep);
          }
        } else if (dep.type === "age") {
          let res = false;
          const age = user.info ? user.info.age : 0;
          switch (dep.condition) {
            case "equal":
              res = age === dep.value;
              break;
            case "greater":
              res = age > dep.value;
              break;
            case "less":
              res = age < dep.value;
              break;
          }
          if (res) {
            metDependencies.push(dep);
          }
        }
      });

      if (metDependencies.length !== question.dependencies.length) {
        nextItem(ind + 1);
        return;
      }
    }

    dispatch({
      type: "set-items",
      payload: {
        item: question,
        index: ind + 1,
      },
    });
  };
  const fetchConversationData = (accessToken) => {
    return getUserDataByKey(accessToken, "conv")
      .then((data) => {
        // const convRaw = data.find((el) => el.key === "conv");
        if (data) {
          const convData = JSON.parse(data.value);
          setInStorage(conversationKey, convData);
          // dispatch({ type: "set-conversation", payload: convData });
          dispatch({ type: "set-from-storage", payload: convData });
          return convData;
        }
      })
      .catch((e) => console.log(e));
  };

  const obj = {
    conversation,
    populateFromStorage,
    saveToStorage,
    nextItem,
    fetchConversationData,
  };

  return (
    <ConversationContext.Provider value={obj}>
      <ConversationDispatchContext.Provider value={dispatch}>
        {children}
      </ConversationDispatchContext.Provider>
    </ConversationContext.Provider>
  );
};

export function useConvContext() {
  return useContext(ConversationContext);
}

export function useConvDispatchContext() {
  return useContext(ConversationDispatchContext);
}
