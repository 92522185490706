import { Link } from "react-router-dom";

export default function NoPage() {
  return (
    <div className="text-center text-dark-blue">
      <div className="mb-3 font-intermedium text-[20px]">
        Sidan hittades inte. Klicka här för att komma till{" "}
        <Link to={"/"} className="underline">
          startsidan
        </Link>
        .
      </div>
      <div className="font-interlight">{window.location.href.toString()}</div>
    </div>
  );
}
