import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import ConversationEmbedded from "../components/ConversationEmbedded";
import Footer from "../components/Footer";
import HeadMenu from "../components/HeadMenu";
import { ServiceSectionProvider } from "../context/ServiceSectionContext";
import { useMediaQuery } from "../services/hooks/useMediaQuery";
import { getCheckmarkIcon } from "../services/icons";
import { submitShareDataConsent } from "../services/ida";
import { useScrollPosition } from "../services/utils";

const hero = new URL("../assets/images/consent_flow.jpg", import.meta.url);

export default function ConfirmConsentPage({ title }: { title?: string }) {
  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }
  const [showConversationModal, setShowConversationModal] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    document.title = title!;
  }, []);

  useEffect(() => {
    const dscidParam = new URLSearchParams(window.location.search).get("dscid");
    if (dscidParam) {
      void submitShareDataConsent("true", dscidParam);
    }
  }, []);

  const renderLoginButton = () => {
    return (
      <Link
        to="/login/"
        className="mt-4 block w-fit rounded border-deep-blue bg-deep-blue p-5 font-intermedium text-[14px] uppercase leading-none tracking-[.12em] text-white drop-shadow-2xl hover:bg-[#6F86A0]"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        Fortsätt livsplanera
      </Link>
    );
  };

  useEffect(() => {
    if (showConversationModal) {
      setShowConversationModal(false);
    }
  }, [showConversationModal]);

  return (
    <ServiceSectionProvider>
      <div className="relative overflow-hidden bg-[#FFFEFB]">
        <div
          className={classNames(
            scrollPosition > 84 ? "bg-white" : "bg-white",
            "fixed z-30 w-full transition-colors"
          )}
        >
          <HeadMenu
            sticky={scrollPosition > 84}
            theme={"dark"}
            color={scrollPosition > 84 ? "#0C2632" : "#0C2632"}
          />
        </div>
        <div className="relative min-h-screen w-full text-white">
          <img
            src={hero.href}
            alt="Consent flow Fenix"
            className="absolute h-full min-h-screen w-screen object-cover object-[center_65px] lg:object-[0_84px]"
          />
          <div className="absolute top-0 z-10 h-full w-full bg-[#0C2632]/10"></div>
          <div className="absolute top-0 z-20 w-full">
            <div className="px-5">
              <div className="container mx-auto pb-10">
                <div className="relative ml-10 mt-[235px] flex flex-col justify-between drop-shadow-[4px_2px_35px_rgba(0,0,0,0.25)] lg:mt-[200px] lg:ml-[190px]">
                  <div className="max-w-[645px]">
                    <h2 className="font-intermedium text-xs uppercase">
                      dela data för bättre rådgivning
                    </h2>
                    <h1 className="relative my-3 font-interblack text-[36px] italic leading-none text-[#FFFEFB] drop-shadow-xl lg:text-[64px]">
                      Ditt svar har registrerats
                    </h1>
                    {getCheckmarkIcon(
                      "#B2E3C2",
                      isDesktop ? "96" : "50",
                      isDesktop ? "96" : "50",
                      "absolute -left-10 lg:-left-24 -ml-2.5 top-4 lg:top-2.5 drop-shadow-2xl"
                    )}
                    <p>
                      Tack för ditt svar. Du kommer nu få en heltäckande
                      upplevelse av din Livsplanering med värdefulla och
                      skräddarsydda rekommendationer kring både sparande och
                      försäkringar. Din finansiella rådgivare kommer att
                      kontakta dig inom kort för ett uppföljningsmöte. Tills
                      dess, logga in igen och slutför ditt testamente.
                    </p>
                    {renderLoginButton()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ConversationEmbedded
        showInline={false}
        showModalOverride={showConversationModal}
      />
    </ServiceSectionProvider>
  );
}
