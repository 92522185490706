import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
} from "@react-pdf/renderer";
import React from "react";

import { removeHtmlTags } from "../services/utils";

const fenixLogo = new URL(
  "../assets/images/fenixlogodark.png",
  import.meta.url
);

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 20,
    textAlign: "center",
    fontFamily: "InterMedium",
    color: "#002733",
    marginBottom: 20,
    textTransform: "uppercase",
  },
  subtitle: {
    fontSize: 15,
    marginBottom: 12,
    marginTop: 12,
    textAlign: "left",
    fontFamily: "InterMedium",
    color: "#002733",
    textTransform: "uppercase",
  },
  text: {
    marginBottom: 10,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "InterNormal",
  },
  textServices: {
    marginBottom: 10,
    fontSize: 14,
    textAlign: "left",
    fontFamily: "InterMedium",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 80,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

Font.register({
  family: "InterMedium",
  src: new URL("../assets/fonts/Inter-Medium.ttf", import.meta.url).href,
});

Font.register({
  family: "InterNormal",
  src: new URL("../assets/fonts/Inter-Regular.ttf", import.meta.url).href,
});

const PdfRecommendation = ({
  name = "test",
  inhImages,
  mainText,
  preContractText,
  ownContracts,
  partnerServices,
  productTextsRecommendation,
}) => {
  return (
    <Document>
      <Page style={styles.body} wrap>
        <Image
          style={{
            width: 150,
            marginBottom: 30,
          }}
          src={fenixLogo.href}
        />
        <Text style={styles.title}>{name}'s Lugn&Ro-paket</Text>
        <Text style={styles.subtitle}>
          Här är en överblick över vad vi rekommenderat
        </Text>
        {mainText &&
          mainText.map((t, i) => (
            <Text style={styles.text} key={i}>
              {t.text}
            </Text>
          ))}

        <Text style={styles.subtitle}>
          Viktigt att tänka på i din situation
        </Text>
        {preContractText && <Text style={styles.text}>{preContractText}</Text>}

        {ownContracts &&
          ownContracts.map((oc) => (
            <Text key={oc.id} style={styles.textServices}>
              {oc.title} {oc.subtitle ? oc.subtitle : ""}
            </Text>
          ))}

        {partnerServices &&
          partnerServices.map((ps) => (
            <Text key={ps.id} style={styles.textServices}>
              {ps.title}
            </Text>
          ))}

        <Text style={styles.subtitle}>
          Vår rekommendation och vad vi baserar den på
        </Text>
        {productTextsRecommendation &&
          productTextsRecommendation.map((item) => (
            <Text key={item.id} style={styles.text}>
              {removeHtmlTags(item.text)}
            </Text>
          ))}

        {inhImages && inhImages.length > 0 && (
          <>
            <Text style={styles.subtitle}>Vem ärver mig?</Text>
            <Text style={styles.text}>
              För att förenkla ytterligare har vi översatt din arvsordning till
              en illustration.
            </Text>
          </>
        )}
        {inhImages &&
          inhImages.length > 0 &&
          inhImages.map((im) => (
            <Image style={styles.image} src={im.href} key={im.href} />
          ))}

        <Text style={styles.subtitle}>
          Fenix Family - enkla lösningar på saker du borde gjort för länge sen
        </Text>
        <Text style={styles.text}>
          Fenix Family föddes ur Fenix Begravning där vi ofta fick frågor om
          andra saker än själva begravningsceremonin. För i livets slutskede
          ställs ofta ekonomi, juridik och hur man planerat både sitt liv och
          framtiden för sina närmaste, på sin spets. Idag kan vi hjälpa alla som
          vill skapa testamenten, äktenskapsförord, avtal och fullmakter, med en
          enkel, trygg och digital lösning på problem som ofta kan upplevas som
          känsliga och svåra.
        </Text>
        <Text style={styles.text}>
          Vi svarar gärna på dina frågor pre-need@fenixfamily.se
        </Text>
      </Page>
    </Document>
  );
};

export default PdfRecommendation;
