import React, { useEffect, useState } from "react";

import { faq } from "../assets/data/datalist";
import Accordion from "../components/Accordion";
import Footer from "../components/Footer";
import HeadMenu from "../components/HeadMenu";
import ArticlesSlider from "../components/slider/ArticlesSlider";
import { ServiceSectionProvider } from "../context/ServiceSectionContext";
import useFetchPosts from "../services/hooks/useFetchPosts";
import { useScrollPosition } from "../services/utils";

export default function FaqPage({ title }: { title?: string }) {
  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }
  const [showConversationModal, setShowConversationModal] = useState(false);
  const scrollPosition = useScrollPosition();

  const postData = useFetchPosts(
    `${process.env.STRAPI_API_URL}/api/articles?populate=*`,
    `${process.env.STRAPI_API_URL}/api/secondary-articles?populate=*`,
    `${process.env.STRAPI_API_URL}/api/article-categories?populate=*`
  );

  const childCategories = postData.categoriesList?.data
    ?.filter((item: any) => item?.attributes.parent?.data.some((it: any) => it))
    ?.sort((a: any, b: any) =>
      a.attributes.slug > b.attributes.slug
        ? 1
        : b.attributes.slug > a.attributes.slug
        ? -1
        : 0
    );

  useEffect(() => {
    document.title = title!;
  }, []);

  useEffect(() => {
    if (showConversationModal) {
      setShowConversationModal(false);
    }
  }, [showConversationModal]);
  return (
    <ServiceSectionProvider>
      <div className="relative overflow-hidden bg-neutral-yellow">
        <div
          className={classNames(
            scrollPosition > 84 ? "bg-white" : "bg-neutral-yellow",
            "fixed z-30 w-full transition-colors"
          )}
        >
          <HeadMenu
            sticky={scrollPosition > 84}
            theme={"dark"}
            color={scrollPosition > 84 ? "#0C2632" : "#0C2632"}
          />
        </div>
        <div className="relative w-full text-deep-blue">
          <div className="mx-auto px-5 pt-44 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pt-60 xl:max-w-[1360px]">
            <div className="hero-faq max-w-[980px]">
              <p className="text-xs tracking-[0.2em]">FAQ</p>
              <h1 className="font-interblack text-[36px] italic leading-[2.7rem] xl:text-[64px] xl:leading-[4.5rem]">
                Här har vi samlat svar på vanliga funderingar kring
                livsplanering
              </h1>
            </div>
            <div className="mt-3 max-w-[985px] py-16">
              <Accordion items={faq} color={"bg-neutral-yellow"} open={0} />
            </div>
            <div className="pt-12 pb-16 lg:pt-9">
              <h2 className="mb-6 pt-8 font-intermedium text-base uppercase tracking-widest lg:mb-3">
                Populära artiklar
              </h2>
              <ArticlesSlider
                articles={postData?.posts?.data.slice(0, 4)}
                init={false}
                quantity={4}
                childCategories={childCategories}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </ServiceSectionProvider>
  );
}
