import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Sticky from "react-stickynode";

import { faqBlock, steps } from "../assets/data/datalist";
import Accordion from "../components/Accordion";
import TrustboxArticle from "../components/articles/TrustboxArticle";
import Footer from "../components/Footer";
import HeadMenu from "../components/HeadMenu";
import ScrollToHashElement from "../components/ScrollToHashElement";
import ArticlesSlider from "../components/slider/ArticlesSlider";
import VideoItem from "../components/VideoItem";
import { ServiceSectionProvider } from "../context/ServiceSectionContext";
import CleanDataParser from "../helpers/editorjs-parser";
import useFetchPosts from "../services/hooks/useFetchPosts";
import { useMediaQuery } from "../services/hooks/useMediaQuery";
import {
  getBankIdIcon,
  getButtonArrow,
  getQuoteIcon,
  getViewsIcon,
} from "../services/icons";
import { useScrollPosition } from "../services/utils";

// helpers
const clamp = (value: number) => Math.max(0, value);

const isBetween = (value: number, floor: number, ceil: number) =>
  value >= floor && value <= ceil;
// hooks
const useScrollspy = (ids: string[], offset = 0) => {
  const [activeId, setActiveId] = useState("");
  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset;

      const position = ids
        .map((id) => {
          const element = document.getElementById(id);

          if (!element) return { id, top: -1, bottom: -1 };

          const rect = element.getBoundingClientRect();
          const top = clamp(rect.top + scroll - offset);
          const bottom = clamp(rect.bottom + scroll - offset);

          return { id, top, bottom };
        })
        .find(({ top, bottom }) => isBetween(scroll, top, bottom));

      //setActiveId(position?.id || ids[ids.length - 1]);
      setActiveId(
        document.getElementById("heading").getBoundingClientRect().bottom > 0
          ? ids[0]
          : document.getElementById("related").getBoundingClientRect().top -
              84 <
            0
          ? ids[ids.length - 1]
          : position?.id
      );
    };

    listener();

    window.addEventListener("resize", listener);
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("resize", listener);
      window.removeEventListener("scroll", listener);
    };
  }, [ids, offset]);
  return activeId;
};

export default function SinglePostSeo({
  title,
  slug,
  views,
  author,
  publishDate,
  childCategory,
  parentCategory,
  content,
}: {
  title?: string;
  slug?: string;
  publishDate?: string | number | undefined;
  views?: string;
  author?: string;
  childCategory?: object;
  parentCategory?: object;
  content?: object;
}) {
  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }
  const [isHover, setIsHover] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(true);
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const [activeSection, setActiveSection] = useState(null);
  const [activeTitle, setActiveTitle] = useState("");
  const observer = useRef(null);
  const scrollPosition = useScrollPosition();

  const ids = content.map(({ id }) => id.toString());
  const activeId = useScrollspy(ids, 84);
  useEffect(() => {
    document.title = title!;
    // create new instance and pass a callback function
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    observer.current = new IntersectionObserver((entries) => {
      const visibleSection = entries.find(
        (entry) => entry.isIntersecting
      )?.target;
      // Update state with the visible section ID
      if (visibleSection) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setActiveSection(visibleSection.id);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setActiveTitle(visibleSection.getAttribute("data-title"));
      }
    });

    // Get custom attribute data-section from all sections
    const sections = document.querySelectorAll("[data-section]");

    sections.forEach((section) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      observer.current.observe(section);
    });
    // Cleanup function to remove observer
    return () => {
      sections.forEach((section) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        observer.current.unobserve(section);
      });
    };
  }, []);

  function handleClick(e: any) {
    setOpenSidebar(!openSidebar);
  }
  const postData = useFetchPosts(
    `${process.env.STRAPI_API_URL}/api/articles?populate=*`,
    `${process.env.STRAPI_API_URL}/api/secondary-articles?populate=*`,
    `${process.env.STRAPI_API_URL}/api/article-categories?populate=*`
  );

  const childCategories = postData.categoriesList?.data
    ?.filter((item: any) => item?.attributes.parent?.data.some((it: any) => it))
    ?.sort((a: any, b: any) =>
      a.attributes.slug > b.attributes.slug
        ? 1
        : b.attributes.slug > a.attributes.slug
        ? -1
        : 0
    );

  const childCatSlug = childCategory.slug
    ? childCategory.slug
    : childCategory.split("/")[0];

  const renderLoginButton = (buttonClass: string, iconColor: string) => {
    return (
      <Link
        to="/login"
        className={
          buttonClass +
          ` inline-flex items-center gap-2 rounded border-[1px] border-deep-blue py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] lg:gap-3 lg:self-end`
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover
          ? getBankIdIcon("#fff", "20", "100%")
          : getBankIdIcon(iconColor, "20", "100%")}
        Logga in
      </Link>
    );
  };

  const publishDay = new Date(publishDate);

  // const quoteHeight = isDesktop ? "98px" : "47px";
  // const quoteWidth = isDesktop ? "119px" : "57px";

  const postNavigationItem = content?.map((item: any, index: number) => {
    return (
      <li
        className={`menu-item relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest  before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
          item.id.toString() === activeId
            ? " text-[#0C2632] before:bg-[#0C2632]"
            : " text-[#736F62] before:bg-[#CECABA]"
        }`}
        key={`menu-item-${item.id.toString()}`}
      >
        <HashLink
          onClick={handleClick}
          scroll={(el) =>
            el.scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }
          to={"#" + item.id.toString()}
        >
          {item.contentBlockTitle}
        </HashLink>
      </li>
    );
  });

  const articleTextBlock = content?.map(
    (item: any, index: number, content: any) => {
      return (
        <Fragment key={index}>
          <div
            key={`section-${item.id.toString()}`}
            id={item.id.toString()}
            className="section scroll-mt-40 pt-10 lg:scroll-my-20"
            data-section
            data-title={item.contentBlockTitle}
          >
            {index + 1 === content.length && <TrustboxArticle />}
            <div className="text-block">
              {item.contentBlock ? (
                item.contentBlock.map((block: any, idx: any) =>
                  CleanDataParser(block, idx)
                )
              ) : (
                <div />
              )}
            </div>
          </div>
        </Fragment>
      );
    }
  );

  return (
    <ServiceSectionProvider>
      <div className="relative overflow-hidden bg-[#FFFEFB]">
        <div
          className={classNames(
            scrollPosition > 84 ? "bg-white" : "bg-white",
            "fixed z-1000 w-full transition-colors"
          )}
        >
          <HeadMenu
            sticky={scrollPosition > 84}
            theme={"dark"}
            color={scrollPosition > 84 ? "#0C2632" : "#0C2632"}
          />
        </div>
        <div className="relative w-full overflow-hidden text-deep-blue">
          <div className="z-1 relative mx-auto px-5 pb-6 pt-24 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-8 lg:pt-24 xl:max-w-[1360px]">
            {isDesktop && (
              <div className="flex max-w-[645px] flex-col justify-between lg:mt-5">
                <ul className="flex space-x-2 text-[11px] capitalize">
                  <li className="after:ml-2.5 after:content-['|']">
                    <Link to={"/"}>hem</Link>
                  </li>
                  <li className="after:ml-2.5 after:content-['|']">
                    <Link to={"/artiklar/"}>artiklar</Link>
                  </li>
                  <li className="after:ml-2.5 after:content-['|']">
                    <Link to={"/artiklar/" + parentCategory.slug + "/"}>
                      {parentCategory.title}
                    </Link>
                  </li>
                  <li className="after:ml-2.5 after:content-['|']">
                    <Link
                      to={
                        "/artiklar/" +
                        parentCategory.slug +
                        "/" +
                        childCatSlug +
                        "/"
                      }
                    >
                      {childCategory.title
                        ? childCategory.title
                        : childCategory.split("/")[0]}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={
                        "/artiklar/" +
                        parentCategory.slug +
                        "/" +
                        childCatSlug +
                        "/" +
                        slug +
                        "/"
                      }
                    >
                      {title}
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="article relative w-full overflow-hidden">
          <div className="relative mx-auto flex flex-col px-5 pt-0 pb-16 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:flex-row lg:pt-4 xl:max-w-[1360px]">
            <aside className="lg:rounded-b-0 absolute left-0 top-0 flex w-full lg:relative lg:max-w-[325px] lg:pt-16">
              <ScrollToHashElement />
              {isDesktop ? (
                <Sticky
                  top="#header"
                  bottomBoundary="#content"
                  activeClass="active w-full"
                  innerZ={999}
                >
                  <div className="sidebar self-start overflow-hidden rounded-[8px] bg-[#E8E3D2]">
                    <div className="inline-flex w-full items-start justify-start gap-3 bg-[#E8E3D2] px-6 py-10 lg:max-w-[324px]">
                      <ul className="relative inline-flex list-none flex-col items-start justify-start gap-6 pl-6 pr-5 before:absolute before:top-0 before:left-[5px] before:h-full before:w-[1px] before:bg-[#CECABA] before:content-['']">
                        {postNavigationItem}
                      </ul>
                    </div>
                  </div>
                </Sticky>
              ) : (
                <Sticky top="#header" activeClass="active w-full" innerZ={999}>
                  <div
                    className={
                      !openSidebar
                        ? "sidebar self-start rounded-b-[8px] bg-[#E8E3D2]"
                        : "sidebar h-[76px] self-start overflow-hidden rounded-b-[8px] bg-[#E8E3D2]"
                    }
                  >
                    <button
                      onClick={handleClick}
                      className={`relative h-[76px] w-full pl-5 pr-7 text-left font-internormal text-xs font-medium uppercase tracking-[0.2em] after:absolute after:right-5 after:top-7 after:h-[10px] after:w-[10px]  after:transform after:border-b after:border-r after:border-black/70 lg:hidden ${
                        openSidebar ? " after:rotate-45" : "after:rotate-225"
                      }`}
                    >
                      {activeTitle}
                    </button>
                    <div className="inline-flex w-full items-start justify-start gap-3 bg-[#E8E3D2] px-6 pt-3 pb-10">
                      <ul className="relative inline-flex list-none flex-col items-start justify-start gap-6 pl-6 pr-5 before:absolute before:top-0 before:left-[5px] before:h-full before:w-[1px] before:bg-[#CECABA] before:content-['']">
                        {postNavigationItem}
                      </ul>
                    </div>
                  </div>
                </Sticky>
              )}
            </aside>
            <article
              id="content"
              className="article-content w-full pt-24 lg:pt-0"
            >
              <div className="max-w-[760px] text-deep-blue lg:ml-24">
                {!isDesktop && (
                  <div className="mb-20 flex max-w-[645px] flex-col justify-between lg:mt-5">
                    <ul className="float-left inline space-x-2 text-[11px] capitalize">
                      <li className="inline after:ml-2.5 after:content-['|']">
                        <Link to={"/"}>hem</Link>
                      </li>
                      <li className="inline after:ml-2.5 after:content-['|']">
                        <Link to={"/artiklar/"}>artiklar</Link>
                      </li>
                      <li className="inline after:ml-2.5 after:content-['|']">
                        <Link to={"/artiklar/"}>artiklar</Link>
                      </li>
                      <li className="inline after:ml-2.5 after:content-['|']">
                        <Link to={"/artiklar/" + parentCategory.slug}>
                          {parentCategory.title}
                        </Link>
                      </li>
                      <li className="inline after:ml-2.5 after:content-['|']">
                        <Link
                          to={
                            "/artiklar/" +
                            parentCategory.slug +
                            "/" +
                            childCategory.slug
                          }
                        >
                          {childCategory.title}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={
                            "/artiklar/" +
                            parentCategory.slug +
                            "/" +
                            childCategory.slug +
                            "/" +
                            slug
                          }
                        >
                          {title}
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
                <div id="heading" className="article-heading mt-0 mb-5">
                  <p className="text-xs font-medium uppercase tracking-[.25em]">
                    {publishDay?.toLocaleDateString("sv-SE", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </p>
                  <h1 className="mb-2 font-interblack text-[36px] italic leading-[2.7rem] lg:mt-0 lg:text-[64px] xl:leading-[4.4rem]">
                    {title}
                  </h1>
                  <div className="flex text-[13px]">
                    av {author}
                    <p className="flex items-center pl-5 text-[#736F62]">
                      {getViewsIcon()}
                      <span className="pl-2">{views}</span>
                    </p>
                  </div>
                </div>
                {articleTextBlock}
                <br />
                <br />
                <br />
                {/*<div*/}
                {/*  data-section*/}
                {/*  data-title="The standard Lorem Ipsum passage, used since the 1500s"*/}
                {/*  id="section_1_1"*/}
                {/*  className="text-block pb-10"*/}
                {/*>*/}
                {/*  <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">*/}
                {/*    1914 translation by H. Rackham*/}
                {/*  </h3>*/}
                {/*  <p className="whitespace-pre-wrap font-interlight text-base">*/}
                {/*    But I must explain to you how all this mistaken idea of*/}
                {/*    denouncing pleasure and praising pain was born and I will*/}
                {/*    give you a complete account of the system, and expound the*/}
                {/*    actual teachings of the great explorer of the truth, the*/}
                {/*    master-builder of human happiness. No one rejects, dislikes,*/}
                {/*    or avoids pleasure itself, because it is pleasure, but*/}
                {/*    because those who do not know how to pursue pleasure*/}
                {/*    rationally encounter consequences that are extremely*/}
                {/*    painful.{" "}*/}
                {/*  </p>*/}
                {/*</div>*/}
                {/*<div className="quote-block relative mb-20 rounded-[3px] bg-[#FEFAEE] pl-14 pr-8 pt-10 pb-8 lg:pr-12 lg:pb-12 lg:pt-24 lg:pl-28">*/}
                {/*  <span className="quote-icon absolute left-6 top-3 lg:left-11 lg:top-12">*/}
                {/*    {getQuoteIcon("#C3A2A1", quoteWidth, quoteHeight)}*/}
                {/*  </span>*/}
                {/*  <div className="z-1 relative font-interlight text-[24px] italic leading-[29px] text-[#43262D] lg:text-[42px] lg:leading-[50.4px]">*/}
                {/*    “Lorem ipsum dolor sit amet, consectetur adipiscing elit,*/}
                {/*    sed sed do eiusmod tempor incididunt ut labore et dolore*/}
                {/*    magna aliqua.”*/}
                {/*    <p className="flex justify-end font-internormal text-[14px] font-semibold uppercase not-italic tracking-wide text-[#43262D]">*/}
                {/*      <Link className="mt-4 flex items-center" to={"/"}>*/}
                {/*        <span className="border-b border-b-transparent tracking-[.25em] hover:border-b hover:border-b-deep-blue">*/}
                {/*          {isDesktop*/}
                {/*            ? "Läs mer i denna sekundära artikeln"*/}
                {/*            : "Läs mer"}*/}
                {/*        </span>{" "}*/}
                {/*        <span className="pl-2">*/}
                {/*          {getButtonArrow("#43262D", "20px", "20px")}*/}
                {/*        </span>*/}
                {/*      </Link>*/}
                {/*    </p>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*<div*/}
                {/*  data-section*/}
                {/*  id="section_1_2"*/}
                {/*  data-title="1914 Translation by H. Rackham"*/}
                {/*  className="text-block pb-10"*/}
                {/*>*/}
                {/*  <h3 className="mb-3 whitespace-pre-wrap font-intermedium text-base font-semibold uppercase tracking-[.25em]">*/}
                {/*    Section 1.10.32 of "de Finibus Bonorum et Malorum",*/}
                {/*    <br />*/}
                {/*    written by Cicero in 45 BC*/}
                {/*  </h3>*/}
                {/*</div>*/}
                {/*<div className="tips-block mb-20 inline-flex flex-col items-start justify-start gap-4 rounded-[3px] bg-[#5F3741] py-[30px] px-5 lg:px-10 lg:py-[60px]">*/}
                {/*  <div className="flex flex-col items-start justify-start gap-4">*/}
                {/*    <div className="font-interblack text-[28px] italic leading-[31px] text-white lg:text-5xl lg:leading-[52.80px]">*/}
                {/*      Fenix tipsar*/}
                {/*    </div>*/}
                {/*    <div className="flex flex-col items-end justify-start">*/}
                {/*      <div className="self-stretch font-intermedium text-lg italic leading-normal text-white">*/}
                {/*        Lorem ipsum dolor sit amet, consectetur adipiscing elit,*/}
                {/*        sed do eiusmod tempor incididunt ut labore et dolore*/}
                {/*        magna aliqua.Lorem ipsum dolor sit amet, consectetur*/}
                {/*        adipiscing elit, sed do eiusmod tempor incididunt ut*/}
                {/*        labore et dolore magna aliqua.*/}
                {/*      </div>*/}
                {/*      <div className="inline-flex items-center justify-start gap-2 rounded-lg py-4">*/}
                {/*        <div className="inline-flex flex-col items-start justify-center">*/}
                {/*          <Link*/}
                {/*            to={"/"}*/}
                {/*            className="flex items-center text-right font-intermedium text-sm font-semibold leading-tight text-white"*/}
                {/*          >*/}
                {/*            <span className="border-b border-[#fff] hover:border-b-transparent">*/}
                {/*              Läs mer i sekundär artikel*/}
                {/*            </span>{" "}*/}
                {/*            <span className="pl-2">*/}
                {/*              {getButtonArrow("#fff", "20px", "20px")}*/}
                {/*            </span>*/}
                {/*          </Link>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div
                  id="related"
                  className="last-articles relative mb-20 mt-6 pt-10 before:absolute before:top-0 before:left-[-50rem] before:h-full before:w-[500%] before:bg-neutral-yellow before:content-['']"
                >
                  <h4 className="relative mb-6 font-intermedium text-xs uppercase tracking-[0.2em] text-[#736F62]">
                    Har du även läst:
                  </h4>
                  <ArticlesSlider
                    articles={postData?.posts?.data.slice(0, 2)}
                    init={false}
                    quantity={2}
                    childCategories={childCategories}
                  />
                </div>
                {/*<div*/}
                {/*  data-section*/}
                {/*  id="section_2_1"*/}
                {/*  data-title='Section 1.10.32 of "de Finibus Bonorum et Malorum"'*/}
                {/*  className="text-block pb-10"*/}
                {/*>*/}
                {/*  <h3 className="mb-3 whitespace-pre-wrap font-intermedium text-base font-semibold uppercase tracking-[.25em]">*/}
                {/*    Section 1.10.32 of "de Finibus Bonorum et Malorum",*/}
                {/*    <br />*/}
                {/*    written by Cicero in 45 BC*/}
                {/*  </h3>*/}
                {/*  <p className="whitespace-pre-wrap font-interlight text-base">*/}
                {/*    Sed ut perspiciatis unde omnis iste natus error sit*/}
                {/*    voluptatem accusantium doloremque laudantium, totam rem*/}
                {/*    aperiam, eaque ipsa quae ab illo inventore veritatis et*/}
                {/*    quasi architecto beatae vitae dicta sunt explicabo. Nemo*/}
                {/*    enim ipsam voluptatem quia voluptas sit aspernatur aut odit*/}
                {/*    aut fugit, sed quia consequuntur magni dolores eos qui*/}
                {/*    ratione voluptatem sequi nesciunt.*/}
                {/*  </p>*/}
                {/*  <br />*/}
                {/*  <br />*/}
                {/*  <img*/}
                {/*    // src={post[0].hero.href}*/}
                {/*    alt="blog image"*/}
                {/*    loading="lazy"*/}
                {/*  />*/}
                {/*  <br />*/}
                {/*  <br />*/}
                {/*</div>*/}
                {/*<div className="checkmark-block pb-10 ">*/}
                {/*  <h3 className="mb-3 whitespace-pre-wrap font-intermedium text-base font-semibold uppercase tracking-[.25em]">*/}
                {/*    Här är en lista med vad du borde <br />*/}
                {/*    checka av inför:*/}
                {/*  </h3>*/}
                {/*  <ul className="max-w-[420px] list-none rounded-[3px] border border-[#A0C1AB] py-4 pr-6 pl-12 lg:py-6">*/}
                {/*    <li className="relative mb-4 font-intermedium text-base italic leading-[22px] before:absolute before:top-1 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#467655] before:content-['']">*/}
                {/*      Kan vara lång text här men boxen får inte bli för stor på*/}
                {/*      bredden. Fast bredd på 4 kolumner*/}
                {/*    </li>*/}
                {/*    <li className="relative mb-4 font-intermedium text-base italic leading-[22px] before:absolute before:top-1 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#467655] before:content-['']">*/}
                {/*      List Checkmark*/}
                {/*    </li>*/}
                {/*    <li className="relative mb-4 font-intermedium text-base italic leading-[22px] before:absolute before:top-1 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#467655] before:content-['']">*/}
                {/*      Kan vara lång text här men boxen får inte bli för stor på*/}
                {/*      bredden. Fast bredd på 4 kolumner*/}
                {/*    </li>*/}
                {/*    <li className="relative mb-4 font-intermedium text-base italic leading-[22px] before:absolute before:top-1 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#467655] before:content-['']">*/}
                {/*      List Checkmark*/}
                {/*    </li>*/}
                {/*    <li className="relative mb-4 font-intermedium text-base italic leading-[22px] before:absolute before:top-1 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#467655] before:content-['']">*/}
                {/*      Kan vara lång text här men boxen får inte bli för stor på*/}
                {/*      bredden. Fast bredd på 4 kolumner*/}
                {/*    </li>*/}
                {/*    <li className="relative mb-4 font-intermedium text-base italic leading-[22px] before:absolute before:top-1 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#467655] before:content-['']">*/}
                {/*      List Checkmark*/}
                {/*    </li>*/}
                {/*  </ul>*/}
                {/*</div>*/}
                {/*<TrustboxArticle />*/}
                <div className="mb-16"></div>
                {/*<div*/}
                {/*  data-section*/}
                {/*  id="section_2_2"*/}
                {/*  data-title="The standard Lorem Ipsum passage, used since the 1500s"*/}
                {/*  className="text-block pb-10"*/}
                {/*>*/}
                {/*  <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">*/}
                {/*    1914 translation by H. Rackham*/}
                {/*  </h3>*/}
                {/*  <p className="whitespace-pre-wrap font-interlight text-base">*/}
                {/*    But I must explain to you how all this mistaken idea of*/}
                {/*    denouncing pleasure and praising pain was born and I will*/}
                {/*    give you a complete account of the system, and expound the*/}
                {/*    actual teachings of the great explorer of the truth, the*/}
                {/*    master-builder of human happiness. No one rejects, dislikes,*/}
                {/*    or avoids pleasure itself, because it is pleasure, but*/}
                {/*    because those who do not know how to pursue pleasure*/}
                {/*    rationally encounter consequences that are extremely*/}
                {/*    painful.{" "}*/}
                {/*  </p>*/}
                {/*</div>*/}
                {/*<div className="headline-block pb-10">*/}
                {/*  <h4 className="mb-0 font-intermedium text-[18px] ">*/}
                {/*    Headline i text*/}
                {/*  </h4>*/}
                {/*  <p className="whitespace-pre-wrap font-interlight text-base font-semibold">*/}
                {/*    Nor again is there anyone who loves or pursues or desires to*/}
                {/*    obtain pain of itself, because it is pain, but because*/}
                {/*    occasionally circumstances occur in which toil and pain can*/}
                {/*    procure him some great pleasure. To take a trivial example,*/}
                {/*    which of us ever undertakes laborious physical exercise.*/}
                {/*  </p>*/}
                {/*</div>*/}
                {/*<div className="table-block pb-12">*/}
                {/*  <table className="w-full table-fixed border-collapse border border-[#CECABA] text-left">*/}
                {/*    <thead>*/}
                {/*      <tr className="bg-[#F3F0E6]">*/}
                {/*        <th className="border border-[#CECABA] py-2.5 pl-3 pr-2 font-intermedium text-[12px] uppercase tracking-[0.2em] lg:pl-5">*/}
                {/*          Headline*/}
                {/*        </th>*/}
                {/*        <th className="border border-[#CECABA] py-2.5 pl-3 pr-2 font-intermedium text-[12px] uppercase tracking-[0.2em] lg:pl-5">*/}
                {/*          Headline*/}
                {/*        </th>*/}
                {/*        <th className="border border-[#CECABA] py-2.5 pl-3 pr-2 font-intermedium text-[12px] uppercase tracking-[0.2em] lg:pl-5">*/}
                {/*          Headline*/}
                {/*        </th>*/}
                {/*      </tr>*/}
                {/*    </thead>*/}
                {/*    <tbody>*/}
                {/*      <tr>*/}
                {/*        <td className="border border-[#CECABA] py-2.5 pl-3 pr-2 font-intermedium text-[13px] font-semibold italic lg:pl-5">*/}
                {/*          Headline*/}
                {/*        </td>*/}
                {/*        <td className="border border-[#CECABA] py-2.5 pl-3 pr-2 font-internormal text-[13px] italic lg:pl-5">*/}
                {/*          Headline*/}
                {/*        </td>*/}
                {/*        <td className="border border-[#CECABA] pl-3  lg:pl-5">*/}
                {/*          <div className="relative h-4 max-w-[205px]">*/}
                {/*            <div className="absolute left-0 top-0 h-4 w-full max-w-[90px] bg-[#E8E3D2] lg:max-w-[205px]" />*/}
                {/*            <div className="absolute left-[0.32px] top-0 h-4 w-full max-w-[60px] bg-[#273F5C] lg:max-w-[135px]" />*/}
                {/*          </div>*/}
                {/*        </td>*/}
                {/*      </tr>*/}
                {/*      <tr>*/}
                {/*        <td className="border border-[#CECABA] py-2.5 pl-3 pr-2 font-intermedium text-[13px] font-semibold italic lg:pl-5">*/}
                {/*          Headline*/}
                {/*        </td>*/}
                {/*        <td className="border border-[#CECABA] py-2.5 pl-3 pr-2 font-internormal text-[13px] italic lg:pl-5">*/}
                {/*          Headline*/}
                {/*        </td>*/}
                {/*        <td className="border border-[#CECABA] pl-3 lg:pl-5">*/}
                {/*          <div className="relative h-4 max-w-[205px]">*/}
                {/*            <div className="absolute left-0 top-0 h-4 w-full max-w-[90px] bg-[#E8E3D2] lg:max-w-[205px]" />*/}
                {/*            <div className="absolute left-[0.32px] top-0 h-4 w-full max-w-[60px] bg-[#273F5C] lg:max-w-[135px]" />*/}
                {/*          </div>*/}
                {/*        </td>*/}
                {/*      </tr>*/}
                {/*      <tr>*/}
                {/*        <td className="border border-[#CECABA] py-2.5 pl-3 pr-2 font-intermedium text-[13px] font-semibold italic lg:pl-5">*/}
                {/*          Headline*/}
                {/*        </td>*/}
                {/*        <td className="border border-[#CECABA] py-2.5 pl-3 pr-2 font-internormal text-[13px] italic lg:pl-5">*/}
                {/*          Headline*/}
                {/*        </td>*/}
                {/*        <td className="border border-[#CECABA] pl-3 lg:pl-5">*/}
                {/*          <div className="relative h-4 max-w-[205px]">*/}
                {/*            <div className="absolute left-0 top-0 h-4 w-full max-w-[90px] bg-[#E8E3D2] lg:max-w-[205px]" />*/}
                {/*            <div className="absolute left-[0.32px] top-0 h-4 w-full max-w-[60px] bg-[#273F5C] lg:max-w-[135px]" />*/}
                {/*          </div>*/}
                {/*        </td>*/}
                {/*      </tr>*/}
                {/*      <tr>*/}
                {/*        <td className="border border-[#CECABA] py-2.5 pl-3 pr-2 font-intermedium text-[13px] font-semibold italic lg:pl-5">*/}
                {/*          Headline*/}
                {/*        </td>*/}
                {/*        <td className="border border-[#CECABA] py-2.5 pl-3 pr-2 font-internormal text-[13px] italic lg:pl-5">*/}
                {/*          Headline*/}
                {/*        </td>*/}
                {/*        <td className="border border-[#CECABA] pl-3 lg:pl-5">*/}
                {/*          <div className="relative h-4 max-w-[205px]">*/}
                {/*            <div className="absolute left-0 top-0 h-4 w-full max-w-[90px] bg-[#E8E3D2] lg:max-w-[205px]" />*/}
                {/*            <div className="absolute left-[0.32px] top-0 h-4 w-full max-w-[60px] bg-[#273F5C] lg:max-w-[135px]" />*/}
                {/*          </div>*/}
                {/*        </td>*/}
                {/*      </tr>*/}
                {/*      <tr>*/}
                {/*        <td className="border border-[#CECABA] py-2.5 pl-3 pr-2 font-intermedium text-[13px] font-semibold italic lg:pl-5">*/}
                {/*          Headline*/}
                {/*        </td>*/}
                {/*        <td className="border border-[#CECABA] py-2.5 pl-3 pr-2 font-internormal text-[13px] italic lg:pl-5">*/}
                {/*          Headline*/}
                {/*        </td>*/}
                {/*        <td className="border border-[#CECABA] pl-3 lg:pl-5">*/}
                {/*          <div className="relative h-4 max-w-[205px]">*/}
                {/*            <div className="absolute left-0 top-0 h-4 w-full max-w-[90px] bg-[#E8E3D2] lg:max-w-[205px]" />*/}
                {/*            <div className="absolute left-[0.32px] top-0 h-4 w-full max-w-[60px] bg-[#273F5C] lg:max-w-[135px]" />*/}
                {/*          </div>*/}
                {/*        </td>*/}
                {/*      </tr>*/}
                {/*      <tr>*/}
                {/*        <td className="border border-[#CECABA] py-2.5 pl-3 pr-2 font-intermedium text-[13px] font-semibold italic lg:pl-5">*/}
                {/*          Headline*/}
                {/*        </td>*/}
                {/*        <td className="border border-[#CECABA] py-2.5 pl-3 pr-2 font-internormal text-[13px] italic lg:pl-5">*/}
                {/*          Headline*/}
                {/*        </td>*/}
                {/*        <td className="border border-[#CECABA] pl-3 lg:pl-5">*/}
                {/*          <div className="relative h-4 max-w-[205px]">*/}
                {/*            <div className="absolute left-0 top-0 h-4 w-full max-w-[90px] bg-[#E8E3D2] lg:max-w-[205px]" />*/}
                {/*            <div className="absolute left-[0.32px] top-0 h-4 w-full max-w-[60px] bg-[#273F5C] lg:max-w-[135px]" />*/}
                {/*          </div>*/}
                {/*        </td>*/}
                {/*      </tr>*/}
                {/*      <tr>*/}
                {/*        <td className="border border-[#CECABA] py-2.5 pl-3 pr-2 font-intermedium text-[13px] font-semibold italic lg:pl-5">*/}
                {/*          Headline*/}
                {/*        </td>*/}
                {/*        <td className="border border-[#CECABA] py-2.5 pl-3 pr-2 font-internormal text-[13px] italic lg:pl-5">*/}
                {/*          Headline*/}
                {/*        </td>*/}
                {/*        <td className="border border-[#CECABA] pl-3 lg:pl-5">*/}
                {/*          <div className="relative h-4 max-w-[205px]">*/}
                {/*            <div className="absolute left-0 top-0 h-4 w-full max-w-[90px] bg-[#E8E3D2] lg:max-w-[205px]" />*/}
                {/*            <div className="absolute left-[0.32px] top-0 h-4 w-full max-w-[60px] bg-[#273F5C] lg:max-w-[135px]" />*/}
                {/*          </div>*/}
                {/*        </td>*/}
                {/*      </tr>*/}
                {/*    </tbody>*/}
                {/*  </table>*/}
                {/*  <br />*/}
                {/*</div>*/}
                <div className="step-block scroll-my-40 pb-10 lg:scroll-my-20">
                  <div className="inline-flex flex-col items-center justify-start gap-10">
                    <h2 className="text-center font-internormal text-base font-semibold uppercase leading-normal tracking-[4px]">
                      Varför Fenix livsplanering?
                    </h2>
                    <div className="flex flex-col items-start justify-start gap-8 md:flex-row lg:inline-flex lg:gap-20">
                      {steps.map((step, index) => (
                        <div
                          key={index}
                          className="inline-flex flex-col items-center justify-start gap-6"
                        >
                          <div className="flex h-[80px] w-[80px] items-center justify-center rounded-full bg-[#E8E3D2] text-center text-[28px] font-semibold uppercase lg:h-[118px] lg:w-[118px] lg:text-[42px]">
                            {index + 1}
                          </div>
                          <div className="max-w-[194px] text-center text-[13px] font-normal leading-none">
                            {step.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <VideoItem />
              </div>
            </article>
          </div>
        </div>
        <div className="relative w-full border-t border-[#CECABA] text-deep-blue lg:border-t-0">
          <div className="mx-auto px-5 pt-28 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:border-t lg:border-[#CECABA] lg:pt-24 lg:pb-32 xl:max-w-[1360px]">
            <div className="mx-auto max-w-[980px]">
              <h2 className="text-center font-interblack text-[28px] italic leading-[2.7rem] text-deep-blue xl:text-[48px] xl:leading-[4.5rem]">
                FAQ
              </h2>
            </div>
            <div className="mx-auto mt-3 max-w-[985px] pt-5 pb-24">
              <Accordion items={faqBlock} />
            </div>
          </div>
        </div>
        <div className="relative w-full bg-[#F3F0E6] text-deep-blue">
          <div className="mx-auto px-5 pt-12 pb-16 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pt-9 xl:max-w-[1360px]">
            <h2 className="mb-6 pt-8 font-intermedium text-base uppercase tracking-[.25em] lg:mb-3">
              Populära artiklar
            </h2>
            <ArticlesSlider
              articles={postData?.posts?.data.slice(0, 4)}
              init={false}
              quantity={4}
              childCategories={childCategories}
            />
          </div>
        </div>
      </div>
      <Footer />
    </ServiceSectionProvider>
  );
}
