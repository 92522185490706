// Import Swiper styles
import "swiper/swiper-bundle.css";

import React, { useState } from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { timeline } from "../../assets/data/datalist";

export default function TimelineSlider() {
  const [pagesEl, setPagesEl] = useState<HTMLElement | null>(null);
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [timelineSwiper, setTimelineSwiper] = useState({
    slideTo(index: number) {},
  });

  const handleClick = (event: any, index: number) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    setCurrentIndex(index);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    timelineSwiper.slideTo(index);
  };

  return (
    <>
      <div className="pt-28">
        <div className="relative list-none">
          <ul className="swiper-pagination-custom !top-0 !bottom-auto left-0 h-[6px] w-full">
            {timeline.map((item: any, index: number) => {
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              return (
                <li
                  key={index}
                  onClick={(event) => handleClick(event, index)}
                  className={`swiper-pagination-switch absolute h-[12px] w-[12px] rounded-full before:absolute before:top-0 before:left-0 before:z-30 before:h-[12px] before:w-[12px] before:rounded-full ${
                    currentIndex === index
                      ? "before:bg-[#6F86A0]"
                      : "before:bg-[#CECABA]"
                  } before:content-[''] after:absolute after:-left-1.5 after:-top-1.5 after:z-20 after:h-[24px] after:w-[24px] after:rounded-full after:bg-[#fff] after:content-['']`}
                  style={{
                    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                    left: (100 / timeline.length) * index + "%",
                    top: "-5px",
                  }}
                ></li>
              );
            })}
          </ul>
          <Swiper
            modules={[Pagination, Navigation]}
            navigation={{ prevEl, nextEl }}
            keyboard={true}
            slidesPerView={1.15}
            spaceBetween={30}
            allowTouchMove={true}
            simulateTouch={true}
            grabCursor={true}
            edgeSwipeDetection={true}
            touchReleaseOnEdges={true}
            slidesOffsetAfter={45}
            pagination={{
              type: "progressbar",
              el: pagesEl,
            }}
            mousewheel={{
              releaseOnEdges: true,
            }}
            onInit={(swiperCore) => {
              setTimelineSwiper(swiperCore);
            }}
            onActiveIndexChange={(swiperCore) => {
              setCurrentIndex(swiperCore.activeIndex);
            }}
            className="swiper-timeline relative after:absolute after:-right-24 after:z-10 after:h-[86px] after:w-[174px] after:origin-top-left after:-rotate-90 after:bg-gradient-to-b after:from-[#FFFFFF00] after:to-white after:content-['']"
          >
            {timeline.map((item: any, index: number) => {
              return (
                <SwiperSlide key={index} className="pt-9">
                  <div
                    key={index}
                    className="relative self-stretch font-medium text-[#0C2632]"
                  >
                    <p className="font-internormal text-base font-semibold tracking-[0.25em]">
                      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                      {item.year}
                    </p>
                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                    <p className="text-[13px]">{item.text}</p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div
            ref={(node) => setPagesEl(node)}
            className="swiper-pagination swiper-pagination-progressbar swiper-pagination-horizontal z-10 !h-[1px]"
          ></div>
          <div
            ref={(node) => setPrevEl(node)}
            className="swiper-button-prev swiper-button-prev-advisor swiper-button-advisor relative !right-20 !top-[140%] !left-auto !h-[32px] !w-[32px] rounded-full bg-deep-blue !text-transparent after:absolute after:top-2.5 after:left-3 after:h-[11px] after:w-[11px] after:rotate-45 after:border-b after:border-l after:border-[#fff] after:content-[''] lg:!top-[45%] lg:!left-10 lg:bg-transparent lg:after:border-[#467655]"
          ></div>
          <div
            ref={(node) => setNextEl(node)}
            className="swiper-button-next swiper-button-next-advisor swiper-button-advisor relative !top-[140%] !right-2 !h-[32px] !w-[32px] rounded-full bg-deep-blue !text-transparent after:absolute after:top-2.5 after:left-2 after:h-[11px] after:w-[11px] after:rotate-[-130deg] after:border-b after:border-l after:border-[#fff] after:content-[''] lg:!right-10 lg:!top-[45%] lg:bg-transparent lg:after:border-[#467655]"
          ></div>
        </div>
      </div>
    </>
  );
}
