import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

// import { usePartnerPackageCleanUp } from "../services/hooks/usePartnerPackageCleanUp";

export default function LayoutPublic() {
  // usePartnerPackageCleanUp();
  return (
    <main className="relative flex flex-grow flex-col bg-[#FFFEFB]">
      <Outlet />

      <div className="bg-[#F3F0E6] py-10 text-[18px] text-dark-blue">
        <div className="mb-2 flex w-full flex-row items-center justify-center gap-5">
          <a
            target="_blank"
            rel="noreferrer"
            className="font-interlight hover:underline"
            href="https://static.fenixbegravning.se/fenix-allmanna-villkor.pdf"
          >
            Allmänna villkor
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            className="font-interlight hover:underline"
            href="https://static.fenixbegravning.se/fenix-integritetspolicy.pdf"
          >
            Integritetspolicy
          </a>
        </div>
        <div className="text-center font-interlight">
          © Fenix Family AB {new Date().getFullYear()}
        </div>
      </div>
    </main>
  );
}
