import React from "react";
import { Link } from "react-router-dom";

import { getButtonArrow } from "../services/icons";

export default function ArticleGridItem({ item, hasParentCat }: any) {
  const textArray = item?.attributes?.content[0].contentBlock.filter(
    (x: any) => x.type === "paragraph"
  );

  let textStr = "";
  for (let i = 0; i < textArray?.length; i++) {
    textStr = textStr.concat(textArray[i].children[0].text);
  }

  return (
    <>
      <div></div>
      <Link
        to={
          hasParentCat &&
          hasParentCat?.attributes.slug ===
            item?.attributes?.article_category.data.attributes.slug
            ? "/artiklar/" +
              hasParentCat?.attributes.parent?.data[0]?.attributes?.slug +
              "/" +
              item?.attributes?.article_category.data.attributes.slug +
              "/" +
              item?.attributes?.slug +
              "/"
            : "/artiklar/" +
              item?.attributes?.article_category.data.attributes.slug +
              "/" +
              item?.attributes?.slug +
              "/"
        }
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "auto" });
        }}
        className=""
      >
        <div className="article-grid-item mb-7 rounded-tl-[13px] rounded-tr-[13px] lg:mb-16 lg:max-w-[365px]">
          <img
            src={
              item?.attributes?.featuredImage?.data?.attributes?.formats?.small
                ?.url
            }
            alt={item?.attributes?.title}
            className="h-[217px] w-full rounded-tl-[13px] rounded-tr-[13px] object-cover object-center"
          />
          <p className="mt-5 font-intermedium text-xs uppercase text-[#736F62]">
            {/*{item.categoryFirstLevel.title}*/}
          </p>
          <div className="flex w-full flex-1 cursor-pointer justify-between hover:underline lg:justify-start">
            <h3 className="my-1 font-intermedium text-lg italic text-deep-blue">
              {item?.attributes?.title}
            </h3>
            <div className="ml-2">
              {getButtonArrow("#0C2632", "18", "100%")}
            </div>
          </div>
          <p className="pb-4 text-[13px] text-[#736F62]">
            {textStr.split(" ").slice(0, 15).join(" ") + "..."}
          </p>
        </div>
      </Link>
    </>
  );
}
