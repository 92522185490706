import * as Sentry from "@sentry/react";

type cmpEventDataType = { eventName: string; email?: string };

export const sendCmpEvent = async (eventName: string, email = "") => {
  if (process.env.NODE_ENV === "development") {
    return;
  }

  const data: cmpEventDataType = { eventName: eventName };
  if (email) {
    data["email"] = email;
  }
  try {
    await fetch("https://hooks.zapier.com/hooks/catch/815532/32c80mz/", {
      method: "POST",
      body: JSON.stringify(data),
    });
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
  }
};
