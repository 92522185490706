import React, { useState } from "react";
import { Link } from "react-router-dom";

import { getButtonArrow, getCloseIcon } from "../services/icons";
import { isDesktopView } from "../services/utils";

type ServiceType = {
  id: number;
  image: URL;
  title: string;
  desc: string;
  text: string;
  link?: string;
};

type ServicePropsType = {
  service: ServiceType;
  onClick: (d: number, m: boolean) => void;
};

export const services: ServiceType[] = [
  {
    id: 1,
    image: new URL("../assets/images/service_1.jpg", import.meta.url),
    title: "Kompetens, empati och en smart digital tjänst",
    desc: "Vi har lång erfarenhet av och stor förståelse för hur viktigt det är att ha familjens juridik på plats.",
    text: "Vi hjälper dig att enkelt skapa avtal som testamenten och framtidsfullmakter för att underlätta för dig själv och dina närmaste. Alltid med din situation och dina önskemål i centrum.",
    link: "/",
  },
  {
    id: 2,
    image: new URL("../assets/images/service_2.jpg", import.meta.url),
    title: "Ärlighet och transparens\n",
    desc: "Vårt mål är alltid det som är bäst för dig. Därför är vi alltid öppna och transparenta.",
    text: "Vårt mål är alltid det som är bäst för dig. Därför är vi alltid öppna och transparenta med våra avtal, kostnader, avgifter och villkor. Så att målet med vårt samarbete, processen dit och vad det kommer kosta alltid är tydligt för dig.",
    link: "/",
  },
  {
    id: 3,
    image: new URL("../assets/images/service_3.jpg", import.meta.url),
    title: "Dedikerade partners för din livsplanering",
    desc: "Våra samarbetspartners hjälper dig med försäkringar och sparande för en komplett livsplanering.",
    text: "När man gör en livsplanering som utgår från ett testamente, hamnar man automatiskt nära sin personliga ekonomi och försäkringssituation. Därför har vi partners som kan hjälpa dig att reda ut eventuella frågor och hjälpa dig att planera framåt.",
    link: "/",
  },
];

const CompactService = ({ service, onClick }: ServicePropsType) => {
  return (
    <div
      className="relative cursor-pointer overflow-hidden rounded-bl-[16px] rounded-br-[16px]"
      onClick={() => onClick(service.id, false)}
    >
      <div
        className="absolute bottom-0 w-full rounded-bl-[16px] rounded-br-[16px] px-5 pb-8 pt-6 text-white backdrop-blur"
        style={{ WebkitBackdropFilter: "blur(8px)" }}
      >
        <div className="min-h-[48px] text-base uppercase tracking-[0.25em] text-[#E8E3D2]">
          {service.title}
        </div>
        <div className="mt-2 min-h-[50px] text-[13px] leading-tight">
          {service.desc}
        </div>
        <div className="flex flex-row items-center gap-1">
          Läs mer {getButtonArrow("#ffffff", "20", "100%")}
        </div>
      </div>
      <img
        src={service.image.href}
        className="min-h-[545px] rounded-tl-[4px] rounded-tr-[4px] rounded-bl-[16px] rounded-br-[16px] object-cover object-right-top"
        loading="lazy"
      />
    </div>
  );
};

const ExpandedService = ({ service, onClick }: ServicePropsType) => {
  const [isHover, setIsHover] = useState(false);
  const renderLoginButton = (buttonClass: string) => {
    return (
      <Link
        to="/login"
        className={
          buttonClass +
          ` mt-10 inline-flex items-center gap-2 rounded border-[1px] border-deep-blue bg-deep-blue py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-widest text-white lg:gap-3 lg:self-end`
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >Läs mer</Link>
    );
  };
  return (
    <div
      className="relative flex flex-col-reverse overflow-hidden rounded-[8px] bg-[#f3f1e8] text-dark-blue lg:grid lg:grid-cols-2 lg:flex-row"
      id={`el-${service.id}`}
    >
      <div className="flex-1 p-6 lg:p-10 xl:px-28 xl:py-20">
        <h2 className="mb-3 font-interblack text-[22px] italic leading-[24px] lg:mb-5 lg:text-[48px] lg:leading-[53px]">
          {service.title}
        </h2>
        <div
          className="max-w-lg font-interlight"
          dangerouslySetInnerHTML={{
            __html: service.text,
          }}
        />
        {/*{renderLoginButton(`bg-deep-blue mt-8 text-white hover:border-[#6F86A0] hover:bg-[#6F86A0]`)}*/}
      </div>
      <div className="relative h-full flex-1 overflow-hidden rounded-t-[8px] lg:block lg:max-h-[545px] lg:rounded-r-[8px] lg:rounded-t-[0]">
        <div
          className="absolute top-5 right-5 z-10 flex h-[36px] w-[36px] cursor-pointer justify-center rounded-full bg-white bg-opacity-80"
          onClick={() => onClick(0, true)}
        >
          {getCloseIcon("#525252", "18", "100%")}
        </div>
        <img
          src={service.image.href}
          alt="Fenix"
          className="relative w-full lg:top-[50%] lg:translate-y-[-50%]"
          style={{
            transform: `${isDesktopView() ? "translateY(-50%)" : "none"}`,
          }}
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default function ServicesSingleSection() {
  const [compactMode, setCompactMode] = useState(true);
  const [expandeSingleSection, setExpandeSingleSection] = useState(0);

  const toggleMode = (ref: number, mode = false) => {
    const el = document.getElementById("services-list");
    if (el) {
      setTimeout(() => {
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }, 50);
    }

    setCompactMode(mode);
    setExpandeSingleSection(ref);
  };

  const filteredService = services.filter((obj) => {
    return obj.id === expandeSingleSection;
  });

  if (compactMode) {
    return (
      <div
        id="services-list"
        className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3"
      >
        {services.map((service) => (
          <CompactService
            key={service.id}
            service={service}
            onClick={toggleMode}
          />
        ))}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-8">
      {filteredService.map((service) => (
        <ExpandedService
          key={service.id}
          service={service}
          onClick={toggleMode}
        />
      ))}
    </div>
  );
}
