import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { getButtonArrow } from "../services/icons";

export default function AccordionItem(props: any) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (props.isOpen) {
      const contentEl = contentRef.current as HTMLDivElement;

      setHeight(contentEl.scrollHeight);
    } else {
      setHeight(0);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }, [props.isOpen]);

  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    <div className={`accordion-item ${props.isOpen ? "active" : ""}`}>
      <div className="accordion-item-title">
        <button
          className={`accordion-item-btn relative w-full border-t border-[#CECABA] py-5 pl-4 pr-9 text-left text-base italic leading-7 ${
            props.isOpen ? "border-b-[#FFFEFB] bg-[#FFFEFB]" : props.color
          }`}
          onClick={props.btnOnClick}
        >
          {props.data?.question}
          <svg
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute top-[25px] right-4"
          >
            <g stroke="#0c2632">
              {props.isOpen ? (
                <path
                  d="M3.80005 8H12.2"
                  stroke="#0C2632"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              ) : (
                <path
                  d="m3.80005 8.00051h8.39995m-4.19995-4.20009v8.39998"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              )}
              <rect height="15" rx="7.5" width="15" x=".5" y=".5" />
            </g>
          </svg>
        </button>
      </div>
      <div
        className="accordion-item-container w-full overflow-hidden bg-white text-left"
        style={{ height }}
      >
        <div
          ref={contentRef}
          className={`accordion-item-content pl-4 pr-4 pt-4 pb-9 font-interlight text-base lg:pr-32 lg:pb-8`}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
          <p
            className="whitespace-pre-wrap"
            dangerouslySetInnerHTML={{
              __html: props.data?.answer,
            }}
          />
          {props.data?.link && (
            <div className="mt-10 flex justify-end text-right font-intermedium text-base uppercase hover:underline">
              <Link to={props.data?.link}>{props.data?.linkTitle}</Link>
              <div className="ml-2">
                {getButtonArrow("#0C2632", "18", "100%")}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
