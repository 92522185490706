import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

import { useConvDispatchContext } from "../../context/ConversationContext";
import { useUserContext } from "../../context/UserContext";
import Button from "../Button";
import PageHead from "../PageHead";

const delayDelta = 300;

const Start = ({ showHead = true, embeddedMode = false, heightClass = "" }) => {
  const [messages, setMessages] = useState();

  const { user } = useUserContext();
  const dispatch = useConvDispatchContext();

  const { ref, inView, entry } = useInView({
    triggerOnce: true,
    delay: 0,
  });

  useEffect(() => {
    const username = user.info ? user.info.firstName : "";
    const msg = [
      `Hej ${username} och välkommen till Fenix Family!`,
      "Juridik, ekonomi och livsplanering kan upplevas som krångligt. Tillsammans ska vi få det att kännas precis tvärtom - tydligt och enkelt.",
      "För att kunna ge så bra rekommendationer som möjligt behöver vi lära känna varandra lite. Därför kommer vi ställa några frågor om hur ditt liv ser ut just nu, så att vi kan skapa lite lugn och ro inför framtiden. Det tar inte mer än 5 minuter.",
      "Är du beredd?",
    ];
    setMessages(msg);
  }, [user]);

  if (!messages) {
    return;
  }

  return (
    <>
      {showHead && (
        <PageHead
          title={user.info ? `Hej ${user.info.firstName},` : "Hej!"}
          subtitle="nu börjar vi!"
          icon="chat"
        />
      )}

      <div
        className={`shadow-default relative mx-auto flex h-full w-full max-w-[560px] flex-col justify-between rounded-lg lg:w-[500px] ${
          embeddedMode ? heightClass : "mt-5 max-h-[600px] lg:mt-10"
        }`}
        ref={ref}
      >
        {inView && (
          <>
            <div className="flex flex-col gap-4">
              {messages.map((el, index) => (
                <div className="relative" key={index}>
                  <div
                    className="items-conv relative inline-block rounded-[20px] bg-[#E9E3D0] py-2 px-3 text-[14px] leading-tight text-dark-blue"
                    style={{
                      animationDelay: `${parseInt(index) * delayDelta}ms`,
                    }}
                  >
                    <div className="conv-tail-question">
                      <div className="relative">{el}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              className="items-conv"
              style={{
                animationDelay: `${(messages.length + 1) * delayDelta}ms`,
              }}
            >
              <Button
                onClick={() => {
                  if (window.fbq) {
                    window.fbq("trackCustom", "Complete Registration");
                  }
                  dispatch({
                    type: "set-status",
                    payload: {
                      status: "started",
                      accessToken: user.accessToken,
                    },
                  });
                }}
                title="Sätt igång"
                cssClasses="mx-auto mt-10"
                id="conversation-start"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Start;
