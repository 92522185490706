import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

import LoaderIcon from "./LoaderIcon";

export default function LoaderScreen() {
  const elRef = useRef<HTMLDivElement | null>(null);
  if (!elRef.current) {
    elRef.current = document.createElement("div");
  }

  useEffect(() => {
    if (!elRef.current) {
      return;
    }

    const modalRoot = document.getElementById("loading");
    if (modalRoot) {
      modalRoot.appendChild(elRef.current);
      return () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        modalRoot.removeChild(elRef.current);
      };
    }
  }, []);

  return createPortal(
    <div className="fixed top-0 left-0 z-50 flex h-screen w-full items-center justify-center bg-[#FFFEFB]">
      <LoaderIcon theme="dark" />
    </div>,
    elRef.current
  );
}
