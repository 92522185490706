import React, {
  Dispatch,
  forwardRef,
  PropsWithChildren,
  PropsWithRef,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  useConvContext,
  useConvDispatchContext,
} from "../../context/ConversationContext";
import { getArrowIcon } from "../../services/icons";
import { inputValueMask, isFieldValid } from "../../services/utils";
import { ConvItem, ConvOption } from "../../types/conversation";
import { ErrorFields } from "../../types/entities";
import ErrorFormMessage from "../contract/ErrorFormMessage";

type ItemEditProps = {
  item: ConvItem;
  onCloseHandler: () => void;
};

const ItemEdit = ({ item, onCloseHandler }: ItemEditProps) => {
  const [itemData, setItemData] = useState<string[]>([]);
  const [errorFields, setErrorFields] = useState({} as ErrorFields);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { conversation } = useConvContext();
  const dispatch = useConvDispatchContext();

  useEffect(() => {
    const data = conversation.values.find((el) => el.id === item.id);
    if (data) {
      setItemData(data.value);
    }
  }, []);

  const updateData = (val) => {
    const errors = validateData(val);
    if (Object.entries(errors).length > 0) {
      return;
    }

    if (!item.multiple) {
      setItemData([val]);
      return;
    }
    let d = [...itemData];

    if (itemData.includes(val)) {
      d = d.filter((el) => el !== val);
      setItemData(d);
    } else {
      d.push(val);
    }

    setItemData(d);
  };

  const validateData = (val) => {
    const errors = {} as ErrorFields;
    if (item.inputType === "email") {
      errors["email"] = isFieldValid(val, "email");
    }

    const resOut = {} as ErrorFields;
    for (const [k, v] of Object.entries({ ...errorFields, ...errors })) {
      if (v !== true) {
        resOut[k] = v;
      }
    }
    setErrorFields(resOut);
    return resOut;
  };

  const removeError = (key: string) => {
    const errors = { ...errorFields };
    delete errors[key];
    setErrorFields(errors);
  };

  const submitItemData = () => {
    if (Object.entries(errorFields).length > 0) {
      return;
    }
    dispatch({
      type: "update-values-for-item",
      payload: { itemId: item.id, itemValues: itemData },
    });
    onCloseHandler();
  };

  const renderOptions = () => {
    if (!item.options || item.select) {
      return;
    }
    return (
      <div className="flex flex-col gap-1">
        {item.options.map((el) => {
          return (
            <div key={el.label} className="flex items-start gap-3">
              <label
                className="relative flex cursor-pointer items-center rounded-full p-1"
                htmlFor={el.label}
              >
                <input
                  id={el.label}
                  type={item.multiple ? "checkbox" : "radio"}
                  value={el.value}
                  name="default-radio"
                  // defaultChecked={itemData.includes(el.value)}
                  // onClick={!item.multiple ? updateData : undefined}
                  onChange={(e) => updateData(e.target.value)}
                  checked={itemData.includes(el.value)}
                  style={{ WebkitAppearance: "none", MozAppearance: "none" }}
                  className={`before:content[''] peer relative h-6 w-6 cursor-pointer appearance-none rounded-full border border-[#002733] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-6 before:w-6 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:opacity-0 before:transition-opacity checked:border-[#002733] checked:before:bg-[#002733] hover:before:opacity-10`}
                />
                <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-[#002733] opacity-0 transition-opacity peer-checked:opacity-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                  >
                    <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                  </svg>
                </div>
              </label>

              <label
                htmlFor={el.label}
                className={`cursor-pointer select-none self-center pt-[2px] font-internormal text-[14px] leading-tight text-dark-blue`}
              >
                {el.label}
              </label>
            </div>
          );
        })}
      </div>
    );
  };

  const renderInputField = () => {
    if (!item.input) {
      return false;
    }
    const inputValue = conversation.values.find((el) => el.id === item.id);
    return (
      <div
        className={`shadow-default relative mt-0 flex max-w-[300px] flex-row items-center rounded-[16px] border border-[#CFCAB8] ${
          item.inputType === "email" ? "mb-2" : ""
        }`}
      >
        <textarea
          className="grow resize-none border-0 bg-transparent py-2 px-3 text-[14px] text-dark-blue outline-0 placeholder:text-[#CFCAB8] focus:outline-none"
          rows={1}
          onChange={(e) => {
            if (item.inputType === "numbers") {
              e.target.value = inputValueMask(e.currentTarget.value, "numbers");
            }
            if (item.inputType === "email") {
              removeError("email");
            }
            updateData(e.target.value);
          }}
          name="text"
          defaultValue={inputValue && inputValue["value"][0]}
          placeholder={item.inputPlaceholder}
          onKeyDown={(e) => {
            updateData(e.currentTarget.value);
          }}
        />
        {errorFields["email"] ? (
          <div className="absolute bottom-[-24px] left-[10px]">
            <ErrorFormMessage message={errorFields["email"]} />
          </div>
        ) : null}
      </div>
    );
  };

  const renderSelectOption = () => {
    if (!item.select || !item.options) {
      return;
    }

    return (
      <div className="shadow-default relative mt-0 mb-[22px] flex flex-row items-center rounded-[16px] border border-[#CFCAB8] lg:max-w-[70%]">
        <div className="relative w-full">
          <select
            className="item-input w-full grow resize-none appearance-none border-0 bg-transparent py-2.5 px-3 text-[14px] text-dark-blue outline-0 placeholder:text-[#CFCAB8] focus:outline-none"
            style={{ WebkitAppearance: "none", MozAppearance: "none" }}
            onChange={(e) => {
              const v = e.currentTarget.value;
              if (v !== "") {
                updateData(v);
              }
            }}
          >
            {item.options?.map((o) => (
              <option
                key={o.value}
                value={o.value}
                selected={itemData.includes(o.value)}
              >
                {o.label}
              </option>
            ))}
          </select>
          <div className="absolute right-[12px] top-[16px]">
            {getArrowIcon("#CFCAB8")}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-3 rounded-[8px] border-[1px] border-[#CFCAB8] bg-white p-6 px-7">
      <div className="mb-1 font-intermedium text-[16px] text-dark-blue lg:text-[24px]">
        {item.title}
      </div>
      <div className="flex flex-row">
        <div className="grow">
          {renderOptions()}
          {renderInputField()}
          {renderSelectOption()}
        </div>
        <div className="self-end">
          <button
            type="button"
            className="p-1 text-[12px] text-dark-blue underline"
            onClick={submitItemData}
          >
            Spara
          </button>
        </div>
      </div>
    </div>
  );
};

ItemEdit.displayName = "ItemEdit";
export default ItemEdit;
