import React from "react";

import { partners } from "../assets/data/datalist";

export default function PartnersSection() {
  return (
    <>
      <div
        id="partners"
        className={`mt-7 flex flex-wrap items-center justify-center justify-items-center gap-4 lg:grid lg:grid-cols-5 lg:gap-8`}
      >
        {partners.map((partner, index) => (
          <img
            key={index}
            src={partner.logo.href}
            alt={partner.title}
            className="h-auto w-[35%] max-w-full lg:w-full"
            style={{ filter: "grayscale(100%)" }}
            loading="lazy"
          />
        ))}
      </div>
    </>
  );
}
