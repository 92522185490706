import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  useConvContext,
  useConvDispatchContext,
} from "../../context/ConversationContext";
import { useUserContext } from "../../context/UserContext";
import { conversationData } from "../../services/conversation";
import { getBankIdIcon, getRestartIcon } from "../../services/icons";
import { deleteUserDataByKey } from "../../services/ida";
import { conversationKey, removeFromStorage } from "../../services/storage";
import { ConvItem } from "../../types/conversation";
import BankIdLoginButton from "../BankIdLoginButton";
import Button from "../Button";
import PageHead from "../PageHead";
import ItemEdit from "./ItemEdit";

const Summary = ({
  showHead = true,
  embeddedMode = false,
  heightClass = "",
}) => {
  const [editItem, setEditItem] = useState<ConvItem>();
  const { user } = useUserContext();
  const { conversation } = useConvContext();
  const dispatch = useConvDispatchContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (embeddedMode) {
      return;
    }
    window.scrollTo({ top: 0 });
  }, []);

  const getQuestionById = (id) => {
    return conversationData.find((el) => el.id === id);
  };

  return (
    <>
      {showHead && (
        <PageHead
          title="Klart!"
          subtitle="Titta igenom en extra gång så att allt stämmer."
          icon="chat"
        />
      )}

      <div
        className={`mx-auto w-full lg:max-w-4xl ${
          embeddedMode ? "h-full overflow-auto" : "mt-5 lg:mt-20"
        } ${heightClass}`}
      >
        <h1
          className={`mb-5 font-intermedium text-[20px] italic leading-none text-dark-blue lg:mx-8 lg:mb-10 lg:text-[24px]`}
        >
          Stämmer dina svar?
        </h1>

        <div className="mb-10">
          {conversation &&
            conversationData &&
            conversation.values.map((value, ind) => {
              const q = getQuestionById(value.id);
              if (!q) return;
              if (q.type === "line") return;

              const hasDependantQuestions = conversationData.find((el) => {
                if (el.dependencies && el.dependencies.length > 0) {
                  // console.log("dep", value.id, q.title, el);
                  return el.dependencies.find(
                    (d) => d.type === "question" && d.id === value.id
                  );
                }
              });

              if (editItem && editItem.id === value.id) {
                return (
                  <div key={q.id} className="my-5 lg:my-10">
                    <ItemEdit
                      item={q}
                      onCloseHandler={() => setEditItem(undefined)}
                    />
                  </div>
                );
              } else {
                return (
                  <div
                    key={q.id}
                    className={`mb-8 flex flex-row items-center justify-between gap-1 pb-3 lg:mx-8 ${
                      conversation.values.length !== ind + 1
                        ? "border-b-[1px]"
                        : ""
                    }`}
                  >
                    <div>
                      <div className="mb-2 font-intermedium leading-tight text-dark-blue">
                        {q.title}
                      </div>
                      {q.options &&
                        q.options.map((option) => {
                          if (value.value.includes(option.value)) {
                            return (
                              <div
                                key={q.id + option.value}
                                className="font-interlight text-[13px] italic"
                              >
                                {option.label}
                              </div>
                            );
                          }
                        })}
                      {q.input && (
                        <div className="font-interlight text-[13px] italic">
                          {value.value.filter(
                            (v) => !q.options?.find((o) => o.value === v)
                          )}{" "}
                          {q.inputValueSuffix}
                        </div>
                      )}
                    </div>
                    {!hasDependantQuestions && (
                      <div
                        className="cursor-pointer self-end text-[11px] underline"
                        onClick={() => setEditItem(q)}
                      >
                        Ändra
                      </div>
                    )}
                  </div>
                );
              }
            })}
        </div>
        {!embeddedMode && (
          <div className="flex flex-col items-center justify-between gap-4">
            <Button
              id="get-recommendation"
              title="Se din rekommendation"
              onClick={() => {
                dispatch({
                  type: "set-status",
                  payload: {
                    status: "recommendation",
                    accessToken: user.accessToken,
                  },
                });
              }}
            />
            <button
              type="button"
              onClick={() => {
                void deleteUserDataByKey(user.accessToken, "conv")
                  .then(() => {
                    removeFromStorage(conversationKey);
                    dispatch({ type: "clear" });
                  })
                  .finally(() => {
                    setTimeout(() => {
                      navigate("/conversation");
                    }, 300);
                  });
              }}
              className="flex flex-row gap-3 font-intermedium uppercase leading-tight tracking-widest text-dark-blue"
            >
              {getRestartIcon("#0C2632", "16px", "100%")}
              <span className="border-b-[1px] border-dark-blue">
                börja om från början
              </span>
            </button>
          </div>
        )}
      </div>
      {embeddedMode && (
        <>
          <div className="items-conv my-5 flex flex-col items-center justify-center gap-4">
            <div>
              <Button
                id="get-recommendation"
                title="Se din rekommendation"
                iconComp={getBankIdIcon("#FFFEFB", "30", "100%")}
                icon={{ width: -20 }}
                onClick={() => {
                  if (user.info) {
                    navigate("/recommendation");
                  } else {
                    dispatch({
                      type: "set-recommendation-status-public",
                    });
                    navigate("/login");
                  }
                }}
              />
            </div>
            <button
              type="button"
              onClick={() => {
                dispatch({ type: "clear" });
              }}
              className="flex flex-row gap-3 font-intermedium uppercase leading-tight tracking-widest text-dark-blue"
            >
              {getRestartIcon("#0C2632", "16px", "100%")}
              <span className="border-b-[1px] border-dark-blue">
                börja om från början
              </span>
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default Summary;
