import React, { PropsWithChildren, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

import { isDesktopView } from "../services/utils";
import ModalFullScreen from "./ModalFullScreen";
import ModalPopup from "./ModalPopup";

type Props = PropsWithChildren<{
  title: string;
  onClose: () => void;
  titleClass?: string;
  showLogo?: boolean;
  footerComp?: React.ReactElement;
}>;

const Modal = (props: Props) => {
  const elRef = useRef<HTMLDivElement | null>(null);
  if (!elRef.current) {
    elRef.current = document.createElement("div");
  }

  useEffect(() => {
    if (!elRef.current) {
      return;
    }

    const modalRoot = document.getElementById("modal");
    if (modalRoot) {
      modalRoot.appendChild(elRef.current);
      return () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        modalRoot.removeChild(elRef.current);
      };
    }
  }, []);

  const ModalElement = isDesktopView() ? ModalPopup : ModalFullScreen;

  return createPortal(
    <ModalElement {...props}>{props.children}</ModalElement>,
    elRef.current
  );
};

export default Modal;
