const nordeaLogo = new URL("../assets/images/nordea.png", import.meta.url);

export type CardProps = {
  theme?:
    | "blue"
    | "yellow"
    | "brown"
    | "grey"
    | "green"
    | "dark-grey"
    | "dark-blue"
    | "white";
  title: string;
  subtitle?: string;
  rightArrow?: boolean;
  profileView?: boolean;
  profileStatus?: string;
};

export const getCardLogo = (color = "#FFEEC1", logoIcon = false) => {
  if (logoIcon) {
    return (
      <svg
        width="25"
        height="13"
        viewBox="0 0 25 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.2665 3.15898C21.9316 1.127 20.088 0.0759813 17.7995 0.00591316C16.2103 -0.064155 14.7482 0.496392 13.3497 1.26714C11.824 2.17803 10.5526 3.43925 9.40832 4.84061C9.09047 5.26102 8.77264 5.68144 8.39122 6.17191C8.32765 6.10184 8.32764 6.10184 8.26407 6.03177C7.88265 5.61136 7.50124 5.12089 7.05625 4.77055C6.16627 3.92973 5.08559 3.43925 3.87776 3.36919C3.05135 3.29912 2.22494 3.64945 1.52567 4.13993C0.826403 4.70047 0.381417 5.47122 0.127138 6.38211C0.0635678 6.59231 0.0635698 6.80252 0 7.08279V7.85354C0 7.92361 0 7.92361 0 7.99368C0.0635698 8.34402 0.127136 8.69437 0.254275 8.97464C0.953543 10.9365 2.79707 11.9175 4.70416 11.427C5.84841 11.1468 6.80197 10.4461 7.62837 9.60525C7.88265 9.32497 8.13694 9.0447 8.39122 8.76443C9.40833 10.2359 10.6161 11.6372 12.0147 12.7583C12.269 12.3379 12.5232 11.9876 12.7775 11.5672C12.714 11.5672 12.714 11.4971 12.714 11.4971C11.379 10.4461 10.2983 9.11477 9.34476 7.64334C9.28119 7.57327 9.28119 7.57327 9.28119 7.5032C9.28119 7.43313 9.28119 7.43314 9.34476 7.36307C9.98046 6.31205 10.7433 5.40116 11.6333 4.56034C12.5868 3.57939 13.6675 2.73856 14.8753 2.17802C16.0196 1.68754 17.1638 1.40727 18.3717 1.54741C20.0245 1.75761 21.3594 2.6685 22.2494 4.21C23.4572 6.31205 23.4573 8.41408 22.313 10.5862C22.1223 11.0066 21.8044 11.357 21.5501 11.7073L22.5037 12.6882C22.5673 12.6182 22.6944 12.478 22.8215 12.3379C23.8387 11.1467 24.4108 9.60525 24.4744 7.92362C24.6015 6.24198 24.2201 4.56034 23.2665 3.15898ZM5.02201 9.81545C4.32274 10.0957 3.62348 10.1658 2.92421 9.88552C1.39854 9.2549 0.953545 7.08279 2.03423 5.7515C2.7335 4.84061 3.68704 4.70047 4.70416 4.98074C5.467 5.19095 6.16627 5.68143 6.7384 6.31205C7.05625 6.66239 7.3741 7.08279 7.75552 7.5032C6.92911 8.48416 6.16626 9.39504 5.02201 9.81545Z"
          fill={`${color}`}
        />
      </svg>
    );
  }
  return (
    <svg
      width="104"
      height="13"
      viewBox="0 0 104 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2665 3.15898C21.9316 1.127 20.088 0.0759813 17.7995 0.00591316C16.2103 -0.064155 14.7482 0.496392 13.3497 1.26714C11.824 2.17803 10.5526 3.43925 9.40832 4.84061C9.09047 5.26102 8.77264 5.68144 8.39122 6.17191C8.32765 6.10184 8.32764 6.10184 8.26407 6.03177C7.88265 5.61136 7.50124 5.12089 7.05625 4.77055C6.16627 3.92973 5.08559 3.43925 3.87776 3.36919C3.05135 3.29912 2.22494 3.64945 1.52567 4.13993C0.826403 4.70047 0.381417 5.47122 0.127138 6.38211C0.0635678 6.59231 0.0635698 6.80252 0 7.08279V7.85354C0 7.92361 0 7.92361 0 7.99368C0.0635698 8.34402 0.127136 8.69437 0.254275 8.97464C0.953543 10.9365 2.79707 11.9175 4.70416 11.427C5.84841 11.1468 6.80197 10.4461 7.62837 9.60525C7.88265 9.32497 8.13694 9.0447 8.39122 8.76443C9.40833 10.2359 10.6161 11.6372 12.0147 12.7583C12.269 12.3379 12.5232 11.9876 12.7775 11.5672C12.714 11.5672 12.714 11.4971 12.714 11.4971C11.379 10.4461 10.2983 9.11477 9.34476 7.64334C9.28119 7.57327 9.28119 7.57327 9.28119 7.5032C9.28119 7.43313 9.28119 7.43314 9.34476 7.36307C9.98046 6.31205 10.7433 5.40116 11.6333 4.56034C12.5868 3.57939 13.6675 2.73856 14.8753 2.17802C16.0196 1.68754 17.1638 1.40727 18.3717 1.54741C20.0245 1.75761 21.3594 2.6685 22.2494 4.21C23.4572 6.31205 23.4573 8.41408 22.313 10.5862C22.1223 11.0066 21.8044 11.357 21.5501 11.7073L22.5037 12.6882C22.5673 12.6182 22.6944 12.478 22.8215 12.3379C23.8387 11.1467 24.4108 9.60525 24.4744 7.92362C24.6015 6.24198 24.2201 4.56034 23.2665 3.15898ZM5.02201 9.81545C4.32274 10.0957 3.62348 10.1658 2.92421 9.88552C1.39854 9.2549 0.953545 7.08279 2.03423 5.7515C2.7335 4.84061 3.68704 4.70047 4.70416 4.98074C5.467 5.19095 6.16627 5.68143 6.7384 6.31205C7.05625 6.66239 7.3741 7.08279 7.75552 7.5032C6.92911 8.48416 6.16626 9.39504 5.02201 9.81545Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.4375 0.00585938H40.2395V1.47728H34.836V5.61131H39.9216V7.08274H34.836V12.6882H33.5011V0.00585938H33.4375Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.0781 0.00585938H55.8801V1.47728H50.4766V5.61131H55.5622V7.08274H50.4766V11.2168H56.0708V12.6882H49.1417V0.00585938H49.0781Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.5042 0.00585938V12.6882H73.2964L66.3037 2.66845V12.6882H64.9688V0.00585938H66.1766L73.1692 10.0256V0.00585938H74.5042Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.7412 0.00585938V12.6882H83.4062V0.00585938H84.7412Z"
        fill={color}
      />
      <path
        d="M97.9623 6.38206L93.7031 0.00585938H95.4195L98.8523 5.12084L102.285 0.00585938H104.001L99.7423 6.38206L104.001 12.6882H102.285L98.9159 7.64329L95.5466 12.6882H93.8303L97.9623 6.38206Z"
        fill={color}
      />
    </svg>
  );
};
export const getCardIcon = (color = "#FFEEC1") => {
  return (
    <div>
      <svg
        width="6"
        height="10"
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 0.75L5 4.98529L1 8.75"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export const RecommendationCard = ({
  theme,
  title,
  subtitle,
  rightArrow,
  profileView,
  profileStatus,
}: CardProps) => {
  let gradient = {
    background: "linear-gradient(113.53deg, #547CA0 -23.45%, #102B38 88.93%)",
  };
  let logo = getCardLogo("#FFEEC1", profileView);
  let icon = getCardIcon();
  let titleClasses = "text-[#FFEEC1]";
  let borderClasses = "border-[#FFEEC1]";

  if (theme === "white") {
    gradient = {
      background: "white",
    };
    logo = getCardLogo("#0C2632", profileView);
    icon = getCardIcon("#0C2632");
    titleClasses = "text-[#0C2632]";
    borderClasses = "border-[#CFCAB8]";
  }

  if (theme === "yellow") {
    gradient = {
      background: "linear-gradient(113.53deg, #FFECB5 -23.45%, #F5C317 88.93%)",
    };
    logo = getCardLogo("#0C2632", profileView);
    icon = getCardIcon("#0C2632");
    titleClasses = "text-[#0C2632]";
    borderClasses = "border-[#0C2632]";
  }

  if (theme === "blue") {
    gradient = {
      background: "#0000A0",
    };
    // logo = null;
    logo = (
      <img src={nordeaLogo.href} width={84} style={{ height: "min-content" }} />
    );
    titleClasses = "text-white";
    borderClasses = "border-white";
  }

  if (theme === "brown") {
    gradient = {
      background: "linear-gradient(113.53deg, #C3A2A1 -23.45%, #43262D 88.93%)",
    };
  }

  if (theme === "grey") {
    gradient = {
      background: "linear-gradient(113.53deg, #FDF8EB -23.45%, #CECABA 88.93%)",
    };
    logo = getCardLogo("#0C2632", profileView);
    icon = getCardIcon("#0C2632");
    titleClasses = "text-[#0C2632]";
    borderClasses = "border-[#0C2632]";
  }

  if (theme === "green") {
    gradient = {
      background: "linear-gradient(113.53deg, #C3E8CE -23.45%, #6A8C75 88.93%)",
    };
    logo = getCardLogo("#0C2632", profileView);
    icon = getCardIcon("#0C2632");
    titleClasses = "text-[#0C2632]";
    borderClasses = "border-[#0C2632]";
  }

  if (theme === "dark-grey") {
    gradient = {
      background:
        "linear-gradient(108.91deg, #FFFEFB -70.11%, #0C2632 119.65%)",
    };
    logo = getCardLogo("#FFFFFFFF", profileView);
    icon = getCardIcon("#FFFFFFFF");
    titleClasses = "text-white";
    borderClasses = "border-white";
  }

  return (
    <div
      className={`flex w-full flex-col rounded-[8px] px-4 py-4 ${
        profileView && theme === "white" ? `border-[1px] ${borderClasses}` : ""
      }`}
      style={gradient}
    >
      {profileView ? (
        <div>
          <div className="flex flex-row items-center justify-between gap-3">
            <div className="flex flex-row gap-3">
              {logo}
              <div
                className={`${titleClasses} font-intermedium text-[14px] uppercase leading-none tracking-[0.3em]`}
              >
                {title}
                {subtitle ? (
                  <span className="text-[10px] tracking-wide">
                    ({subtitle})
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            {rightArrow && icon}
          </div>
          <hr className={`mt-2.5 mb-2 ${borderClasses}`} />
          <div
            className={`${titleClasses} font-interlight text-[12px] leading-none`}
          >
            {profileStatus}
          </div>
        </div>
      ) : (
        <div className="flex flex-row items-center justify-between gap-3">
          <div className="flex flex-col gap-3">
            {logo}
            <div
              className={`${titleClasses} font-intermedium text-[14px] uppercase leading-none tracking-[0.3em]`}
            >
              {title}
              {subtitle ? (
                <span className="text-[10px] tracking-wide">({subtitle})</span>
              ) : (
                ""
              )}
            </div>
          </div>
          {rightArrow && icon}
        </div>
      )}
    </div>
  );
};
