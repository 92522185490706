import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { faqPartners } from "../assets/data/datalist";
import Accordion from "../components/Accordion";
import ConversationEmbedded from "../components/ConversationEmbedded";
import Footer from "../components/Footer";
import HeadMenu from "../components/HeadMenu";
import PartnersSection from "../components/PartnersSection";
import ReviewsSlider from "../components/slider/ReviewsSlider";
import TrustBox from "../components/TrustBox";
import VideoItem from "../components/VideoItem";
import { ServiceSectionProvider } from "../context/ServiceSectionContext";
import { useMediaQuery } from "../services/hooks/useMediaQuery";
import {
  getBankIdIcon,
  getButtonArrow,
  getChatIcon,
  getQuoteIcon,
  getShortDownArrowIcon,
} from "../services/icons";
import { useScrollPosition } from "../services/utils";

const aboutUsImg = new URL("../assets/images/about_Fenix.jpg", import.meta.url);

const gifThumb = new URL(
  "../assets/video/image_thumb_tn.jpg",
  import.meta.url
);
const gifThumb2 = new URL(
  "../assets/images/partner_dashboard.jpg",
  import.meta.url
);

const video =
  "https://static.fenixbegravning.se/fenix_family_bank_partner_case.mp4";
const videoThumb = new URL(
  "../assets/video/fenix_partners.jpg",
  import.meta.url
);

export default function PartnerPage({ title }: { title?: string }) {
  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }
  const [showConversationModal, setShowConversationModal] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    document.title = title!;
    document.body.classList.add("partner");
  }, []);

  useEffect(() => {
    if (showConversationModal) {
      setShowConversationModal(false);
    }
  }, [showConversationModal]);

  const renderLoginButton = (
    buttonClass: string,
    iconColor: string,
    showArrow?: true
  ) => {
    return (
      <Link
        to="/login"
        className={
          `inline-flex items-center gap-2 rounded border-[1px] border-deep-blue bg-deep-blue py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] lg:gap-3 lg:self-end ` +
          buttonClass
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover
          ? getBankIdIcon(iconColor, "20", "100%")
          : getBankIdIcon("#fff", "20", "100%")}
        Kom igång
        {showArrow
          ? isHover
            ? getButtonArrow("#fff")
            : getButtonArrow(iconColor)
          : ""}
      </Link>
    );
  };

  return (
    <ServiceSectionProvider>
      <div className="relative overflow-hidden bg-[#FFFEFB]">
        <div
          className={classNames(
            scrollPosition > 84 ? "bg-white" : "bg-transparent",
            "fixed z-1000 w-full transition-colors"
          )}
        >
          <HeadMenu
            sticky={scrollPosition > 84}
            theme={"dark"}
            color={scrollPosition > 84 ? "#0C2632" : "#0C2632"}
            loginTitle={"Logga in"}
            loginUrl={"https://partnerapp.fenixfamily.se/ "}
          />
        </div>
        <div className="relative w-full text-white">
          <div className="flex flex-col pt-24 lg:flex-row lg:gap-5 lg:pt-0">
            <div className="w-full lg:max-w-[50%]">
              <img
                src={aboutUsImg.href}
                alt="Fenix"
                className="relative h-auto w-[100%] max-w-[85%] rounded-tr-[13px] rounded-br-[13px] object-cover object-right-top lg:h-full lg:max-h-[640px] lg:max-w-[100%] lg:rounded-l-none lg:rounded-tr-[0] lg:rounded-br-[24px] 2k:max-h-[1000px] 4k:max-h-[1200px]"
                loading="lazy"
              />
            </div>
            <div className="w-full lg:max-w-[50%]">
              <div className="flex h-full flex-col items-start justify-end pb-10 pr-5 pl-5 pt-10 text-deep-blue lg:pt-0">
                <p className="mb-2 text-xs uppercase tracking-[0.2em]">
                  Fenix Family Livsplanering
                </p>
                <h1 className="mb-9 max-w-[630px] font-interblack text-[28px] italic leading-[31px] lg:mb-0 lg:text-[50px] lg:leading-[54px] xl:text-[64px] xl:leading-[74px]">
                  Sinnesro för era kunder och tillväxt för er
                </h1>
                <Link
                  className="mt-8 flex min-w-[260px] justify-center space-x-3 rounded-[4px] border-[#0C2632] bg-deep-blue px-2 py-4 font-internormal text-[14px] font-semibold uppercase tracking-[0.12em] text-[#fff] hover:border-[#6F86A0] hover:bg-[#6F86A0] lg:gap-3 lg:space-x-1 lg:px-5 lg:py-[16px] lg:pb-4"
                  to="#"
                  onClick={() =>
                    (window.location =
                      "mailto:pre-need@fenixfamily.se?subject=Bli partner med Fenix Family&body=Hej,%0D%0A%0D%0AJag är intresserad av att veta mer om ett framtida partnerskap med Fenix Family och önskar att ni kontaktar mig på denna mailadress inom kort.%0D%0A%0D%0ATack på förhand!%0D%0A%0D%0AMed vänlig hälsning,")
                  }
                >
                  {getChatIcon("#FFFEFB", "18px", "18px")}{" "}
                  <span>Bli vår nya partner</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full bg-white text-deep-blue">
          <div className="mx-auto px-5 pb-16 pt-6 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-4 lg:pt-28 xl:max-w-[1360px]">
            <div id="tidslinje" className="max-w-[950px]">
              <h3 className="mb-3 font-internormal text-base font-semibold uppercase tracking-[0.25em]">
                Tillsammans kan vi hjälpa era kunder
              </h3>
              <p className="mb-5 whitespace-pre-wrap font-interlight text-base leading-[22px]">
                Livsplanering innefattar kombinationen av era finansiella
                tjänster och de juridiska avtal vi tillhandahåller. Detta
                möjliggör för era kunder att få hjälp med allt inom sparande,
                försäkringar och juridik på ett holistiskt och okomplicerat
                sätt. Allt sammanställs till en trygg livsplan så att era kunder
                kan njuta av lugn och ro här och nu, det underlättar även
                framtiden för deras närmaste. Du som partner får samtidigt
                tillgång till den data du behöver för att förvalta och ge
                anpassade rekommendationer över generationer.{" "}
              </p>
              <span className="mt-3 inline-flex items-center font-internormal text-[14px] font-semibold uppercase tracking-[0.12em] lg:mt-0">
                Ta reda på hur Livsplanering fungerar{" "}
                {getShortDownArrowIcon("#0C2632")}
              </span>
            </div>
          </div>
        </div>
        <div className="relative w-full bg-[#fff] text-deep-blue">
          <div className="mx-auto px-5 pt-16 pb-10 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-20 lg:pt-20 xl:max-w-[1360px]">
            <div className="flex flex-col gap-3 lg:flex-row lg:items-end lg:justify-between">
              <div className="w-full max-w-[760px]">
                <img
                  src={gifThumb.href}
                  alt="conversation flow"
                  className="min-h-[510px] object-cover drop-shadow-[4px_2px_35px_0px_rgba(0,0,0,0.08)]"
                  loading="lazy"
                />
              </div>
              <div className="max-w-[530px]">
                <h3 className="mb-3 mt-10 font-interblack text-[22px] font-extrabold italic leading-[22px] lg:text-[36px] lg:leading-[40px]">
                  Ett snabbt, tryggt och enkelt sätt att reda ut svåra saker.
                </h3>
                <p className="font-interlight text-base leading-[22px]">
                  Korrekt skrivna avtal för livets olika skeden kan bespara dig
                  och din familj mycket besvär. I vår värld är de här sakerna inte
                  heller något dyrt eller krångligt, utan går nästan lika snabbt
                  och enkelt som att dricka en kopp kaffe.
                </p>
                {/*<Button*/}
                {/*  cssClasses="!px-0 py-4 mt-0 bg-transparent border-transparent hover:underline !text-deep-blue justify-center !text-[14px] lg:px-0 lg:mt-0 lg:py-[10px]"*/}
                {/*  rightArrow={false}*/}
                {/*  title={"Läs mer"}*/}
                {/*  onClick={() => "https://www.fenixfamily.se/om-oss"}*/}
                {/*>*/}
                {/*  {getButtonArrow("#0C2632", "20", "100%")}*/}
                {/*</Button>*/}
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full text-deep-blue">
          <div className="mx-auto px-5 pb-14 pt-20 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pt-20 xl:max-w-[1360px]">
            <div className="ml-auto flex flex-col items-center justify-between gap-5 lg:flex-row lg:gap-10">
              <div id="behovsanalys" className="max-w-[530px]">
                <h2 className="mb-5 font-interblack text-[28px] italic leading-[31px]  lg:text-[48px] lg:leading-[53px]">
                  Ditt egna kundvårdssystem
                </h2>
                <p className="font-interlight text-base leading-[22px]">
                  Från vår portal kan du enkelt bjuda in dina kunder att påbörja
                  sin livsplanering. Här finns en tydlig översikt där du steg
                  för steg kan följa dina kunder i processen.
                  <br /> Vi vägleder dig i varje steg fram till att dina kunder
                  har skapat de juridiska avtalen vi rekommenderat och givit en
                  uppdaterad bild av sin livssituation. Efter att ha samlat in
                  kundens samtycke delas den information ni behöver för att ge
                  relevanta råd inom sparande och försäkringar.
                </p>
                <div className="mt-10 mb-10 inline-block lg:mb-0">
                  <Link
                    className="min-w-[260px] justify-center rounded-[4px] border-[#0C2632] bg-deep-blue px-5 py-4 font-internormal text-[14px] font-semibold uppercase tracking-[0.12em] text-[#fff] hover:border-[#6F86A0] hover:bg-[#6F86A0] lg:gap-3 lg:p-4 lg:px-4 lg:py-[14px]"
                    to="#"
                    onClick={() =>
                      (window.location =
                        "mailto:pre-need@fenixfamily.se?subject=Bli partner med Fenix Family&body=Hej,%0D%0A%0D%0AJag är intresserad av att veta mer om ett framtida partnerskap med Fenix Family och önskar att ni kontaktar mig på denna mailadress inom kort.%0D%0A%0D%0ATack på förhand!%0D%0A%0D%0AMed vänlig hälsning,")
                    }
                  >
                    Hör av dig så berättar vi mer
                  </Link>
                </div>
              </div>
              <div className="max-w-[700px]">
                <img
                  src={gifThumb2.href}
                  alt="conversation flow"
                  className="object-cover drop-shadow-[4px_2px_35px_0px_rgba(0,0,0,0.08)]"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full bg-[#F9F7F1] text-deep-blue">
          <div className="mx-auto px-5 pt-10 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pt-20 lg:pb-8 xl:max-w-[1360px]">
            <div className="mx-auto max-w-[870px]">
              <h2 className="text-center font-interblack text-[28px] italic leading-[2.7rem] text-deep-blue xl:text-[48px] xl:leading-[4.5rem]">
                FAQ
              </h2>
            </div>
            <div className="mx-auto mt-3 max-w-[870px] pt-5 pb-16 lg:pb-16">
              <Accordion items={faqPartners} />
            </div>
          </div>
        </div>
        <div className="relative w-full bg-white text-deep-blue">
          <div className="mx-auto flex flex-col items-center px-5 pt-28 pb-16 sm:max-w-[640px] md:max-w-[700px] lg:max-w-[1000px] lg:pb-36 lg:pt-24 xl:max-w-[1000px]">
            <h2 className="text-center font-intermedium text-base font-semibold uppercase tracking-[0.25em]">
              Partners
            </h2>
            <PartnersSection />
            <ReviewsSlider />
            <Link
              className="mt-8 flex min-w-[260px] justify-center space-x-3 rounded-[4px] border-[#0C2632] bg-deep-blue px-2 py-4 font-internormal text-[14px] font-semibold uppercase tracking-[0.12em] text-[#fff] hover:border-[#6F86A0] hover:bg-[#6F86A0] lg:gap-3 lg:space-x-1 lg:px-5 lg:py-[16px] lg:pb-4"
              to="#"
              onClick={() =>
                (window.location =
                  "mailto:pre-need@fenixfamily.se?subject=Bli partner med Fenix Family&body=Hej,%0D%0A%0D%0AJag är intresserad av att veta mer om ett framtida partnerskap med Fenix Family och önskar att ni kontaktar mig på denna mailadress inom kort.%0D%0A%0D%0ATack på förhand!%0D%0A%0D%0AMed vänlig hälsning,")
              }
            >
              {getChatIcon("#FFFEFB", "18px", "18px")}{" "}
              <span>Bli vår nya partner</span>
            </Link>
          </div>
        </div>
        <div className="relative w-full bg-deep-blue text-white">
          <div className="mx-auto px-5 pt-16 pb-20 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-24 lg:pt-24 xl:max-w-[1360px]">
            <div className="quote-block relative max-w-[970px] rounded-[3px] pt-10 pb-8 lg:pb-12 lg:pt-20">
              <span className="quote-icon absolute -left-0.5 top-5 opacity-40 lg:-left-3 lg:top-10">
                {getQuoteIcon(
                  "#CECABA",
                  isDesktop ? "91" : "48px",
                  isDesktop ? "76" : "40px"
                )}
              </span>
              <div className="z-1 relative font-interlight text-[24px] italic leading-[29px] lg:text-[42px] lg:leading-[50.4px]">
                Jag är mycket nöjd med Fenix Familys tjänst. Du får hjälp
                snabbt. Det är lätt att hitta den information du letar efter. Du
                känner dig trygg hela vägen."
                <div className="mt-10 flex flex-col items-start lg:flex-row lg:items-end">
                  {renderLoginButton(
                    `  !py-2.5 not-italic !border-[#fff] hover:border-[#6F86A0] hover:bg-[#6F86A0]`,
                    `#fff`,
                    true
                  )}
                  <div className="-ml-4 mt-12 lg:ml-10 lg:mt-0">
                    <TrustBox
                      template={"5419b732fbfb950b10de65e5"}
                      height={"24px"}
                      theme={"dark"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ConversationEmbedded
        showInline={false}
        showModalOverride={showConversationModal}
      />
    </ServiceSectionProvider>
  );
}
