import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.parcel2";

import { isDesktopView } from "../services/utils";

export default function PdfPreview({ file, hidePreview }) {
  const [numPages, setNumPages] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      {file && (
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={!isDesktopView() ? window.innerWidth - 30 : undefined}
              className={`bg-green-50 ${
                hidePreview
                  ? " after:z-100 w-full after:absolute after:bottom-0 after:left-0 after:h-full after:w-full after:shadow-[inset_-5px_-25rem_35px_-20px_#fff] after:content-[''] after:lg:shadow-[inset_-5px_-43rem_35px_-20px_#fff]"
                  : "drop-shadow-xl"
              }`}
            />
          ))}
        </Document>
      )}
    </>
  );
}
